import React, { useEffect, useState, useRef } from "react";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline";
import isEmpty from "../../../components/isEmpty";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import antennaSVG from "./antennaSVG";

import {
  Select,
  CaretIcon,
  MultiSelectOptionMarkup,
  ModalCloseButton,
} from "react-responsive-select";
// for default styles...
import "react-responsive-select/dist/react-responsive-select.css";

import "./RadioTimeline.css";

var keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "group",
  itemTimeStartKey: "start",
  itemTimeEndKey: "end",
  groupLabelKey: "title",
};

//RadioTimeline React Class
function RadioTimeline(props) {
  //UI

  //filter events
  function handleChangeModel(currOptions) {
    let currOptionsValues = currOptions.options.map(function (item) {
      return item.value;
    });
    props.filter_radio_groups(currOptionsValues);
  }

  function reloadTimeLineData() {
    props.reloadTimeLineData();
  }

  function randomPodcast() {
    props.randomPodcast();
  }

  function randomWorldBroadcast() {
    props.randomWorldBroadcast();
  }

  function randomSongDropBroadcast() {
    props.randomSongDropBroadcast();
  }

  //VIEW
  return (
    <div>
      {props.isApiWorking && (
        <Timeline
          stackItems
          groups={[]}
          items={[]}
          visibleTimeStart={props.visible_start_time}
          visibleTimeEnd={props.visible_end_time}
          defaultTimeStart={props.start_time}
          defaultTimeEnd={props.end_time}
          onItemSelect={onItemSelect}
          sidebarWidth={props.sidebarWidth}
          itemRenderer={itemRenderer}
        >
          <TimelineHeaders style={{ backgroundColor: "#0b68fd" }}>
            <SidebarHeader>
              {({ getRootProps }) => {
                return (
                  <div {...getRootProps()}>
                    <div className="radio_">
                      <button onClick={randomSongDropBroadcast}>
                        <i className="fa fa-calendar-check-o" />
                      </button>
                      <button onClick={randomWorldBroadcast}>
                        <i className="fa fa-globe" />
                      </button>
                      <button onClick={randomPodcast}>
                        <i className="fa fa-podcast" />
                      </button>
                      <button onClick={reloadTimeLineData}>
                        <i className="fa fa-refresh spin-it1" />
                      </button>
                    </div>
                    {props.sidebarWidth > 0 && (
                      <div className="radio_select">
                        <form>
                          <Select
                            disabled={true}
                            className="select_multi"
                            multiselect={true}
                            name="select_station"
                            selectedValues={props.selected_radio_groups}
                            modalCloseButton={<ModalCloseButton />}
                            options={selectedGroups()}
                            caretIcon={<CaretIcon />}
                            onChange={(e) => handleChangeModel(e)}
                            onSubmit={() => console.log("onSubmit")}
                          />
                        </form>
                      </div>
                    )}
                  </div>
                );
              }}
            </SidebarHeader>

            <DateHeader
              unit="primaryHeader"
              style={{ backgroundColor: "#0b68fd" }}
            />
            <DateHeader />
          </TimelineHeaders>
        </Timeline>
      )}
      {!props.isApiWorking &&
        !isEmpty(props.radio_groups) &&
        !isEmpty(props.radio_artists) && (
          <Timeline
            stackItems
            groups={selectedRadioGroups()}
            items={selectedRadioArtists()}
            visibleTimeStart={props.visible_start_time}
            visibleTimeEnd={props.visible_end_time}
            defaultTimeStart={props.start_time}
            defaultTimeEnd={props.end_time}
            onItemSelect={onItemSelect}
            sidebarWidth={props.sidebarWidth}
            itemRenderer={itemRenderer}
          >
            <TimelineHeaders style={{ backgroundColor: "#0b68fd" }}>
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <div {...getRootProps()}>
                      <div className="radio_">
                        <button onClick={randomSongDropBroadcast}>
                          <i className="fa fa-calendar-check-o" />
                        </button>
                        <button onClick={randomWorldBroadcast}>
                          <i className="fa fa-globe" />
                        </button>
                        <button onClick={randomPodcast}>
                          <i className="fa fa-podcast" />
                        </button>
                        <button onClick={reloadTimeLineData}>
                          <i className="fa fa-refresh" />
                        </button>
                      </div>{" "}
                      {props.sidebarWidth > 0 && (
                        <div className="radio_select">
                          <form>
                            <Select
                              className="select_multi"
                              multiselect={true}
                              name="select_station"
                              selectedValues={props.selected_radio_groups}
                              modalCloseButton={<ModalCloseButton />}
                              options={selectedGroups()}
                              caretIcon={<CaretIcon />}
                              onChange={(e) => handleChangeModel(e)}
                              onSubmit={() => console.log("onSubmit")}
                            />
                          </form>
                        </div>
                      )}
                    </div>
                  );
                }}
              </SidebarHeader>

              <DateHeader
                unit="primaryHeader"
                style={{ backgroundColor: "#0b68fd" }}
              />
              <DateHeader />
            </TimelineHeaders>
          </Timeline>
        )}
      {props.isApiWorking && (
        <div className="radio_loading_container">
          <div className="radio_loading">
            <div className="radio_loading_icon">{antennaSVG()}</div>
            <div className="radio_loading_text">Loading...</div>
          </div>
        </div>
      )}
    </div>
  );

  function onItemSelect(itemId, e, time) {
    props.selected_radio_artist(itemId);
  }

  function selectedGroups() {
    var tableData = [
      {
        value: "All",
        text: "All",
        markup: <MultiSelectOptionMarkup text="All" />,
      },
    ];
    if (isEmpty(props.radio_groups)) {
      return tableData;
    }

    props.radio_groups.forEach((_data, index) => {
      const tableRow = {
        value: `${_data.title}`,
        text: `${_data.title}`,
        markup: <MultiSelectOptionMarkup text={`${_data.title}`} />,
      };
      tableData.push(tableRow);
    });

    return tableData;
  }

  function selectedRadioGroups() {
    var tableData = [];
    if (isEmpty(props.radio_groups)) {
      return tableData;
    }
    var groups_to_show = props.radio_groups;
    if (!isEmpty(props.selected_radio_groups)) {
      groups_to_show = groups_to_show.filter((group) =>
        props.selected_radio_groups.includes(group.title)
      );
    }

    if (
      !isEmpty(props.selected_radio_groups) &&
      props.selected_radio_groups[0] == "All"
    ) {
      groups_to_show = props.radio_groups;
    }

    groups_to_show.forEach((_data, index) => {
      const updatedGroupObj = Object.assign({}, _data, {
        title: (
          <div
            className="radio_group_row"
            onClick={() => playRadiosFromGroup(_data.id)}
          >
            {props.selected_group_id === _data.id ? "▶︎ " : ""}
            {_data.title}
          </div>
        ),
      });
      tableData.push(updatedGroupObj);
    });

    return tableData;
  }

  //PLAY_RANDOM_FROM_GROUP_ID
  function playRadiosFromGroup(group_id) {
    props.playRadiosFromGroup(group_id);
  }

  function selectedRadioArtists() {
    var tableData = [];
    if (isEmpty(props.radio_artists)) {
      return tableData;
    }
    props.radio_artists.forEach((_data, index) => {
      tableData.push(_data);
    });

    return tableData;
  }

  //STYLING
  function itemRenderer({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = itemContext.selected
      ? itemContext.dragging
        ? "red"
        : item.selectedBgColor
      : item.bgColor;
    const borderColor = itemContext.resizing ? "red" : item.color;
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color: item.color,
            borderColor,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1,
          },
          onMouseDown: () => {
            // console.log("on item click", item);
          },
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  }
}

export default RadioTimeline;
