import React, { useState, useEffect } from "react";
import isEmpty from "../../../components/isEmpty";
import { revalidateIAP_Apple } from "../../../utils/apis/api/auth_api_iap";

//CSS
import "./IAP_Revalidate.css";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//IAPRevalidate class
function IAP_Revalidate(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //ViewDidLoad
  useEffect(() => {}, []);

  //validateData
  function validateData(e) {
    e.preventDefault();
    //
    // props.validateData();
    revalidateAppleIAPData();
  }

  function checkValidationFieldsAreCorrect() {
    const formData = {
      user_id: props.user.user_id,
      track_id: props.track.track_id,
      album_id: props.track.album_id,
      discovered_from: props.iap_json.discovered_from,
      transaction_source: props.iap_json.transaction_source,
      payment_method: props.iap_json.payment_method,
      product_id: props.iap_json.receipt.in_app.product_id,
      transaction_id: props.iap_json.receipt.in_app.transaction_id,
      original_transaction_id:
        props.iap_json.receipt.in_app.original_transaction_id,
      purchased_at: props.iap_json.receipt.in_app.purchased_at,
    };
  }

  function isDataValid() {
    //Apple Receipt
    var error_msg = null;
    console.log("props.iap_json", props.iap_json);
    if (isEmpty(props.iap_json)) {
      return "Apple Receipt is missing";
    }
    if (isEmpty(props.iap_json.discovered_from)) {
      return `Apple Receipt "discovered_from" is missing`;
    }
    if (isEmpty(props.iap_json.transaction_source)) {
      return `Apple Receipt "transaction_source" is missing`;
    }
    if (isEmpty(props.iap_json.payment_method)) {
      return `Apple Receipt "payment_method" is missing`;
    }
    if (isEmpty(props.iap_json.receipt.in_app)) {
      return `Apple Receipt "in_app" is missing`;
    }
    if (isEmpty(props.iap_json.receipt.in_app.product_id)) {
      return `Apple Receipt "product_id" is missing`;
    }
    if (isEmpty(props.iap_json.receipt.in_app.transaction_id)) {
      return `Apple Receipt "transaction_id" is missing`;
    }
    if (isEmpty(props.iap_json.receipt.in_app.original_transaction_id)) {
      return `Apple Receipt "original_transaction_id" is missing`;
    }
    if (isEmpty(props.iap_json.receipt.in_app.purchased_at)) {
      return `Apple Receipt "purchased_at" is missing`;
    }
    //USER
    if (isEmpty(props.user)) {
      return `Buyer user is missing`;
    }
    if (isEmpty(props.user.user_id)) {
      return `Buyer user "user_id" is missing`;
    }
    //track
    if (isEmpty(props.track)) {
      return `Buyer user's track is missing`;
    }
    if (isEmpty(props.track.track_id)) {
      return `Buyer user's "track_id" is missing`;
    }
    if (isEmpty(props.track.album_id)) {
      return `Buyer user's "album_id" is missing`;
    }
    return null;
  }

  //CALL AGAIN TO PURCHASE
  async function revalidateAppleIAPData() {
    //
    setApiWorking(true);
    setApiError("");
    //
    const validFormError = isDataValid();
    if (!isEmpty(validFormError)) {
      alert(validFormError);
      setApiWorking(false);
      setApiError("");
      return;
    }

    const receipt = {
      receipt: {
        in_app: {
          product_id: props.iap_json.receipt.in_app.product_id,
          transaction_id: props.iap_json.receipt.in_app.transaction_id,
          original_transaction_id:
            props.iap_json.receipt.in_app.original_transaction_id,
          purchased_at: props.iap_json.receipt.in_app.purchased_at,
        },
      },
    };

    const formData = {
      user_id: props.user.user_id,
      track_id: props.track.track_id,
      album_id: props.track.album_id,
      discovered_from: props.iap_json.discovered_from,
      transaction_source: props.iap_json.transaction_source,
      payment_method: props.iap_json.payment_method,
      receipt: JSON.stringify(receipt),
    };
    //
    try {
      //get user details
      var queryParams = [];
      queryParams.push(`token=${props.token}`);
      for (const [key, value] of Object.entries(formData)) {
        queryParams.push(`${key}=${value}`);
      }
      const qParameters = queryParams.join("&");
      //   console.log(bParameters);
      //   return;
      //✅SUCCESS
      let result = await revalidateIAP_Apple(qParameters);
      console.log(result);

      //set_iap_db_items(result["receipt"]);
      //console.log("RESULT", result);
      setApiWorking(false);
      //🚫ERROR
    } catch (error) {
      console.log(error.message);
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //VIEW
  return (
    <div className="iap_revalidate">
      {!isEmpty(props.track) && (
        <div>
          <div>Title: {props.track.title}</div>
          <div>Artist: {props.track.artist}</div>
          <div>Album: {props.track.album_title}</div>
          <div>
            <strong>
              <i className="fa fa-check-circle" /> track_id:{" "}
            </strong>{" "}
            {props.track.track_id}
          </div>
          <div>
            <strong>
              <i className="fa fa-check-circle" /> album_id:{" "}
            </strong>{" "}
            {props.track.album_id}
          </div>
        </div>
      )}
      {!isEmpty(props.user) && (
        <div>
          <div>Buyer Name: {props.user.name}</div>
          <div>Buyer Username: {props.user.username}</div>
          <div>
            <strong>
              <i className="fa fa-check-circle" /> user_id:{" "}
            </strong>
            {props.user.user_id}
          </div>
        </div>
      )}
      {!isEmpty(props.iap_json) && (
        <div>
          <div>
            <div>
              <strong>
                <i className="fa fa-check-circle" /> discovered_from:{" "}
              </strong>
              {props.iap_json.discovered_from}
            </div>
            <div>
              <strong>
                <i className="fa fa-check-circle" /> transaction_source:{" "}
              </strong>{" "}
              {props.iap_json.transaction_source}
            </div>
            <div>
              <strong>
                <i className="fa fa-check-circle" /> payment_method:{" "}
              </strong>{" "}
              {props.iap_json.payment_method}
            </div>
            <div>
              <strong>
                <i className="fa fa-check-circle" /> product_id:{" "}
              </strong>{" "}
              {props.iap_json.receipt.in_app.product_id}
            </div>
            <div>
              <strong>
                <i className="fa fa-check-circle" /> transaction_id:{" "}
              </strong>{" "}
              {props.iap_json.receipt.in_app.transaction_id}
            </div>
            <div>
              <strong>
                <i className="fa fa-check-circle" /> original_transaction_id:{" "}
              </strong>
              {props.iap_json.receipt.in_app.original_transaction_id}
            </div>
            <div>
              <strong>
                <i className="fa fa-check-circle" /> purchased_at:{" "}
              </strong>{" "}
              {props.iap_json.receipt.in_app.purchased_at}
            </div>
          </div>
        </div>
      )}
      {isApiWorking && (
        <button disabled={true}>
          <i className={apiFaIcon} />
        </button>
      )}
      {!isApiWorking && (
        <div className="create_db_data">
          <button onClick={(e) => validateData(e)}>Revalidate Receipt</button>
        </div>
      )}
    </div>
  );
}

export default IAP_Revalidate;
