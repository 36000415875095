import React, { useState, useEffect } from "react";
import isEmpty from "../isEmpty";
import URLParser from "../../components/URLParser";
//CSS
import "./SearchBar.css";

//fa icons
const faSearchIcon = "fa fa-search";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

const defPageItems = [{ title: "home", link: "/" }];

// SearchBar class
function SearchBar(props) {
  //api
  const [isApiWorking, setApiWorking] = useState(false);
  //user input
  const [searchText, setSearchText] = useState("");

  //ViewDidLoad
  useEffect(() => {
    let url_paths = URLParser();
    var search = url_paths["search"] || "";
    setSearchText(search);
  }, []);

  //UPDATE: SearchBar text
  function handleSearchChange(searchtext) {
    setSearchText(searchtext);
  }

  //SEARCH
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setApiWorking(true);
    let path = isEmpty(props.path) ? "" : `${props.path}`;
    let path_char = isEmpty(props.path_char) ? "/" : `${props.path_char}`;
    setTimeout(function () {
      //redirect client to search
      window.location.href = `${path}${path_char}search=${searchText}`;
      //stop api after
      setTimeout(function () {
        setApiWorking(false);
      }, 2000);
    }, 500);
  };

  //VIEW
  return (
    <div className="_searchbar">
      <div className="_search_container">
        {/* LEFt */}
        <div className="_link_container">{buildPageBar()}</div>
        {/* RIGHT */}
        <form className="_searchbar" onSubmit={handleSearchSubmit}>
          <input
            placeholder="Search"
            type="text"
            value={searchText}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          {/* SUBMIT SEARCH */}
          {props.isApiWorking === false && isApiWorking === false && (
            <button
              className="searchButton"
              type="submit"
              disabled={searchText.length === 0}
            >
              <i className={faSearchIcon}></i>
            </button>
          )}
          {/* API_LOADING */}
          {(props.isApiWorking === true || isApiWorking === true) && (
            <button className="searchButton" type="submit" disabled={true}>
              <i className={apiFaIcon}></i>
            </button>
          )}
        </form>
      </div>
    </div>
  );

  //BUILD: pagedata
  function buildPageBar() {
    var pageData = [];
    var items = isEmpty(props.pageItems) ? defPageItems : props.pageItems;
    items.forEach((pageitem, index) => {
      const tableRow = (
        <div key={index} className="linkitem">
          <a href={pageitem.link}>{pageitem.title}</a>
          {index !== items.length - 1 && <p>{">"}</p>}
        </div>
      );
      pageData.push(tableRow);
    });
    return pageData;
  }
}

export default SearchBar;
