import isEmpty from "../../../components/isEmpty";
import "moment-timezone";
const moment = require("moment");
const timeZone = "America/Los_Angeles";

//MUSIC_GENRES
export const get_music_genres = async () => {
  return [
    { id: 0, title: "SongDrop" },
    { id: 1, title: "rock" },
    { id: 2, title: "pop" },
    { id: 3, title: "electronic" },
    { id: 4, title: "dance" },
    { id: 5, title: "blues" },
    { id: 6, title: "r&b and soul" },
    { id: 7, title: "hip hop" },
    { id: 8, title: "country" },
    { id: 9, title: "latin" },
    { id: 10, title: "comedy" },
    { id: 11, title: "easy istening" },
    { id: 12, title: "african" },
    { id: 13, title: "folk" },
    { id: 14, title: "asian" },
    { id: 15, title: "jazz" },
    { id: 16, title: "avant-garde" },
    { id: 17, title: "classical" },
    { id: 18, title: "asmr" },
    { id: 19, title: "podcasts" },
  ];
};

export const get_mp3_list = () => {
  return [
    "http://as.fm1.be:8000/vlar1.mp3",
    "http://rhm1.de/listen.mp3",
    "http://ice37.fluidstream.net/rgw.mp3",
    "http://stream2.joyhits.online:8880/joyhits.mp3",
    "http://188.138.9.183/digital.mp3",
    "http://stream.dancewave.online:8080/dance.mp3",
    "http://s37.derstream.net/live.mp3",
    "http://ais-edge23-dal02.cdnstream.com/2093_128.mp3",
    "http://s02.whooshserver.net:9091/live.mp3",
    "http://streamradiox.hu:8500/live.mp3",
    "http://ais-edge69-nyc04.cdnstream.com/2093_128.mp3",
    "http://diffusion.lafrap.fr:8000/sunhd.mp3",
    "http://s04.diazol.hu:9600/live.mp3",
  ];
};

export const get_broadcast_by_id = async (broadcast_id) => {
  try {
    //SUCCESS
    let result = await get_artist_radios();
    let artist_radio = result.filter(function (item) {
      return item.id == broadcast_id;
    });
    if (!isEmpty(artist_radio)) {
      return artist_radio[0];
    } else {
      throw Error("Sorry, but we couldn't find the broadcast");
    }
    //ERROR
  } catch (error) {
    throw error;
  }
};

export const get_artist_radios = async () => {
  const items = [];

  for (let i = 0; i < artists.length; i++) {
    const artist = generateRandomArtists(i);
    const id = i; //artist["id"];
    const group = artist["group"];
    const title = artist["title"];

    let mp3_list = get_mp3_list();
    const mp3_url = mp3_list[Math.floor(Math.random() * mp3_list.length)];

    const random = Math.random() * 11.9;
    const start_time = moment().add(random - 0.2, "hour");
    const end_time = moment().add(random + 1, "hour");

    // const start_time = moment(artist["start_time"]);
    // const end_time = moment(artist["end_time"]);

    // Create your start and end times in your desired time zone
    // const start_time = moment(artist["start_time"]);
    // const end_time = moment(artist["end_time"]);

    // const start_time = moment(
    //   moment()
    //     .add(random - 0.2, "hour")
    //     .format("YYYY-MM-DD HH:mm:ss")
    // );
    // const end_time = moment(
    //   moment()
    //     .add(random + 1, "hour")
    //     .format("DD-MM-YYYY hh:mm:ss")
    // );

    // console.log(start_time);

    const bgColor = generateRandomColor();
    const genres = "rock, pop, dance";
    //
    const description =
      "LDN Death Metal Power Trio Manifestation music video OUT NOW! Debut LP coming January 2023! https://www.youtube.com/watch?v=JLftv9KnUgQ";

    const item = {
      id: id,
      group: group,
      title: title,
      genres: genres,
      description: description,
      start_time: start_time,
      end_time: end_time,
      canMove: false,
      canResize: false,
      canChangeGroup: false,
      url: group === 0 ? "http://radio.songdrop.cloud:8000/radio.mp3" : mp3_url,
      itemProps: {
        onDoubleClick: () => {
          alert(title);
        },
      },
      bgColor: bgColor,
      color: "white",
      selectedBgColor: "green",
    };

    items.push(item);
  }
  return items;
};

// const artists = [
//   {
//     id: 1,
//     group: 0,
//     title: "Xminus1",
//     start_time: "2023-03-03T09:00:00+0100",
//     end_time: "2023-03-03T10:00:00+0100",
//   },
//   {
//     id: 2,
//     group: 0,
//     title: "Hand Of Doom",
//     start_time: "2023-03-03T11:00:00-0500",
//     end_time: "2023-03-03T12:00:00-0500",
//   },
//   {
//     id: 3,
//     group: 1,
//     title: "Beabadoobee",
//     start_time: "2023-03-03T13:00:00+0900",
//     end_time: "2023-03-03T14:00:00+0900",
//   },
//   {
//     id: 4,
//     group: 2,
//     title: "Phoebe Bridgers",
//     start_time: "2023-03-03T15:00:00+0530",
//     end_time: "2023-03-03T16:00:00+0530",
//   },
//   {
//     id: 5,
//     group: 3,
//     title: "Jamie xx",
//     start_time: "2023-03-03T17:00:00-0200",
//     end_time: "2023-03-03T18:00:00-0200",
//   },
//   {
//     id: 6,
//     group: 4,
//     title: "Kaytranada",
//     start_time: "2023-03-03T19:00:00+0400",
//     end_time: "2023-03-03T20:00:00+0400",
//   },
//   {
//     id: 7,
//     group: 5,
//     title: "Gary Clark Jr.",
//     start_time: "2023-03-03T21:00:00-0700",
//     end_time: "2023-03-03T22:00:00-0700",
//   },
//   {
//     id: 8,
//     group: 6,
//     title: "Thundercat",
//     start_time: "2023-03-03T23:00:00+0600",
//     end_time: "2023-03-04T00:00:00+0600",
//   },
//   {
//     id: 9,
//     group: 7,
//     title: "SZA",
//     start_time: "2023-03-04T01:00:00-0800",
//     end_time: "2023-03-04T02:00:00-0800",
//   },
//   {
//     id: 10,
//     group: 8,
//     title: "Maren Morris",
//     start_time: "2023-03-04T03:00:00+0800",
//     end_time: "2023-03-04T04:00:00+0800",
//   },
//   {
//     id: 11,
//     group: 9,
//     title: "Bad Bunny",
//     start_time: "2023-03-04T05:00:00+0200",
//     end_time: "2023-03-04T06:00:00+0200",
//   },
//   {
//     id: 12,
//     group: 10,
//     title: "Burna Boy",
//     start_time: "2023-03-04T07:00:00+0330",
//     end_time: "2023-03-04T08:00:00+0330",
//   },
// ];

const artists = [
  { id: 1, group: 0, title: "Xminus1" },
  { id: 1, group: 0, title: "Hand Of Doom" },
  { id: 1, group: 1, title: "Beabadoobee" },
  { id: 2, group: 2, title: "Phoebe Bridgers" },
  { id: 3, group: 3, title: "Jamie xx" },
  { id: 4, group: 4, title: "Kaytranada" },
  { id: 5, group: 5, title: "Gary Clark Jr." },
  { id: 6, group: 6, title: "Thundercat" },
  { id: 7, group: 7, title: "SZA" },
  { id: 8, group: 8, title: "Maren Morris" },
  { id: 9, group: 9, title: "Bad Bunny" },
  { id: 10, group: 10, title: "Burna Boy" },
  { id: 11, group: 11, title: "Ali Gatie" },
  { id: 12, group: 12, title: "Ladipoe" },
  { id: 13, group: 13, title: "Koffee" },
  { id: 14, group: 14, title: "BTS" },
  { id: 15, group: 15, title: "Kamasi Washington" },
  { id: 16, group: 16, title: "Nils Frahm" },
  { id: 17, group: 17, title: "Tame Impala" },
  { id: 18, group: 18, title: "David Bowie" },
  { id: 19, group: 19, title: "Duncan Laurence" },
  { id: 21, group: 0, title: "Anderson .Paak" },
  { id: 22, group: 1, title: "Aurora" },
  { id: 23, group: 2, title: "Black Pumas" },
  { id: 24, group: 3, title: "Caroline Polachek" },
  { id: 25, group: 4, title: "Clairo" },
  { id: 26, group: 5, title: "Dominic Fike" },
  { id: 27, group: 6, title: "Fontaines D.C." },
  { id: 28, group: 7, title: "Gus Dapperton" },
  { id: 29, group: 8, title: "Hozier" },
  { id: 30, group: 9, title: "Jorja Smith" },
  { id: 31, group: 10, title: "King Gizzard & The Lizard Wizard" },
  { id: 32, group: 11, title: "Lianne La Havas" },
  { id: 33, group: 12, title: "Lil Simz" },
  { id: 34, group: 13, title: "Moses Sumney" },
  { id: 35, group: 14, title: "Nilüfer Yanya" },
  { id: 36, group: 15, title: "Noname" },
  { id: 37, group: 16, title: "Rex Orange County" },
  { id: 38, group: 17, title: "Snail Mail" },
  { id: 39, group: 18, title: "Tash Sultana" },
  { id: 40, group: 19, title: "Joe Rogan Experience" },
  { id: 41, group: 0, title: "Raveena" },
  { id: 42, group: 1, title: "Adrianne Lenker" },
  { id: 43, group: 2, title: "Angèle" },
  { id: 44, group: 3, title: "The Japanese House" },
  { id: 45, group: 4, title: "FKA twigs" },
  { id: 46, group: 5, title: "Big Thief" },
  { id: 47, group: 6, title: "Caribou" },
  { id: 48, group: 7, title: "Chromatics" },
  { id: 49, group: 8, title: "DIIV" },
  { id: 50, group: 9, title: "Frank Ocean" },
  { id: 51, group: 10, title: "Girl in Red" },
  { id: 52, group: 11, title: "Jacob Collier" },
  { id: 53, group: 12, title: "King Krule" },
  { id: 54, group: 13, title: "Mitski" },
  { id: 55, group: 14, title: "Mount Kimbie" },
  { id: 56, group: 15, title: "Nick Hakim" },
  { id: 57, group: 16, title: "Slow Pulp" },
  { id: 58, group: 17, title: "Steve Lacy" },
  { id: 59, group: 18, title: "Sudan Archives" },
  { id: 60, group: 19, title: "Tirzah" },
  { id: 60, group: 0, title: "Arlo Parks" },
  { id: 61, group: 1, title: "Black Midi" },
  { id: 62, group: 2, title: "Yves Tumor" },
  { id: 63, group: 3, title: "FKA Twigs" },
  { id: 64, group: 4, title: "Caribou" },
  { id: 65, group: 5, title: "Sault" },
  { id: 66, group: 6, title: "Kelly Lee Owens" },
  { id: 67, group: 7, title: "Moses Boyd" },
  { id: 68, group: 8, title: "Vagabon" },
  { id: 69, group: 9, title: "Poppy Ajudha" },
  { id: 70, group: 10, title: "Flying Lotus" },
  { id: 71, group: 11, title: "Kamaal Williams" },
  { id: 72, group: 12, title: "Little Simz" },
  { id: 73, group: 13, title: "Oscar Jerome" },
  { id: 74, group: 14, title: "Ezra Collective" },
  { id: 75, group: 15, title: "The Comet Is Coming" },
  { id: 76, group: 16, title: "Jamila Woods" },
  { id: 77, group: 17, title: "Jaimie Branch" },
  { id: 78, group: 18, title: "Irreversible Entanglements" },
  { id: 79, group: 19, title: "Sudan Archives" },
  { id: 80, group: 0, title: "The Mariachi Ghost" },
  { id: 81, group: 0, title: "Elephant Gym" },
  { id: 82, group: 1, title: "Polyphia" },
  { id: 83, group: 2, title: "Charlie Hunter" },
  { id: 84, group: 3, title: "Tigran Hamasyan" },
  { id: 85, group: 4, title: "Hiatus Kaiyote" },
  { id: 86, group: 5, title: "Anna Meredith" },
  { id: 87, group: 6, title: "Sons of Kemet" },
  { id: 88, group: 7, title: "Kamasi Washington" },
  { id: 89, group: 8, title: "Thundercat" },
  { id: 90, group: 9, title: "BADBADNOTGOOD" },
  { id: 91, group: 10, title: "GoGo Penguin" },
  { id: 92, group: 11, title: "Makoto Ozone" },
  { id: 93, group: 12, title: "Erykah Badu" },
  { id: 94, group: 13, title: "Robert Glasper" },
  { id: 95, group: 14, title: "Snarky Puppy" },
  { id: 96, group: 15, title: "Jacob Collier" },
  { id: 97, group: 16, title: "Esperanza Spalding" },
  { id: 98, group: 17, title: "Alex Han" },
  { id: 99, group: 18, title: "Bokanté" },
  { id: 100, group: 19, title: "Cory Henry & The Funk Apostles" },
  { id: 101, group: 0, title: "Moonchild" },
  { id: 102, group: 0, title: "Tommy Guerrero" },
  { id: 103, group: 1, title: "Living Room" },
  { id: 104, group: 2, title: "Papooz" },
  { id: 105, group: 3, title: "Boy Pablo" },
  { id: 106, group: 4, title: "Raveena" },
  { id: 107, group: 5, title: "Cuco" },
  { id: 108, group: 6, title: "Oscar Jerome" },
  { id: 109, group: 7, title: "Jordan Rakei" },
  { id: 110, group: 8, title: "Jamila Woods" },
  { id: 111, group: 9, title: "Pink Sweat$" },
  { id: 112, group: 10, title: "Arlo Parks" },
  { id: 113, group: 11, title: "Mereba" },
  { id: 114, group: 12, title: "Odunsi (The Engine)" },
  { id: 115, group: 13, title: "Mahalia" },
  { id: 116, group: 14, title: "Juto" },
  { id: 117, group: 15, title: "YEBBA" },
  { id: 118, group: 16, title: "Steve Lacy" },
  { id: 119, group: 17, title: "Snoh Aalegra" },
  { id: 120, group: 18, title: "JW Ridley" },
  { id: 121, group: 19, title: "Omar Apollo" },
  { id: 61, group: 0, title: "Arctic Monkeys" },
  { id: 62, group: 0, title: "The Strokes" },
  { id: 63, group: 1, title: "Tyler, The Creator" },
  { id: 64, group: 2, title: "Rosalía" },
  { id: 65, group: 3, title: "Lorde" },
  { id: 66, group: 4, title: "Radiohead" },
  { id: 67, group: 5, title: "Bon Iver" },
  { id: 68, group: 6, title: "James Blake" },
  { id: 69, group: 7, title: "FKA Twigs" },
  { id: 70, group: 8, title: "Little Simz" },
  { id: 71, group: 9, title: "Frank Ocean" },
  { id: 72, group: 10, title: "Kendrick Lamar" },
  { id: 73, group: 11, title: "Childish Gambino" },
  { id: 74, group: 12, title: "J. Cole" },
  { id: 75, group: 13, title: "Chance the Rapper" },
  { id: 76, group: 14, title: "Mac Miller" },
  { id: 77, group: 15, title: "Outkast" },
  { id: 78, group: 16, title: "Nas" },
  { id: 79, group: 17, title: "Run the Jewels" },
  { id: 80, group: 18, title: "Anderson .Paak & The Free Nationals" },
  { id: 81, group: 0, title: "Billie Eilish" },
  { id: 82, group: 1, title: "Harry Styles" },
  { id: 83, group: 2, title: "Dua Lipa" },
  { id: 84, group: 3, title: "The Weeknd" },
  { id: 85, group: 4, title: "Justin Bieber" },
  { id: 86, group: 5, title: "Lady Gaga" },
  { id: 87, group: 6, title: "Ariana Grande" },
  { id: 88, group: 7, title: "Beyoncé" },
  { id: 89, group: 8, title: "Post Malone" },
  { id: 90, group: 9, title: "Drake" },
  { id: 91, group: 10, title: "Travis Scott" },
  { id: 92, group: 11, title: "Nicki Minaj" },
  { id: 93, group: 12, title: "Megan Thee Stallion" },
  { id: 94, group: 13, title: "Cardi B" },
  { id: 95, group: 14, title: "Kanye West" },
  { id: 96, group: 15, title: "Jay-Z" },
  { id: 97, group: 16, title: "Eminem" },
  { id: 98, group: 17, title: "Snoop Dogg" },
  { id: 99, group: 18, title: "50 Cent" },
];

function generateRandomArtists(index) {
  return artists[index];
}

function generateRandomColor() {
  const colors = [
    "#0b68fd",
    "#ff1493",
    "#00ff7f",
    "#9400d3",
    "#ff4500",
    "#ffa500",
    "#1e90ff",
    "#d2691e",
    "#4b0082",
    "#008b8b",
    "#8b0000",
    "#483d8b",
    "#2f4f4f",
    "#008000",
    "#4169e1",
    "#696969",
    "#ff00ff",
    "#ffa07a",
    "#7fffd4",
    "#e6e6fa",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
}
