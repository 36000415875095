import React, { useState, useRef, useEffect } from "react";
import QRCode from "react-qr-code";
import Zoom from "react-medium-image-zoom";
import html2canvas from "html2canvas";
import Switch from "react-switch";
import ImageToBase64URL from "../../../../components/ImageToBase64URL";
import SaveDataURL from "../../../../components/SaveDataURL";
import useLocalStorage from "../../../../components/useLocalStorage";
import isEmpty from "../../../../components/isEmpty";
import NavBar from "../../../../components/Navbar/NavBar";

//API
import { getAlbumQR } from "../../../../utils/apis/api/auth_api_albums";
//JS
//QR cards
import EDDesign from "./EDDesign";
import SaveQrDesign from "../SaveQrDesign";
import DownloadQR from "./DownloadQR";
//img
import songdroplogoblack from "../../../../assets/img/songdrop-logo-black-svg.svg";
import songdroplogowhite from "../../../../assets/img/songdrop-logo-white-svg.svg";
import songdroplogowhiteout from "../../../../assets/img/songdrop-logo-white-black-out-svg.svg";

//json
import qrinfojson from "../../../../assets/json/albums/album_qr_info.json";
import about from "../../../../assets/about/about.json";
//css
import "./AlbumQrCode.css";
//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//AlbumQrCode
function AlbumQrCode(props) {
  //Helper:siteWidth ref(resize chartview)
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  const [album, setAlbum] = useState(null);
  //toggle
  const createYourSqrRef = useRef(null);
  const [edStyle, setEDStyle] = useLocalStorage(`edorderadmin`, 0);

  const [isQRDesignFullScreen, setQRDesignFullScreen] = useState(false);
  const [isQrBack, setQRBack] = useState(false);

  const [fontSize, setFontSize] = useLocalStorage(`qrfs`, 12);
  const [trackFontSize, setTrackFontSize] = useLocalStorage(`qrtfs`, 12);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "QR | SongDrop.uk";
    //album_id
    var album_id = window.location.href.split("/").pop();
    //api_call

    getAlbumWithId(album_id);
    // //resize waves
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  //api
  async function getAlbumWithId(album_id) {
    setApiWorking(true);
    setApiError("");

    try {
      //album
      var queryParams = [];
      queryParams.push(`album_id=${album_id}`);
      const qParameters = queryParams.join("&");
      //token
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      let result = await getAlbumQR(qParameters, bParameters);
      //RESULT
      console.log("RESULT", result);
      if (result === "ALBUM_NOT_FOUND") {
        //❌Error: ALBUM NOT FOUND
        throw new Error("ALBUM NOT FOUND");
      } else {
        //✅SUCCESS
        setAlbum(result["album"]);

        setApiWorking(false);
      }
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //SELECT: Download card front
  function downloadFront() {
    setQRBack(false);
    setQRDesignFullScreen(true);
    scrollToTop();
  }

  //SELECT: Download card back
  function downloadBack() {
    setQRBack(true);
    setQRDesignFullScreen(true);
    scrollToTop();
  }

  //DOWNLOAD: songdrop logo black
  const downloadLogoBlack = (event) => {
    event.preventDefault();
    const title = `Would you like to download this image?`;
    if (window.confirm(title)) {
      ImageToBase64URL(songdroplogoblack, function (base64ImgData) {
        downloadImgPng(base64ImgData, "songdrop-logo-black");
      });
    }
  };

  //DOWNLOAD: songdrop logo white
  const downloadLogoWhite = (event) => {
    event.preventDefault();
    const title = `Would you like to download this image?`;
    if (window.confirm(title)) {
      ImageToBase64URL(songdroplogowhite, function (base64ImgData) {
        downloadImgPng(base64ImgData, "songdrop-logo-white");
      });
    }
  };

  //DOWNLOAD: songdrop logo white
  const downloadLogoWhiteBlackOut = (event) => {
    event.preventDefault();
    const title = `Would you like to download this image?`;
    if (window.confirm(title)) {
      ImageToBase64URL(songdroplogowhiteout, function (base64ImgData) {
        downloadImgPng(base64ImgData, "songdrop-logo-white-black-out");
      });
    }
  };

  //DOWNLOAD image
  function downloadImgPng(base64ImgData, filename) {
    const downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = `${base64ImgData}`;
    downloadLink.click();
  }

  const handleScroll = () => {
    createYourSqrRef.current.scrollIntoView({ behavior: "smooth" });
  };

  //VIEW
  return (
    <div id="qrcode-container" className="qrcode-container">
      <NavBar title={props.title} handleLogout={props.handleLogout} />

      {/* API_LOADING */}
      {isApiWorking === true && (
        <button className="searchButton" type="submit" disabled={true}>
          <i className={apiFaIcon}></i>
        </button>
      )}
      {/* API ERROR */}

      {!isApiWorking && isEmpty(album) && <div>{apiError}</div>}

      {/* DATA SUCCESS */}

      {!isApiWorking && !isEmpty(album) && (
        <div>
          {/* DOWNLOAD/SAVE QR CODED ED */}
          {isQRDesignFullScreen === true && (
            <SaveQrDesign
              fontSize={fontSize}
              album={album}
              isBack={isQrBack}
              edStyle={edStyle}
              hideSaveQrDesign={hideSaveQrDesign}
            />
          )}
          {/* SELECT ED STYLE */}
          <div className="qrcode-style-select _style-select">
            <button className="crtyoursbtn" onClick={handleScroll}>
              <i className="fa fa-qrcode" />
            </button>{" "}
            Choose{"  "}
            <select
              value={edStyle}
              onChange={(e) => setEDStyle(e.target.value)}
            >
              <option value={0}>ED Style 1</option>
              <option value={1}>ED Style 2</option>
              <option value={2}>ED Style 3</option>
              <option value={3}>ED Style 4</option>
            </select>
            {"  "}
            or{" "}
            <button className="crtyoursbtn" onClick={handleScroll}>
              Create yours
            </button>
          </div>
          {/* ED STYLE QR CODE */}
          <div
            id="qrcode-songdrop-container"
            className="qrcode-songdrop-container"
          >
            <div className="sdqrmatzoomcontent">
              <Zoom>
                <EDDesign
                  fontSize={fontSize}
                  trackFontSize={trackFontSize}
                  album={album}
                  isBack={false}
                  edStyle={edStyle}
                />
              </Zoom>
              <button
                type="submit"
                className="dwnqrbtn"
                onClick={downloadFront}
              >
                <i className="fa fa-cloud-download" /> Download Front
              </button>
              <select
                onChange={(e) => setFontSize(e.target.value)}
                value={fontSize}
              >
                {optionList([8, 10, 12, 14], "Title", fontSize)}
              </select>
            </div>
            <div className="sdqrmatzoomcontent">
              <Zoom>
                <EDDesign
                  fontSize={fontSize}
                  trackFontSize={trackFontSize}
                  album={album}
                  isBack={true}
                  edStyle={edStyle}
                />
              </Zoom>
              <button type="submit" className="dwnqrbtn" onClick={downloadBack}>
                <i className="fa fa-cloud-download" /> Download Back
              </button>
              <select
                onChange={(e) => setTrackFontSize(e.target.value)}
                value={trackFontSize}
              >
                {optionList([8, 10, 12, 14, 16], "List", trackFontSize)}
              </select>
            </div>
            {/* HOW TO ORDER ED? */}
            <div className="orderBox">
              <p>
                What is an{" "}
                <strong>
                  <a onClick={getEDGuideline}>ED(Electronically Distributed)</a>
                </strong>
                ?
              </p>
              <p>{qrinfojson.o1}</p>
              <p>
                <a onClick={orderEDOnPartners}>Click here</a> to register/login
                our EDShop.
              </p>
              <p>
                For help how to make your first order, follow this{" "}
                <a onClick={getHelpOrder}>link</a>.
              </p>
              <div style={{ fontSize: "12px", marginTop: "6px" }}>
                <p>{qrinfojson.notice}</p>
              </div>
            </div>
          </div>
          {/* CREATE YOUR QR DESIGN */}
          <div id="createyoursqr" ref={createYourSqrRef}>
            <DownloadQR album={album} />
          </div>
          {/* SONGDROP TRADEMARK LOGO DOWNLOAD/GUIDELINES */}
          <div className="trademark-container">
            <div className="trademarkguidelinesbox">
              <div className="title">
                Guidelines of using SongDrop Trademark Logo:
              </div>
              <div className="">
                We created a guideline how to use SongDrop logo in your
                marketing. Please follow this guideline when you are using our
                logo.
              </div>
              <p>
                <a onClick={getTrademarkGuideline}>Click here</a> to download.
              </p>
            </div>
            <form className="sdiconwrapper" onSubmit={downloadLogoBlack}>
              <Zoom>
                <img id="songdrop-logo-black" alt="" src={songdroplogoblack} />
              </Zoom>
              <button type="submit" className="dwnqrbtn">
                Download Logo
              </button>
            </form>
            <form className="sdiconwrapper" onSubmit={downloadLogoWhite}>
              <Zoom overlayBgColorEnd={"lightGray"}>
                <img id="songdrop-logo-white" alt="" src={songdroplogowhite} />
              </Zoom>
              <button type="submit" className="dwnqrbtn">
                Download Logo
              </button>
            </form>
            <form
              className="sdiconwrapper"
              onSubmit={downloadLogoWhiteBlackOut}
            >
              <Zoom overlayBgColorEnd={"lightGray"}>
                <img
                  id="songdrop-logo-white-black-out"
                  alt=""
                  src={songdroplogowhiteout}
                />
              </Zoom>
              <button type="submit" className="dwnqrbtn">
                Download Logo
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );

  //Creates a list of <option/>
  function optionList(list, title) {
    let tableRows = [];
    list.map((item, index) => {
      tableRows.push(
        <option key={index} value={item}>
          {`${title}: ${item}px`}
        </option>
      );
    });
    return tableRows;
  }

  //Order ED
  function orderEDOnPartners() {
    let url = `${about.edshop_url}`;
    window.open(url, "_blank");
  }

  //Get help with ordering ED(Youtube Link)
  function getHelpOrder() {
    let url = `${about.edshop_help_url}`;
    window.open(url, "_blank");
  }

  //TRADEMARK_GUIDELINE
  function getTrademarkGuideline() {
    let url = `${about.logoguideline_url}`;
    window.open(url, "_blank");
  }

  //ED_GUIDELINE
  function getEDGuideline() {
    let url = `${about.edguideline_url}`;
    window.open(url, "_blank");
  }

  //ScrollToTop
  function scrollToTop() {
    var element = document.getElementById("qrcode-container");
    element.scrollTop = 0;
  }

  //toogle element
  function hideSaveQrDesign() {
    setQRDesignFullScreen(false);
  }

  //GENERATE_QR_CODE
  function generateQrCode() {
    if (!isEmpty(album.album_id)) {
      return `${about.qr_url}/${album.album_id}`;
    } else {
      return "";
    }
  }
  //UPDATE::Window - Update ContentWidth
  function updateWindowDimensions() {
    const _containerWidth = window.innerWidth;
    setContainerWidth(_containerWidth);
  }
}

export default AlbumQrCode;
