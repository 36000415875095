import moment from "moment";
const axios = require("axios");

//
export default async function getMp3Info(stream_url, status_url) {
  //
  let newUrl = stream_url.slice(0, stream_url.lastIndexOf("/"));
  //this is the status metadata of the icecast server
  let status_meta_url = `${newUrl}/${status_url}`;

  const response = await axios.get(status_meta_url);

  if (!response) {
    throw new Error("Response is empty");
  }

  const data = response.data;

  console.log("JSON_RESPONSE", data);
  //
  let mp3Info = {};
  //server data
  mp3Info.server_id = data.icestats.server_id;
  mp3Info.server_host = data.icestats.host;
  mp3Info.server_location = data.icestats.location;
  mp3Info.server_start_iso8601 = data.icestats.server_start_iso8601;

  let sources = data.icestats.source;
  if (!Array.isArray(sources)) {
    sources = [sources];
  }

  //the server can have multiple sources, but we want only the specific url's data
  const radio_channel_url = stream_url.substring(
    stream_url.lastIndexOf("/") + 1
  );
  let mp3Source = sources.find((source) =>
    source.listenurl.endsWith(radio_channel_url)
  );
  if (!mp3Source) {
    throw new Error("No mp3 source found");
  }
  //source_data
  mp3Info.listenurl = mp3Source.listenurl || "";
  mp3Info.genre = mp3Source.genre || "";
  mp3Info.server_description = mp3Source.server_description || "";
  mp3Info.title = mp3Source.title || "";
  mp3Info.server_name = mp3Source.server_name || "";
  mp3Info.bitrate = mp3Source.bitrate || "";
  mp3Info.stream_start = mp3Source.stream_start || "";
  mp3Info.peak_listeners =
    mp3Source.listener_peak || mp3Source.listener_peak || 1;
  mp3Info.listeners = mp3Source.listeners || 1;
  mp3Info.server_type = mp3Source.server_type || "";
  mp3Info.request_date = moment().format("HH:mm:ss");

  return mp3Info;
}
