import React, { useState, useEffect } from "react";
import isEmpty from "../../../components/isEmpty";
import ProfileSearchBar from "./ProfileSearchBar";
import { searchUsers } from "../../../utils/apis/api/public";

//CSS
import "./ProfileSearch.css";
import placeholder from "../../../assets/img/ic_placeholder_profile.png";

//ProfileSearch
function ProfileSearch(props) {
  //
  const [users, setUsers] = useState([]);
  const [isApiWorking, setApiWorking] = useState(props.isApiWorking || true);
  const [apiError, setApiError] = useState("");

  //SEARCH
  const [searchText, setSearchText] = useState(props.searchText || "");
  const [isSearching, setIsSearching] = useState(false);

  //useEffect
  useEffect(() => {
    _searchUsers(searchText);
  }, []);

  //api
  async function _searchUsers(search_text) {
    setApiWorking(true);
    setApiError("");

    try {
      let result = await searchUsers(search_text);
      setUsers(result["users"]);
      //✅Result
      console.log(result);
      setApiWorking(false);
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //UPDATE: SearchBar text
  function textDidChange(searchtext) {
    setSearchText(searchtext);
  }

  //SEARCH
  const searchForText = () => {
    setApiWorking(true);
    setApiError("");
    setIsSearching(true);
    //search again
    _searchUsers(searchText);
  };

  const userClicked = (username) => {
    window.location.href = `/@/${username}`;
  };

  //VIEW
  return (
    <div className={`popup show`}>
      <div className="popup-inner">
        <button className="close-btn" onClick={props.handleClose}>
          <i className="fa fa-times-circle" />
        </button>
        <ProfileSearchBar
          isApiWorking={isApiWorking}
          searchText={searchText}
          searchForText={searchForText}
          textDidChange={textDidChange}
        />
        <div>Result: {users.length}</div>
        {!isEmpty(users) && (
          <div className="content">
            {users.map((user, index) => {
              return (
                <div
                  className="p_cell"
                  key={index}
                  onClick={() => userClicked(user.username)}
                >
                  <div
                    className="p_img"
                    style={{
                      borderRadius: user.usertype === 1 ? "2px" : "25px",
                    }}
                  >
                    <img
                      alt=""
                      src={
                        isEmpty(user.profile_img_url)
                          ? placeholder
                          : user.profile_img_url
                      }
                      style={{
                        borderRadius: user.usertype === 1 ? "2px" : "25px",
                      }}
                    />
                  </div>
                  <div className="p_info">
                    <div className="p_username">{user.username}</div>
                    <div className="p_name">{user.name}</div>
                    <div className="p_genres">{user.genres}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileSearch;
