import React, { useEffect, useState } from "react";
import BlogEmptyList from "../BlogContent/BlogEmptyList";
import { fileToS3 } from "../../../../utils/apis/s3/UploadImage";

//CSS
import "./BlogWriter.css";
import { isEmpty } from "lodash";
import QuillEditor from "./ReactQuill";
//BlogWriter
const BlogWriter = (props) => {
  //
  const [cover_img, set_cover_img] = useState(null);
  const [author_img, set_author_img] = useState(null);

  //useEffect
  useEffect(() => {
    //
  }, []);

  //API: UPLOAD_EXTRA_IMAGE
  const uploadImage = async (file, path, filename) => {
    try {
      //2.RECEIVE_S3_URL
      var uploadURL = await fileToS3(file, path, filename);
      console.log("FILE_UPLOAD_SUCCESS", uploadURL);

      //✅SUCCESS
      return uploadURL;
    } catch (err) {
      //❌FAILED
      alert(err.message);
      console.log("UPLOAD_FAILED", err.message);
    }
  };

  //
  async function uploadCoverImage(event) {
    const imgurl = URL.createObjectURL(event.target.files[0]);
    set_cover_img(imgurl);
    //upload
    const url = props.blog.id + "_cover.png";
    let cover_url = await uploadImage(
      event.target.files[0],
      `blog/${props.blog.id}`,
      url
    );
    //HERE UPLOAD NEW IMAGE TO S3
    changeCover(cover_url);
  }

  function changeCover(value) {
    props.changeCover(value);
  }

  async function uploadAuthorAvatar(event) {
    const imgurl = URL.createObjectURL(event.target.files[0]);
    set_author_img(imgurl);
    const url = props.blog.id + "_avatar.png";
    let cover_url = await uploadImage(
      event.target.files[0],
      `blog/${props.blog.id}`,
      url
    );
    //HERE UPLOAD NEW IMAGE TO S3
    changeAuthorAvatar(cover_url);
  }

  //
  function changeTitle(value) {
    props.changeTitle(value);
  }

  function changeCategory(value) {
    props.changeCategory(value);
  }

  function changeSubcategories(value) {
    props.changeSubcategories(value);
  }

  function changeDurations(value) {
    props.changeDurations(value);
  }

  function changeDescription(value) {
    props.changeDescription(value);
  }

  function changeAuthorName(value) {
    props.changeAuthorName(value);
  }

  function changeAuthorAvatar(value) {
    props.changeAuthorAvatar(value);
  }

  function changeContent(value) {
    props.changeContent(value);
  }

  //
  //VIEW
  return (
    <div className="blog_writer">
      {props.blog ? (
        <div>
          <div className="input_row">
            <div className="_title">Title</div>
            <input
              value={props.blog.title}
              placeholder="Title"
              onChange={(e) => changeTitle(e.target.value)}
            />
          </div>
          <div className="input_row">
            <div className="_title">Cover Image</div>
            <div>
              <input type="file" onChange={uploadCoverImage} />
              <input
                value={props.blog.cover}
                placeholder="Cover"
                onChange={(e) => changeCover(e.target.value)}
              />
              <img
                className="c_img"
                src={cover_img ? cover_img : props.blog.cover}
                alt=""
              />
            </div>
          </div>
          <div className="input_row">
            <div className="_title">Category</div>
            <input
              value={props.blog.category}
              placeholder="Category"
              onChange={(e) => changeCategory(e.target.value)}
            />
          </div>
          <div className="input_row">
            <div className="_title">Subcategories</div>
            <input
              value={props.blog.subcategories}
              placeholder="Subcategories"
              onChange={(e) => changeSubcategories(e.target.value)}
            />
          </div>
          <div className="input_row">
            <div className="_title">Duration</div>
            <input
              value={!isEmpty(props.blog.duration) ? props.blog.duration : ""}
              placeholder="Read Duration"
              onChange={(e) => changeDurations(e.target.value)}
            />
          </div>
          <div className="input_row">
            <div className="_title">Description</div>
            <textarea
              value={props.blog.description}
              placeholder="Text"
              onChange={(e) => changeDescription(e.target.value)}
            />
          </div>
          <div className="input_row">
            <div className="_title">Content</div>
            <QuillEditor blog={props.blog} changeContent={changeContent} />
          </div>
          <div className="input_row">
            <div className="_title">Author Name</div>
            <input
              value={props.blog.author_name}
              placeholder="Author Name"
              onChange={(e) => changeAuthorName(e.target.value)}
            />
          </div>
          <div className="input_row">
            <div className="_title">Author Avatar</div>
            <img
              className="a_img"
              alt=""
              src={author_img ? author_img : props.blog.author_avatar}
            />
            <input type="file" onChange={uploadAuthorAvatar} />
            <input
              value={props.blog.author_avatar}
              placeholder="Author Avatar"
              onChange={(e) => changeAuthorAvatar(e.target.value)}
            />
          </div>

          <div className="input_row">
            {!isEmpty(props.blog.updated_at) && (
              <div style={{ marginLeft: "18px" }}>
                Updated: {props.blog.updated_at}
              </div>
            )}
          </div>
        </div>
      ) : (
        <BlogEmptyList />
      )}
    </div>
  );
};

export default BlogWriter;
