import React, { useEffect, useState } from "react";
import Chip from "../BlogContent/Chip";
import EmptyList from "../BlogContent/BlogEmptyList";
import "./BlogContent.css";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactLinkify from "react-linkify";
import isEmpty from "../../../../components/isEmpty";

//

const BlogContent = (props) => {
  //

  const [linkCopied, setLinkToCopy] = useState(false);

  return (
    <div className="blog-content">
      {props.blog ? (
        <div className="blog-wrap">
          <div className="sharing">
            <a
              href={`https://songdrop.uk/blog/${props.blog.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-globe" />
            </a>
            <CopyToClipboard
              text={window.location.href.replace(
                "admin.songdrop.band",
                "songdrop.uk"
              )}
              onCopy={() => setLinkToCopy(true)}
            >
              <div className="share-button">
                <i className="fa fa-share-square-o"></i>
              </div>
            </CopyToClipboard>
            {linkCopied && (
              <div style={{ display: "inline-block" }}>Link copied. </div>
            )}
            <Mailto
              email=""
              subject={props.blog.title}
              body={window.location.href}
            >
              <i className="fa fa-envelope" />
            </Mailto>

            <a
              href="https://twitter.com/intent/tweet?original_referer=
    http%3A%2F%2Ffiddle.jshell.net%2F_display%2F&text=I'm on SongDrop! @songdropuk&url
    =https://songdrop.uk"
              target="_blank"
              rel="noopener noreferrer"
              onclick="return Share.me(this)"
            >
              <i className="fa fa-twitter" />
            </a>
            <a
              href="http://www.facebook.com/sharer/sharer.php?s=100&
p%5Btitle%5D=I'm on SongDrop! @songdropuk&p%5Bsummary%5D=DESC&p%5Burl%5D=
URL&p%5Bimages%5D%5B0%5D=IMG_PATH"
              target="_blank"
              rel="noopener noreferrer"
              onclick="return Share.me(this);"
            >
              <i className="fa fa-facebook" />
            </a>
          </div>
          <header>
            <p className="blog-date">
              Published {props.blog.created_at} - {props.blog.duration} to read
            </p>
            <h1>{props.blog.title}</h1>
            <div className="blog-subCategory">
              {!isEmpty(props.blog.subcategories) &&
                props.blog.subcategories.map((category, i) => (
                  <div key={i}>
                    <Chip label={category} />
                  </div>
                ))}
            </div>
          </header>
          <img src={props.blog.cover} alt="cover" />
          <ReactLinkify>
            <div className="blog-desc">{props.blog.description}</div>
          </ReactLinkify>
          <div className="blog_content">
            <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: props.blog.content }} />
            </React.Fragment>
          </div>
          <div className="blog_author">
            Author: <strong>{props.blog.author_name}</strong>
          </div>
          {!isEmpty(props.blog.updated_at) && (
            <div className="blog_updated">Updated: {props.blog.updated_at}</div>
          )}
        </div>
      ) : (
        <EmptyList />
      )}
    </div>
  );
};

const Mailto = ({ email, subject, body, children }) => {
  return (
    <a
      href={`mailto:${email}?subject=${
        encodeURIComponent(subject) || ""
      }&body=${encodeURIComponent(body) || ""}`}
    >
      {children}
    </a>
  );
};

export default BlogContent;
