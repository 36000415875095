import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";

const TimezoneSelector = (props) => {
  const [timezone, setTimezone] = useState(moment.tz.guess());

  const handleChange = (event) => {
    setTimezone(event.target.value);
  };

  return (
    <div style={{ marginTop: "4px" }}>
      <label htmlFor="timezone-select">Select your timezone:</label>
      <br />
      <select id="timezone-select" value={timezone} onChange={handleChange}>
        {moment.tz.names().map((tz) => (
          <option key={tz} value={tz}>
            {tz}
          </option>
        ))}
      </select>
      <div>
        Start Time ({timezone}):{" "}
        {moment(props.start_time)
          .tz(timezone)
          .format("dddd, MMMM Do YYYY, h:mm:ss a")}
      </div>
      <div>
        End Time ({timezone}):{" "}
        {moment(props.end_time)
          .tz(timezone)
          .format("dddd, MMMM Do YYYY, h:mm:ss a")}
      </div>
    </div>
  );
};

export default TimezoneSelector;
