import React, { useState, useEffect } from "react";
import {
  Select,
  CaretIcon,
  MultiSelectOptionMarkup,
  ModalCloseButton,
} from "react-responsive-select";
import isEmpty from "../../../../components/isEmpty";
// for default styles...
import "react-responsive-select/dist/react-responsive-select.css";

import { getNewsletterSignUps } from "../../../../utils/apis/api/auth_api_newsletters";
//CSS
import "./Audience.css";

const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//Audience class
function Audience(props) {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  const [emails, setEmails] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [filterGenres, setFilterGenres] = useState([]);
  const [filterArtists, setFilterArtists] = useState([]);
  const [filterCities, setFilterCities] = useState([]);

  const [selectedGenres, setSelecteGenres] = useState([]);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  //expand rows
  const genresToShowInitially = 14; // Change this value to adjust the number of genres shown initially
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (index) => {
    const isRowExpanded = expandedRows.includes(index);
    if (isRowExpanded) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  //
  //ViewDidLoad
  useEffect(() => {
    //API
    getAudienceUsers();
  }, []);

  //AUDIENCE API
  const getAudienceUsers = async () => {
    setApiWorking(true);
    //
    try {
      var bodyParams = [];
      bodyParams.push(`genres=${selectedGenres.join(",")}`);
      bodyParams.push(`artists=${selectedArtists.join(",")}`);
      bodyParams.push(`cities=${selectedCities.join(",")}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getNewsletterSignUps(bParameters);
      console.log(result);
      setEmails(result["audience-table"]);
      setFilterGenres(result["audience-genres"]);
      setFilterArtists(result["audience-artists"]);
      setFilterCities(result["audience-cities"]);
      // console.log("RESULT", result);
      setApiWorking(false);
      //🚫Error
    } catch (error) {
      setApiWorking(false);
      //   alert(error.message);
    }
  };

  const deleteRows = () => {
    const confirmation = window.confirm(
      `Are you sure you want to delete these ${selectedRows.length} emails? Once deleted, subscribers won't receive any emails until they resubscribe to the service.`
    );

    if (confirmation) {
      // Call your delete function here
    }
  };

  //SELECT ROWS TO DELETE
  const handleRowSelection = (email) => {
    if (selectedRows.includes(email)) {
      setSelectedRows(selectedRows.filter((row) => row !== email));
    } else {
      setSelectedRows([...selectedRows, email]);
    }
  };

  //filter events
  function handleChangeModel(currOptions) {
    let currOptionsValues = currOptions.options.map(function (item) {
      return item.value;
    });
    // props.filter_radio_groups(currOptionsValues);
  }

  function audienceFilterGroups(defaultValue, filter_group_data) {
    //
    var tableData = [
      {
        value: defaultValue,
        text: defaultValue,
        markup: <MultiSelectOptionMarkup text={defaultValue} />,
      },
    ];
    //
    if (isEmpty(filter_group_data)) {
      return tableData;
    }
    //
    filter_group_data.forEach((_data, index) => {
      const tableRow = {
        value: `${_data}`,
        text: `${_data}`,
        markup: <MultiSelectOptionMarkup text={_data} />,
      };
      tableData.push(tableRow);
    });
    //
    // console.log("tabledata", tableData);
    return tableData;
  }

  const tableData = (genres) => {
    // Function to render table rows for genres
    if (isEmpty(genres)) {
      return <p></p>;
    }
    return <p>{genres.join(", ")}</p>;
  };

  //VIEW
  return (
    <div className="audience">
      <div className="title">Audience</div>
      <div className="audience-filter">
        <div className="genre-select">
          <form>
            <Select
              className="select_multi"
              multiselect={true}
              name="select_station"
              selectedValues={selectedGenres}
              modalCloseButton={<ModalCloseButton />}
              options={audienceFilterGroups("Audience", filterGenres)}
              caretIcon={<CaretIcon />}
              onChange={(e) => handleChangeModel(e)}
              onSubmit={() => console.log("onSubmit")}
            />
          </form>
        </div>
        <div className="genre-select">
          <form>
            <Select
              className="select_multi"
              multiselect={true}
              name="select_station"
              selectedValues={selectedGenres}
              modalCloseButton={<ModalCloseButton />}
              options={audienceFilterGroups("Genres", filterGenres)}
              caretIcon={<CaretIcon />}
              onChange={(e) => handleChangeModel(e)}
              onSubmit={() => console.log("onSubmit")}
            />
          </form>
        </div>
        <div className="artist-select">
          <form>
            <Select
              className="select_multi"
              multiselect={true}
              name="select_station"
              selectedValues={[]}
              modalCloseButton={<ModalCloseButton />}
              options={audienceFilterGroups("Artists", filterArtists)}
              caretIcon={<CaretIcon />}
              // onChange={(e) => handleChangeModel(e)}
              onSubmit={() => console.log("onSubmit")}
            />
          </form>
        </div>
        <div className="country-select">
          <form>
            <Select
              className="select_multi"
              multiselect={true}
              name="select_station"
              selectedValues={[]}
              modalCloseButton={<ModalCloseButton />}
              options={audienceFilterGroups("Cities", filterCities)}
              caretIcon={<CaretIcon />}
              // onChange={(e) => handleChangeModel(e)}
              onSubmit={() => console.log("onSubmit")}
            />
          </form>
        </div>
      </div>
      <div className="audience-metrix"></div>
      <div className="audience-table">
        <div>
          Your audience has <strong>{emails.length} </strong> contacts.
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Email</th>
              <th>Artist</th>
              <th>Genres</th>
              <th>Cities</th>
              <th>Subscribed At</th>
            </tr>
          </thead>
          <tbody>
            {emails.map((result, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    onChange={() => handleRowSelection(result.email)}
                    checked={selectedRows.includes(result.email)}
                  />
                </td>
                <td>{result.email}</td>
                <td>{result.artist}</td>
                <td>
                  {!isEmpty(result.genres) && (
                    <div>
                      {expandedRows.includes(index)
                        ? tableData(result.genres)
                        : tableData(
                            result.genres.slice(0, genresToShowInitially)
                          )}
                      <button
                        onClick={() => toggleRowExpansion(index)}
                        className="expand-button"
                      >
                        {expandedRows.includes(index) ||
                        result.genres.length <= genresToShowInitially
                          ? "Collapse"
                          : "+ Show More"}
                      </button>
                    </div>
                  )}
                </td>
                <td>{result.cities}</td>
                <td>{result.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isApiWorking && (
        <button className="api-emails" disabled={true}>
          <i className={apiFaIcon}></i>
        </button>
      )}
      <button
        className="delete-emails"
        onClick={() => deleteRows()}
        disabled={selectedRows.length === 0}
      >
        Delete Selected Rows
      </button>
    </div>
  );
}

export default Audience;
