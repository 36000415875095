import React, { useEffect, useRef, useState } from "react";
//CSS
import "./NavBar.css";

//fa icons
const homeIcon = "fa fa-home";
const logoutIcon = "fa fa-sign-out";

//LINK
//✅logged in
//https://admin.songdrop.band

//Dashboard
function NavBar(props) {
  //VIEW
  return (
    <div className="nav-bar">
      <a className="left-btn" title="Home" href="/">
        <i className={homeIcon} />
      </a>
      <div className="title-text">{props.title}</div>
      <div className="right-btn" title="Logout" onClick={() => handleLogout()}>
        <i className={logoutIcon} />
      </div>
    </div>
  );

  //Logout Request
  function handleLogout() {
    if (window.confirm("Are you sure you want to logout?")) {
      props.handleLogout();
    }
  }
}

export default NavBar;
