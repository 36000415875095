import React, { useState, useEffect, useCallback } from "react";

//CSS
import "./AdminUserBlockDeleteForm.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//AdminUserBlockDeleteForm
function AdminUserBlockDeleteForm(props) {
  //
  const [blockReason, setBlockReason] = useState("terms_violation");
  const [otherReason, setOtherReason] = useState("");

  const handleReasonChange = (event) => {
    const selectedReason = event.target.value;
    setBlockReason(selectedReason);

    // Clear the other reason when a non-'other' option is selected
    if (selectedReason !== "other") {
      setOtherReason("");
    }
  };

  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };

  useEffect(() => {}, [handleBlockUser]);

  const handleBlockUser = useCallback(() => {
    props.handleBlockUserButton(blockReason, otherReason);
  }, [blockReason, otherReason, props]);

  //VIEW
  return (
    <div>
      <label htmlFor="blockReason">{props.title}</label>
      <select
        id="blockReason"
        value={blockReason}
        onChange={handleReasonChange}
      >
        <option value="terms_violation">Violation of Terms of Service</option>
        <option value="scam_activity">Scam or Fraudulent Activity</option>
        <option value="harassment_abuse">Harassment or Abuse</option>
        <option value="suspicious_behavior">Suspicious Behavior</option>
        <option value="copyright_infringement">Copyright Infringement</option>
        <option value="offensive_content">Offensive Content</option>
        <option value="security_concerns">Security Concerns</option>
        <option value="other">Other</option>
      </select>

      {blockReason === "other" && (
        <div>
          <label htmlFor="otherReason">Other Reason:</label>
          <input
            type="text"
            id="otherReason"
            value={otherReason}
            onChange={handleOtherReasonChange}
            placeholder="Specify other reason"
          />
        </div>
      )}
      {!props.isApiWorking && (
        <button
          className="red_i_button"
          onClick={() => handleBlockUser()}
          disabled={
            props.selectedRows.length === 0 ||
            (blockReason === "other" && otherReason.trim() === "")
          }
        >
          {props.btn_title}
        </button>
      )}
      {props.isApiWorking && (
        <button className="red_i_button" disabled={true}>
          <i className={apiFaIcon}></i>
        </button>
      )}
    </div>
  );
}

export default AdminUserBlockDeleteForm;
