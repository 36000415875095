import React, { useEffect, useState } from "react";
import useLocalStorage from "../../../../components/useLocalStorage";

import { getUserForecasts } from "../../../../utils/apis/api/auth_api_users";
//chart json default values [jan, febr...dec]
import chartJSON from "../../../../assets/json/statistics/user_metrix_chart_data.json";
//CSS
import "./ForecastUsers.css";
import UserGrowthTable from "./UserGrowthTable";

//ForecastUsers React Class
function ForecastUsers(props) {
  //
  const [is_opened, setIsOpened] = useLocalStorage("forecast_opened", true);
  //API
  const [isApiWorking, setIsApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  //body:chart
  const [chartData, setChartData] = useState(chartJSON);

  //ViewDidLoad
  useEffect(() => {}, []);

  //API: getUserForecastDataAPI
  async function getUserForecastDataAPI() {
    setIsApiWorking(true);

    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`metrix_only=${props.metrix_only || "1"}`);
      const bParameters = bodyParams.join("&");
      const result = await getUserForecasts(bParameters);
      //✅SUCCESS
      console.log("USER_FORECAST_METRIX_SUCCESS", result);
    } catch (error) {
      //🚫ERROR
      console.log(error);
      setIsApiWorking(false);
    }
  }

  function exportToCSV() {
    const data = props.chartData;
    const csvRows = [];

    // Pushing header row
    csvRows.push("Month,Current Value,Forecast Value,Note");

    // Processing each item in the data array
    data.forEach((item) => {
      // Check if date exists in the item
      if (item.date && item.users_number !== undefined) {
        const [year, month] = item.date.split("-");
        const monthName = new Date(`${year}-${month}-01`).toLocaleString(
          "default",
          { month: "short" }
        );
        const formattedDate = `${year} ${monthName}`;

        // Pushing data row
        csvRows.push(`${formattedDate},${item.users_number},,`);
      }
    });

    // Creating the CSV content
    const csvContent = csvRows.join("\n");

    const filename = "songdrop-usermetrix.csv";

    // Creating a Blob and creating a link to trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(
        "Your browser does not support downloading files directly. Please try again in a different browser."
      );
    }
  }

  //VIEW
  return (
    <div className="forecast">
      <button
        className="more_options_bnt"
        onClick={() => setIsOpened(!is_opened)}
      >
        <i className="fa fa-cog" /> Advanced Options
      </button>
      {is_opened && (
        <div className="more_options">
          Here more advance options
          <UserGrowthTable />
          <button className="more_options_bnt" onClick={() => exportToCSV()}>
            <i className="fa fa-share-square" /> Export to CSV
          </button>
        </div>
      )}
    </div>
  );
}

export default ForecastUsers;
