import React, { useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import CopyToClipboard from "react-copy-to-clipboard";
//CSS
import "./ProfileHeader.css";
import isEmpty from "../../../components/isEmpty";
import placeholder from "../../../assets/img/ic_placeholder_profile.png";
//
//ProfileHeader class
function ProfileHeader(props) {
  //share
  const [shareActive, setShareActive] = useState(false);

  function shareSongDropUk() {
    setShareActive(true);
  }
  //VIEW
  return (
    <div>
      <div>
        {shareActive && (
          <div className="profile_share_enabled">Link copied.</div>
        )}
      </div>
      <div
        className="profile_header"
        style={{ backgroundImage: `url(${props.user.bcg_url})` }}
      >
        <div className="img-image">
          <Zoom>
            <img
              id="p-image"
              alt=""
              style={{
                width: "60px",
                height: "60px",
                borderRadius: props.user.usertype === 1 ? "2px" : "30px",
              }}
              src={
                isEmpty(props.user.profile_img_url)
                  ? placeholder
                  : props.user.profile_img_url
              }
              width="500"
            />
          </Zoom>
        </div>
        <div className="username">
          <h1>{props.user.name}</h1>
          <h2>@{props.user.username}</h2>
          <h3>{props.user.location}</h3>
        </div>
        <div className="share">
          <a
            href={`https://songdrop.uk/@/${props.user.username}`}
            target="_blank"
          >
            <i className="fa fa-share-square" />
          </a>
          {/* <CopyToClipboard
            text={window.location.href}
            onCopy={() => shareSongDropUk()}
          >
            <i className="fa fa-share-square" />
          </CopyToClipboard> */}
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
