import React, { useEffect, useState, useContext } from "react";
import { getSession } from "../../../../utils/apis/Accounts";

import {
  getMFAQRCode,
  verifyMFACode,
  disableMFACode,
} from "../../../../utils/apis/api/auth_api_mfa";

import "./MFA.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const apiFaEnabled = "fa fa-check-square";
const faWarning = "fa fa-exclamation-triangle";

//CSS

//MFA
function MFA(props) {
  //API
  const [isApiWorking, setIsApiWorking] = useState(true);
  //
  const [userCode, setUserCode] = useState("");
  const [mfaEnabled, setMFAEnabled] = useState(false);
  const [isMfaDisableBtnVisible, setMfaDisableBtnVisible] = useState(false);
  const [image, setImage] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //CHECK_FOR_MFA_ENABLED
    getSession().then(({ mfaEnabled }) => {
      setIsApiWorking(false);
      setMFAEnabled(mfaEnabled);
    });
  }, []);

  //GET_MFA_QR
  const getQRCode = async (event) => {
    event.preventDefault();
    setIsApiWorking(true);
    //
    try {
      //getMFAQRCode -> result will give back a img data
      const result = await getMFAQRCode();
      //✅SUCCESS
      setImage(result);
      setIsApiWorking(false);
      //🚫ERROR
    } catch (error) {
      console.log("GET_QR_CODE_ERROR", error.message);
      alert(error.message);
      setIsApiWorking(false);
    }
  };

  //ENABLE_MFA
  const enableMFA = async (event) => {
    event.preventDefault();
    console.log("USER CODE", userCode);
    setIsApiWorking(true);

    //CHECK FOR TEXT
    if (userCode.length < 6) {
      alert("Please provide a 6-digit code");
      setIsApiWorking(false);
      return;
    }
    if (!userCode.match(/\d/)) {
      alert("Only numbers are allowed");
      setIsApiWorking(false);
      return;
    }

    try {
      //verifyMFAQR
      const result = await verifyMFACode(userCode);
      //✅SUCCESS
      console.log("MFA_RESULT", result);
      setIsApiWorking(false);
      //
      if (result.Status && result.Status === "SUCCESS") {
        getSession().then(({ user, accessToken, headers }) => {
          //->ENABLE_MFA_ON_COGNITO
          const SoftwareTokenMfaSettings = {
            PrefferedMfa: true,
            Enabled: true,
          };
          user.setUserMfaPreference(null, SoftwareTokenMfaSettings, () => {});
          //->update ui
          setMFAEnabled(true);
          //
          setUserCode("");
        });
      }
      //🚫ERROR
    } catch (error) {
      if (error.message == "EnableSoftwareTokenMFAException") {
        alert("Incorrect 6-digit code.");
      }
      if (error.message == "InvalidParameterException") {
        alert("Please provide a 6-digit code.");
      }
      setIsApiWorking(false);
    }
  };

  //DISABLE_MFA
  const disableMFA = async (event) => {
    event.preventDefault();
    console.log("USER CODE", userCode);
    setIsApiWorking(true);

    //CHECK FOR TEXT
    if (userCode.length < 6) {
      alert("Please provide a 6-digit code");
      setIsApiWorking(false);
      return;
    }
    if (!userCode.match(/\d/)) {
      alert("Only numbers are allowed");
      setIsApiWorking(false);
      return;
    }

    try {
      //disableMFACode
      const result = await disableMFACode(userCode);
      //✅SUCCESS
      console.log("MFA_DISABLE_RESULT", result);
      setIsApiWorking(false);
      //
      if (result.Status && result.Status === "SUCCESS") {
        getSession().then(({ user, accessToken, headers }) => {
          //->ENABLE_MFA_ON_COGNITO
          const SoftwareTokenMfaSettings = {
            PrefferedMfa: true,
            Enabled: true,
          };
          user.setUserMfaPreference(null, null, () => {});
          setMFAEnabled(false);
        });
      }
      //🚫ERROR
    } catch (error) {
      if (error.message == "EnableSoftwareTokenMFAException") {
        alert("Incorrect 6-digit code.");
      }
      if (error.message == "InvalidParameterException") {
        alert("Please provide a 6-digit code.");
      }
      setIsApiWorking(false);
    }
  };

  //VIEW
  return (
    <div
      className="mfa-user"
      style={{
        backgroundColor: isApiWorking
          ? "transparent"
          : mfaEnabled
          ? "#bcffaa"
          : "#ffbdbd",
      }}
    >
      <h1>Multi-Factor Authentication</h1>
      {!mfaEnabled && !isApiWorking && (
        <p>
          <i className={faWarning} />
          YOUR MFA NOT Enabled
        </p>
      )}
      {mfaEnabled ? (
        <div>
          <i className={apiFaEnabled} /> MFA is enabled
          <form
            className="mfa-submit"
            onSubmit={disableMFA}
            style={{
              display: isMfaDisableBtnVisible ? "block" : "none",
            }}
          >
            <input
              placeholder="Enter 6 digit code"
              value={userCode}
              onChange={(event) => setUserCode(event.target.value)}
              required
            />
            {/* API_WORKING */}
            {isApiWorking && (
              <button type="">
                <i className={apiFaIcon} />
              </button>
            )}
            {/* API_DONE */}
            {!isApiWorking && <button type="submit">Disable MFA</button>}
          </form>
          {!isMfaDisableBtnVisible && (
            <form className="mfa-submit">
              <button
                onClick={() => setMfaDisableBtnVisible(!isMfaDisableBtnVisible)}
              >
                Disable MFA
              </button>
            </form>
          )}
        </div>
      ) : image ? (
        <div>
          <div>
            <strong>Scan this QR code:</strong>
          </div>
          <img alt="" src={image} />
          <br />
          <form className="mfa-submit" onSubmit={enableMFA}>
            <input
              placeholder="Enter 6 digit code"
              value={userCode}
              onChange={(e) => setUserCode(e.target.value)}
              required
            />
            {/* API_WORKING */}
            {isApiWorking && (
              <button type="">
                <i className={apiFaIcon} />
              </button>
            )}
            {/* API_DONE */}
            {!isApiWorking && <button type="submit">Confirm Code</button>}
          </form>
        </div>
      ) : (
        <div>
          {/* API_WORKING */}
          {isApiWorking && (
            <div className="">
              <i className={apiFaIcon} />
            </div>
          )}
          {/* API_DONE */}
          {!isApiWorking && (
            <button className="s-mfa" onClick={getQRCode}>
              Enable MFA
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default MFA;
