import React, { useEffect, useState } from "react";

//CSS
import "./IAP_SearchBox.css";

const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//IAP_SearchBox
function IAP_SearchBox(props) {
  //ViewDidLoad
  useEffect(() => {
    //title
  }, []);

  //iap
  function changeSearchBox(prop, value) {
    props.updateSearchValues(prop, value);
  }

  function setNewActiveTab(new_tab) {
    props.setActiveTab(new_tab);
  }

  //VIEW
  return (
    <div className="IAP_SearchBox">
      <div>Lookup IAP doc number</div>
      <form onSubmit={props.confirmSearch}>
        <div>
          <input
            onChange={(e) => changeSearchBox("iap_number", e.target.value)}
            id="iap-input"
            name="iap"
            type="text"
            placeholder="IAP number"
            value={props.search_values["iap_number"]}
          />
          {!props.isApiWorking && <button type="submit">Search</button>}
          {!props.isApiWorking && (
            <select
              value={props.active_tab}
              onChange={(e) => setNewActiveTab(e.target.value)}
            >
              {optionList(props.tabs)}
            </select>
          )}
          {props.isApiWorking && (
            <button type="submit">
              <i className={apiFaIcon} />
            </button>
          )}
        </div>
        <div>
          <input
            onChange={(e) => changeSearchBox("user_id", e.target.value)}
            id="iap-input"
            name="iap"
            type="text"
            placeholder="User Id"
            value={props.search_values["user_id"]}
          />
        </div>
        <div>
          <input
            onChange={(e) => changeSearchBox("track_title", e.target.value)}
            id="iap-input"
            name="iap"
            type="text"
            placeholder="Track Title"
            value={props.search_values["track_title"]}
          />
        </div>
        <div>
          <input
            onChange={(e) => changeSearchBox("album_title", e.target.value)}
            id="iap-input"
            name="iap"
            type="text"
            placeholder="Album Title"
            value={props.search_values["album_title"]}
          />
        </div>
      </form>
    </div>
  );

  //Creates a list of <option/>
  function optionList(list) {
    let tableRows = [];
    list.map((item, index) => {
      tableRows.push(
        <option key={index} value={item}>
          {`${item}`}
        </option>
      );
    });
    return tableRows;
  }
}

export default IAP_SearchBox;
