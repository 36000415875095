import React from "react";
import "./BlogSearchBar.css";
import isEmpty from "../../../../components/isEmpty";
//BlogSearchBar
function BlogSearchBar(props) {
  //
  function _createNew(e) {
    e.preventDefault();
    props.createNew();
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      _formSubmit(e);
    }
  }

  function _formSubmit(e) {
    e.preventDefault();
    props.submitSearch();
  }

  return (
    <div className="searchBar-wrap">
      <form>
        <button onClick={(e) => _createNew(e)}>
          <i className="fa fa-plus" />
        </button>
        <input
          type="text"
          placeholder="Search"
          value={props.value}
          onChange={props.handleSearchKey}
          onKeyDown={handleKeyDown}
        />
        {props.value && (
          <button className="close-button" onClick={props.clearSearch}>
            <i className="fa fa-close" />
          </button>
        )}
        <button disabled={isEmpty(props.value)} onClick={(e) => _formSubmit(e)}>
          Go
        </button>
        <a href="https://songdrop.uk/blog" target="_blank">
          <i className="fa fa-external-link-square" aria-hidden="true"></i>
        </a>
      </form>
    </div>
  );
}

export default BlogSearchBar;
