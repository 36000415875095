import React, { useEffect, useRef, useState } from "react";

export default function antennaSVG() {
  return (
    <svg viewBox="0 0 28 28">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          transform="translate(-746.000000, -335.000000)"
          fill="#6d6d6d"
          fill-rule="nonzero"
        >
          <g
            id="fdec33c816000ed44157ee45df5daacd"
            transform="translate(746.000000, 335.000000)"
          >
            <g
              id="Group"
              transform="translate(14.000000, 14.000000) scale(-1, 1) rotate(-180.000000) translate(-14.000000, -14.000000) "
            >
              <path
                d="M18.1513014,27.9636739 C16.3135755,27.7308125 14.5381358,26.8960589 13.2278418,25.6410678 C12.9046969,25.3286789 12.8646967,25.2663155 12.8646967,25.0732179 C12.8646967,24.7948714 13.0461264,24.6132166 13.3355568,24.6132166 C13.5112722,24.6132166 13.6078442,24.6701446 13.9821323,25.0162899 C15.0144245,25.9588921 16.256718,26.58367 17.7030129,26.8960589 C18.5255895,27.0665569 19.9095982,27.0665569 20.7318891,26.8960589 C23.8967662,26.2203605 26.2110665,23.9031897 26.8859279,20.7343872 C27.0562147,19.9165118 27.0562147,18.5307864 26.8802136,17.7017542 C26.579926,16.2591009 25.9502077,15.0098312 25.0087732,13.9762586 C24.6230565,13.5560211 24.583342,13.4764935 24.6001992,13.2264679 C24.6170565,13.0505346 24.8667723,12.857437 25.0879166,12.857437 C25.2467747,12.857437 25.3319181,12.9198004 25.6947776,13.3059955 C27.356788,15.0721945 28.2187935,17.68459 27.9522204,20.1322089 C27.5325034,23.9827173 24.7530573,27.0493927 20.9473191,27.8501039 C20.2953149,27.9862735 18.8204485,28.0432015 18.1513014,27.9636739 Z"
                id="Path"
              ></path>
              <path
                d="M18.6218758,25.9646135 C17.2152955,25.8682078 15.6667143,25.1865018 14.6061362,24.1984145 C14.3224201,23.9372321 14.2827056,23.8691474 14.2827056,23.6872065 C14.2827056,23.40886 14.4869926,23.1931628 14.7478514,23.1931628 C14.8952809,23.1931628 15.0201388,23.266969 15.3321408,23.5395942 C16.2452894,24.334584 17.175581,24.7719857 18.3213025,24.9536405 C18.9395921,25.0557676 20.0853136,24.9991257 20.6638887,24.8400705 C22.7681877,24.2664992 24.3336261,22.6593554 24.8667723,20.5241254 C25.0256305,19.8881908 25.0142018,18.3943308 24.8442008,17.7867172 C24.583342,16.8326722 24.1919109,16.0886029 23.5793356,15.3731406 C23.176476,14.9016965 23.1199042,14.7426413 23.2501907,14.4986232 C23.3410484,14.3226899 23.6244788,14.2202767 23.8230515,14.2886475 C24.1236248,14.3964961 24.861058,15.3619839 25.2693463,16.1798594 C26.057637,17.7644037 26.1939236,19.6950932 25.6267771,21.3874861 C24.968773,23.3525041 23.4090489,24.931327 21.4296078,25.6239036 C20.5504594,25.9305711 19.6770253,26.0384197 18.6218758,25.9646135 Z"
                id="Path"
              ></path>
              <path
                d="M18.6164472,24.0450807 C18.173873,23.9827173 17.8675853,23.9031897 17.3855823,23.7212489 C16.7107209,23.4715094 15.6838573,22.7159973 15.6329998,22.4322154 C15.5989996,22.2505607 15.7181432,21.9664927 15.8655727,21.8926865 C16.0924313,21.7676737 16.2627181,21.8246017 16.6484348,22.1481474 C17.0624374,22.5003002 17.7544418,22.8578883 18.2818737,22.9943439 C18.7638767,23.1193567 19.6658824,23.1079138 20.2047429,22.9660229 C20.8967473,22.7900896 21.3844647,22.5003002 21.9404682,21.9438931 C22.496186,21.3872 22.7856164,20.8931563 22.9613318,20.2060151 C23.1030469,19.6664862 23.1144756,18.7690831 22.9896176,18.2807608 C22.8590454,17.7752743 22.5019003,17.0824117 22.1444695,16.6395746 C21.7870386,16.1964514 21.7473241,16.0205181 21.9630397,15.7762139 C22.0650404,15.6569225 22.1444695,15.6171588 22.3090419,15.6171588 C22.496186,15.6171588 22.5587578,15.6569225 22.779902,15.8955053 C23.6419075,16.8552717 24.0561958,17.9457723 24.0505398,19.2462487 C24.0447672,20.5638892 23.608193,21.6315042 22.6836157,22.5855492 C21.815896,23.4829522 20.7210319,23.9884387 19.4733098,24.0622449 C19.1947366,24.0791231 18.8035912,24.0734017 18.6164472,24.0450807 Z"
                id="Path"
              ></path>
              <path
                d="M5.68979428,21.7167532 C4.81064588,21.5805836 3.81806819,21.1546247 3.18263561,20.6376954 C1.1009082,18.9510239 0.834335093,15.520753 2.48491693,11.8238644 C3.31863647,9.96125962 4.54950138,8.19506057 6.17179732,6.53098869 C8.58809827,4.05504882 11.2992582,2.38525553 14.0275611,1.69811427 C15.054139,1.43693194 15.7178575,1.36312576 16.7104352,1.40288955 C17.9924433,1.44837476 18.9281635,1.74359948 19.8015975,2.37409878 C22.3087562,4.16289739 22.4276141,8.33151618 20.0910279,12.5281699 C19.4613097,13.6581482 18.191016,15.4529543 17.9584431,15.5436386 C17.9015856,15.5608029 17.0338658,14.7314846 15.3664267,13.0619774 L12.8592681,10.5517091 L13.0066976,10.2564843 C13.7044162,8.8650376 13.6078442,7.43954848 12.7572674,6.5367101 C12.2466928,5.9971812 11.5886886,5.73599887 10.7663977,5.74172028 C10.4998246,5.74744169 10.1595367,5.77576266 10.0063929,5.80980505 C8.16295273,6.23576397 6.40465593,7.9507564 5.88293835,9.8190826 C5.73550885,10.3528901 5.73550885,11.1876437 5.88293835,11.6590878 C6.40465593,13.3400379 8.36152541,13.9590943 10.2106799,13.0219275 L10.5566821,12.8459942 L13.0861266,15.3788621 L15.6101425,17.9060085 L15.383284,18.1217057 C15.258426,18.240997 14.9578527,18.4853012 14.7195655,18.6726774 C12.7401244,20.1605299 10.6641114,21.1543386 8.66181302,21.5745762 C8.10609523,21.6938675 7.75437873,21.7279099 6.99437393,21.7450742 C6.46694204,21.756517 5.87693832,21.7450742 5.68979428,21.7167532 Z"
                id="Path"
              ></path>
              <path
                d="M18.0490151,20.1834155 C17.5441547,20.0243603 17.0224372,19.47911 16.8861506,18.9739096 C16.7952929,18.6331997 16.8464361,18.0993922 16.9938656,17.798446 L17.1187235,17.5429851 L13.9312748,14.3510109 C12.172978,12.5905332 10.7263975,11.1536013 10.7095402,11.1536013 C10.692683,11.1536013 10.5392534,11.2557285 10.3692524,11.3864627 C9.5015326,12.0395615 8.4180972,12.3007439 7.78295033,12.0112406 C6.67122904,11.5057541 6.9263735,9.67147024 8.30466791,8.29146632 C9.45038942,7.13860235 10.9818276,6.72408625 11.7135465,7.3657423 C12.0084055,7.62120322 12.1275492,7.92214935 12.1275492,8.40503629 C12.1275492,9.04669234 11.878119,9.71123403 11.3958303,10.3586115 C11.265258,10.5291095 11.1632574,10.6824433 11.1632574,10.6993214 C11.1632574,10.7161996 12.5984093,12.1645743 14.3567061,13.925052 L17.5444405,17.1167401 L17.7995849,16.9917273 C18.1398728,16.8212293 18.7127335,16.787187 19.0927359,16.9121997 C19.4558811,17.0372125 19.8641694,17.3893653 20.0570277,17.7641176 C20.1818857,18.0084218 20.2101715,18.1277131 20.2101715,18.5081869 C20.2101715,19.2350919 19.9038839,19.7520212 19.2970229,20.047246 C18.9567351,20.2174579 18.361017,20.2798212 18.0490151,20.1834155 Z M18.4235889,19.6267224 C18.5030179,19.5697944 18.6107329,19.4279034 18.6733047,19.308612 C18.7641624,19.1269573 18.769591,19.0588725 18.7187336,18.8826531 C18.5201609,18.2352756 17.7090129,18.1274271 17.3915823,18.7121551 C17.0395801,19.3540972 17.8278708,20.0526813 18.4235889,19.6267224 Z"
                id="Shape"
              ></path>
              <path
                d="M0.726905844,17.7583962 C-0.0899564488,15.9241124 -0.226243022,13.5728992 0.358046376,11.2614499 C1.65691171,6.13907215 6.15494007,1.63546484 11.2766866,0.334988513 C12.3544077,0.0623633611 12.8761253,0 14.1127045,0 C15.1564254,0 15.3492838,0.0171642278 15.9901449,0.153333769 C16.7161495,0.306667537 17.4535827,0.550971713 17.8392995,0.760947433 C17.9584431,0.823310795 18.0038719,0.863074589 17.9413001,0.85163177 C17.0621517,0.624491822 17.0167228,0.624491822 15.9278588,0.624491822 C14.9409955,0.624491822 14.7424228,0.64165605 14.090133,0.777825591 C11.860976,1.24926971 9.68867664,2.32260609 7.47666269,4.03187712 C6.59751429,4.70786162 4.69750231,6.61595161 4.01664087,7.50191184 C2.29234429,9.75671923 1.27119499,11.8521854 0.806049201,14.0783857 C0.562047662,15.2710135 0.562047662,16.730545 0.806049201,17.6903114 C0.851478059,17.8608094 0.879763951,18.0027004 0.868335308,18.0141432 C0.85719238,18.0252999 0.794906273,17.9117299 0.726905844,17.7583962 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
