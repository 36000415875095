import React from "react";
import { Switch } from "react-router-dom";

//ROUTES
//Unauth
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Login from "./containers/unauth/login/Login";
import Register from "./containers/unauth/register/Register";
import Validate from "./containers/unauth/validate/Validate";
import MFA from "./containers/unauth/mfa/MFA";
//->error route
import NotFound from "./containers/notfound/NotFound";

//Auth
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Home from "./containers/auth/home/Home";

//ROUTES class
function Routes(props) {
  //
  //CALLBACK: Logout user
  function handleLogout() {
    props.handleLogout();
  }

  //VIEW
  return (
    <Switch>
      {/* UnauthenticatedRoute */}
      <UnauthenticatedRoute path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/login:id">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/register">
        <Register />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/validate:id">
        <Validate />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/mfa:id">
        <MFA />
      </UnauthenticatedRoute>
      {/* AuthenticatedRoute */}
      <AuthenticatedRoute path="/">
        <Home handleLogout={handleLogout} />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <UnauthenticatedRoute>
        <NotFound title={"Unknown error"} />
      </UnauthenticatedRoute>
    </Switch>
  );
}

export default Routes;
