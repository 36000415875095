import React, { useEffect, useState } from "react";
import { getSurveys } from "../../../../../utils/apis/api/auth_api_emails";
//JS
import EventsSavedTable from "./EventsSavedTable/EventsSavedTable";
import EventFormItemSelect from "./EventForms/EventFormItemSelect/EventFormItemSelect";
//CSS
import "./Events.css";

const events_json = [
  {
    event_id: "1123s34",
    title: "Xminus1 @ Fiddlers Elbow",
    status: 1,
    opened: "100/1000",
    coming: "65",
    maybe: "10",
    created_at: "2023-12-06 11:11",
    updated_at: "2023-12-06 12:12",
  },
  {
    event_id: "123s34",
    title: "Untitled Event",
    status: 0,
    opened: "0",
    created_at: "2023-12-06 11:11",
    updated_at: "2023-12-06 12:12",
  },
];

//Surveys React Class
function Events(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [events, setEvents] = useState([]);
  //
  const [selectBtnsVisible, setSelectBtnsVisible] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Music | SongDrop.band";
    //API
    getUserEvents();
  }, []);

  //AUDIENCE API
  const getUserEvents = async () => {
    setApiWorking(true);
    //
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getSurveys(bParameters);
      console.log(result);
      setEvents(events_json);
      // console.log("RESULT", result);
      setApiWorking(false);
      //🚫Error
    } catch (error) {
      setApiWorking(false);
      //   alert(error.message);
    }
  };
  return (
    <div className="events">
      {/* CREATE NEW EVENTS IS HERE */}
      <div className="startview">
        <div className="title">Create Event notifications</div>
        <div className="subtitle">
          Invite your audience for your performances. Create anticipations for
          an event, track engagement, and follow-up after events.
        </div>
        <div className="question-wrapper">
          <button
            className="addquestionbtn"
            onClick={() => setSelectBtnsVisible(!selectBtnsVisible)}
          >
            Add an event{" "}
            <i className={`fa fa-caret-${selectBtnsVisible ? "up" : "down"}`} />
          </button>
          {selectBtnsVisible && <EventFormItemSelect />}
        </div>
      </div>
      {/* ALL SAVED SURVEYS IS HERE */}
      <div className="eventstable">
        <EventsSavedTable events={events} isApiWorking={isApiWorking} />
      </div>
    </div>
  );
}

export default Events;
