import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//CREATE_BLOG
export const createBlogAPI = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await create_blog_api(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const create_blog_api = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/admin/create_blog" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//DELETE_BLOG
export const deleteBlogAPI = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await delete_blog_api(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const delete_blog_api = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/admin/delete_blog" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//PUBLISH_BLOG
export const publishBlogAPI = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await publish_blog_api(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const publish_blog_api = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/admin/publish_blog" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//EDIT_BLOG
export const editBlogAPI = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await edit_blog_api(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const edit_blog_api = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/admin/edit_blog_by_id" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//GET_ALL_BLOG
export const getBlogArticlesAPI = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_articles_api(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_articles_api = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/admin/list_all_blog" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//GET_BLOG_BY_ID
export const getBlogArticleByIdAPI = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_article_by_id_api(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_article_by_id_api = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/admin/get_blog_by_id" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
