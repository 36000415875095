import React, { useEffect, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import "./ImageCrop.css";
//CSS
import "./Marketing.css";

function YO() {
  const [imageRef, set_imageRef] = useState("");
  const [croppedImageUrl, set_croppedImageUrl] = useState("");

  const [state, setState] = useState({
    src: null,
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 50,
      height: 50,
    },
  });

  //

  const onSelectFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setState({ src: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
      console.log("read_image", e.target.files[0]);
    }
  };

  async function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.jpeg"
      );
      setState({ croppedImageUrl });
    }
  }

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    console.log("ima_loaded", image);
    set_imageRef(image);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setState({ crop });
  };

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        const fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  }

  return (
    <div className="image-crop-page">
      <div>
        <div>
          <button>
            <i className="fa fa-th" />
            3x3 Grid
          </button>
          <button>
            <i className="fa fa-pause" />
            3x1 Carousel
          </button>
        </div>
        <input type="file" accept="image/*" onChange={(e) => onSelectFile(e)} />
      </div>
      {state.src && (
        <img alt="Crop" style={{ maxWidth: "100%" }} src={state.src} />
      )}
      {state.src && (
        <ReactCrop
          src={state.src}
          crop={state.crop}
          ruleOfThirds
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      )}
      {croppedImageUrl && (
        <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
      )}
    </div>
  );
}

export default YO;
