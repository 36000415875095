import React, { useState, useEffect } from "react";
import ReactLinkify from "react-linkify";
//CSS
import "./GithubIssues.css";

//
const GITHUB_READ_ACCESS_TOKEN = process.env.REACT_APP_GITHUB_REPO_API_KEY;
const GITHUB_READ_REPO_OWNER = process.env.REACT_APP_GITHUB_REPO_OWNER;
const GITHUB_READ_REPO_NAME = process.env.REACT_APP_GITHUB_REPO_NAME;

//GithubIssues
function GithubIssues() {
  //
  const [issues, setIssues] = useState([]);
  //
  const accessToken = GITHUB_READ_ACCESS_TOKEN;
  const repoOwner = GITHUB_READ_REPO_OWNER;
  const repoName = GITHUB_READ_REPO_NAME;

  useEffect(() => {
    fetch(`https://api.github.com/repos/${repoOwner}/${repoName}/issues`, {
      headers: {
        Authorization: `token ${accessToken}`,
      },
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setIssues(data); // Assuming the issues are directly in the response
        } else if (Array.isArray(data.data)) {
          setIssues(data.data); // If the issues are nested under a 'data' key
        } else {
          console.error("Unexpected response structure:", data);
        }
      })
      .catch((error) => console.error("Error fetching issues:", error));
  }, [accessToken, repoOwner, repoName]);

  const parseIssueBody = (body) => {
    // Define regular expressions to match the individual sections
    const userIdRegex = /\[u1\]:([^#]+)##/;
    const titleRegex = /##\s(.*?)\sbody/;
    const reportIdRegex = /reportId:(.*?),\smessage:/;
    const messageRegex = /message:(.*)<details>/;
    const detailsRegex = /<details>(.*?)<\/details>/;

    const userIdMatch = body.match(userIdRegex);
    const titleMatch = body.match(titleRegex);
    const reportIdMatch = body.match(reportIdRegex);
    const messageMatch = body.match(messageRegex);
    const detailsMatch = body.match(detailsRegex);

    let result = [];

    if (userIdMatch) {
      const user = userIdMatch[1].trim();
      result.push(<p>User Details: {user}</p>);
    }

    if (titleMatch) {
      const title = titleMatch[1].trim();
      result.push(<p>Title: {title}</p>);
    }

    if (reportIdMatch) {
      const reportId = reportIdMatch[1].trim();
      result.push(<p>Report ID: {reportId}</p>);
    }

    if (messageMatch) {
      const message = messageMatch[1].trim();
      result.push(<p>Message: {message}</p>);
    }

    if (detailsMatch) {
      const details = detailsMatch[1].trim();
      result.push(<p>Details: {details}</p>);
    }

    if (result.length === 0) {
      result = <ReactLinkify>{body}</ReactLinkify>;
    }

    return <div>{result}</div>;
  };

  return (
    <div>
      <h1>GitHub Issues</h1>
      <ul>
        {issues.map((issue) => (
          <li key={issue.id}>
            <a href={issue.html_url} target="_blank" rel="noopener noreferrer">
              {issue.title}
            </a>
            <div>
              <p>Submitted by: {issue.user.login} ios app</p>
              {parseIssueBody(issue.body)}{" "}
              <ul className="label-list">
                {issue.labels.map((label) => (
                  <li
                    key={label.id}
                    className={`label label-${label.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}`}
                  >
                    {label.name}
                  </li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default GithubIssues;
