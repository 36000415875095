import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

//CSS
import "./UserMetrixChart.css";

// UserMetrixChart class
function UserMetrixChart(props) {
  // Custom Tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    const labels = {
      standard_user_count: "Standard User", // Revised label for standard_user_count
      artist_user_count: "Artist User", // Revised label for artist_user_count
    };

    if (active && payload && payload.length) {
      return (
        <div className="user-metrix-tooltip">
          <div className="title">{`Date: ${label}:`}</div>
          <div className="label">{`New Users: ${payload[0].payload.users_number}`}</div>
          {payload.map((entry, index) => (
            <div key={`tooltip-${index}`} className="label">
              <span style={{ color: entry.fill }}>
                &#9632; {/* Colored square */}
              </span>{" "}
              {`${labels[entry.dataKey]}: ${entry.value}`}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  // VIEW
  return (
    <BarChart
      className="stat-wrapper"
      style={{ backgroundColor: props.backgroundColor }}
      width={props.width} // props.width -> relative windows width
      height={200}
      data={props.data}
      margin={{
        top: 16,
        right: 32,
        left: 12,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip content={<CustomTooltip />} cursor={{ fill: "#f3f3f390" }} />
      <Legend />
      <Bar
        dataKey="standard_user_count"
        stackId="a"
        fill="#8884d8"
        name="Standard Users"
      />
      <Bar
        dataKey="artist_user_count"
        stackId="a"
        fill="#82ca9d"
        name="Artist Users"
      />
    </BarChart>
  );
}

export default UserMetrixChart;
