import React, { useState, useEffect, useRef } from "react";
import { CSVLink } from "react-csv"; // import CSVLink from react-csv
import isEmpty from "../../../../components/isEmpty";
import {
  Select,
  CaretIcon,
  MultiSelectOptionMarkup,
  ModalCloseButton,
} from "react-responsive-select";
import { fileToS3 } from "../../../../utils/apis/s3/UploadImage";
//API
import { getEPKContactList } from "../../../../utils/apis/api/auth_api_presskit";
// CSS
import "react-responsive-select/dist/react-responsive-select.css";
import countries from "../../../../assets/json/countries.json";
//Genres: Predefined [1600 items]
import genreList from "../../../../assets/json/genres.json";

//JS
import EPKViewer from "../EPK/EPKViewer";
import CreateEPKContact from "../EPK/CreateEPKContact";
//CSS
import "./BulkEmailSender.css";

const apiFaIcon = "fa fa-circle-o-notch fa-spin";

// BulkEmailSender class
function BulkEmailSender(props) {
  //BulkEmailSenderRef
  const BulkEmailSenderRef = useRef(null);
  //
  const [isApiWorking, setIsApiWorking] = useState(false);
  //
  const [sender_email, setSenderEmail] = useState(
    `${props.user.username}-epk@sdappnet.com`
  );
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  //
  //EPK_CONTACT_LIST -> this will be exported as a bulk CSV file to send emails.
  const [epk_contacts, setEPKContactList] = useState([]);

  //
  const [add_new_opened, setAddNewOpened] = useState(false);

  //FILTERING_VALUES_TO_CREATE_BULK_LIST_TO_SEND
  const [selected_genres, setSelectedGenres] = useState([]);
  const [selected_countries, setSelectedCountries] = useState([]);
  //
  //
  // ViewDidLoad
  useEffect(() => {
    //API
    get_list_of_epk_contacts();
    //
  }, []);

  async function get_list_of_epk_contacts() {
    setIsApiWorking(true);
    //
    try {
      var queryParams = [];
      queryParams.push(`genres=${"all"}`);
      queryParams.push(`limit=${50}`);
      let qParameters = queryParams.join("&");
      let bParameters = null;

      //✅SUCCESS
      let result = await getEPKContactList(qParameters, bParameters);
      console.log(result);
      setIsApiWorking(false);

      // Sort stations alphabetically by name
      const sortedStations = result.contacts.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const uniqueStations = sortedStations.filter(
        (station, index, self) =>
          index === self.findIndex((s) => s.name === station.name)
      );
      // setEPKContactList(uniqueStations);

      //🚫ERROR
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
    }
  }

  //API_SEND_EMAIL
  async function sendEmail(e) {
    e.preventDefault();

    setIsApiWorking(true);
    if (isEmpty(email)) {
      alert("Please enter an email address");
      setIsApiWorking(false);
      return;
    }
    if (isEmpty(subject)) {
      alert("Please enter a subject");
      setIsApiWorking(false);
      return;
    }
    if (isEmpty(message)) {
      alert("Please enter a message");
      setIsApiWorking(false);
      return;
    }
  }

  //API_UPLOAD_BULK_EMAIL
  async function sendBulkFile(e) {
    e.preventDefault();

    setIsApiWorking(true);
    if (isEmpty(email)) {
      alert("Please enter an email address");
      setIsApiWorking(false);
      return;
    }
    if (isEmpty(subject)) {
      alert("Please enter a subject");
      setIsApiWorking(false);
      return;
    }
    if (isEmpty(message)) {
      alert("Please enter a message");
      setIsApiWorking(false);
      return;
    }
  }

  //VALIDATE_IMG_AND_UPLOAD
  function bulkFileInput(event) {
    const file = event.target.files[0];
    uploadBulkEmails(file, "path", "filename");
  }

  //API: UPLOAD_EXTRA_IMAGE
  const uploadBulkEmails = async (file, path, filename) => {
    alert("under development");
    // try {
    //   //1.UPLOAD_TO_S3
    //   const album_id = props.album_id;
    //   const extra_id = props.extra.extra_id;
    //   //2.RECEIVE_S3_URL
    //   var uploadURL = await fileToS3(file, path, filename);
    //   console.log("VIDEO_UPLOAD_SUCCESS", uploadURL);
    //   //3.UPLOAD_TO_DB
    //   var queryParams = [];
    //   queryParams.push(`album_id=${album_id}`);
    //   queryParams.push(`extra_id=${extra_id}`);
    //   const qParameters = queryParams.join("&");
    //   var bodyParams = [];
    //   bodyParams.push(`video_img_url=${uploadURL}`);
    //   bodyParams.push(`token=${props.token}`);
    //   const bParameters = bodyParams.join("&");
    //   //4.
    //   const response = await updateExtraContent(qParameters, bParameters);
    //   console.log(response);
    //   //✅SUCCESS
    //   setVideoApiState(2);
    //   setTimeout(function() {
    //     setVideoApiState(0);
    //   }, 1000);
    // } catch (err) {
    //   //❌FAILED
    //   alert(err.message);
    //   console.log("EXTRA_CONTENT_VIDEO_UPDATE_FAILED", err.message);
    // }
  };

  // VIEW
  return (
    <div className="bulk_sender">
      <div className="email">
        <div className="uploaded_file">
          <h1>
            <strong>Send EPK to Radios/Magazines/Blogs</strong>
          </h1>
          <p>
            To get started, you can upload a CSV or TXT file with up to 5,000
            recipients and also add a customised email message.
          </p>
          <input
            type="file"
            accept="image/*"
            // style={{ display: "none" }}
            ref={BulkEmailSenderRef}
            onChange={bulkFileInput}
            placeholder="Upload recipient file"
          />
          {isApiWorking && (
            <button disabled={true}>
              <i className={apiFaIcon} />
            </button>
          )}
          {!isApiWorking && (
            <button type="file" onClick={(e) => sendBulkFile(e)}>
              Upload
            </button>
          )}
        </div>
        <div className="message">
          <p>Add personalised message</p>
          <input
            placeholder="Sender Email"
            disabled
            value={`from: ${sender_email}`}
          />
          <input
            placeholder="Enter email subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <textarea
            placeholder="Email text(Optional)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {isApiWorking && (
            <button disabled={true}>
              <i className={apiFaIcon} />
            </button>
          )}
          {!isApiWorking && (
            <button onClick={(e) => sendEmail(e)}>Send Email</button>
          )}
          <EPKViewer
            selected_email_html={props.selected_email_html}
            load_more_epks={props.load_more_epks}
          />
        </div>
      </div>
      <div>
        <i className="fa fa-info-circle" /> To be able to collect royalties in a
        Radio, you must obtain an IPRS number for each of your songs.
      </div>
      <div>
        {isApiWorking && (
          <div className="stat-load">
            <div className="stat-loader">
              <i className={apiFaIcon} />
            </div>
          </div>
        )}
        <div className="contact_lenght">
          Found Contacts: {epk_contacts.length}
        </div>
      </div>
      <div className="table_wrapper">
        <Select
          disabled={isApiWorking}
          className="select_multi"
          multiselect={true}
          name="select_country"
          options={countryOptions()}
          selectedValues={selected_countries}
          modalCloseButton={<ModalCloseButton />}
          caretIcon={<CaretIcon />}
          onChange={(e) => handleSelectCountries(e)}
          onSubmit={() => console.log("onSubmit")}
        />
        <Select
          disabled={isApiWorking}
          className="select_multi"
          multiselect={true}
          name="select_genres"
          options={genreOptions()}
          selectedValues={selected_genres}
          modalCloseButton={<ModalCloseButton />}
          caretIcon={<CaretIcon />}
          onChange={(e) => handleSelectGenres(e)}
          onSubmit={() => console.log("onSubmit")}
        />
        <table>
          <thead>
            <tr>
              <th>
                <button>
                  <i className="fa fa-search" />
                  Name
                </button>
              </th>
              <th>Email</th>
              <th>Country</th>
              <th>Genres</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>{selectedTableItems()}</tbody>
        </table>
      </div>
      <div>
        <button
          onClick={() => setAddNewOpened(!add_new_opened)}
          className="new_epk_btn"
        >
          <i
            className={
              !add_new_opened ? "fa fa-plus-square" : "fa fa-chevron-up"
            }
          />{" "}
          Add New EPK Contact
        </button>
        {add_new_opened && (
          <div>
            <CreateEPKContact />
          </div>
        )}
      </div>
      <div className="dwn_btns">
        <CSVLink
          data={epk_contacts}
          filename={"epk_contact_email_list.csv"}
          className="btn btn-primary mt-3"
          target="_blank"
        >
          Download CSV
        </CSVLink>
        <button className="btn btn-primary mt-3">JSON</button>
        <button className="btn btn-primary mt-3">TXT</button>
      </div>
    </div>
  );

  function selectedTableItems() {
    var tableData = [];
    if (isEmpty(epk_contacts)) {
      return tableData;
    }
    epk_contacts.forEach((station, index) => {
      //filter_by_country
      //filter_by_?
      const tableRow = (
        <tr key={index}>
          <td>
            {Boolean(station.verified) && <i className="fa fa-check-circle" />}
            {station.name}
          </td>
          <td>
            <a href={station.email} alt="">
              {station.email}
            </a>
          </td>
          <td>{station.country}</td>
          <td>{station.genres}</td>
          <td>{station.type}</td>
        </tr>
      );
      tableData.push(tableRow);
    });

    return tableData;
  }

  //filter events
  function handleSelectCountries(currOptions) {
    let currOptionsValues = currOptions.options.map(function (item) {
      return item.value;
    });
    console.log(currOptionsValues);
    setSelectedCountries(currOptionsValues);
  }

  //filter events
  function handleSelectGenres(currOptions) {
    let currOptionsValues = currOptions.options.map(function (item) {
      return item.value;
    });
    setSelectedGenres(currOptionsValues);
    console.log(currOptionsValues);
  }

  //
  function countryOptions() {
    var tableData = [
      {
        value: "All Countries",
        text: "All Countries",
        markup: <MultiSelectOptionMarkup text="All Countries" />,
      },
    ];
    if (isEmpty(countries)) {
      return tableData;
    }

    countries.forEach((_data, index) => {
      const tableRow = {
        value: `${_data.name}`,
        text: `${_data.name}`,
        markup: <MultiSelectOptionMarkup text={`${_data.name}`} />,
      };
      tableData.push(tableRow);
    });

    return tableData;
  }

  function genreOptions() {
    var tableData = [
      {
        value: "All Genres",
        text: "All Genres",
        markup: <MultiSelectOptionMarkup text="All Genres" />,
      },
    ];
    if (isEmpty(genreList)) {
      return tableData;
    }

    genreList.forEach((_data, index) => {
      const tableRow = {
        value: `${_data}`,
        text: `${_data}`,
        markup: <MultiSelectOptionMarkup text={`${_data}`} />,
      };
      tableData.push(tableRow);
    });

    return tableData;
  }
}

export default BulkEmailSender;
