import React, { useEffect, useState } from "react";

//img
import logoIcon from "../../assets/img/songdrop-logo-black.png";
//css
import "./NotFound.css";
import about from "../../assets/about/about.json";
import Footer from "../../components/Footer/Footer";
//fa icons
const title = "404 - Page not found";
const info =
  "The page you are looking for might have been removed or is temporarily unavailable.";
const errorIcon = "fa fa-chain-broken";

//NotFound class
function NotFound(props) {
  const [_title, setTitle] = useState(props.title || title);
  const [_info, setInfo] = useState(props.info || info);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "404 | SongDrop.band";
  }, []);

  return (
    <div className="notfound-container">
      <div className="body">
        <div className="top">
          <img className="logo" alt="" src={logoIcon} onClick={goToLoginPage} />
          <a
            title="What is SongDrop?"
            className="info"
            href={about.pr_url}
            target="_blank"
          >
            <i className="fa fa-question-circle" />
          </a>
        </div>
        <div className="header">
          <div className="welcome">
            <p>{_title}</p>
          </div>
        </div>
        <div className="content">
          <i className={errorIcon} aria-hidden="true"></i>
          <p>{_info}</p>
        </div>
        <div className="footer-container">
          <Footer />
        </div>
      </div>
    </div>
  );

  //GO-TO: Email Contact
  function contactClicked() {
    window.location.href = `mailto:${about.contact}?subject=&body=`;
  }

  //show info(what songdrop is about)
  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/login";
  }
}

export default NotFound;
