import React, { Component } from "react";
import moment from "moment-timezone";

class LiveTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: moment.tz.guess(),
      currentTime: moment().tz(moment.tz.guess()),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        currentTime: moment().tz(this.state.timezone),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { currentTime, timezone } = this.state;

    return (
      <div>
        <div>{timezone}</div>
        <div>
          <i className="fa fa-clock-o" /> {currentTime.format("HH:mm:ss z")}
        </div>
      </div>
    );
  }
}

export default LiveTime;
