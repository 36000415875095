import { Auth } from "aws-amplify";
const AwsConfig = require("../AWSConfig");

//CHANGE_PASSWORD
export const changePass = async (oldPass, newPass) => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.changePassword(user, oldPass, newPass);
      })
      .then((_) => {
        resolve("PASSWORD_CHANGED_SUCCESS");
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//GET_AUTHORIZATION_TOKEN
export const getIDToken = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession().then((res, err) => {
      // console.log(`GETTING_USER_TOKEN`);
      if (err) {
        reject(err);
      }
      let idtoken = res.getIdToken().getJwtToken();
      let accessToken = res.getAccessToken();
      let a_jwt = accessToken.getJwtToken();
      var params = {
        AccessToken: a_jwt,
        IDToken: idtoken,
      };
      resolve(params);
    });
  });
};

//GET_USER_ID
export const getUserID = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentUserInfo().then((res, err) => {
      if (res.attributes) {
        let userId = res.attributes.sub;
        //You can print them to see the full objects
        // console.log(`USER_ID: ${JSON.stringify(userId)}`);
        resolve(userId);
      } else {
        reject("USER_ID NOT FOUND");
      }
      if (err) {
        reject(err);
      }
    });
  });
};

//GET_USER_ID
export const getUserAttr = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentUserInfo().then((res, err) => {
      // console.log("user", res);
      if (res.attributes) {
        let userId = res.attributes.sub;
        //You can print them to see the full objects
        // console.log(`USER_ID: ${JSON.stringify(userId)}`);
        resolve(res);
      } else {
        reject("USER_ID NOT FOUND");
      }
      if (err) {
        reject(err);
      }
    });
  });
};

//VALIDATE_ACCOUNT
export const validate = async (email, code) => {
  return new Promise((resolve, reject) => {
    AwsConfig.getCognitoUser(email).confirmRegistration(
      code,
      true,
      (err, result) => {
        if (err) {
          reject(err);
        }
        if (result) {
          resolve(result);
        }
      }
    );
  });
};

//RESEND_VALIDATION_CODE
export const resendCode = async (email) => {
  return new Promise((resolve, reject) => {
    AwsConfig.getCognitoUser(email).resendConfirmationCode((err, result) => {
      if (err) {
        reject(err);
      }
      if (result) {
        resolve(result);
      }
    });
  });
};

//RESET_PASSWORD
export const resetPass = async (email, confirmationCode, password) => {
  return new Promise((resolve, reject) => {
    AwsConfig.getCognitoUser(email).confirmPassword(
      confirmationCode,
      password,
      {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve("Success");
        },
      }
    );
  });
};
