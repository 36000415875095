import axios from "axios";

export const getDOServerBillingMetrix = async () => {
  try {
    const token = process.env.REACT_APP_DO_TOKEN;
    const result = await get_do_billing_metrix(token);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_do_billing_metrix = async (token) => {
  return new Promise((resolve, reject) => {
    const URL = "https://api.digitalocean.com/v2/customers/my/balance";

    // POST request using axios with set headers
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDOServerBillingHistory = async () => {
  try {
    const token = process.env.REACT_APP_DO_TOKEN;
    const result = await get_do_billing_history(token);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_do_billing_history = async (token) => {
  return new Promise((resolve, reject) => {
    const URL = "https://api.digitalocean.com/v2/customers/my/billing_history";

    // POST request using axios with set headers
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
