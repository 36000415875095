import React, { useEffect, useState } from "react";
import moment from "moment";
import isEmpty from "../../../components/isEmpty";
//CSS
import "./PostGenerator.css";
import HashtagGenerator from "./HashtagGenerator";
import Marketing from "./Marketing";
import ImageCrop from "./ImageCrop";
//HashtagGenerator
function PostGenerator(props) {
  //API
  const [isApiWorking, setIsApiWorking] = useState(false);
  //
  const [postText, setPostText] = useState("");
  const [isHashtagOn, setHashtagOn] = useState(true);

  //Add hashtags to post
  function addHashtagsToPostClicked(hashtags) {
    let text = hashtags;
    console.log("addHashtagsToPostClicked", hashtags);
    setPostText(postText + ` ${text.join(",").replace(",", "")}`);
  }

  //VIEW
  return (
    <div className="post_generator">
      <div>Create Post</div>
      <textarea
        className="new_post"
        onChange={(e) => setPostText(e.target.value)}
        value={postText}
      />
      <ImageCrop />
      <div className="hashtag_generator">
        <button onClick={() => setHashtagOn(!isHashtagOn)}>
          <i
            className={`fa fa-${isHashtagOn ? "chevron-up" : "chevron-down"}`}
          />
          HASHTAGS
        </button>
        {isHashtagOn && (
          <HashtagGenerator
            addHashtagsToPostClicked={addHashtagsToPostClicked}
          />
        )}
      </div>
      <Marketing />
    </div>
  );
}

export default PostGenerator;
