import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer/Footer";
//API
import { getSession } from "../../../utils/apis/Accounts";
//JS
import MFACheck from "./MFACheck";
import UserMetrix from "../users/last_signups/UserMetrix";

//css
import "./Dashboard.css";
//json
import internal from "../../../assets/about/internal.json";
//FA
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faCog = "fa fa-cog";
const faWifi = "fa fa-wifi";
const faInstagram = "fa fa-instagram";
const faLinkedin = "fa fa-linkedin-square";
const faYouTube = "fa fa-youtube";
const faFaq = "fa fa-info-circle";
const faForum = "fa fa-comments-o";
const faPayPal = "fa fa-cc-paypal";
const faBank = "fa fa-credit-card";
const faXero = "fa fa-file-excel-o";
const faBitbucket = "fa fa-bitbucket";

//LINK
//✅logged in
//https://admin.songdrop.band
//Dashboard
function Dashboard(props) {
  //API
  const [isApiWorking, setIsApiWorking] = useState(true);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  //DOWNOAD ADMIN USER WITH PRIVILIGES
  //ViewDidLoad
  useEffect(() => {
    document.title = "Admin | SongDrop";
    //CHECK_FOR_MFA_ENABLED
    getSession().then(({ mfaEnabled }) => {
      setUnreadNotifications(
        !mfaEnabled ? unreadNotifications + 1 : unreadNotifications + 0
      );
    });
  }, []);

  //VIEW
  return (
    <div className="dashboard_body">
      <div className="_head">
        <div className="admintitle">
          <a href="/profile">
            <i className="fa fa-id-card" />
            {unreadNotifications === 0
              ? `SONGDROP ADMIN`
              : `SONGDROP ADMIN(+${unreadNotifications}!)`}
          </a>
          <button className="signout" onClick={() => handleLogout()}>
            <i className="fa fa-sign-out" />
          </button>
        </div>
        <div className="logged_in">
          You logged in as: <strong>{props.user.email}</strong>
        </div>
      </div>
      <div className="_body">
        {/* CHECK_IF_MFA_IS_ENABLED */}
        <div className="mfacheck">
          <MFACheck user={props.user} />
        </div>
        {/* USER_METRICS */}
        <div className="usermetrix">
          <UserMetrix
            user={props.user}
            setApiWorking={setIsApiWorking}
            hrefOnClick="/last_signups"
          />
        </div>
        {/* SELECTABLE_MENUS */}
        <div className="dash-menus">
          {/* READY */}
          <div className="menu-item">
            <a href="/statistics">
              <i className="fa fa-bar-chart" />
              Statistics
            </a>
            <p>Create Statistics</p>
            <div className="btns">
              <a
                title="Share on Buffer"
                href={"https://buffer.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-bullhorn"} />
              </a>
            </div>
          </div>
          <div className="menu-item">
            <a href="/users">
              <i className="fa fa-address-book" />
              Users
            </a>
            <p>✅ Our User Base</p>
            <div className="btns">
              <a
                title="Last Signups"
                href={"/last_signups"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-line-chart"} />
              </a>
            </div>
          </div>

          <div className="menu-item">
            <a href="/blog">
              <i className="fa fa-rss-square" />
              Blog
            </a>
            <p>✅ Company Blog</p>
            <div className="btns">
              <a
                title="Blog"
                href={internal["songdrop_blog_url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-rss"} />
              </a>
            </div>
          </div>
          <div className="menu-item">
            <a href="/faq">
              <i className={faFaq} />
              FAQ Admin
            </a>
            <p>✅ Add/update FAQ</p>
            <div className="btns">
              <a
                title="FAQ"
                href={internal["songdrop_faq_url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-info"} />
              </a>
            </div>
          </div>
          <div className="menu-item">
            <a href="/email_marketing">
              <i className="fa fa-at" />
              Email Marketing
            </a>
            <p>Create HTML emails</p>
          </div>
          <div className="menu-item">
            <a href="/forum">
              <i className="fa fa-clipboard" />
              Forum Admin
            </a>
            <p>Edit Forum</p>
            <div className="btns">
              <a
                title="Band Forum"
                href={internal["songdrop_forum_url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={faForum} />
              </a>
            </div>
          </div>
          <div className="menu-item">
            <a href="/decission_making_process">
              <i className="fa fa-lightbulb-o" />
              Decission Making Process
            </a>
            <p>✅ Company Legal Documents</p>
            <div className="btns">
              <a
                title="Terms and Usage"
                href={internal["songdrop_terms_url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-book"} />
              </a>
              <a
                title="Privacy Policy"
                href={internal["songdrop_privacy_url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-file-o"} />
              </a>
            </div>
          </div>
          <div className="menu-item">
            <a href="/copyrights">
              <i className="fa fa-copyright" />
              Copyright Admin
            </a>
            <p>Access to Copyright Cases</p>
            <div className="btns">
              <a
                title="Copyright"
                href={internal["songdrop_copyright_url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-flag"} />
              </a>
            </div>
          </div>
          <div className="menu-item">
            <a href="/albums">
              <i className="fa fa-music" />
              Albums Admin
            </a>
            <p>✅ Review tracks/albums</p>
            <div className="btns">
              <a
                title="Copyright"
                href={internal["songdrop_copyright_url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-flag"} />
              </a>
            </div>
          </div>
          <div className="menu-item">
            <a href="/omv">
              <i className="fa fa-film" />
              OMV Admin
            </a>
            <p>Music Videos Claims</p>
            <div className="btns">
              <a
                title="Official Music Videos"
                href={"https://songdrop.uk/omv"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-film"} />
              </a>
            </div>
          </div>
          <div className="menu-item">
            <a href="/admins">
              <i className="fa fa-address-card-o" />
              Admins
            </a>
            <p>User Admins and Permissions</p>
            <div className="btns">
              <a
                title="Permissions"
                href={"/admin_permissions"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-lock"} />
              </a>
            </div>
          </div>
          <div className="menu-item">
            <a href="/admin_logs">
              <i className="fa fa-bars" />
              Admin Logs
            </a>
            <p>Admin Generated Logs</p>
            <div className="btns">
              <a
                title="Permissions"
                href={"/permissions"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={"fa fa-lock"} />
              </a>
            </div>
          </div>
          {/* NOT READY */}

          {/* <div className="menu-item">
            <a href="/iap">
              <i className="fa fa-shopping-basket" />
              In App Purchases
            </a>
            <p>Customer paid items</p>
          </div>

          <div className="menu-item">
            <a href="/socialmedia">
              <i className="fa fa-newspaper-o" />
              Social Media
            </a>
            <p>Company Social Media</p>
            <a title="Instagram" href={internal["instagram_url"]}>
              <i className={faInstagram} />
            </a>
            <a title="LinkedIN" href={internal["linkedin_url"]}>
              <i className={faLinkedin} />
            </a>
            <a title="YouTube" href={internal["youtube_url"]}>
              <i className={faYouTube} />
            </a>
          </div>
          <div className="menu-item">
            <a href="/payments">
              <i className="fa fa-university" />
              Payments Admin
            </a>
            <p>Access to User Payments</p>

            <a title="PayPal Business" href={internal["songdrop_paypal_url"]}>
              <i className={faPayPal} />
            </a>
            <a title="BANK" href={internal["songdrop_bank_url"]}>
              <i className={faBank} />
            </a>
            <a title="Xero/Accounting" href={internal["songdrop_xero_url"]}>
              <i className={faXero} />
            </a>
          </div>



     
          <div className="menu-item">
            <a href="/server">Server Admin</a>
            <p>Server informations </p>
            <div>
              <a
                title="StatusPage.IO"
                href={internal["status_page_public_url"]}
              >
                <i className={faWifi} />
              </a>
              <a
                title="StatusPage Settings"
                href={internal["status_page_int_url"]}
              >
                <i className={faCog} />
              </a>
            </div>
          </div>
          <div className="menu-item">
            <a href="/statistics">Statistics Admin</a>
            <p>SongDrop Statistics</p>
          </div>
          <div className="menu-item">
            <a href="/system_messages">PR Admin</a>
            <p>Global System Messages</p>
          </div>

          <div className="menu-item">
            <a href="/employers">Employers Admin</a>
            <p>Employers Access</p>
          </div>
          <div className="menu-item">
            <a href="/global_users">Global Users Admin</a>
            <p>Access to Global Users</p>
          </div>
          <div className="menu-item">
            <a href="/timeline">
              <i className="fa fa-calendar" />
              TimeLine
            </a>
            <p>Company timeline </p>
          </div>
          <div className="menu-item">
            <a href="/radio">
              <i className="fa fa-globe" />
              Public Radio
            </a>
            <p>Radio Timeline</p>
          </div> */}
        </div>
        <Footer />
      </div>
    </div>
  );

  //Logout Request
  function handleLogout() {
    if (window.confirm("Are you sure you want to logout?")) {
      props.handleLogout();
    }
  }
}

export default Dashboard;
