import React, { useState, useEffect } from "react";
//CSS
import "./UnblockOmvs.css";

const default_unblocking_message =
  "Your music video clip has been unblocked by our team. The blocked content is now restored and can be reached via our website. ";
const def_placeholder =
  "Send customised email, when unblocking user account(s)";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//UnblockOmvs class
function UnblockOmvs(props) {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  //
  const [blocking_message, setBlockingMessage] = useState(
    default_unblocking_message
  );
  const [blocking_message_editing, setBlockingMessageEditing] = useState(false);
  //this is by default. use it all the time to tell users they have been blocked, if this turned off, users willb e blocked, but won't receive email notification about that.
  const [send_message_with_blocking, setSendMessageWithBlocking] =
    useState(true);

  //

  //ViewDidLoad
  useEffect(() => {}, []);

  const blockRows = () => {
    const confirmation = window.confirm(
      `Are you sure you want to block these ${props.selectedRows.length} users? Once blocked, users won't be able to access their account until it's unblocked.`
    );

    if (confirmation) {
      // Call your delete function here
      blockUsersAPICall();
    }
  };

  //CHANGE MESSAGE TO SEND
  function changeBlockingMessage(should_change) {
    setBlockingMessageEditing(should_change);
    if (should_change) {
      setBlockingMessage("");
    } else {
      setBlockingMessage(default_unblocking_message);
    }
  }

  async function blockUsersAPICall() {
    setApiWorking(true);
  }

  //VIEW
  return (
    <div className="unblock_users">
      <div className="title">Unblock OMVs({props.selectedRows.length})</div>
      <div className="selected_user_ids_to_block">
        {selectedUserIdsToBlock(props.selectedRows)}
      </div>
      <div className="text">
        <textarea
          value={blocking_message}
          disabled={!blocking_message_editing}
          onChange={(e) => setBlockingMessage(e.target.value)}
          placeholder={def_placeholder}
        />
      </div>
      <div className="checkbox">
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={send_message_with_blocking}
            onChange={() =>
              setSendMessageWithBlocking(!send_message_with_blocking)
            }
          />
          <span
            style={{
              textDecoration: !send_message_with_blocking
                ? "line-through"
                : "none",
            }}
          >
            Notify user via email
          </span>
        </label>
        {!isApiWorking && (
          <button
            className="unblock_button"
            onClick={() => blockRows()}
            disabled={props.selectedRows.length === 0}
          >
            Unblock
          </button>
        )}
        {isApiWorking && (
          <button className="unblock_button" disabled={true}>
            <i className={apiFaIcon}></i>
          </button>
        )}

        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={blocking_message_editing}
            onChange={() => changeBlockingMessage(!blocking_message_editing)}
          />
          Change message
        </label>
      </div>
      {!send_message_with_blocking && (
        <div className="warning_message">
          If this turned off, users won't receive email notification, when their
          account is unblocked.
        </div>
      )}
    </div>
  );

  //selectedUserIdsToBlock
  function selectedUserIdsToBlock(data) {
    var model = [];
    data.forEach((item, index) => {
      model.push(
        <div className="user_id" key={index}>
          {item}
        </div>
      );
    });
    return model;
  }
}

export default UnblockOmvs;
