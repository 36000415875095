import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//GET FAQ DASHBOARD
export const getFaqDash = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_faq_dash(userId, tokens["IDToken"]);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_faq_dash = async (user_id, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/faq/get_faq_dash" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, null, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//GET FAQ CATEGORIES
export const getFAQCategories = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_faq_categories(userId, tokens["IDToken"]);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_faq_categories = async (user_id, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/faq/get_faq_categories" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, null, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createFaqPost = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await create_faq_post(
      userId,
      tokens["IDToken"],
      bodyParameters
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const create_faq_post = async (user_id, token, bodyParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/faq/create_faq_post" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteFaqPost = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await delete_faq_post(
      userId,
      tokens["IDToken"],
      bodyParameters
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const delete_faq_post = async (user_id, token, bodyParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/faq/delete_faq_post" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editFaqPost = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await edit_faq_post(
      userId,
      tokens["IDToken"],
      bodyParameters
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const edit_faq_post = async (user_id, token, bodyParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/faq/edit_faq_post" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//
export const searchFaq = async (queryParameters) => {
  try {
    const result = await search_faq_dash(queryParameters);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const search_faq_dash = async (queryParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/faq/search_faq" + "?" + `${queryParameters}`;

    axios
      .post(URL, null)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
