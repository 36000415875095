import axios from "axios";

//GET_ALBUM_QR
export const getNewsAndUsers = async () => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await get_news_and_users();
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_news_and_users = async () => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL = apiEndpoint + "/prod" + "/news/get_news";

    axios
      .post(URL, null)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//GET_BLOG_ARTICLES
export const getBlogArticlesAPI = async (queryParameters) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await get_articles_api(queryParameters);
    // if (isEmpty(result["error"])) {
    //   throw new Error(result["error"]);
    // }
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_articles_api = async (queryParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/blog/list_all_blog" +
      "?" +
      `${queryParameters}`;

    axios
      .post(URL, null)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBlogArticleByIdAPI = async (queryParameters) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await get_article_by_id_api(queryParameters);
    // if (isEmpty(result["error"])) {
    //   throw new Error(result["error"]);
    // }
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_article_by_id_api = async (queryParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/blog/get_blog_by_id" +
      "?" +
      `${queryParameters}`;

    axios
      .post(URL, null)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//SEND_COPRYRIGHT_FORM
export const signUpToNewsLetterAPI = async (bodyParameters) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await signup_to_newsletter(bodyParameters);
    // if (isEmpty(result["error"])) {
    //   throw new Error(result["error"]);
    // }
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const signup_to_newsletter = async (bodyParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL = apiEndpoint + "/prod" + "/newsletter/signup";

    axios
      .post(URL, bodyParameters)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//GET_USERNAME
export const getUsername = async (username) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await get_username_req(username);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_username_req = async (username) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/user/get_username" +
      "?" +
      `username=${username}`;

    axios
      .post(URL, null)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const searchUsers = async (search_text) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await search_users_req(search_text);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const search_users_req = async (search_text) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/user/search_users" +
      "?" +
      `search_text=${search_text}`;

    axios
      .post(URL, null)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//GET_ALBUM_QR
export const getAlbumQR = async (album_id) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await get_album_req(album_id);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_album_req = async (album_id) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/qr/get_qr" + "?" + `album_id=${album_id}`;

    axios
      .get(URL)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//SEARCH_COPYRIGHTED_MATERIAL
export const searchMaterial = async (search_text) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await search_req(search_text);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const search_req = async (search_text) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/copyright/search" +
      "?" +
      `search=${search_text}`;

    // POST request using axios with set headers
    const headers = {
      jtw_token: "allow",
    };
    axios
      // .get(URL, { headers })
      .get(URL)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//SEND_COPRYRIGHT_FORM
export const sendClaimForm = async (bodyParameters) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await send_form_req(bodyParameters);
    // if (isEmpty(result["error"])) {
    //   throw new Error(result["error"]);
    // }
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const send_form_req = async (bodyParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL = apiEndpoint + "/prod" + "/copyright/send_form";

    axios
      .post(URL, bodyParameters)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//VALIDATE_COPYRIGHT_CLAIM
export const validateClaim = async (queryParameters) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await validate_req(queryParameters);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const validate_req = async (queryParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/copyright/validate_form" +
      "?" +
      `${queryParameters}`;

    axios
      .post(URL)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//GET_COPYRIGHT_CASE
export const getCopyrightCase = async (case_id) => {
  try {
    // console.log(tokens["IDToken"]);
    const result = await get_case_req(case_id);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_case_req = async (case_id) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/copyright/get_case" +
      "?" +
      `case_id=${case_id}`;

    axios
      .get(URL)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFaqDash = async () => {
  try {
    const result = await get_faq_dash();
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_faq_dash = async () => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL = apiEndpoint + "/prod" + "/faq/get_faq_dash";

    axios
      .get(URL)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const searchFaq = async (queryParameters) => {
  try {
    const result = await search_faq_dash(queryParameters);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const search_faq_dash = async (queryParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/faq/search_faq" + "?" + `${queryParameters}`;

    axios
      .post(URL, null)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
