import React, { useEffect, useState } from "react";
import emailvalidator from "email-validator";
import passwordValidator from "password-validator";
import { authenticate } from "../../../utils/apis/Accounts";
import useLocalStorage from "../../../components/useLocalStorage";
import { useAppContext } from "../../../libs/contextLib";

//JS
import Footer from "../../../components/Footer/Footer";

//css
import "./Login.css";

//icons+about
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
import about from "../../../assets/about/about.json";
import isEmpty from "../../../components/isEmpty";
//password schema
var schema = new passwordValidator();

const welcomeTxt = "Please log in with your admin account";
const emailErrorTxt = "Email is not valid: example@mail.com";
const passErrorTxt =
  "Password should have: 8-16 chars, 1 digit, 1 lowercase, 1 uppercase, 1 special char";

//Login
function Login() {
  //api
  const { userHasAuthenticated } = useAppContext();
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //memory
  const [loginTitle, setLoginTitle] = useState(welcomeTxt);
  const [shouldRememberLogin, setRememberLogin] = useLocalStorage(
    "admin_remember_login",
    true
  );
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage(
    "admin_cookies_accepted",
    false
  );

  //userinput
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //get path
    var _emailPathUrl = window.location.href.split("/")[3].split("=")[1];
    //sets the email
    setEmail(_emailPathUrl || "");
    //password schema setup
    setupPasswordSchema();
  }, []);

  //
  //Submit Login
  const handleSubmit = async (event) => {
    event.preventDefault();
    //set api to working
    setApiWorking(true);
    setApiError("");

    //check valid email address form
    if (!emailvalidator.validate(email)) {
      let error = emailErrorTxt;
      setApiError(error);
      setApiWorking(false);
      return;
    }
    //check if password valid
    if (!schema.validate(password)) {
      const error = passErrorTxt;
      setApiError(error);
      setApiWorking(false);
      return;
    }
    authenticate(email, password)
      .then((data) => {
        //✅Success: User logged in
        setApiWorking(false);
        console.log("Logged in!", data);
        //redirect user
        userHasAuthenticated(true);
        redirectToHome();
      })
      //❌Error: Login error
      .catch((error) => {
        setApiWorking(false);
        console.error("Failed to login!", error.message);
        alert(error.message);
        //
        if (error.code == "UserNotConfirmedException") {
          setApiError(error.message);
          // Not confirmed
          redirectOnUnconfirmed();
        } else {
          setApiError(error.message);
        }
        // else if (error.code == "PasswordResetRequiredException") {
        //   // Reset Password Required
        // } else if (error.code == "NotAuthorizedException") {
        //   // Not Authorised (Incorrect Password)
        // } else if (error.code == "ResourceNotFoundException") {
        //   // User Not found
        // } else {
        //   // Unknown
        // }
      });
  };

  function redirectToHome() {
    setTimeout(function () {
      window.location.href = "/";
    }, 1000);
    setTimeout(function () {
      setApiWorking(false);
    }, 1500);
  }

  function redirectToMFA() {
    setTimeout(function () {
      window.location.href = `/mfa=${email}`;
    }, 1000);
    setTimeout(function () {
      setApiWorking(false);
    }, 1500);
  }

  //UPDATE: Email
  function handleEmailChanged(email) {
    setEmail(email);
  }

  //UPDATE: Password
  function handlePasswordChanged(password) {
    setPassword(password);
  }

  //UPDATE: Remember at login
  function handleRemember() {
    setRememberLogin(!shouldRememberLogin);
  }

  //VIEW
  return (
    <div className="login-container">
      {/* <img className="login-container-wallpaper" alt="" src={wallpaper}></img> */}
      <form className="form" onSubmit={(e) => handleSubmit(e)}>
        <div className="logo-container">
          <img className="logo" alt="" src={logoIcon} />
          <span className="band"></span>
        </div>
        <div className="info-wrapper">
          {isEmpty(apiError) && <div className="welcome">{loginTitle}</div>}
          {!isEmpty(apiError) && <div className="error">{apiError}</div>}
        </div>
        <div className="group" style={{ marginTop: "6px" }}>
          <div className="login_title">Email</div>
          <input
            className="form-control"
            type="text"
            name="user"
            placeholder="example@mail.com"
            style={{ textTransform: "lowercase" }}
            onChange={(e) => handleEmailChanged(e.target.value)}
            value={email}
          />
        </div>
        <div className="group">
          <div className="login_title">Password</div>
          <input
            className="form-control"
            type="password"
            name="pass"
            placeholder="••••••••"
            onChange={(e) => handlePasswordChanged(e.target.value)}
            value={password}
          />
        </div>
        <button
          type="button"
          className="btn btn-light btn-left"
          onClick={() => handleRemember()}
        >
          {shouldRememberLogin && <span className="fa fa-check-square" />}
          <span className="remember"> Remember me</span>
        </button>
        {isApiWorking === false && (
          <button
            type="submit"
            className="btn btn_sign_in btn-primary"
            disabled={!isSubmitEnabled()}
          >
            <span className="fa.fa-lock">Sign In</span>
          </button>
        )}
        {isApiWorking === true && (
          <button
            className="btn btn_sign_in btn-primary"
            style={{ backgroundColor: "transparent" }}
            disabled={true}
          >
            <i className="fa fa-refresh spin-it" aria-hidden="true"></i>
          </button>
        )}
        <div className="separator" />
      </form>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  //accept cookie policy
  function acceptCookies() {
    setCookiesAccepted(true);
  }

  //learn more about cookies
  function learnMoreCookies() {
    window.open(about.cookies_url, "_blank");
  }

  //password schema setup
  function setupPasswordSchema() {
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(16) // Maximum length 16
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .has()
      .symbols() // Should has symbols
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]); // Blacklist these values
  }

  //Is submit button enabled
  function isSubmitEnabled() {
    if (isApiWorking) {
      return false;
    } else {
      return email.length > 0 && password.length > 0;
    }
  }

  //Redirect user if acc hasn't been confirmed
  function redirectOnUnconfirmed() {
    setTimeout(function () {
      setApiError("");
      setLoginTitle("Redirecting to validation page");
    }, 1000);
    setTimeout(function () {
      //go to validation
      window.location.href = `/validate=${email}`;
    }, 3000);
    setTimeout(function () {
      setApiWorking(false);
      setApiError("");
      setLoginTitle(welcomeTxt);
    }, 3500);
  }
}

export default Login;
