import React, { useState, useEffect } from "react";
import numberFormatter from "../../../components/numberFormatter";
import isEmpty from "../../../components/isEmpty";
import openWikiForGenre from "../../../components/wikiLink";
import Linkify from "react-linkify";
import NavBar from "../../../components/Navbar/NavBar";
import { getUsername } from "../../../utils/apis/api/public";
//JS
import ProfileSearch from "./ProfileSearch";
import ProfileSearchBar from "./ProfileSearchBar";
import ProfileHeader from "./ProfileHeader";
import DropCell from "./DropCell";
import VerticalTimeline from "./VerticalTimeLine/VerticalTimeline";
//CSS
import "./ProfileCard.css";

import Footer from "../../../components/Footer/Footer";
import ListenDrop from "./ListenDrop";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//ProfileCard class
function ProfileCard(props) {
  //
  const [user, setUser] = useState(null);
  const [_title, setTitle] = useState("");
  //API:
  //def: 0, sync: 1, success: 2, error: 3
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");
  //user's is in editing mode.
  //viewing_drop
  const [dropToListen, setSelecteddropToListen] = useState(null);
  const [timeline, setTimeline] = useState(null);
  //streamurl to play audio source
  const [streamUrl, setStreamUrl] = useState("");

  //SEARCH
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  //
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "SongDrop | @";
    //get path, remove extra stuff it has
    var username = window.location.href.split("/")[4].split("?")[0];
    setTitle(username);
    _getUserName(username);
  }, []);

  //api
  async function _getUserName(username) {
    setApiWorking(true);
    setApiError("");

    try {
      let result = await getUsername(username);
      console.log(result);
      //✅SUCCESS
      setUser(result["user"]);
      setTimeline(result["artist_timeline"]);
      setApiWorking(false);
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //UPDATE: SearchBar text
  function textDidChange(searchtext) {
    setSearchText(searchtext);
  }

  //SEARCH
  const searchForText = () => {
    setApiWorking(true);
    setApiError("");
    setIsSearching(true);
  };

  const togglePopup = () => {
    setApiWorking(false);
    setApiError("");
    setIsSearching(false);
  };
  //

  //VIEW
  return (
    <div id="profile-container" className="profile-container">
      <NavBar title={props.title} handleLogout={props.handleLogout} />

      {isSearching && (
        <ProfileSearch searchText={searchText} handleClose={togglePopup} />
      )}
      {isEmpty(user) && isApiWorking && (
        <div className="title">
          <p>
            <i className={apiFaIcon} />
            {_title}
          </p>
        </div>
      )}
      {isEmpty(user) && !isApiWorking && apiError && (
        <div className="error-title">
          <p>{apiError}</p>
        </div>
      )}
      {!isEmpty(user) && (
        <div>
          <div style={{ backgroundColor: "#f0f0f0" }}>
            <ProfileSearchBar
              isApiWorking={isApiWorking}
              searchText={searchText}
              searchForText={searchForText}
              textDidChange={textDidChange}
            />
          </div>
          <div className="header-container">
            <ProfileHeader user={user} setApiError={setApiError} />
          </div>
          {/* PROFILE_VIEWING */}
          <div className="user-profile">
            <div className="about">
              <Linkify>{user.about}</Linkify>
            </div>
            <div className="genre-container">
              {!isEmpty(user.genres) &&
                user.genres.split(",").map((genre, index) => {
                  return (
                    <div
                      key={index}
                      title={titleWiki(genre)}
                      onClick={() => openWikiForGenre(genre)}
                      className="genre"
                    >
                      {genre}
                    </div>
                  );
                })}
            </div>
          </div>
          {/* SOCIAL_INDICATOR_NUMBERS */}
          <div className="info-container">
            <div className="profile-stat-table">
              <div>
                <div className="title">Albums</div>
                <div className="value">
                  {numberFormatter(user.albums_number, 1)}
                </div>
              </div>
              <div>
                <div className="title">Drops</div>
                <div className="value">
                  {numberFormatter(user.drops_number, 1)}
                </div>
              </div>
              <div>
                <div className="title">Following</div>
                <div className="value">
                  {numberFormatter(user.following_number, 1)}
                </div>
              </div>
              <div>
                <div className="title">Followers</div>
                <div className="value">
                  {numberFormatter(user.followers_number, 1)}
                </div>
              </div>
            </div>
          </div>
          {/* USER_ACTIVE_DROPS */}
          <div className="drops-container">
            <div className="header" id="drops">
              Drops
            </div>

            {/* NO DROPS */}
            {isEmpty(user.last_drops) && (
              <div className="no-drops-content">
                <p>No drops in the last 24h </p>
              </div>
            )}

            {/* ACTIVE DROPS */}
            {!isEmpty(user.last_drops) && (
              <div className="content">
                {user.last_drops.map((drop, index) => {
                  return (
                    <DropCell
                      key={index}
                      drop={drop}
                      didSelectDrop={didSelectDrop}
                      dropToListen={dropToListen}
                    />
                  );
                })}
              </div>
            )}
            {!isEmpty(dropToListen) && (
              <ListenDrop
                dropToListen={dropToListen}
                streamUrl={streamUrl}
                setSelecteddropToListen={setSelecteddropToListen}
              />
            )}
          </div>
          {/* VERTICAL TIMELINE */}
          {!isEmpty(timeline) && !isEmpty(user) && (
            <VerticalTimeline timeline={timeline} user={user} />
          )}
        </div>
      )}
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  //GET: Title
  function titleWiki(genre) {
    return `go-to:wikipedia-${genre.toLowerCase()}`;
  }

  //SELECT: Drop
  function didSelectDrop(drop) {
    setSelecteddropToListen(drop);
    setStreamUrl(drop.song.prev_url);
    var element = document.getElementById("drops");
    setTimeout(function () {
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }, 10);
  }
}

export default ProfileCard;
