import React, { useEffect, useRef, useState } from "react";
import { getSession } from "../../../utils/apis/Accounts";

//CSS
import "./MFACheck.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faApple = "fa fa-apple";
const faAndroid = "fa fa-android";
const faWarning = "fa fa-exclamation-triangle";
//MFACheck
function MFACheck(props) {
  //API
  const [isMFAEnabled, setMFAEnabled] = useState(true);

  //ViewDidLoad
  useEffect(() => {
    //CHECK_FOR_MFA_ENABLED
    getSession().then(({ mfaEnabled }) => {
      setMFAEnabled(mfaEnabled);
    });
  }, []);

  //VIEW
  return (
    <div>
      {!isMFAEnabled && (
        <div className="mfacheckbox">
          <p>
            <i className={faWarning} />
            <strong>YOUR MFA NOT Enabled</strong>
          </p>
          <p>
            Secure your account with <strong>DUO Mobile</strong> or other MFA
            application
          </p>
          <a href="https://apps.apple.com/us/app/duo-mobile/id422663827">
            <i className={faApple} />
            Download from AppStore
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.duosecurity.duomobile&gl=US">
            <i className={faAndroid} />
            Download from GooglePlay
          </a>
          <a href="/profile">
            <strong>{`>>ACTIVATE YOUR MFA<<`}</strong>
          </a>
        </div>
      )}
    </div>
  );
}

export default MFACheck;
