import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import { Link } from "react-router-dom";

import {
  getBlogArticleByIdAPI,
  publishBlogAPI,
  deleteBlogAPI,
  editBlogAPI,
} from "../../../utils/apis/api/auth_api_blog";
import BlogEmptyList from "./BlogContent/BlogEmptyList";
import BlogWriter from "./BlogWriter/BlogWriter";
import BlogContent from "./BlogContent/BlogContent";
import NavBar from "../../../components/Navbar/NavBar";

//CSS
import "./BlogEditor.css";
import { DeleteFolder } from "../../../utils/apis/s3/DeleteFile";

const _ = require("lodash");

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//BlogWriting
const BlogWriting = (props) => {
  //apiWorking
  const [isApiWorking, setApiWorking] = useState(false);
  const [is_published, setIsPublished] = useState(false);
  //
  const [blog, setBlog] = useState(null);
  const [blogToEdit, setBlogToEdit] = useState(null);
  //
  const [isPreview, showPreview] = useState(false);

  //useEffect
  useEffect(() => {
    //
    document.title = "Blog | Admin SongDrop";
    //
    //get the id from the path:
    //https://admin.songdrop.band/blog/{id}
    //-- {id}
    var blog_id = window.location.pathname.split("/")[2];
    getBlogById(blog_id);

    var is_preview = window.location.pathname.split("/")[3] === "p";
    showPreview(is_preview);
    //
    //
  }, []);

  // Clear search and show all blogs
  const getBlogById = async (blog_id) => {
    setApiWorking(true);
    //
    try {
      var bodyParams = [];
      bodyParams.push(`blog_id=${blog_id}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      let result = await getBlogArticleByIdAPI(bParameters);
      //✅SUCCESS
      let _blog = result["blog"];
      setBlog(_blog);
      setBlogToEdit(_blog);

      console.log("content=", _blog["content"]);
      //IS_PUBLISHED
      let is_published = Boolean(_blog.is_published);
      setIsPublished(is_published);
      //
      setApiWorking(false);
    } catch (error) {
      // alert(error.message);
      console.log("ERROR", error.message);
      //🚫ERROR
      setApiWorking(false);
    }
  };

  async function publish_blog() {
    if (isApiWorking) {
      return;
    }
    var to_publish_confirm = blogToEdit.is_published ? "unpublish" : "publish";
    if (
      window.confirm(
        `Are you sure you want to ${to_publish_confirm} this blog?`
      )
    ) {
      //CHECK BLOG VALUES IS FILLED
      if (!checkBlogValues(blogToEdit)) {
        return;
      }
      //
      setApiWorking(true);
      //
      try {
        var new_publish_state = is_published === true ? "0" : "1";
        //bodyParams
        var bodyParams = [];
        bodyParams.push(`blog_id=${blogToEdit.id}`);
        bodyParams.push(`token=${props.user.edit_token}`);
        bodyParams.push(`is_published=${new_publish_state}`);
        let bParameters = bodyParams.join("&");
        let result = await publishBlogAPI(bParameters);
        //✅SUCCESS
        var publish_state =
          result["is_published"] === "PUBLISHED" ? true : false;
        setIsPublished(publish_state);
        //
        setApiWorking(false);
      } catch (error) {
        alert(error.message);
        console.log("ERROR", error.message);
        //🚫ERROR
        setApiWorking(false);
      }
    }
  }

  async function delete_blog() {
    if (isApiWorking) {
      return;
    }
    if (
      window.confirm(
        `Are you sure you want to delete this blog article? After deleting the blog article you won't be able to reverse it`
      )
    ) {
      setApiWorking(true);
      //
      try {
        var bodyParams = [];
        bodyParams.push(`blog_id=${blogToEdit.id}`);
        bodyParams.push(`token=${props.user.edit_token}`);
        let bParameters = bodyParams.join("&");
        let result = await deleteBlogAPI(bParameters);
        let deleted_folder = await DeleteFolder(`blog/${blogToEdit.id}`);
        //✅SUCCESS
        console.log(result);
        window.location.href = "/blog";
        //
        setApiWorking(false);
      } catch (error) {
        // alert(error.message);
        console.log("ERROR", error.message);
        //🚫ERROR
        setApiWorking(false);
      }
    }
  }

  async function update_blog() {
    if (isApiWorking) {
      return;
    }
    setApiWorking(true);
    //
    //CHECK BLOG VALUES IS FILLED
    if (isEmpty(blogToEdit.title)) {
      alert("Please add a title to your article");
      return;
    }

    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`blog_id=${blogToEdit.id}`);
      bodyParams.push(`title=${blogToEdit.title}`);
      bodyParams.push(`category=${blogToEdit.category}`);
      bodyParams.push(`subcategories=${blogToEdit.subcategories.join(",")}`);
      bodyParams.push(`duration=${blogToEdit.duration}`);
      bodyParams.push(`description=${blogToEdit.description}`);
      // const content = `content=${blogToEdit.content
      //   .replace(/\\&quot;/g, "")
      //   .replace(`""`, `''`)}
      //   `;
      const content = `content=${blogToEdit.content}`;
      //
      console.log(content);
      bodyParams.push(content);
      bodyParams.push(`cover=${blogToEdit.cover}`);
      bodyParams.push(`author_avatar=${blogToEdit.author_avatar}`);
      bodyParams.push(`author_name=${blogToEdit.author_name}`);
      let bParameters = bodyParams.join("~");
      let result = await editBlogAPI(bParameters);
      //✅SUCCESS
      console.log(result);
      //
      setApiWorking(false);
    } catch (error) {
      alert(error.message);
      console.log("ERROR", error.message);
      //🚫ERROR
      setApiWorking(false);
    }
  }

  function changeTitle(value) {
    const edit = _.cloneDeep(blogToEdit);
    edit["title"] = value;
    setBlogToEdit(edit);
  }

  function changeCategory(value) {
    const edit = _.cloneDeep(blogToEdit);
    edit["category"] = value;
    setBlogToEdit(edit);
  }

  function changeSubcategories(value) {
    let subcategories = value.split(",");
    const edit = _.cloneDeep(blogToEdit);
    edit["subcategories"] = subcategories;
    setBlogToEdit(edit);
  }

  function changeDurations(value) {
    const edit = _.cloneDeep(blogToEdit);
    edit["duration"] = value;
    setBlogToEdit(edit);
  }

  function changeDescription(value) {
    const edit = _.cloneDeep(blogToEdit);
    edit["description"] = value;
    setBlogToEdit(edit);
  }

  function changeAuthorName(value) {
    const edit = _.cloneDeep(blogToEdit);
    edit["author_name"] = value;
    setBlogToEdit(edit);
  }

  function changeAuthorAvatar(value) {
    const edit = _.cloneDeep(blogToEdit);
    edit["author_avatar"] = value;
    setBlogToEdit(edit);
  }

  function changeContent(value) {
    const edit = _.cloneDeep(blogToEdit);
    edit["content"] = value;
    setBlogToEdit(edit);
  }

  function changeCover(value) {
    const edit = _.cloneDeep(blogToEdit);
    edit["cover"] = value;
    setBlogToEdit(edit);
  }

  //SEO
  async function seo_blog() {
    if (isApiWorking) {
      return;
    }
  }

  //VIEW
  return (
    <div className="blog_editor">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="button_wrap">
        <Link className="blog-goBack" to="/blog">
          <i className="fa fa-arrow-left" /> <span>Go Back</span>
        </Link>
        <button
          className={!isPreview ? "active" : ""}
          disabled={isApiWorking}
          onClick={() => showPreview(false)}
        >
          <i className="fa fa-pencil" />
        </button>
        <button
          className={isPreview ? "active" : ""}
          disabled={isApiWorking}
          onClick={() => showPreview(true)}
        >
          <i className="fa fa-binoculars" />
        </button>
        <button
          disabled={isApiWorking}
          onClick={() => update_blog()}
          style={{ color: "#1986fb" }}
        >
          <i className="fa fa-floppy-o" />
        </button>
        <button onClick={() => seo_blog()}>SEO</button>
        {blog && (
          <button
            className={is_published ? "" : "active"}
            disabled={isApiWorking}
            onClick={() => publish_blog()}
          >
            {is_published ? <i className="fa fa-check-square" /> : ""}
            {is_published ? " Published " : "Publish"}
          </button>
        )}
        {blog && (
          <button
            disabled={isApiWorking}
            style={{
              color: "red",
            }}
            onClick={() => delete_blog()}
          >
            Delete
          </button>
        )}
        {blog && (
          <button style={{ fontSize: "12px" }} disabled>
            {blog.created_at}
          </button>
        )}
      </div>
      {/* API_LOADING */}
      {isApiWorking === true && (
        <button className="_api_working" type="submit" disabled={true}>
          <i className={apiFaIcon}></i> Loading...
        </button>
      )}
      {/* EDITING */}
      {!isPreview && !isEmpty(blogToEdit) && (
        <div className="writing">
          <BlogWriter
            blog={blogToEdit}
            changeTitle={changeTitle}
            changeCategory={changeCategory}
            changeSubcategories={changeSubcategories}
            changeDurations={changeDurations}
            changeDescription={changeDescription}
            changeAuthorName={changeAuthorName}
            changeAuthorAvatar={changeAuthorAvatar}
            changeContent={changeContent}
            changeCover={changeCover}
          />
        </div>
      )}
      {/* PREVIEW */}
      {!isApiWorking && isPreview && !isEmpty(blogToEdit) && (
        <div className="preview">
          <BlogContent blog={blogToEdit} />
        </div>
      )}
      {/* PREVIEW */}
      {!isApiWorking && isEmpty(blogToEdit) && <BlogEmptyList />}
    </div>
  );

  function checkBlogValues(blog) {
    if (isEmpty(blogToEdit.title)) {
      alert("Please add a title to your article");
      return false;
    }
    if (isEmpty(blogToEdit.category)) {
      alert("Please add a category to your article");
      return false;
    }
    if (isEmpty(blogToEdit.subcategories.join(","))) {
      alert("Please add a subcategories to your article");
      return false;
    }
    if (isEmpty(blogToEdit.duration)) {
      alert("Please add a duration to your article");
      return false;
    }
    if (isEmpty(blogToEdit.description)) {
      alert("Please add a description to your article");
      return false;
    }
    if (isEmpty(blogToEdit.cover)) {
      alert("Please add a cover to your article");
      return false;
    }
    if (isEmpty(blogToEdit.author_avatar)) {
      alert("Please add an author_avatar to your article");
      return false;
    }
    if (isEmpty(blogToEdit.author_name)) {
      alert("Please add an author_name to your article");
      return false;
    }
    return true;
  }
};

export default BlogWriting;
