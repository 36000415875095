import React, { useEffect, useState } from "react";

//CSS
import "./Marketing.css";
//img
import logo from "../../../assets/img/songdrop-logo-black.png";

function Marketing() {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "SongDrop.band";
  }, []);

  return (
    <div className="marketing-page">
      <div
        className="instagram-post"
        style={{
          width: "600px",
          height: "600px",
          border: "1px solid lightgray",
        }}
      >
        <img
          src="https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80"
          alt="Guitar on stage"
          className="app-screenshot"
        />
        <div className="text-overlay">
          <h2>Discover new indie artists and support them on SongDrop</h2>
          <p>
            As an artist, sell your music directly to fans and receive a fair
            share of the revenue. No more relying on streaming platforms to pay
            you pennies on the dollar. You can also share your songs as Drops to
            get free promotion from your loyal followers.
          </p>
          <p>
            And with the world's first ED (Electronically Distributed) album
            option, you can even hold a physical copy of your digital music.
          </p>
          <p>
            Join the SongDrop community today and discover the power of
            independent music.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Marketing;
