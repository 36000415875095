import React, { useEffect, useRef, useState } from "react";
import NavBar from "../../../components/Navbar/NavBar";
import isEmpty from "../../../components/isEmpty";
// import { getIssuesFromGitHub } from "../../../utils/apis/api/auth_api_github";
import {
  getIAP_Apple,
  getIAPRecord,
  getPurchaseExtraDetails,
} from "../../../utils/apis/api/auth_api_iap";
//CSS
import "./InAppPurchases.css";
//
//
import IAP_SearchBox from "./IAP_SearchBox";
import IAP_Revalidate from "./IAP_Revalidate";
import IAP_SearchResult from "./IAP_SearchResult";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

const IAP_tableColumns = [
  "id",
  "user_id",
  "seller_id",
  "product_id",
  "receipt_type",
  "transaction_id",
  "original_transaction_id",
  "transaction_source",
  "in_app_ownership_type",
  "status",
  "track_id",
  "album_id",
  "full_album_purchase",
  "track_ids",
  "is_downloaded",
  "payment_method",
  "purchase_json",
  "item_price",
  "item_price_currency",
  "item_price_symbol",
  "item_tax_in_currency",
  "item_tax_percentage",
  "discovered_from",
  "purchase_date",
  "original_purchase_date",
  "created_at",
];

//InAppPurchases
function InAppPurchases(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //
  const [active_tab, setActiveTab] = useState("Apple IAP");
  const [tabs, setTabs] = useState(["Apple IAP", "Google IAP", "PayPal"]);

  const [search_values, setSearchValues] = useState({
    iap_number: "MKX3K47MMJ", //"1675094550629",
    user_id: "3e75877c-7b8d-4aef-a793-622b2c702c59",
    track_title: "",
    album_title: "",
  });
  //
  //SONGDROP_DB
  const [apple_iap_visible, setAppleIAPVisible] = useState(false);
  const [user_iap_visible, setUserIAPVisible] = useState(false);
  const [track_iap_visible, setTrackIAPVisible] = useState(false);

  const [iap_json, set_iap_json] = useState(null);
  const [user, setUser] = useState(null);
  const [track, setTrack] = useState(null);

  //->this two should match
  const [iap_items, set_iap_items] = useState([]);
  const [iap_db_items, set_iap_db_items] = useState([]);

  //-> SELECTED_ITEM_TO_DISPLAY_AND_MATCH
  const [iap_receipt, set_iap_receipt] = useState([]);

  //

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "In App Purchases | Admin SongDrop";
    //

    let db_result = createDBItems(
      IAP_tableColumns,
      active_tab,
      iap_items,
      "SONGDROP_DB",
      []
    );
    set_iap_db_items(db_result);

    // getIssues();
  }, []);

  //GITHUB_ISSUES_TO_GET
  async function getIssues() {
    // getIssuesFromGitHub("SongDrop", "ios-app-feedback")
    //   .then((data) => {
    //     console.log("GET_GITHUB_ISSUES", data);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }

  //UPDATE_SEARCH
  function updateSearchValues(prop, value) {
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [prop]: value,
      };
    });
  }

  //
  //SEARCH_FOR
  async function confirmSearch(e) {
    e.preventDefault();

    setUser(null);
    setTrack(null);
    set_iap_db_items([]);
    set_iap_items([]);
    set_iap_json(null);
    set_iap_receipt(null);

    switch (active_tab) {
      case "Apple IAP":
        getAppleIAPData(search_values.iap_number);
        break;
      case "Google IAP":
        break;
      case "PayPal":
        break;
      default:
        break;
    }
    //->GET_APPLE_IAP_DATA
    //->Start searching
    getIAPData(search_values.iap_number);

    //
    if (
      !isEmpty(search_values.user_id) &&
      (!isEmpty(search_values.track_title) ||
        !isEmpty(search_values.album_title))
    ) {
      getPurchaseExtraData();
    }
  }

  //SEARCH DATA IN SONGDROP DATABASE -> THIS SHOULD BE MATCHED WITH APPLE, GOOGLE OR PAYPAL
  async function getPurchaseExtraData() {
    //
    setApiWorking(true);
    setApiError("");
    //
    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`user_id=${search_values.user_id || ""}`);
      bodyParams.push(`track_title=${search_values.track_title || ""}`);
      bodyParams.push(`album_title=${search_values.album_title || ""}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getPurchaseExtraDetails(bParameters);
      console.log("getPurchaseExtraDetails", result);

      setUser(result["user"]);
      setTrack(result["track"]);

      setApiWorking(false);
      //🚫ERROR
    } catch (error) {
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //API
  async function getAppleIAPData(iap_transaction_id) {
    //
    setApiWorking(true);
    setApiError("");
    //
    if (isEmpty(iap_transaction_id)) {
      alert("Enter Apple Transaction Id");
      setApiWorking(false);
      setApiError("");
      return;
    }
    //
    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`transaction_id=${iap_transaction_id || ""}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getIAP_Apple(bParameters);
      console.log(result["receipt"]);

      set_iap_json(result["receipt"]);
      let db_iap_receipt_should_be = result["receipt"]["db_receipt_type"];
      // console.log("db_iap_receipt_should_be", db_iap_receipt_should_be);

      let db_result = createDBItems(
        IAP_tableColumns,
        active_tab,
        isEmpty(db_iap_receipt_should_be) ? [] : [db_iap_receipt_should_be],
        "SONGDROP_DB",
        isEmpty(iap_db_items) ? [] : iap_db_items[0]
      );

      set_iap_receipt(db_result);
      //  console.log("RESULT", db_result);

      //set_iap_db_items(result["receipt"]);
      //console.log("RESULT", result);
      setApiWorking(false);
      //🚫ERROR
    } catch (error) {
      console.log(error.message);
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //SEARCH DATA IN SONGDROP DATABASE -> THIS SHOULD BE MATCHED WITH APPLE, GOOGLE OR PAYPAL
  async function getIAPData(iap_transaction_id) {
    //
    setApiWorking(true);
    setApiError("");
    //
    if (isEmpty(iap_transaction_id)) {
      alert("Enter Apple Transaction Id");
      setApiWorking(false);
      setApiError("");
      return;
    }
    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`transaction_id=${iap_transaction_id || ""}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getIAPRecord(bParameters);
      //
      set_iap_db_items(result["purchases"]);

      //
      //
      let db_result = createDBItems(
        IAP_tableColumns,
        active_tab,
        iap_items,
        "SONGDROP_DB",
        result["purchases"]
      );

      set_iap_receipt(db_result);
      // console.log("RESULT", db_result);
      //
      setApiWorking(false);
      //🚫ERROR
    } catch (error) {
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  // VIEW
  return (
    <div className="iap-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="iap-content">
        {isApiWorking && (
          <button type="submit">
            <i className={apiFaIcon} />
          </button>
        )}
        <IAP_SearchBox
          isApiWorking={isApiWorking}
          search_values={search_values}
          active_tab={active_tab}
          tabs={tabs}
          setActiveTab={setActiveTab}
          updateSearchValues={updateSearchValues}
          confirmSearch={confirmSearch}
        />
        <div className="search_result">
          {!isEmpty(user) && (
            <div>
              <div
                className="check_item"
                onClick={() => setUserIAPVisible(!user_iap_visible)}
              >
                <i className="fa fa-check-circle" /> Buyer User Found
              </div>
              {user_iap_visible && <pre>{JSON.stringify(user, null, 2)}</pre>}
            </div>
          )}
          {!isEmpty(track) && (
            <div>
              <div
                className="check_item"
                onClick={() => setTrackIAPVisible(!track_iap_visible)}
              >
                <i className="fa fa-check-circle" /> Track/Album To Buy Found
              </div>
              {track_iap_visible && <pre>{JSON.stringify(track, null, 2)}</pre>}
            </div>
          )}
          {!isEmpty(iap_json) && (
            <div>
              <div
                className="check_item"
                onClick={() => setAppleIAPVisible(!apple_iap_visible)}
              >
                <i className="fa fa-check-circle" /> Apple Receipt Found
              </div>
              {apple_iap_visible && (
                <pre>{JSON.stringify(iap_json, null, 2)}</pre>
              )}
            </div>
          )}
        </div>
        <IAP_Revalidate
          token={props.user.edit_token}
          track={track}
          user={user}
          iap_json={iap_json}
        />
        <IAP_SearchResult
          isApiWorking={isApiWorking}
          search_values={search_values}
          active_tab={active_tab}
          tabs={tabs}
          //
          tableColumnItems={IAP_tableColumns}
          table_items={iap_receipt}
        />
      </div>
    </div>
  );

  function createDBItems(
    IAP_tableColumns,
    db1_name,
    database1,
    db2_name,
    database2
  ) {
    const result = [];
    // handle the id column separately
    const idObj = {
      id: {
        [db1_name]: database1.length > 0 ? database1[0].id : "🚫",
        [db2_name]: database2.length > 0 ? database2[0].id : "🚫",
      },
    };
    result.push(idObj);

    // handle the other columns
    IAP_tableColumns.forEach((column) => {
      if (column !== "id") {
        const obj = {
          [column]: {
            [db1_name]:
              database1.length > 0
                ? database1
                    .reduce((str, item) => {
                      return item[column] &&
                        Object.keys(item[column]).length > 0
                        ? str + item[column] + ", "
                        : str;
                    }, "")
                    .slice(0, -2)
                : "🚫",
            [db2_name]:
              database2.length > 0
                ? database2
                    .reduce((str, item) => {
                      return item[column] &&
                        Object.keys(item[column]).length > 0
                        ? str + item[column] + ", "
                        : str;
                    }, "")
                    .slice(0, -2)
                : "🚫",
          },
        };
        result.push(obj);
      }
    });
    return result;
  }
}

export default InAppPurchases;
