import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthenticatedRoute from "../../../components/AuthenticatedRoute";
import isEmpty from "../../../components/isEmpty";
import NotFound from "../../notfound/NotFound";
import { getUserDetails } from "../../../utils/apis/api/auth_api_user";
//css
import "./Home.css";

//JS
//< Dashboard >
import Dashboard from "../0dashboard/Dashboard";

// < PAGES >
//ADMINS
import AdminList from "../admin_list/AdminList";
import AdminLogs from "../admin_logs/AdminLogs";
import AdminPermissions from "../admin_permissions/AdminPermissions";
//USERS
import LastSignups from "../users/last_signups/LastSignups";
import GlobalUsers from "../users/global_users/GlobalUsers";

//BLOG
import Blog from "../Blog/Blog";
import BlogEditor from "../Blog/BlogEditor";

//STATISTICS
import Statistics from "../statistics/Statistics";
//
import InAppPurchases from "../InAppPurchases/InAppPurchases";
import TimeLine from "../timeline/TimeLine";
import SocialMedia from "../social_media/SocialMedia";
//
import Server from "../server/Server";
import Forum from "../forum/Forum";
import Albums from "../albums/Albums";
import FAQ from "../faq/Faq";
import Profile from "../profile/Profile";
import Payments from "../payments/Payments";
import Employers from "../employers/Employers";
import Copyrights from "../copyrights/Copyrights";
import SystemMessages from "../system_messages/SystemMessages";
import DecissionMaking from "../decission_process/DecissionProcess";
import Radio from "../radio/Radio";
import Email from "../email/Email";
import ProfileCard from "../@/ProfileCard";
import OMV from "../omv/OMV";
import QR from "../qrcode/qr/AlbumQrCode";
//LINK
//✅logged in
//https://songdrop.band
//Home class
function Home(props) {
  //
  const [isMainMenuOpened, setMainMenuOpened] = useState(false);
  //API
  const [isApiWorking, setIsApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");
  //LOGIN_USER
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  //ViewDidLoad
  useEffect(() => {
    let isMounted = true; // note mutable flag
    //
    getUser().then((user) => {
      if (isMounted) setLoggedInUser(user);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  //API: Cognito user logged in
  async function getUser() {
    try {
      //get user details
      const result = await getUserDetails();
      //✅SUCCESS
      setIsApiWorking(false);
      // console.log("USER_IS_LOGGED_IN", result);
      return result["user"];
      //🚫ERROR
    } catch (error) {
      console.log("USER_GET_ERROR", error.message);
      setApiError(error.message);
      setIsApiWorking(false);
    }
  }

  //VIEW
  return (
    <div>
      {isEmpty(loggedInUser) && !isApiWorking && (
        <NotFound title={"Unknown error"} info={apiError} />
      )}
      {!isEmpty(loggedInUser) && (
        <div className="home-container">
          <Router>
            <Switch>
              <AuthenticatedRoute path="/admins">
                <AdminList
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Admins"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/admin_permissions">
                <AdminPermissions
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Admin Permissions"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/admin_logs">
                <AdminLogs
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Admin Logs"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/statistics">
                <Statistics
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Statistics"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/statistics">
                <Statistics
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Statistics"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/iap">
                <InAppPurchases
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="In App Purchases"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/radio">
                <Radio
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Radio"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/blog/:id">
                <BlogEditor
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Blog"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/blog">
                <Blog
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Blog"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/socialmedia">
                <SocialMedia
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="SocialMedia"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/timeline">
                <TimeLine
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="TimeLine"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/server">
                <Server
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Server Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/system_messages">
                <SystemMessages
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="System Messages"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/forum">
                <Forum
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Forum Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/albums">
                <Albums
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Albums Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/qr/:id">
                <QR
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="ELECTRONICALLY DISTRIBUTED"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/omv">
                <OMV
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="OMV Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/faq">
                <FAQ
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="FAQ Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/payments">
                <Payments
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Payments Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/employers">
                <Employers
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Employers Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/statistics">
                <Statistics
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Statistics Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/@/:id">
                <ProfileCard
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Users Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/last_signups">
                <LastSignups
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Users Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/users">
                <GlobalUsers
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Users Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/copyrights">
                <Copyrights
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Copyrights Admin"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/profile">
                <Profile
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="My Profile"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/email_marketing">
                <Email
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Email Marketing"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/decission_making_process">
                <DecissionMaking
                  user={loggedInUser}
                  handleLogout={handleLogout}
                  title="Decission Making Process"
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute
                path="/"
                component={() => (
                  <Dashboard
                    user={loggedInUser}
                    handleLogout={handleLogout}
                    title="Dashboard"
                  />
                )}
              />
              <AuthenticatedRoute
                component={() => <NotFound info={"Page doesn't exist"} />}
              />
            </Switch>
          </Router>
        </div>
      )}
    </div>
  );

  //Forward logout request to App.js
  function handleLogout() {
    props.handleLogout();
  }
}

export default Home;
