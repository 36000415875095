import React from "react";

//CSS
import "./ProfileSearchBar.css";

//fa icons
const faSearchIcon = "fa fa-search";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

// SearchInput class
function ProfileSearchBar(props) {
  //UPDATE: SearchBar text
  function handleSearchChange(searchtext) {
    props.textDidChange(searchtext);
  }

  //SEARCH
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    props.searchForText();
  };

  return (
    <div className="_searchbarinput">
      <form className="search__bar" onSubmit={handleSearchSubmit}>
        <input
          className="_search_input"
          placeholder="Search Artist/Genre"
          type="text"
          value={props.searchText}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
        {!props.isApiWorking && (
          <button
            type="submit"
            disabled={props.searchText.length === 0}
            className="_search_input_btn"
          >
            <i className={faSearchIcon}></i>
          </button>
        )}
        {props.isApiWorking && (
          <button type="submit" disabled={true} className="_search_input_btn">
            <i className={apiFaIcon}></i>
          </button>
        )}
      </form>
    </div>
  );
}

export default ProfileSearchBar;
