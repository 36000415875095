import React, { useEffect, useState } from "react";
import moment from "moment";
import isEmpty from "../../../components/isEmpty";
//CSS
import "./HashtagGenerator.css";

import hashtags from "./hashtags";

//HashtagGenerator
function HashtagGenerator(props) {
  //API
  const [isApiWorking, setIsApiWorking] = useState(false);
  //

  const [hashtagCreatorVisivle, setHashtagCreatorVisible] = useState(true);
  const [hashtagFilterText, setHashtagFilterText] = useState("");
  const [filteredHashtags, setFilteredHashtags] = useState([]);
  //
  const [hashtagGeneratedLists, setGeneratedLists] = useState([]);
  const [tagsToCopyPaste, setTagsToCopyPaste] = useState([]);

  //BUILD: pagedata
  function filter_hashtags(_hashtags) {
    setHashtagFilterText(_hashtags);
    setFilteredHashtags(
      hashtags.filter(function (str) {
        return str.indexOf(_hashtags) > -1;
      })
    );
  }

  function addHashtagResults(_filteredHashtags) {
    if (isEmpty(_filteredHashtags[0])) {
      return;
    }
    const new_list_element = {
      tag1: _filteredHashtags[0],
      hashtags: _filteredHashtags,
      count: _filteredHashtags.length,
    };
    //Append new element to list
    setGeneratedLists(hashtagGeneratedLists.concat(new_list_element));
    //remove the filtered hashtags to display
    setFilteredHashtags([]);
    //reset input hashtag searchtext
    setHashtagFilterText("");
  }

  function removeListAtIndex(index) {
    // assigning the list to temp variable
    const temp = [...hashtagGeneratedLists];

    // removing the element using splice
    temp.splice(index, 1);

    // updating the list
    setGeneratedLists(temp);
  }

  function mixSelection() {
    var listOfTagsToPost = [];
    //
    hashtagGeneratedLists.forEach((tag, index) => {
      //
      if (!isEmpty(tag.hashtags)) {
        //
        tag.hashtags.forEach((hashtag, index) => {
          if (!listOfTagsToPost.indexOf(hashtag) > -1) {
            listOfTagsToPost.push(hashtag.toLowerCase());
          }
        });
      }
    });
    let shuffledArray = shuffle(listOfTagsToPost);
    if (shuffledArray.lenght > 11) {
      shuffledArray.length = 11;
    }
    console.log("shuffledArray", shuffledArray);
    setTagsToCopyPaste(shuffledArray);
  }

  function addHashtagsToPostClicked() {
    props.addHashtagsToPostClicked(tagsToCopyPaste);
  }

  //VIEW
  return (
    <div className="hashtag_generator">
      <div>1 post should have up to 11 hashtags</div>
      {!isEmpty(hashtagGeneratedLists) && (
        <div className="selected_hashtags">
          <div>Selected hashtags:</div>
          <div>{build_hashtag_list(hashtagGeneratedLists)}</div>
          <div>
            <br />
            <button onClick={() => mixSelection()}>
              <i className="fa fa-random" />
              Mix 11
            </button>
            Recommended hashtaglist to{" "}
            <button onClick={() => addHashtagsToPostClicked()}>
              <i className="fa fa-puzzle-piece" />
              Add to post
            </button>
            <button>
              <i className="fa fa-clipboard" />
              Copy paste
            </button>
            :<div className="copytags">{tagsToCopyPaste.join(" ")}</div>
          </div>
          <div></div>
        </div>
      )}
      <div>
        <br />
        <input
          placeholder="hashtag"
          value={hashtagFilterText}
          onChange={(e) => filter_hashtags(e.target.value)}
        />
        <button onClick={(e) => addHashtagResults(filteredHashtags)}>
          +Add Group
        </button>
      </div>
      <div className="fitlered_hashtags">
        <div>Result: {filteredHashtags.length}</div>
        <div className="hashtags">{build_hashtags(filteredHashtags)}</div>
      </div>
      <div>All Hashtags #{hashtags.length}</div>
      <div className="hashtags">{build_hashtags(hashtags)}</div>
    </div>
  );

  //BUILD: pagedata
  function build_hashtags(_hashtags) {
    var ui_items = [];
    if (isEmpty(_hashtags)) {
      return ui_items;
    }

    const arraylist = [];
    _hashtags.forEach((tag, index) => {
      const tableRow = <div className="tag">{tag.toLowerCase()}</div>;
      ui_items.push(tableRow);
      arraylist.push(tag.toLowerCase());
    });
    // console.log(arraylist);
    return ui_items;
  }

  //BUILD: pagedata
  function build_hashtag_list(_hashtags) {
    var ui_items = [];
    if (isEmpty(_hashtags)) {
      return ui_items;
    }

    _hashtags.forEach((tag, index) => {
      const tableRow = (
        <div className="tag">
          <button>{tag["count"]}</button>
          <div>
            {tag["tag1"].toLowerCase()}
            <button onClick={(e) => removeListAtIndex(index)}>
              <i className="fa fa-close" />
            </button>
          </div>
        </div>
      );
      ui_items.push(tableRow);
    });
    return ui_items;
  }

  function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }
}

export default HashtagGenerator;
