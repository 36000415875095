import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import Footer from "../../../components/Footer/Footer";
import NavBar from "../../../components/Navbar/NavBar";

//CSS
import "./AdminLogs.css";

//AdminLogs
function AdminLogs(props) {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Admin Logs | SongDrop.band";
  }, []);

  return (
    <div className="default">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
    </div>
  );
}

export default AdminLogs;
