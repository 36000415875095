import React, { useEffect } from "react";
import isEmpty from "../../../../components/isEmpty";
import Moment from "moment";
import Linkify from "react-linkify";
//CSS
import "./VerticalTimeline.css";

//VerticalTimeline
function VerticalTimeline(props) {
  //VIEW
  return (
    <div id="timeline-content">
      {isEmpty(props.user) && <h1>Timeline</h1>}
      {!isEmpty(props.user) && (
        <h1>
          Timeline of{" "}
          <a href={`/@/${props.user.username}`}>
            <strong>@{props.user.username} </strong>
          </a>
          <i className="fa fa-caret-right" aria-hidden="true"></i>
        </h1>
      )}
      <ul className="timeline">{createTimeline(props.timeline)}</ul>
    </div>
  );

  //createTimeline
  function createTimeline(data) {
    var model = [];
    if (isEmpty(data)) {
      return model;
    }
    //sort
    let sorted_data = data.sort(function (left, right) {
      if (left["date"] === right["date"]) {
        return left["title"].localeCompare(right["title"]);
      } else {
        return left["date"] < right["date"] ? 1 : -1;
      }
    });

    sorted_data.forEach((item, index) => {
      //here is your code
      model.push(
        <li key={index} className="t_event" data-date={convertDate(item.date)}>
          <Linkify>
            <label className="t_wrap">
              <div className="t_title">{item.title}</div>
              <span className="t_subtitle">{item.subtitle}</span>
            </label>
          </Linkify>
        </li>
      );
    });
    return model;
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    // console.log(dateString);
    return Moment(new Date(dateString)).format("yyyy-MM-DD");
  }
}

export default VerticalTimeline;
