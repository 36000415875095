import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";

//CSS
import "./Profile.css";
//JS
import NavBar from "../../../components/Navbar/NavBar";
import MFA from "./MFA/MFA";
import ChangePassword from "./Password/ChangePass";
import LastActivity from "./LastActivity/LastActivity";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const placeholderURL =
  "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia.istockphoto.com%2Fvectors%2Fdefault-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1214428300%3Fk%3D6%26m%3D1214428300%26s%3D170667a%26w%3D0%26h%3DhMQs-822xLWFz66z3Xfd8vPog333rNFHU6Q_kc9Sues%3D&f=1&nofb=1&ipt=708af11dd32f5b757eea2ff13952da745edaf701eb282879441e8b1bc8b95550&ipo=images";
//LINK
//✅logged in
//https://admin.songdrop.band/profile
//Profile
function Profile(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  const [user_number, setUsername] = useState(0);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Profile | Admin SongDrop";
    //api
  }, []);

  //VIEW
  return (
    <div className="profile">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="p-body">
        <div>You logged in as:</div>
        <div>
          <strong>{props.user.email}</strong>
        </div>
        <br />
        <MFA user={props.user} />
        <br />
        <div className="profile_edit">
          <div>
            <strong>My Profile</strong>
          </div>
          <div className="img">
            <img
              alt=""
              src={props.user.profile_img_url || placeholderURL}
              style={{
                width: "80px",
                height: "80px",
                // backgroundColor: "lightgray",
                marginTop: "12px",
                marginBottom: "12px",
              }}
            />
            <button>Upload Photo</button>
          </div>
          <div>Name: {props.user["name"]}</div>
          <div>Username: {props.user["username"]}</div>
          <div>Position: {props.user["company_role"]}</div>
          <div>Permissions: {props.user["permission"]}</div>
          <div>About: {props.user["about"]}</div>
          <textarea
            placeholder="Describe yourself"
            style={{ width: "calc(100% - 12px)" }}
          />
          <div>
            <button>Save</button>
          </div>
          <br />
          <div>created_at: {props.user["created_at"]}</div>
          <div>updated_at: {props.user["updated_at"]}</div>
        </div>
        <div className="password">
          <div className="title">Change Password</div>
          <ChangePassword user={props.user} />
        </div>
        <div className="activity">
          <LastActivity user={props.user} />
        </div>
      </div>
    </div>
  );
}

export default Profile;
