const formatted_hashtags = [
  "#indiemusic",
  "#unsignedartist",
  "#indieartist",
  "#diymusic",
  "#songdrop",
  "#independentartist",
  "#newmusic",
  "#supportindie",
  "#discoverindie",
  "#indiemusiclover",
  "#songwriters",
  "#songwriterlife",
  "#songwriting",
  "#musicproduction",
  "#musicpromotion",
  "#musicindustry",
  "#singersongwriter",
  "#musicmarketing",
  "#independentmusic",
  "#indiemusic",
  "#unsignedartist",
  "#musicproducer",
  "#songwriter",
  "#diymusic",
  "#newmusic",
  "#listenlocal",
];
const genres = [
  "#indiepop",
  "#indierock",
  "#indiefolk",
  "#indieelectronic",
  "#indiehiphop",
  "#indiejazz",
  "#indieblues",
  "#indiecountry",
  "#indiepsych",
  "#indiepunk",
  "#indielounge",
  "#indiegrunge",
  "#indiefunk",
  "#indieambient",
  "#indietriphop",
  "#indieclassical",
  "#indiebrass",
  "#indieexperimental",
  "#indiepoprock",
  "#indierap",
  "#indiedisco",
  "#indielatin",
  "#indiepopmusic",
  "#indieclassic",
  "#indiebrassmusic",
  "#indiejang",
  "#indiejazzy",
  "#indiemusicworld",
  "#indiemusiclover",
  "#indiemusicnation",
  "#indiemusiclife",
  "#indiemusicdiscovery",
  "#indiemusicdiscover",
  "#indiemusicartist",
  "#indiemusicart",
  "#indiemusicspot",
  "#indiemusiclive",
  "#indiemusicfans",
  "#indiemusicfan",
  "#indiemusicfestival",
  "#indiemusicfest",
  "#indiemusiclove",
  "#indiemusiclives",
  "#indiemusiclover",
  "#indiemusiclovers",
  "#indiemusicflow",
  "#indiemusicfeature",
  "#indiemusicfeatures",
  "#indiemusicfollow",
  "#indiemusicfollowers",
  "#indiemusicguitar",
  "#indiemusicguitars",
  "#indiemusichits",
  "#indiemusichit",
];
const production = [
  "#diymusic",
  "#musicmarketing",
  "#musicpromotion",
  "#indiemusic",
  "#unsignedartist",
  "#newmusic",
  "#musicproducer",
  "#homerecording",
  "#DIYrecording",
  "#indieproducer",
  "#musicpromo",
  "#unsignedhype",
  "#selfpromotion",
  "#musicnetworking",
  "#indiepromotion",
  "#musicdistribution",
  "#musicstrategist",
  "#musicbranding",
  "#indierecordlabel",
  "#digitalmusicmarketing",
  "#promomusic",
  "#artistpromotion",
  "#indiemusicpromo",
  "#musicmarketingtips",
  "#promotingyourmusic",
  "#musicpromotionstrategy",
  "#indiepromo",
  "#newartist",
  "#musicindustry",
  "#musicmarketingplan",
  "#indieartist",
  "#musicdistributor",
  "#musicmarketingagency",
  "#indiepromoter",
  "#musicmarketingconsultant",
  "#promomusicvideo",
  "#indiepromotions",
  "#musicmarketingcompanies",
  "#promomusicsingle",
  "#musicpromotionservice",
];

const community = [
  "#independentartist",
  "#supportindie",
  "#discoverindie",
  "#indieartist",
  "#indiemusic",
  "#indiepop",
  "#indierock",
  "#indiefolk",
  "#indiehiphop",
  "#indielive",
  "#indieelectronic",
  "#indiebands",
  "#indiecommunity",
  "#indieentrepreneur",
  "#indieproducers",
  "#indierecordlabel",
  "#indievenue",
  "#indiepromotion",
  "#indiepublicity",
  "#indiemarketing",
  "#indiemusician",
  "#indieartistsupport",
  "#indiespotlight",
  "#indieupandcomer",
  "#indiesongwriter",
  "#indiealbum",
  "#indiemusiclover",
  "#indiemusicfans",
  "#indiemusicpromo",
  "#indiemusiclife",
  "#indierecordings",
  "#indieproducer",
  "#indiemusiccollector",
  "#indiemusicnews",
  "#indiemusicworldwide",
  "#indiemusicindustry",
  "#indiemusicnetwork",
  "#indiemusiccommunity",
  "#indiemusicexplorer",
  "#indiemusicgenre",
  "#indiemusicstyle",
];
const industry = [
  "#musicindustry",
  "#singersongwriter",
  "#musicproduction",
  "#indiemusic",
  "#indieartist",
  "#unsignedartist",
  "#indierecordlabel",
  "#behindthescenes",
  "#studiolife",
  "#songwriting",
  "#musicbiz",
  "#unsignedhype",
  "#artistdevelopment",
  "#indiescene",
  "#indiemusician",
  "#songwriterlife",
  "#indiepromo",
  "#indieband",
  "#indiemusicians",
  "#indiemusicproduction",
  "#indieproducer",
  "#indierecording",
  "#indierecordlabels",
  "#indiepromotions",
  "#indiemusicpromo",
  "#indiemusicnetwork",
  "#indiemusicindustry",
  "#indiemusicgenre",
  "#indiemusicartist",
  "#indiemusicdistribution",
];
const lover = [
  "#musiclover",
  "#newmusic",
  "#listenlocal",
  "#musicdiscovery",
  "#musicfan",
  "#musiccommunity",
  "#musicdiscovery",
  "#indiemusiclover",
  "#musicappreciation",
  "#musicstreaming",
  "#musicpodcast",
  "#musicblog",
  "#musicplaylist",
  "#musicreview",
  "#musicexplorer",
  "#musicdiscoveries",
  "#musicdiscovernew",
  "#musicartistdiscovery",
  "#musicnewartist",
  "#musicfans",
  "#musicfancommunity",
  "#newmusicdiscovery",
  "#musicdiscoveryshow",
  "#newmusicmonday",
  "#newmusicfriday",
  "#musiclifestyle",
  "#musiclistening",
  "#musicdiscover",
  "#newalbum",
  "#newalbumrelease",
  "#albumreview",
  "#albumrelease",
  "#newmusicalert",
  "#newartist",
  "#newmusicfind",
  "#newmusicdiscovery",
  "#newartistdiscovery",
  "#undergroundmusic",
];
const songwriters = [
  "#songwriters",
  "#songwriterlife",
  "#songwriting",
  "#lyrics",
  "#songlyrics",
  "#composer",
  "#singersongwriter",
  "#originalsong",
  "#songsfromtheheart",
  "#songcraft",
  "#newmusic",
  "#musiccreation",
  "#songwritingcommunity",
  "#songwriterspotlight",
  "#songwritinginspiration",
  "#creativesongwriting",
  "#songwritingtips",
  "#unreleasedmusic",
  "#songwritingchallenge",
  "#songwritingcompetition",
  "#cowritesession",
  "#songwritingcontest",
  "#songwritingcollaboration",
  "#songwritingprocess",
  "#songwritingstudio",
  "#songwritingclass",
  "#songwritingworkshop",
  "#songwritingadvice",
  "#songwritingbooks",
  "#songwritingsoftware",
  "#songwritingresources",
  "#songwritingcontest",
  "#songwritingjourney",
  "#songwritinggoals",
  "#songwritingdreams",
  "#songwritingmastery",
  "#songwritingcareer",
  "#songwritinggrind",
  "#songwritinghustle",
  "#songwritingstruggle",
  "#songwritingmotivation",
  "#songwritingquotes",
  "#songwritinglife",
  "#songwritinglifestyle",
  "#songwritingpassion",
  "#songwritinglove",
  "#songwritingaddict",
  "#songwritingobsessed",
  "#songwritingpicoftheday",
  "#songwritingdaily",
  "#songwritingfan",
  "#songwritingnerd",
  "#songwritinggeek",
  "#songwritingfanatic",
];
const diymusic = [
  "#DIYMusic",
  "#DIYRecording",
  "#DIYProduction",
  "#homestudio",
  "#homerecording",
  "#recordingstudio",
  "#productiontips",
  "#DIYMusician",
  "#DIYAudio",
  "#DIYMusicProduction",
  "#DIYMusicStudio",
  "#DIYRecordingStudio",
  "#DIYMusicTips",
  "#DIYMusicCommunity",
  "#DIYMusicChallenge",
  "#DIYMusicContest",
  "#DIYMusicGear",
  "#DIYMusicEquipment",
  "#DIYMusicTech",
  "#DIYMusicLife",
  "#DIYMusicGoals",
  "#DIYMusicDreams",
  "#DIYMusicMastery",
  "#DIYMusicCareer",
  "#DIYMusicGrind",
  "#DIYMusicHustle",
  "#DIYMusicStruggle",
  "#DIYMusicMotivation",
  "#DIYMusicLove",
  "#DIYMusicObsessed",
  "#DIYMusicAddict",
  "#DIYMusicPicoftheday",
  "#DIYMusicDaily",
  "#DIYMusicFan",
  "#DIYMusicNerd",
  "#DIYMusicGeek",
  "#DIYMusicFanatic",
  "#DIYMixing",
  "#DIYMastering",
  "#DIYSoundEngineering",
  "#DIYMusicEditing",
  "#DIYMusicRecording",
  "#DIYMusicEngineering",
  "#DIYMusicTechTips",
  "#DIYMusicEditing",
  "#DIYMusicRecording",
  "#DIYMusicProductionTips",
  "#DIYMusicProductionTutorial",
  "#DIYMusicProductionAdvice",
  "#DIYMusicMaking",
  "#DIYMusicRecordingEquipment",
  "#DIYMusicRecordingTips",
];
const indilabel = [
  "#IndieLabel",
  "#DIYLabel",
  "#IndieRecordLabel",
  "#IndieMusic",
  "#IndieArtists",
  "#IndieBands",
  "#IndieMusicCommunity",
  "#IndieLabelSpotlight",
  "#IndieLabelSupport",
  "#IndieLabelLove",
  "#IndieLabelPromotion",
  "#IndieLabelNews",
  "#IndieLabelReleases",
  "#IndieLabelSuccess",
  "#IndieLabelGoals",
  "#IndieLabelDreams",
  "#IndieLabelMastery",
  "#IndieLabelCareer",
  "#IndieLabelGrind",
  "#IndieLabelHustle",
  "#IndieLabelStruggle",
  "#IndieLabelMotivation",
  "#IndieLabelSuccessStory",
  "#IndieLabelInspiration",
  "#IndieLabelAdvice",
  "#IndieLabelCollaboration",
  "#IndieLabelPartnership",
  "#IndieLabelNetworking",
  "#IndieLabelManagement",
  "#IndieLabelMarketing",
  "#IndieLabelDistribution",
  "#IndieLabelPromo",
  "#IndieLabelMerch",
  "#IndieLabelLife",
  "#IndieLabelPicoftheday",
  "#IndieLabelDaily",
  "#IndieLabelFan",
  "#IndieLabelNerd",
  "#IndieLabelGeek",
  "#IndieLabelFanatic",
  "#DIYLabelSpotlight",
  "#DIYLabelSupport",
  "#DIYLabelLove",
  "#DIYLabelPromotion",
  "#DIYLabelReleases",
  "#DIYLabelSuccess",
  "#DIYLabelGoals",
  "#DIYLabelDreams",
  "#DIYLabelMastery",
  "#DIYLabelCareer",
  "#DIYLabelGrind",
  "#DIYLabelHustle",
  "#DIYLabelStruggle",
  "#DIYLabelMotivation",
  "#DIYLabelSuccessStory",
  "#DIYLabelInspiration",
  "#DIYLabelAdvice",
  "#DIYLabelCollaboration",
  "#DIYLabelPartnership",
  "#DIYLabelNetworking",
  "#DIYLabelManagement",
  "#DIYLabelMarketing",
  "#DIYLabelDistribution",
  "#DIYLabelPromo",
  "#DIYLabelMerch",
  "#DIYLabelLife",
];
const streaming = [
  "#StreamingMusic",
  "#MusicStreaming",
  "#IndependentMusicStreaming",
  "#StreamingService",
  "#MusicOnDemand",
  "#StreamingPlaylist",
  "#StreamingApps",
  "#MusicSubscription",
  "#StreamingCommunity",
  "#StreamingSpotlight",
  "#StreamingSupport",
  "#StreamingLove",
  "#StreamingPromotion",
  "#StreamingNews",
  "#StreamingUpdates",
  "#StreamingSuccess",
  "#StreamingGoals",
  "#StreamingDreams",
  "#StreamingMastery",
  "#StreamingCareer",
  "#StreamingGrind",
  "#StreamingHustle",
  "#StreamingStruggle",
  "#StreamingMotivation",
  "#StreamingSuccessStory",
  "#StreamingInspiration",
  "#StreamingAdvice",
  "#StreamingCollaboration",
  "#StreamingPartnership",
  "#StreamingNetworking",
  "#StreamingManagement",
  "#StreamingMarketing",
  "#StreamingDistribution",
  "#StreamingPromo",
  "#StreamingMerch",
  "#StreamingLife",
  "#StreamingPicoftheday",
  "#StreamingDaily",
  "#StreamingFan",
  "#StreamingNerd",
  "#StreamingGeek",
  "#StreamingFanatic",
  "#IndieStreaming",
  "#IndieStreamingSpotlight",
  "#IndieStreamingSupport",
  "#IndieStreamingLove",
  "#IndieStreamingPromotion",
  "#IndieStreamingReleases",
  "#IndieStreamingSuccess",
  "#IndieStreamingGoals",
  "#IndieStreamingDreams",
  "#IndieStreamingMastery",
  "#IndieStreamingCareer",
  "#IndieStreamingGrind",
  "#IndieStreamingHustle",
  "#IndieStreamingStruggle",
  "#IndieStreamingMotivation",
  "#IndieStreamingSuccessStory",
  "#IndieStreamingInspiration",
  "#IndieStreamingAdvice",
  "#IndieStreamingCollaboration",
  "#IndieStreamingPartnership",
  "#IndieStreamingNetworking",
  "#IndieStreamingManagement",
  "#IndieStreamingMarketing",
];
const distribution = [
  "#MusicDistribution",
  "#IndependentMusicDistribution",
  "#DistributionService",
  "#MusicPromotion",
  "#DigitalDistribution",
  "#MusicMarketing",
  "#DistributionDeals",
  "#MusicDistro",
  "#DistributionCommunity",
  "#DistributionSpotlight",
  "#DistributionSupport",
  "#DistributionLove",
  "#DistributionPromotion",
  "#DistributionNews",
  "#DistributionUpdates",
  "#DistributionSuccess",
  "#DistributionGoals",
  "#DistributionDreams",
  "#DistributionMastery",
  "#DistributionCareer",
  "#DistributionGrind",
  "#DistributionHustle",
  "#DistributionStruggle",
  "#DistributionMotivation",
  "#DistributionSuccessStory",
  "#DistributionInspiration",
  "#DistributionAdvice",
  "#DistributionCollaboration",
  "#DistributionPartnership",
  "#DistributionNetworking",
  "#DistributionManagement",
  "#DistributionMarketing",
  "#DistributionStrategy",
  "#DistributionPromo",
  "#DistributionMerch",
  "#DistributionLife",
  "#DistributionPicoftheday",
  "#DistributionDaily",
  "#DistributionFan",
  "#DistributionNerd",
  "#DistributionGeek",
  "#DistributionFanatic",
  "#IndieDistribution",
  "#IndieDistributionSpotlight",
  "#IndieDistributionSupport",
  "#IndieDistributionLove",
  "#IndieDistributionPromotion",
  "#IndieDistributionReleases",
  "#IndieDistributionSuccess",
  "#IndieDistributionGoals",
  "#IndieDistributionDreams",
  "#IndieDistributionMastery",
  "#IndieDistributionCareer",
  "#IndieDistributionGrind",
  "#IndieDistributionHustle",
  "#IndieDistributionStruggle",
  "#IndieDistributionMotivation",
  "#IndieDistributionSuccessStory",
  "#IndieDistributionInspiration",
  "#IndieDistributionAdvice",
  "#IndieDistributionCollaboration",
  "#IndieDistributionPartnership",
  "#IndieDistributionNetworking",
  "#IndieDistributionManagement",
  "#IndieDistributionMarketing",
];

const download = [
  "#IndependentMusicDownload",
  "#PayForMusic",
  "#DigitalMusicConsumption",
  "#MusicDownloads",
  "#DigitalAlbums",
  "#MusicSales",
  "#MusicRetail",
  "#MusicPlatform",
  "#MusicDistributionPlatforms",
  "#MusicDownloadCommunity",
  "#MusicDownloadSpotlight",
  "#MusicDownloadSupport",
  "#MusicDownloadLove",
  "#MusicDownloadPromotion",
  "#MusicDownloadNews",
  "#MusicDownloadUpdates",
  "#MusicDownloadSuccess",
  "#MusicDownloadGoals",
  "#MusicDownloadDreams",
  "#MusicDownloadMastery",
  "#MusicDownloadCareer",
  "#MusicDownloadGrind",
  "#MusicDownloadHustle",
  "#MusicDownloadStruggle",
  "#MusicDownloadMotivation",
  "#MusicDownloadSuccessStory",
  "#MusicDownloadInspiration",
  "#MusicDownloadAdvice",
  "#MusicDownloadCollaboration",
  "#MusicDownloadPartnership",
  "#MusicDownloadNetworking",
  "#MusicDownloadManagement",
  "#MusicDownloadMarketing",
  "#MusicDownloadDistribution",
  "#MusicDownloadPromo",
  "#MusicDownloadMerch",
  "#MusicDownloadLife",
  "#MusicDownloadPicoftheday",
  "#MusicDownloadDaily",
  "#MusicDownload",
];
const promo = [
  "#MusicPromotion",
  "#IndieMusicMarketing",
  "#IndependentMusicPromotion",
  "#MusicPublicity",
  "#IndieMusicPublicity",
  "#IndependentMusicPublicity",
  "#MusicPR",
  "#IndieMusicPR",
  "#IndependentMusicPR",
  "#MusicAdvertising",
  "#IndieMusicAdvertising",
  "#IndependentMusicAdvertising",
  "#MusicMarketingTips",
  "#IndieMusicMarketingTips",
  "#IndependentMusicMarketingTips",
  "#MusicBranding",
  "#IndieMusicBranding",
  "#IndependentMusicBranding",
  "#MusicPromo",
  "#IndieMusicPromo",
  "#IndependentMusicPromo",
  "#MusicPromotionServices",
  "#IndieMusicPromotionServices",
  "#IndependentMusicPromotionServices",
  "#MusicPromotionStrategies",
  "#IndieMusicPromotionStrategies",
  "#IndependentMusicPromotionStrategies",
  "#MusicPromotionCommunity",
  "#IndieMusicPromotionCommunity",
  "#IndependentMusicPromotionCommunity",
  "#MusicPromotionSpotlight",
];

const support = [
  "#MusicCommunity",
  "#IndieMusicSupport",
  "#IndependentMusicCommunity",
  "#MusicSupportGroup",
  "#IndieMusicSupportGroup",
  "#IndependentMusicSupportGroup",
  "#MusicArtistCommunity",
  "#IndieMusicArtistCommunity",
  "#IndependentMusicArtistCommunity",
  "#MusicCollaboration",
  "#IndieMusicCollaboration",
  "#IndependentMusicCollaboration",
  "#MusicNetworking",
  "#IndieMusicNetworking",
  "#IndependentMusicNetworking",
  "#MusicMentorship",
  "#IndieMusicMentorship",
  "#IndependentMusicMentorship",
  "#MusicAdvice",
  "#IndieMusicAdvice",
  "#IndependentMusicAdvice",
  "#MusicInsiration",
  "#IndieMusicInspiration",
  "#IndependentMusicInspiration",
  "#MusicEmpowerment",
  "#IndieMusicEmpowerment",
  "#IndependentMusicEmpowerment",
  "#MusicAdvocate",
  "#IndieMusicAdvocate",
  "#IndependentMusic",
];

const press = [
  "#indiemusicpress",
  "#musicjournalism",
  "#indiejournalism",
  "#independentmusicmedia",
  "#indiepress",
  "#musicnews",
  "#indiemusicnews",
  "#indiereviews",
  "#indiemusicreviews",
  "#indiemusicfeatures",
  "#indieartistinterview",
  "#indiemusicspotlight",
  "#indiemusiccoverage",
  "#indiemusicpromotion",
  "#indiemusicpublicity",
  "#indiemusicpr",
  "#indiemusicmarketing",
  "#indiemusicindustry",
  "#indiemusicbusiness",
  "#indiemusiccommunity",
  "#indiemusicresources",
  "#indiemusicnetworking",
  "#indiemusicexposure",
  "#indiemusicrepresentation",
  "#indiemusicdistribution",
  "#indiemusicevents",
  "#indiemusicfestivals",
  "#indiemusicawards",
  "#indiemusiccharts",
  "#indiemusicpromo",
  "#indiemusicdistro",
  "#indiemusicdistributor",
  "#indiemusiclabel",
  "#indiemusiclicensing",
  "#indiemusicproduction",
  "#indiemusicconsulting",
  "#indiemusicconsultancy",
  "#indiemusicstrategy",
  "#indiemusicmanagement",
  "#indiemusicartist",
];

const eventpromo = [
  "#indiefest",
  "#indiemusicevent",
  "#indieconcert",
  "#indieperformance",
  "#indiemusiclive",
  "#indieartistlive",
  "#liveindiemusic",
  "#indiemusicfestival",
  "#indieartistshow",
  "#indiebandslive",
  "#liveindieperformance",
  "#indieevent",
  "#indiemusicpromo",
  "#indiepromotion",
  "#indiemusicmarketing",
  "#indiemusicadvertising",
  "#indiemusicpublicity",
  "#indieperformancemarketing",
  "#indieconcertpromo",
  "#indiemusicfest",
  "#indiemusicfestivals",
  "#indieartistfest",
  "#indiebandfest",
  "#indiemusicexpo",
  "#indieartistshowcase",
  "#indiebandexpo",
  "#indieperformanceexpo",
  "#indiemusiceventpromo",
  "#indieartistspotlight",
  "#indiebandspotlight",
  "#indiemusicgig",
  "#indieartistgig",
  "#indiebandgig",
  "#indiemusiclivepromo",
  "#indieartistpromo",
  "#indiebandpromo",
  "#indiemusiceventmarketing",
  "#indieartistmarketing",
  "#indiebandmarketing",
  "#indiemusicliveadvertising",
  "#indieartistadvertising",
  "#indiebandadvertising",
  "#indiemusiclivepublicity",
  "#indieartistpublicity",
  "#indiebandpublicity",
  "#indieperformanceadvertising",
  "#indieconcertmarketing",
  "#indiemusicfestpromo",
  "#indieartistfestpromo",
  "#indiebandfestpromo",
];
export default formatted_hashtags.concat(
  genres,
  production,
  community,
  industry,
  lover,
  songwriters,
  diymusic,
  indilabel,
  streaming,
  distribution,
  download,
  promo,
  support,
  press,
  eventpromo
);
