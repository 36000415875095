import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//User
export const getUserDetails = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    var bodyParams = [];
    bodyParams.push(`access_token=${tokens["AccessToken"]}`);
    const bParameters = bodyParams.join("&");
    const user = await getUserData(userId, tokens["IDToken"], bParameters);
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const getUserData = async (userId, token, bodyParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/admin/get_admin_user" +
      "?" +
      `user_id=${userId}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let user = response["data"]["result"];
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editUser = async (parameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await post_edit_user(userId, parameters, tokens["IDToken"]);
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const editUserImg = async (parameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await post_edit_user_img(
      userId,
      parameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

const post_edit_user = async (user_id, parameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/admin/edit_user" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, parameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const post_edit_user_img = (user_id, parameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/admin/edit_user_image" +
      "?" +
      `user_id=${user_id}`;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    console.log("POST", URL, headers);
    axios
      .post(URL, parameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        // console.log("S3_URL_SYNC_RESULT", result);
        resolve(result);
      })
      .catch((error) => {
        // console.log("S3_URL_SYNC_FAILED", error);
        throw new Error(error.response.data.error);
      });
  });
};

//User
export const getUserActivityHistory = async (page) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    var bodyParams = [];
    bodyParams.push(`access_token=${tokens["AccessToken"]}`);
    const bParameters = bodyParams.join("&");
    const user = await get_user_activity(
      userId,
      tokens["IDToken"],
      bParameters
    );
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_user_activity = async (userId, token, bodyParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/admin/get_admin_user_activity" +
      "?" +
      `user_id=${userId}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let user = response["data"]["result"];
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
