import React, { useEffect, useRef, useState } from "react";
import { useContainerDimensions } from "../../../components/useContainerDimensions";
import { usdCurrency } from "../../../components/currencyFormatter";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import Footer from "../../../components/Footer/Footer";
import {
  getDOServerBillingHistory,
  getDOServerBillingMetrix,
} from "../../../utils/apis/api/auth_api_server";
import internal from "../../../assets/about/internal.json";

//CSS
import "./Server.css";

//JS
import NavBar from "../../../components/Navbar/NavBar";
import ServerCostChart from "./ServerCostChart";

//chart json default values [jan, febr...dec]
import chartJSON from "../../../assets/json/payments/payment-chart-json.json";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faCog = "fa fa-cog";
const faWifi = "fa fa-wifi";
//LINK
//✅logged in
//https://admin.songdrop.band

//Dashboard
function Server(props) {
  //Helper:siteWidth ref(resize chartview)
  const componentRef = useRef("paymentdashchart");
  const { width, height } = useContainerDimensions(componentRef);
  //API
  const [doAPIWorking, setDOAPIWorking] = useState(false);
  const [isApiWorking, setIsApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  const [allBillingCost, setAllBillingCost] = useState(0);
  //
  const [doBillingData, setDoBillingData] = useState(null);
  const [awsBillingData, setAWSBillingData] = useState(null);
  //body:chart
  const [chartData, setChartData] = useState(chartJSON);

  //
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Server | Admin SongDrop";
    //api
    callDOBillsMetrix();
    callDOBillingHistory();
  }, []);

  async function callDOBillsMetrix() {
    setDOAPIWorking(true);
    try {
      const result = await getDOServerBillingMetrix();
      //✅SUCCESS
      console.log("DIGITAL_OCEAN_BILL_METRIX", result);
      setDoBillingData(result);
      setDOAPIWorking(false);
      setAllBillingCost(result["month_to_date_balance"]);
      //🚫ERROR
    } catch (error) {
      console.log(error);
      setIsApiWorking(false);
    }
  }

  async function callDOBillingHistory() {
    //
    setIsApiWorking(true);
    //
    try {
      const result = await getDOServerBillingHistory();
      //✅SUCCESS
      setIsApiWorking(false);
      console.log("DIGITAL_OCEAN_BILLING_HISTORY", result["billing_history"]);
      var chart = [];
      //sort by date
      const sortedSalesData = result["billing_history"].sort(
        (a, b) => b.date < a.date
        // (a, b) => a.purchase_date - b.purchase_date
      );
      sortedSalesData.forEach((item, index) => {
        if (item["description"].includes("Invoice")) {
          const invoice = {
            name: item["description"].replace("Invoice for ", ""),
            payment: item["amount"],
            date: item["date"],
          };
          chart.push(invoice);
        }
      });
      //
      setChartData(chart);

      //🚫ERROR
    } catch (error) {
      console.log(error);
      setIsApiWorking(false);
    }
  }

  async function callAWSBillsMetrix() {
    setIsApiWorking(true);
    try {
      const result = await getDOServerBillingMetrix();
      //✅SUCCESS
      console.log("RESULT", result);
      setIsApiWorking(false);
    } catch (error) {
      //🚫ERROR
      console.log(error);
      setIsApiWorking(false);
    }
  }

  //VIEW
  return (
    <div className="billing-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="billing-content">
        <div className="billing-dash">
          <div className="bill-head">Billing Dashboard</div>
          <div>
            <p>
              Montly Server Costs:{" "}
              <strong>{usdCurrency(allBillingCost)}</strong>
            </p>
            <p>
              Digital Ocean Billing:{" "}
              {!isEmpty(doBillingData) && !doAPIWorking && (
                <strong>
                  {usdCurrency(doBillingData["month_to_date_balance"])}
                </strong>
              )}
              {doAPIWorking && <i className={apiFaIcon} />}
            </p>
          </div>
          <div>AWS Billing: {awsBillingData}</div>
        </div>
        <div ref={componentRef}>
          <div>Billing Chart</div>
          <ServerCostChart
            backgroundColor={"white"}
            tintColor={"#2ac24b"}
            width={width}
            data={chartData}
          />
        </div>
        <div>
          <a
            title="StatusPage.IO"
            style={{ float: "right", fontSize: "24px" }}
            href={internal["status_page_public_url"]}
          >
            <i className={faWifi} />
          </a>
          <a
            title="StatusPage Settings"
            style={{ float: "right", fontSize: "24px", marginRight: "12px" }}
            href={internal["status_page_int_url"]}
          >
            <i className={faCog} />
          </a>
        </div>
        <div>
          <div>Need to add</div>
          <p>+AWS Lambda ASTRO kinda app</p>
          <p>+AWS Server costs</p>
          <p>+AWS health check</p>
        </div>
      </div>
    </div>
  );
}

export default Server;
