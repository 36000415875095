import React, { useState, useEffect } from "react";
import { unblockUsers } from "../../../../utils/apis/api/auth_api_users";
import AdminUnblockForm from "./AdminUnblockForm";

//CSS
import "./UnblockUsers.css";

const default_unblocking_message =
  "Your account has been reactivated by our team. Now you can login again, and continue to use our service. ";
const def_placeholder =
  "Send customised email, when unblocking user account(s)";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//UnblockUsers class
function UnblockUsers(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  //
  const [unblocking_message, setUnblockingMessage] = useState(
    default_unblocking_message
  );
  const [unblocking_message_editing, setUnblockingMessageEditing] =
    useState(false);
  //this is by default. use it all the time to tell users they have been blocked, if this turned off, users willb e blocked, but won't receive email notification about that.
  const [send_message_with_unblocking, setSendMessageWithBlocking] =
    useState(true);

  //CHANGE MESSAGE TO SEND
  function changeUnblockingMessage(should_change) {
    setUnblockingMessage(should_change);
    if (should_change) {
      setUnblockingMessage("");
    } else {
      setUnblockingMessage(default_unblocking_message);
    }
  }

  async function unblockUsersAPICall(unblockReason, otherReason) {
    setApiWorking(true);

    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      //USER_IDS to block
      var user_ids = props.selectedRows.join(",");
      bodyParams.push(`user_ids=${user_ids}`); //bulk user_ids to take action
      bodyParams.push(`unblock_reason=${unblockReason}`); //this will be stored in admin_log
      bodyParams.push(`other_reason=${otherReason}`); //this will be stored in admin_log
      // #if this true, user will receive email notification on block,unblock and delete action taken by admin
      bodyParams.push(`send_email=${send_message_with_unblocking}`);
      bodyParams.push(`unblocking_message=${unblocking_message}`); //customised email to customer when account action happens. if this empty, it will sends the default message

      const bParameters = bodyParams.join("&");
      //
      const result = await unblockUsers(bParameters);
      //✅SUCCESS
      console.log("GET_USERS", result);
      setApiWorking(false);
      props.refresh(); //-> refresh on successfull unblocking
    } catch (error) {
      //🚫ERROR
      console.log(error);
      setApiWorking(false);
    }
  }

  const handleUnBlockUserButton = (unblockReason, otherReason) => {
    unblockRowsAlert(unblockReason, otherReason);
  };

  const unblockRowsAlert = (unblockReason, otherReason) => {
    const confirmation = window.confirm(
      `Are you sure you want to unblock these ${props.selectedRows.length} users? Once unblocked, users will be able to access their acount again.`
    );

    if (confirmation) {
      // Call your delete function here
      unblockUsersAPICall(unblockReason, otherReason);
    }
  };

  //VIEW
  return (
    <div className="unblock_users">
      <div className="title">Unblock Users({props.selectedRows.length})</div>
      <div className="selected_user_ids_to_block">
        {selectedUserIdsToBlock(props.selectedRows)}
      </div>
      <div className="text">
        <textarea
          value={unblocking_message}
          disabled={!unblocking_message_editing}
          onChange={(e) => setUnblockingMessage(e.target.value)}
          placeholder={def_placeholder}
        />
      </div>
      <div className="checkbox">
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={send_message_with_unblocking}
            onChange={() =>
              setSendMessageWithBlocking(!send_message_with_unblocking)
            }
          />
          <span
            style={{
              textDecoration: !send_message_with_unblocking
                ? "line-through"
                : "none",
            }}
          >
            Notify user via email
          </span>
        </label>

        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={unblocking_message_editing}
            onChange={() =>
              changeUnblockingMessage(!unblocking_message_editing)
            }
          />
          Change message
        </label>
      </div>
      {!send_message_with_unblocking && (
        <div className="warning_message">
          If this turned off, users won't receive email notification, when their
          account is unblocked.
        </div>
      )}
      <AdminUnblockForm
        title="Reason for Unblocking:"
        btn_title="Unblock"
        isApiWorking={isApiWorking}
        selectedRows={props.selectedRows}
        handleUnBlockUserButton={handleUnBlockUserButton}
      />
    </div>
  );

  //selectedUserIdsToBlock
  function selectedUserIdsToBlock(data) {
    var model = [];
    data.forEach((item, index) => {
      model.push(
        <div className="user_id" key={index}>
          {item}
        </div>
      );
    });
    return model;
  }
}

export default UnblockUsers;
