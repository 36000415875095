import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import isEmpty from "../../../../components/isEmpty";
import { fileToS3 } from "../../../../utils/apis/s3/UploadImage";

//CSS
import "./ReactQuill.css";

//UNDO_BUTTON
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

//REDO_BUTTON
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

//AUDIO
const BlockEmbed = Quill.import("blots/block/embed");
// Define the AudioBlot class
class AudioBlot extends BlockEmbed {
  // Create a new audio node with the specified attributes
  static create(value) {
    const node = super.create();
    node.setAttribute("controls", "");
    node.setAttribute("width", value.width);
    node.setAttribute("height", value.height);
    node.innerHTML = `<source src="${value.src}" type="audio/mpeg">`;
    return node;
  }

  // Get the attributes of the current audio node
  static value(node) {
    return {
      src: node.querySelector("source").getAttribute("src"),
      width: node.querySelector("source").getAttribute("width"),
      height: node.querySelector("source").getAttribute("height"),
    };
  }
}

// Set the properties of the AudioBlot class
AudioBlot.blotName = "audio";
AudioBlot.tagName = "audio";

// Register the AudioBlot class with Quill
Quill.register(AudioBlot);

// Import necessary modules
const IBlockEmbed = Quill.import("blots/block/embed");

// Define the IframeBlot class
class IframeBlot extends IBlockEmbed {
  // Create a new iframe node with the specified attributes
  static create(value) {
    const node = super.create();
    node.setAttribute("width", value.width);
    node.setAttribute("height", value.height);
    node.setAttribute("src", value.src);
    node.setAttribute("frameborder", "0");
    node.setAttribute("allow", "autoplay");
    node.setAttribute("autoplay", "");
    node.setAttribute("playsinline", "");
    return node;
  }

  // Get the attributes of the current iframe node
  static value(node) {
    return {
      width: node.getAttribute("width"),
      height: node.getAttribute("height"),
      src: node.getAttribute("src"),
    };
  }
}

// Set the properties of the IframeBlot class
IframeBlot.blotName = "iframe";
IframeBlot.tagName = "iframe";

// Register the IframeBlot class with Quill
Quill.register(IframeBlot);

const AudioURLButton = () => <i className="fa fa-music" />;

function insertAudioURL() {
  let url = prompt("Enter an Audio URL: ");
  //HERE check AUDIO
  const quill = this.quill;
  ///
  if (url != null) {
    const cursorPosition = this.quill.getSelection().index;
    const value = {
      width: "100%",
      height: "45px",
      src: url,
    };
    quill.insertEmbed(cursorPosition, "audio", value);
  }
}

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

const ImageURLButton = () => <i className="fa fa-picture-o" />;

function insertImgURL() {
  let url = prompt("Enter an Image URL: ");

  //HERE check image
  const quill = this.quill;
  const cursorPosition = this.quill.getSelection().index;
  if (url != null) {
    quill.insertEmbed(cursorPosition, "image", url);
    quill.formatText(cursorPosition, 1, { width: "100%", height: "350px" });
  }
}

const VideoURLButton = () => <i className="fa fa-youtube-square" />;

function insertVideoURL() {
  let url = prompt("Enter Video URL: ");
  url = getVideoUrl(url);
  const quill = this.quill;
  const cursorPosition = this.quill.getSelection().index;
  if (url != null) {
    console.log(url);
    quill.insertEmbed(cursorPosition, "video", url);
    quill.formatText(cursorPosition, 1, { width: "100%", height: "350px" });
  }
}

function getVideoUrl(url) {
  if (isEmpty(url)) {
    return;
  }

  // YouTube URL patterns
  let match =
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
    ) ||
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
    ) ||
    url.match(/^.*(youtu\.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/) ||
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/
    );

  if (match && match[2].length === 11) {
    return "https://www.youtube.com/embed/" + match[2] + "?showinfo=0";
  }

  // Vimeo URL pattern
  if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
    return (
      (match[1] || "https") + "://player.vimeo.com/video/" + match[2] + "/"
    );
  }

  return null;
}

const UploadButton = () => <i className="fa fa-cloud-upload" />;

async function insertUpload() {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  //   input.setAttribute("accept", "image/*, audio/*, video/*");
  //   input.setAttribute("accept", "image/*, audio/*, video/*");
  input.setAttribute("accept", "image/*, audio/*");

  input.click();
  input.onchange = async () => {
    var file = input && input.files ? input.files[0] : null;
    //FILE SIZE MAX 200mb
    const maxSize = 1024 * 1024 * 200;
    const minSize = 1024 * 2;
    if (file.size > maxSize || file.size < minSize) {
      alert("Uploaded image size is from 2KB to 200MB!");
      return;
    }
    //UID
    var randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
    var uniqid = randLetter + Date.now();
    //QUILL
    const quill = this.quill;

    //ALLOWED FILE FORMATS
    //.mp3,.png,.jpeg
    const blog_id = document.getElementsByClassName("text-editor")[0].id;

    //
    if (file) {
      //IMAGE
      if (file.type.startsWith("image/")) {
        const file_url = uniqid + ".png";
        let url = await uploadData(file, `blog/${blog_id}`, file_url);
        //✅IMAGE_DATA
        const range = quill.getSelection(true);
        quill.insertEmbed(range.index, "image", url);
        quill.formatText(range.index, range.index + 1, {
          height: "auto",
          width: "auto",
        });
        //AUDIO
      } else if (file.type.startsWith("audio/")) {
        const file_url = uniqid + ".mp3";
        let url = await uploadData(file, `blog/${blog_id}`, file_url);
        ///✅AUDIO_DATA
        if (url != null) {
          const cursorPosition = this.quill.getSelection().index;
          const value = {
            width: "100%",
            height: "45px",
            src: url,
          };
          quill.insertEmbed(cursorPosition, "iframe", value);
        }
      } else {
        // The file is not an audio or an image file
        alert("File is not an audio or an image file");
      }
    } else {
      // No file selected
      alert("No file selected");
    }
  };
}

//API: UPLOAD_DATA
const uploadData = async (file, path, filename) => {
  try {
    //2.RECEIVE_S3_URL
    var uploadURL = await fileToS3(file, path, filename);
    console.log("FILE_UPLOAD_SUCCESS", uploadURL);

    //✅SUCCESS
    return uploadURL;
  } catch (err) {
    //❌FAILED
    alert(err.message);
    console.log("UPLOAD_FAILED", err.message);
  }
};

const CodeSnippetButton = () => <i className="fa fa-file-code-o" />;

function codeSnippet() {
  const content = document.getElementById("text-editor-code-snippet");
  if (content.style.display === "none") {
    content.style.display = "block";
  } else {
    content.style.display = "none";
  }
}
/*
 * Custom toolbar component including insertStar button and dropdowns
 */
const CustomToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-size" defaultValue="medium">
        <option value="extra-small">Size 1</option>
        <option value="small">Size 2</option>
        <option value="medium">Size 3</option>
        <option value="large">Size 4</option>
      </select>
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-strike"></button>
      <button className="ql-underline"></button>
      <select className="ql-color" />
      <select className="ql-background" />
      <select className="ql-align" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>

    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      {/* <button className="ql-image" /> */}
      {/* <button className="ql-video" /> */}
      <button className="ql-insertImgURL">
        <ImageURLButton />
      </button>
      <button className="ql-insertVideoURL">
        <VideoURLButton />
      </button>
      <button className="ql-insertAudioURL">
        <AudioURLButton />
      </button>
      <button className="ql-insertUpload">
        <UploadButton />
      </button>
    </span>
    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
    <span className="ql-formats">
      <button className="ql-codeSnippet">
        <CodeSnippetButton />
      </button>
    </span>
  </div>
);

/*
 * Editor component with custom toolbar and content containers
 */
class Editor extends React.Component {
  //
  constructor(props) {
    super(props);
    this.blog = this.props.blog;
    this.state = { editorHtml: "" };
    this.handleChange = this.handleChange.bind(this);
    this.showCodeSnippet = false;
  }

  handleChange(html) {
    this.props.changeContent(html);
  }

  changeContent() {
    const content = document.getElementsByClassName("ql-editor")[0].innerHTML;
    this.props.changeContent(content);
  }

  render() {
    return (
      <div className="text-editor" id={this.props.blog.id}>
        <CustomToolbar />
        <pre
          style={{ whiteSpace: "pre-wrap", display: "none" }}
          id="text-editor-code-snippet"
        >
          {this.props.blog.content ? (
            <code className="language-html line-numbers" data-trim>
              {this.props.blog.content.replace(/<br>/g, "<br/>")}
            </code>
          ) : (
            <p>No content available</p>
          )}
        </pre>
        <ReactQuill
          value={
            !isEmpty(this.props.blog.content) ? this.props.blog.content : ""
          }
          onChange={this.handleChange}
          placeholder={"Write your article..."}
          modules={Editor.modules}
        />
      </div>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See http://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      insertVideoURL: insertVideoURL,
      insertImgURL: insertImgURL,
      insertAudioURL: insertAudioURL,
      insertUpload: insertUpload,
      undo: undoChange,
      redo: redoChange,
      codeSnippet: codeSnippet,
    },
  },
};

/*
 * Quill editor formats
 * See http://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

export default Editor;
