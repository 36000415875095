import React, { useEffect, useState } from "react";
import useLocalStorage from "../../../../components/useLocalStorage";
//JS
import Surveys from "./Surveys/Surveys";
import Events from "./Events/Events";
import MusicReleases from "./Music/MusicReleases";
import EDS from "./EDS/EDS";
//CSS
import "./Campaigns.css";
import Funding from "./Funding/Funding";

//Campaigns React Class
function Campaigns(props) {
  //
  const [page_title, setPageTitle] = useLocalStorage(
    "campaigns_page",
    "surveys"
  );

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Campaigns | SongDrop.band";
  }, []);

  function switchToView(view) {
    //
    switch (view) {
      case "surveys":
        setPageTitle("surveys");
        break;
      case "events":
        setPageTitle("events");
        break;
      case "music":
        setPageTitle("music");
        break;
      case "funding":
        setPageTitle("funding");
        break;
      case "eds":
        setPageTitle("eds");
        break;
    }
  }
  //VIEW
  return (
    <div className="campaigns">
      <div className="title">Campaigns</div>
      <div className="button_wrap">
        <button
          className={page_title === "surveys" ? "active" : ""}
          onClick={() => switchToView("surveys")}
        >
          <i className="fa fa-check-square-o" /> Surveys
        </button>
        <button
          className={page_title === "events" ? "active" : ""}
          onClick={() => switchToView("events")}
        >
          <i className="fa fa-ticket" /> Events
        </button>
        <button
          className={page_title === "music" ? "active" : ""}
          onClick={() => switchToView("music")}
        >
          <i className="fa fa-music" /> Releases
        </button>
        <button
          className={page_title === "funding" ? "active" : ""}
          onClick={() => switchToView("funding")}
        >
          <i className="fa fa-money" /> Funding
        </button>
        <button
          className={page_title === "eds" ? "active" : ""}
          onClick={() => switchToView("eds")}
        >
          <i className="fa fa-qrcode" /> EDs
        </button>
      </div>
      <div className="content">
        {page_title === "surveys" && <Surveys user={props.user} />}
        {page_title === "events" && <Events user={props.user} />}
        {page_title === "music" && <MusicReleases user={props.user} />}
        {page_title === "funding" && <Funding user={props.user} />}
        {page_title === "eds" && <EDS user={props.user} />}
      </div>
    </div>
  );
}

export default Campaigns;
