import React, { useState } from "react";
import html2canvas from "html2canvas";
import SaveDataURL from "../../../components/SaveDataURL";
import Zoom from "react-medium-image-zoom";

//QrDesign
import EDDesign from "./qr/EDDesign";
//css
import "./SaveQrDesign.css";
//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//QrDesign
function SaveQrDesign(props) {
  //api
  const [isDownloading, setIsDownloading] = useState(false);

  //DOWNLOAD: card
  const downloadCard = (event) => {
    event.preventDefault();
    const element = document.getElementById("qrcard");
    const title = `Would you like to download this image?`;
    if (window.confirm(title)) {
      //api loading
      setIsDownloading(true);
      //scale image for higher resolution
      element.style.transform = "scale(3,3)";
      html2canvas(element, {
        letterRendering: 1,
        allowTaint: false,
        useCORS: true,
      }).then((canvas) => {
        //scale back image
        element.style.transform = "scale(1,1)";
        //save card as png file
        let name = props.isBack ? "cardback" : "cardfront";
        let filename = `${name}_${props.album.title
          .toLowerCase()
          .replace(/\s/g, "")}.png`;
        //save file
        SaveDataURL(canvas.toDataURL(), filename);
        //api false
        setIsDownloading(false);
      });
    }
  };

  return (
    <div className="saveqrdesignbody">
      <form onSubmit={downloadCard}>
        <div className="cardcontainer">
          <div id="qrcard" className="savecard">
            <EDDesign
              fontSize={props.fontSize}
              trackFontSize={props.trackFontSize}
              album={props.album}
              isBack={props.isBack}
              edStyle={props.edStyle}
              corsEnabled={true}
            />
          </div>
          {isDownloading === true && (
            <div className="loading">
              <div className="apiwrapper">
                <div className="wrap">
                  <i className={apiFaIcon} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="dwnbtnrap">
          <button type="submit" className="dwnbtn">
            {isDownloading === true && <i className={apiFaIcon} />}
            {isDownloading === false && (
              <p>
                {"Download as PNG"} <i className="fa fa-picture-o" />
              </p>
            )}
            {/* {isDownloading === false && <p> Download Image</p>} */}
          </button>
          <p>
            {
              "Download the image to get printed. \nThe grey area is the safe area which will be cropped during print.\nThe image size is 1024x1024."
            }
          </p>
          <div className="hidesaveqr" onClick={hideView}>
            Hide
          </div>
        </div>
      </form>
    </div>
  );

  //Callback: hide view
  function hideView() {
    props.hideSaveQrDesign();
  }
}

export default SaveQrDesign;
