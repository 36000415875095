import React, { useState, useEffect } from "react";
import { deleteUserAccounts } from "../../../../utils/apis/api/auth_api_users";
import AdminUserBlockDeleteForm from "./AdminUserBlockDeleteForm";

//CSS
import "./DeleteUsers.css";

const default_delete_message =
  "Your account has been terminated by our team. Termination happened due to suspicious activity or violating our terms of usage. Read more in our faq: https://songdrop.uk/faq ";
const def_placeholder = "Send customised email, when deleting user account(s)";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//DeleteUsers class
function DeleteUsers(props) {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  //
  const [delete_message, setDeleteMessage] = useState(default_delete_message);
  const [delete_message_editing, setDeleteMessageEditing] = useState(false);
  //this is by default. use it all the time to tell users they have been deleted, if this turned off, users will be deleted, but won't receive email notification about that.
  const [send_message_with_deleting, setSendMessageWithDeleting] =
    useState(true);

  //

  //CHANGE MESSAGE TO SEND
  function changeBlockingMessage(should_change) {
    setDeleteMessageEditing(should_change);
    if (should_change) {
      setDeleteMessage("");
    } else {
      setDeleteMessage(default_delete_message);
    }
  }

  //API: DELETE USERS API CALL
  async function deleteUsersAPICall(deleteReason, otherReason) {
    setApiWorking(true);

    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      //USER_IDS to block
      var user_ids = props.selectedRows.join(",");
      bodyParams.push(`user_ids=${user_ids}`); //bulk user_ids to take action
      bodyParams.push(`delete_reason=${deleteReason}`); //this will be stored in admin_log
      bodyParams.push(`other_reason=${otherReason}`); //this will be stored in admin_log
      // #if this true, user will receive email notification on block,unblock and delete action taken by admin
      bodyParams.push(`send_email=${send_message_with_deleting}`);
      bodyParams.push(`delete_message=${delete_message}`); //customised email to customer when account action happens. if this empty, it will sends the default message

      const bParameters = bodyParams.join("&");
      //
      const result = await deleteUserAccounts(bParameters);
      //✅SUCCESS
      console.log("GET_USERS", result);
      setApiWorking(false);
      props.refresh(); //-> refresh on successfull deleting
    } catch (error) {
      //🚫ERROR
      console.log(error);
      setApiWorking(false);
    }
  }

  const handleDeleteUserButton = (deleteReason, otherReason) => {
    deletekRowsAlert(deleteReason, otherReason);
  };

  const deletekRowsAlert = (deleteReason, otherReason) => {
    const confirmation = window.confirm(
      `Are you sure you want to delete these ${props.selectedRows.length} users? Once an account deleted, users won't be able to recover their account anymore and their emails will be restricted.`
    );

    if (confirmation) {
      // Call your delete function here
      deleteUsersAPICall(deleteReason, otherReason);
    }
  };

  //VIEW
  return (
    <div className="delete_users" style={{ backgroundColor: "ff00004b" }}>
      <div className="title">
        <i className="fa fa-warning" /> Delete Users({props.selectedRows.length}
        )
      </div>
      <div className="selected_user_ids_to_block">
        {selectedUserIdsToBlock(props.selectedRows)}
      </div>
      <div className="text">
        <textarea
          value={delete_message}
          disabled={!delete_message_editing}
          onChange={(e) => setDeleteMessage(e.target.value)}
          placeholder={def_placeholder}
        />
      </div>
      <div className="checkbox">
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={send_message_with_deleting}
            onChange={() =>
              setSendMessageWithDeleting(!send_message_with_deleting)
            }
          />
          <span
            style={{
              textDecoration: !send_message_with_deleting
                ? "line-through"
                : "none",
            }}
          >
            Notify user via email
          </span>
        </label>
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={delete_message_editing}
            onChange={() => changeBlockingMessage(!delete_message_editing)}
          />
          Change message
        </label>
      </div>
      {!send_message_with_deleting && (
        <div className="warning_message">
          If this turned off, users won't receive email notification, when their
          account is deleted.
        </div>
      )}
      <AdminUserBlockDeleteForm
        title="Reason for Deleting:"
        btn_title="Delete"
        isApiWorking={isApiWorking}
        selectedRows={props.selectedRows}
        handleBlockUserButton={handleDeleteUserButton}
      />
    </div>
  );

  //selectedUserIdsToBlock
  function selectedUserIdsToBlock(data) {
    var model = [];
    data.forEach((item, index) => {
      model.push(
        <div className="user_id" key={index}>
          {item}
        </div>
      );
    });
    return model;
  }
}

export default DeleteUsers;
