import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import Footer from "../../../components/Footer/Footer";
 
//CSS
import "./Forum.css";
//JS
import NavBar from "../../../components/Navbar/NavBar";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://admin.songdrop.band

//Dashboard
function Forum(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

 

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Forum | Admin SongDrop";
    //api
    // getData();
  }, []);

 

  //VIEW
  return (
    <div className="">
        <NavBar title={props.title} handleLogout={props.handleLogout}/>
    </div>
  );

 
}

export default Forum;
