import React, { useEffect, useState } from "react";
import ReleaseFormListItem from "./ReleaseFormListItem";

//CSS
import "./ReleaseFormItemSelect.css";

const buttons = [
  {
    type: "omv",
    image:
      "https://cdn3.iconfinder.com/data/icons/audio-and-video-collection/17/070-Video-512.png",
    title: "Official Music Video",
    description: "Announce Music Video Clips",
  },
  {
    type: "album",
    image:
      "https://image.freepik.com/iconos-gratis/albumes-de-musica_318-42874.jpg",
    title: "Album Release",
    description: "Select a single answer from a list of options",
  },
  {
    type: "discount",
    image:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fstatic.vecteezy.com%2Fsystem%2Fresources%2Fpreviews%2F000%2F583%2F596%2Foriginal%2Fdiscount-icon-vector.jpg&f=1&nofb=1&ipt=0b201a3faa074ae78ee253efce2299496fa5c7334de0022bda67b1920b3f91b2&ipo=images",
    title: "Discounts",
    description: "Announce discounted prices for songs/albums",
  },
  {
    type: "survey",
    image:
      "https://static.vecteezy.com/system/resources/previews/000/344/949/original/vector-feedback-glyph-icons.jpg",
    title: "Feedback",
    description: "Ask your audience feedback on release",
  },
];

//ReleaseFormItemSelect React Class
function ReleaseFormItemSelect(props) {
  //ViewDidLoad
  useEffect(() => {
    //title
  }, []);

  function selectButtonType(type) {
    // props.createNewRelease(type);
  }

  //VIEW
  return (
    <div className="releaseitemselect">
      {buttons.map((button, index) => (
        <ReleaseFormListItem
          index={index}
          button={button}
          selectButtonType={selectButtonType}
        />
      ))}
    </div>
  );
}

export default ReleaseFormItemSelect;
