import React, { Component } from "react";
import moment from "moment-timezone";

class StreamStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: moment.tz.guess(),
      startTime: this.parseDate(props.startTime),
      currentTime: moment().tz(moment.tz.guess()),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        currentTime: moment().tz(this.state.timezone),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  // NSString *string        = @"Wed Jul 29 14:46:11 +0000 2015";
  // NSDateFormatter *format = [[NSDateFormatter alloc] init];
  // format.dateFormat       = @"EEEE, dd MMM YYYY HH:mm:ss Z";
  //Wed, 01 Mar 2023 14:26:50 +0000
  parseDate(dateString) {
    const formats = [
      "EEEE, dd MMM YYYY HH:mm:ss ZZZ",
      "EEE,[ ]d MMM uuuu H[ ]:[ ]mm[ ]:[ ]ss xx[ ]'('z')'",
      "EEE,d MMM uuuu H: m: s Z '('z')'",
      "uuuu-MM-dd'T'HH:mm:ssxx",
      "yyyy-MM-dd'T'HH:mm:ss'Z'",
      "yyyy-MM-dd HH:mm:ss ZZZ",
      "yyyy-MM-dd'T'HH:mm:ss'Z'",
      "yyyy-MM-dd'T'hh:mm:ssZ",
      "yyyy-MM-dd'T'hh:mm:ssZ",
      "yyyy-MM-dd'T'HH:mm:ssZ",
      "DD/MMM/YYYY:HH:mm:ss ZZZ", // 01/Jan/2023:12:34:56 +0000
      "DD-MMM-YYYY:HH:mm:ss ZZZ", // 01-Jan-2023:12:34:56 +0000
      "DD/MM/YYYY:HH:mm:ss ZZZ", // 01/01/2023:12:34:56 +0000
      "DD-MM-YYYY:HH:mm:ss ZZZ", // 01-01-2023:12:34:56 +0000
      "DD/MM/YYYY HH:mm:ss ZZZ", // 01/01/2023 12:34:56 +0000
      "DD-MM-YYYY HH:mm:ss ZZZ", // 01-01-2023 12:34:56 +0000
      "YYYY/MM/DD HH:mm:ss ZZZ", // 2023/01/01 12:34:56 +0000
      "YYYY-MM-DD HH:mm:ss ZZZ", // 2023-01-01 12:34:56 +0000
      "DD MMM YYYY HH:mm:ss ZZZ", // 01 Jan 2023 12:34:56 +0000
      "DD MMMM YYYY HH:mm:ss ZZZ", // 01 January 2023 12:34:56 +0000
      "YYYY-MM-DDTHH:mm:ss.SSSZ", // 2023-01-01T12:34:56.789Z
      "YYYY/MM/DDTHH:mm:ss.SSSZ", // 2023/01/01T12:34:56.789Z
      "YYYY-MM-DDTHH:mm:ss.SSS", // 2023-01-01T12:34:56.789
      "YYYY/MM/DDTHH:mm:ss.SSS", // 2023/01/01T12:34:56.789
      "DD MMM YYYY HH:mm:ss ZZZ", // 01 Jan 2023 12:34:56 +0000
      "DD MMM YYYY HH:mm:ss", // 01 Jan 2023 12:34:56
      "MMM DD YYYY HH:mm:ss ZZZ", // Jan 01 2023 12:34:56 +0000
      "MMM DD YYYY HH:mm:ss", // Jan 01 2023 12:34:56
      "YYYY-MM-DD HH:mm:ss ZZZ", // 2023-01-01 12:34:56 +0000
      "YYYY-MM-DD HH:mm:ss", // 2023-01-01 12:34:56
      "YYYY/MM/DD HH:mm:ss ZZZ", // 2023/01/01 12:34:56 +0000
      "YYYY/MM/DD HH:mm:ss", // 2023/01/01 12:34:56
      "DD-MM-YYYY HH:mm:ss ZZZ", // 01-01-2023 12:34:56 +0000
      "DD-MM-YYYY HH:mm:ss", // 01-01-2023 12:34:56
      "DD/MM/YYYY HH:mm:ss ZZZ", // 01/01/2023 12:34:56 +0000
      "DD/MM/YYYY HH:mm:ss", // 01/01/2023 12:34:56
      "MM-DD-YYYY HH:mm:ss ZZZ",
      "MM-DD-YYYY HH:mm:ss",
      "MM/DD/YYYY HH:mm:ss ZZZ",
      "MM/DD/YYYY HH:mm:ss",
      "DD/MMM/YYYY:HH:mm:ss ZZ",
      "DD-MMM-YYYY:HH:mm:ss ZZ",
      "DD/MM/YYYY:HH:mm:ss ZZ",
      "DD-MM-YYYY:HH:mm:ss ZZ",
      "DD/MMM/YYYY:HH:mm:ss ZZZ",
    ];
    const parsedDate = moment(dateString, formats, true);
    console.log("date formatter", parsedDate.format(), dateString);
    return parsedDate.isValid() ? parsedDate : null;
  }

  render() {
    const { currentTime, timezone, startTime } = this.state;
    if (!startTime) {
      // return <div>Invalid date format</div>;
      return <div></div>;
    }
    const diff = moment.duration(currentTime.diff(startTime));
    const hours = diff.hours().toString().padStart(2, "0");
    const minutes = diff.minutes().toString().padStart(2, "0");
    const seconds = diff.seconds().toString().padStart(2, "0");
    return (
      <div>
        Listening since:{" "}
        <strong>
          {hours}:{minutes}:{seconds}
        </strong>
      </div>
    );
  }
}

export default StreamStarted;
