import React from "react";

import {
  ResponsiveContainer,
  ComposedChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Area,
  Bar,
} from "recharts";

//ServerCostChart class
function RadioCharts(props) {
  //Custom ToolTip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bill-metrix-tooltip">
          <div className="title">{`${label}:`}</div>
          <div className="label">{`Listeners: ${payload[0].value}`}</div>
          <div className="label">{`Peak:${props.peak_listeners}`}</div>
        </div>
      );
    }
    return null;
  };

  //VIEW
  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <ComposedChart
          className="stat-wrapper"
          style={{ backgroundColor: props.backgroundColor }}
          width={props.width} //props.width -> relative windows width
          height={200}
          data={props.data}
          margin={{
            top: 16,
            right: 32,
            left: -12,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: "#f3f3f399" }} />
          <ReferenceLine
            y={props.peak_listeners}
            label="Peak Listeners"
            stroke="orange"
          />
          {/* <Area
            type="monotone"
            dataKey="listeners"
            fill={props.tintColor}
            stroke={props.tintColor}
          /> */}
          <Line type="monotone" dataKey="listeners" stroke={props.tintColor} />{" "}
          <Line type="monotone" dataKey="peak_listeners" stroke={"#ffa50080"} />
        </ComposedChart>
      </ResponsiveContainer>{" "}
    </div>
  );
}

export default RadioCharts;
