import { getIDToken, getUserID } from "../auth";
import axios from "axios";

export const getNewsletterSignUps = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_newsletter_signups(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_newsletter_signups = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/newsletters/get_newsletter_signups" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
