import React, { useEffect } from "react";
//CSS
import "./Blog.css";
//JS
import NavBar from "../../../components/Navbar/NavBar";
import BlogHome from "../Blog/BlogHome/BlogHome";
//LINK
//✅logged in
//https://admin.songdrop.band/blog
//Blog
function Blog(props) {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Blog | Admin SongDrop";
  }, []);

  //VIEW
  return (
    <div>
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <BlogHome
        title={props.title}
        handleLogout={props.handleLogout}
        user={props.user}
      />
    </div>
  );
}

export default Blog;
