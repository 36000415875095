import React, { useState, useRef, useEffect } from "react";
//CSS
import "./HTMLToString.css";

//HTMLToString
function HTMLToString(props) {
  const [htmlContent, setHtmlContent] = useState("");
  const [isFileAdded, setIsFileAdded] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (htmlContent) {
      handleSave();
    }
  }, [htmlContent]);

  const handleDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];

    if (file && file.type === "text/html") {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target.result;
        setHtmlContent(content);
        setIsFileAdded(true);
      };

      reader.readAsText(file);
    } else {
      console.error("Please drop a valid HTML file.");
    }
  };

  const handleFileOpen = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type === "text/html") {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target.result;
        setHtmlContent(content);
        setIsFileAdded(true);
      };

      reader.readAsText(file);
    } else {
      console.error("Please choose a valid HTML file.");
    }
  };

  const handleShowHTML = () => {
    // You can customize how you want to show the HTML content, e.g., open a modal
    alert(htmlContent);
  };

  const handleExport = () => {
    // Use the htmlContent as needed, for example, you can log it to the console
    console.log("Exported HTML Content:", htmlContent);
    props.exportHTML(htmlContent);
    // Alternatively, you can use it in an API call or any other operation
  };

  const handleSave = () => {
    // Use the htmlContent as needed, for example, you can log it to the console
    console.log("Save HTML Content:", htmlContent);
    props.saveHTML(htmlContent);
    // Alternatively, you can use it in an API call or any other operation
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsFileAdded(false);
  };

  const handleOpenInNewTab = () => {
    const newTab = window.open();
    newTab.document.write(htmlContent);
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="htmldropbox"
      style={{ height: isFileAdded ? "500px" : "200px" }}
    >
      <input
        type="file"
        accept=".html"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileInputChange}
      />
      <p className="dragdrop">
        {isFileAdded ? (
          <i className="fa fa-check-circle" />
        ) : (
          <i className="fa fa-file-code-o" />
        )}{" "}
        {isFileAdded ? "File Added!" : "Drop or Open HTML file"}
      </p>

      <button onClick={handleFileOpen}>Open File</button>
      {isFileAdded && (
        <div>
          <button onClick={handleShowHTML}>Show HTML</button>
          <button onClick={handleExport}>Export as Text</button>
          <button onClick={handleSave}>Save Template</button>
          <button onClick={handleOpenInNewTab}>Open in New Tab</button>
        </div>
      )}

      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
}

export default HTMLToString;
