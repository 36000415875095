import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import isEmpty from "../../../../../components/isEmpty";

import {
  Select,
  CaretIcon,
  MultiSelectOptionMarkup,
  ModalCloseButton,
} from "react-responsive-select";
//JS
import EDSCharts from "./EDSCharts";

//API
import { getEDStats } from "../../../../../utils/apis/api/auth_api_eds";

//CSS
import "./EDS.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//EDS React Class
function EDS(props) {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  const [search, setSearch] = useState("");
  const [edStats, setEDStats] = useState([]);

  const [filterGenres, setFilterGenres] = useState([]);
  const [filterArtists, setFilterArtists] = useState([]);
  const [filterCities, setFilterCities] = useState([]);

  const [selectedGenres, setSelecteGenres] = useState([]);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "EDS | SongDrop.band";

    //getstatsforEDS
    getstatsforEDS();
  }, []);

  //API
  const getstatsforEDS = async () => {
    setApiWorking(true);
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`period=${""}`);
      bodyParams.push(`ed_id=${""}`);
      bodyParams.push(`search=${search}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getEDStats(bParameters);
      setEDStats(result["ed-stat-metrix"]);
      console.log("GET_ED_STATS", result);
      setApiWorking(false);

      //🚫Error
    } catch (error) {
      setApiWorking(false);
      console.log(error.message);
    }
  };

  const handleRangeSelect = (ranges) => {
    console.log(ranges);
  };

  function audienceFilterGroups(defaultValue, filter_group_data) {
    //
    var tableData = [
      {
        value: defaultValue,
        text: defaultValue,
        markup: <MultiSelectOptionMarkup text={defaultValue} />,
      },
    ];
    //
    if (isEmpty(filter_group_data)) {
      return tableData;
    }
    //
    filter_group_data.forEach((_data, index) => {
      const tableRow = {
        value: `${_data}`,
        text: `${_data}`,
        markup: <MultiSelectOptionMarkup text={_data} />,
      };
      tableData.push(tableRow);
    });
    //
    // console.log("tabledata", tableData);
    return tableData;
  }

  //filter events
  function handleChangeModel(currOptions) {
    let currOptionsValues = currOptions.options.map(function (item) {
      return item.value;
    });
    // props.filter_radio_groups(currOptionsValues);
  }

  //VIEW
  return (
    <div className="eds">
      {/* CREATE NEW EVENTS IS HERE */}
      <div className="startview">
        <div className="title">Track your EDs</div>
        <div className="subtitle">
          EDs are a great way to physically distribute your music. Here you can
          track how many people scanned your EDs.
        </div>
        <div className="ed-stats">
          {isApiWorking && (
            <button className="api-emails" disabled={true}>
              <i className={apiFaIcon}></i>
            </button>
          )}
          <div className="button-wrap">
            {/* <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleRangeSelect}
            /> */}
          </div>
        </div>
      </div>
      <div className="eds-filter">
        <div className="genre-select">
          <form>
            <Select
              className="select_multi"
              multiselect={true}
              name="select_station"
              selectedValues={selectedGenres}
              modalCloseButton={<ModalCloseButton />}
              options={audienceFilterGroups("Genres", filterGenres)}
              caretIcon={<CaretIcon />}
              onChange={(e) => handleChangeModel(e)}
              onSubmit={() => console.log("onSubmit")}
            />
          </form>
        </div>
        <div className="artist-select">
          <form>
            <Select
              className="select_multi"
              multiselect={true}
              name="select_station"
              selectedValues={[]}
              modalCloseButton={<ModalCloseButton />}
              options={audienceFilterGroups("Artists", filterArtists)}
              caretIcon={<CaretIcon />}
              // onChange={(e) => handleChangeModel(e)}
              onSubmit={() => console.log("onSubmit")}
            />
          </form>
        </div>
        <div className="country-select">
          <form>
            <Select
              className="select_multi"
              multiselect={true}
              name="select_station"
              selectedValues={[]}
              modalCloseButton={<ModalCloseButton />}
              options={audienceFilterGroups("Albums", filterCities)}
              caretIcon={<CaretIcon />}
              // onChange={(e) => handleChangeModel(e)}
              onSubmit={() => console.log("onSubmit")}
            />
          </form>
        </div>
        <div className="country-select">
          <form>
            <Select
              className="select_multi"
              multiselect={true}
              name="select_station"
              selectedValues={[]}
              modalCloseButton={<ModalCloseButton />}
              options={audienceFilterGroups("Cities", filterCities)}
              caretIcon={<CaretIcon />}
              // onChange={(e) => handleChangeModel(e)}
              onSubmit={() => console.log("onSubmit")}
            />
          </form>
        </div>
      </div>
      {/* ED CHARTS HERE */}
      <EDSCharts data={edStats} />
      {/* ED MORE OPTIONS */}
      <div>
        <button>More charts</button>
        <button>Export data</button>
      </div>
    </div>
  );
}

export default EDS;
