import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";

//CSS
import "./IAP_SearchResult.css";

//IAP_SearchResult
function IAP_SearchResult(props) {
  //
  //ViewDidLoad
  useEffect(() => {
    //title
  }, []);

  function updateInputValue(key, value) {}

  //VIEW
  return (
    <div className="IAP_SearchResult">
      <div className="iap-result">
        <div className="sd_table">
          <div className="sd-db-table">
            <table>
              <thead>
                {createTableHeader(props.tableColumnItems, props.active_tab)}
              </thead>
              <tbody>
                {createTableItems(props.tableColumnItems, props.table_items)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  function createTableHeader(header_names, active_tab) {
    return (
      <tr key={1}>
        <td>Column</td>
        <td>{active_tab}</td>
        <td>SONGDROP_DB</td>
        <td>MATCHING?</td>
      </tr>
    );
  }

  function createTableItems(tableColumnItems, table_items) {
    var tableData = [];
    if (isEmpty(table_items)) {
      const itemRow = tableColumnItems.map((header, index) => {
        return (
          <tr key={index}>
            <td>{header}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
      });
      tableData.push(itemRow);
      return tableData;
    }

    table_items.forEach((item, index) => {
      const keys = Object.keys(item); // get all keys of the item object
      const value1 = item[keys[0]][props.active_tab]; // get the value of props.active_tab for the first key, or return an empty string if it's null or undefined
      const value2 = item[keys[0]]["SONGDROP_DB"]; // get the value of "SONGDROP_DB" for the first key, or return an empty string if it's null or undefined
      //   console.log("item", item);
      //   console.log("value1", value1);
      //   console.log("value2", value2);

      const itemRow = (
        <tr key={`${index}`}>
          <td>{keys[0]}</td>
          <td>{value1}</td>
          <td>{value2}</td>
          <td>{calculateMatching(keys[0], value1, value2)}</td>
        </tr>
      );
      tableData.push(itemRow);
    });

    return tableData;
  }

  function calculateMatching(key, item, match_with_item) {
    if (item === match_with_item) {
      return <i className="fa fa-check-circle" />;
    } else {
      return <i className="fa fa-times-circle" />;

      // return (
      //   <input onChange={(e) => updateInputValue(key, e.target.value)}></input>
      // );
    }
  }
}

export default IAP_SearchResult;
