import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import isEmpty from "../../../../../../components/isEmpty";
//JS
import SurveyResponseTable from "./SurveyResponseTable";
import SurveyResponseAnalitics from "./SurveyResponseAnalitics";
import SurveyQuestionsWithAnswersNumber from "./SurveyQuestionsWithAnswersNumber";
//CSS
import "./SurveyOverview.css";

//SurveyOverview React Class
function SurveyOverview(props) {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  const [survey, setSurvey] = useState(null);

  const [responseToggleOn, setResponseToggleOn] = useState(false);
  const [notificationsOn, setNotificationOn] = useState(false);
  const [dailyNotificationsOn, setDailyNotificationsOn] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Survey Overview";

    //here download data for the survey
    //->by id
    downloadSurveyData(props.survey_id);
  }, []);

  function downloadSurveyData(survey_id) {
    //>setSurveyData
  }

  //dimsissing the view
  function dismissOverview() {}

  function editSurvey() {
    let survey_id = survey.survey_id;
  }

  function previewSurvey() {
    let survey_id = survey.survey_id;
  }

  function deleteSurvey() {
    let survey_id = survey.survey_id;
  }

  function shareSurvey(share_type) {
    let survey_id = survey.survey_id;
  }

  function surveyToggle(toggle_name, toggleValue) {
    //
  }

  //VIEW
  return (
    <div className="surveyoverview">
      <div className="surveyoverviewheader">
        <button onClick={() => dismissOverview()}>
          <i className="fa fa-chevron-left" />
          Survey Overview
        </button>
      </div>
      {isApiWorking && <div>Api working here</div>}
      {!isApiWorking && isEmpty(survey) && <div>survey not found</div>}
      {!isApiWorking && !isEmpty(survey) && (
        <div className="survey_found">
          <div className="survey_header">
            <div className="survey_name">
              {"Almost done!Now share your survey"}
              {"to start collecting results!"}
            </div>
            <div className="survey_edit">
              <button className="survey_edit_btn" onClick={() => editSurvey()}>
                Edit Survey
              </button>
              <button
                className="survey_preview_btn"
                onClick={() => previewSurvey()}
              >
                View Preview
              </button>
              <button
                className="survey_delete_btn"
                onClick={() => deleteSurvey()}
              >
                Delete Survey
              </button>
            </div>
          </div>
          <div className="survey_share">
            <button
              className="survey_share_btn"
              onClick={() => shareSurvey("link")}
            >
              Share as a link
            </button>
            <button
              className="survey_share_btn"
              onClick={() => () => shareSurvey("facebook")}
            >
              Share on Facebook
            </button>
            <button
              className="survey_share_btn"
              onClick={() => shareSurvey("linkedin")}
            >
              Share on LinkedIn
            </button>
            <button
              className="survey_share_btn"
              onClick={() => shareSurvey("twitter")}
            >
              Share on Twitter
            </button>
            <button
              className="survey_share_btn"
              onClick={() => shareSurvey("instagram")}
            >
              Share on instagram
            </button>
          </div>
          <div className="survey_settings">
            <div className="survey_settings_toggle_box">
              <div className="title">Turn On/Off</div>
              <div className="switch">
                <Toggle
                  defaultChecked={responseToggleOn}
                  onChange={() => surveyToggle("response", !responseToggleOn)}
                />
              </div>
              <div className="subtitle">
                Toggles the survey from collecting feedback to no longer
                collecting feedback
              </div>
            </div>
            <div className="survey_settings_toggle_box">
              <div className="title">Immediate Notifications</div>
              <div className="switch">
                <Toggle
                  defaultChecked={notificationsOn}
                  onChange={() =>
                    surveyToggle("notifications", !notificationsOn)
                  }
                />
              </div>
              <div className="subtitle">
                Receive an email notifications for every new response.
              </div>
            </div>
            <div className="survey_settings_toggle_box">
              <div className="title">Immediate Notifications</div>
              <div className="switch">
                <Toggle
                  defaultChecked={dailyNotificationsOn}
                  onChange={() =>
                    surveyToggle("daily_notifications", !dailyNotificationsOn)
                  }
                />
              </div>
              <div className="subtitle">
                Receive a daily report of how many new surveys were collected.
              </div>
            </div>
          </div>
          <div className="survey_results">
            <div className="survey_results_table">
              <SurveyResponseTable survey={survey} />
            </div>
            <div className="survey_analytics">
              <SurveyResponseAnalitics survey={survey} />
            </div>
          </div>
          <div className="survey_questions_with_responses">
            <SurveyQuestionsWithAnswersNumber survey={survey} />
          </div>
        </div>
      )}
    </div>
  );
}

export default SurveyOverview;
