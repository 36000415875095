import React, { useEffect, useState } from "react";
import { getSurveys } from "../../../../../utils/apis/api/auth_api_emails";
import isEmpty from "../../../../../components/isEmpty";
//JS
import FundingFormItemSelect from "./FundingForms/FundingFormItemSelect/FundingFormItemSelect";
import FundingSavedTable from "./FundingSavedTable/FundingSavedTable";
//CSS
import "./Funding.css";

const releases_json = [
  {
    release_id: "123s1324",
    title: "Time Crime CD funding",
    status: 1,
    opened: "0/1000",
    created_at: "2023-12-06 10:02",
    updated_at: "2023-12-06 11:12",
  },
  {
    release_id: "123s324",
    title: "Time Crime ED Funding",
    status: 2,
    opened: "200/500",
    created_at: "2023-12-05 18:32",
    updated_at: "2023-12-05 18:32",
  },
  {
    release_id: "123s34",
    title: "Untitled Funding",
    status: 0,
    opened: "0",
    created_at: "2023-12-03 11:11",
    updated_at: "2023-12-03 12:12",
  },
];

//Funding React Class
function Funding(props) {
  //
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [releases, setReleases] = useState([]);
  //
  const [selectBtnsVisible, setSelectBtnsVisible] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Funding | SongDrop.band";
    //API
    getUserFundings();
  }, []);

  //AUDIENCE API
  const getUserFundings = async () => {
    setApiWorking(true);
    //
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getSurveys(bParameters);
      console.log(result);
      setReleases(releases_json);
      // console.log("RESULT", result);
      setApiWorking(false);
      //🚫Error
    } catch (error) {
      setApiWorking(false);
      //   alert(error.message);
    }
  };

  //VIEW
  return (
    <div className="releases">
      {/* CREATE NEW RELEASES IS HERE */}
      <div className="startview">
        <div className="title">Create Funding Campaigns</div>
        <div className="subtitle">
          Ask your fans to support you buying a new equipment or ordering your
          new batch of EDs, CDs or Merch
        </div>
        <div className="question-wrapper">
          <button
            className="addquestionbtn"
            onClick={() => setSelectBtnsVisible(!selectBtnsVisible)}
          >
            Add a funding{" "}
            <i className={`fa fa-caret-${selectBtnsVisible ? "up" : "down"}`} />
          </button>
          {selectBtnsVisible && <FundingFormItemSelect />}
        </div>
      </div>
      {/* ALL SAVED RELEASES IS HERE */}
      <div className="eventstable">
        <FundingSavedTable releases={releases} isApiWorking={isApiWorking} />
      </div>
    </div>
  );
}

export default Funding;
