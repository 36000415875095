import React, { useState, useEffect } from "react";

import DMPFormUpload from "./DMPFormUpload";
import { uploadFileToS3 } from "../../../utils/apis/s3/UploadFile";
import { createDMPAPI } from "../../../utils/apis/api/auth_api_dmp";
//CSS
import "./DMPForm.css";
import { isEmpty } from "lodash";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

const BUCKETNAME = "decission_making_process";
//DMPForm
function DMPForm(props) {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0.0); //0.0-1.0
  //
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [admin, setAdmin] = useState(props.user.name);
  const [version, setVersion] = useState("");
  //
  const [documentFile, setDocumentFile] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    console.log(props.user.name);
    setAdmin(props.user.name);
  }, []);

  const handleUpload = (file, fileName) => {
    setDocumentFile(file);
    setDocumentName(fileName);
    setUploadSuccess(false);
    const title = removeFileExtension(fileName);
    setTitle(title);
  };

  function handleSubmitNewDoc(e) {
    e.preventDefault();
    if (isEmpty(title)) {
      alert("Add a title to your document");
      return;
    }
    if (isEmpty(description)) {
      alert("Add a description to your document");
      return;
    }
    if (!documentFile) {
      alert("Upload a file to your document");
      return;
    }
    if (isEmpty(admin)) {
      alert("Add an admin to your document");
      return;
    }
    if (isEmpty(version)) {
      alert("Add a version to your document");
      return;
    }

    //START UPLOADING DOCUMENT
    const sanitizedDocumentName = documentName.replace(/ /g, "_");
    const documentPath = `v${version}/` + sanitizedDocumentName;
    uploadDocument(documentFile, BUCKETNAME, documentPath);
  }

  const uploadDocument = async (file, path, filename) => {
    setApiWorking(true);
    //1.UPLOAD_TO_S3
    console.log(file, path, filename);
    //S3_FILE_UPLOAD
    uploadFileToS3(file, path, filename)
      .progress((progress) => {
        // console.log("progress", progress);
        setUploadProgress(progress);
      })
      .then((uploadURL) => {
        const _documentUrl = uploadURL;
        setDocumentUrl(_documentUrl);
        //UPLOAD FILE TO DB
        fileToDBApi(uploadURL);
      })
      //❌FAILED
      .catch((err) => {
        setApiWorking(false);
        setUploadSuccess(false);
        // console.log("err", err);
        alert(err.message);
      });
  };

  function fileToDBApi(uploadURL) {
    var bodyParams = [];
    bodyParams.push(`title=${title}`);
    bodyParams.push(`description=${description}`);
    bodyParams.push(`document_url=${uploadURL}`);
    bodyParams.push(`admin=${admin}`);
    bodyParams.push(`version=${version}`);
    bodyParams.push(`token=${props.user.edit_token}`);
    const bParameters = bodyParams.join("&");
    //
    //✅SUCCESS
    createDMPAPI(bParameters)
      .then((response) => {
        // console.log(response);
        setApiWorking(false);
        setUploadProgress(0);
        setUploadSuccess(true);
        // console.log(response);
      })
      .catch((err) => {
        //❌FAILED
        // console.log("err", err);
        alert(err.message);
        setUploadProgress(false);
        setUploadSuccess(false);
      });
  }

  function uploadNewDocument(e) {
    e.preventDefault();
    setApiWorking(false);
    setUploadProgress(0.0);
    setTitle("");
    setDescription("");
    setAdmin("");
    setVersion("");
    setDocumentFile(null);
    setDocumentName("");
    setDocumentUrl("");
    setUploadSuccess(false);
  }

  //VIEW
  return (
    <div className="dmp-form">
      {!documentFile && (
        <DMPFormUpload
          title="Drag PDF file or upload one"
          isApiWorking={isApiWorking}
          apiProgress={uploadProgress}
          //CALLBACK
          handleUpload={handleUpload}
        />
      )}
      {documentFile && (
        <div className="documentname">
          <label className="doclabel">
            <span
              className="custom-checkbox"
              onClick={() => setDocumentFile(null)}
            >
              <i className="fa fa-check-square"></i>
            </span>
            {documentName}
          </label>
          {uploadProgress > 0 && (
            <div className="uprogress-bar">
              <div
                className="uprogress"
                style={{ width: `${uploadProgress * 100}%` }}
              />
            </div>
          )}
        </div>
      )}
      <form className="your-form" onSubmit={handleSubmitNewDoc}>
        <div className="form-input">
          <label>Title:</label>
          <input
            placeholder="Document title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-input">
          <label>Description:</label>
          <textarea
            placeholder="What is the document about?"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="form-input">
          <label>Admin:</label>
          <input
            disabled={true}
            placeholder="username"
            type="text"
            value={admin}
            onChange={(e) => setAdmin(e.target.value)}
          />
        </div>
        <div className="form-input">
          <label>Version:</label>
          <input
            placeholder="1"
            type="text"
            value={version}
            onChange={(e) => {
              // Use regex to only allow integers
              if (/^\d*$/.test(e.target.value)) {
                setVersion(e.target.value);
              }
            }}
          />
        </div>

        <div className="form-input">
          {!isApiWorking && uploadSuccess && (
            <button
              onClick={(e) => uploadNewDocument(e)}
              className="btn-resubmit"
              type="submit"
            >
              <i className="fa fa-check-square"></i> Upload a new document
            </button>
          )}
          {!isApiWorking && !uploadSuccess && (
            <button
              className="btn-submit"
              type="submit"
              disabled={btnDisabled()}
            >
              Submit
            </button>
          )}
          {isApiWorking && (
            <button disabled={isApiWorking}>
              <i className={apiFaIcon} />
            </button>
          )}
        </div>
      </form>
    </div>
  );

  function btnDisabled() {
    return (
      isEmpty(title) ||
      isEmpty(description) ||
      isEmpty(documentName) ||
      isEmpty(version) ||
      isEmpty(admin)
    );
  }

  function removeFileExtension(fileName) {
    // Find the last occurrence of a period (.) in the fileName
    const lastDotIndex = fileName.lastIndexOf(".");

    // If there's a period in the fileName, return the part before the last dot; otherwise, return the fileName as is
    return lastDotIndex !== -1 ? fileName.slice(0, lastDotIndex) : fileName;
  }
}

export default DMPForm;
