import React, { useEffect, useState } from "react";
import SurveyOverview from "./SurveyOverview/SurveyOverview";
import SurveyFormCreator from "./SurveyForm/SurveyFormCreator";
import SurveyItemSelect from "./SurveyForm/SurveyFormItemSelect/SurveyItemSelect";
import { getSurveys } from "../../../../../utils/apis/api/auth_api_emails";

import SurveySavedTable from "./SurveySavedTable/SurveySavedTables";
import isEmpty from "../../../../../components/isEmpty";

//CSS
import "./Surveys.css";

const surveys_json = [
  {
    survey_id: "12113s34",
    title: "SongDrop pitch-deck",
    status: 1,
    responses: "100",
    created_at: "2023-12-06 11:11",
    updated_at: "2023-12-06 12:12",
  },
  {
    survey_id: "123s34",
    title: "Untitled Survey",
    status: 0,
    responses: "1",
    created_at: "2023-12-05 10:02",
    updated_at: "2023-12-05 11:12",
  },
];

//Surveys React Class
function Surveys(props) {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  const [surveys, setSurveys] = useState([]);
  //
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectBtnsVisible, setSelectBtnsVisible] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Surveys | SongDrop.band";
    //API
    getUserSurveys();
  }, []);

  //AUDIENCE API
  const getUserSurveys = async () => {
    setApiWorking(true);
    //
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getSurveys(bParameters);
      console.log(result);
      setSurveys(surveys_json);
      // console.log("RESULT", result);
      setApiWorking(false);
      //🚫Error
    } catch (error) {
      setApiWorking(false);
      //   alert(error.message);
    }
  };

  function createNewSurveyWithType(formType) {
    setSelectBtnsVisible(false);
    setSelectedForm(formType);

    //Here I need an API to create the new survey in MYSQL DB and return an ID
    //-> It will also fetch the surveys
  }

  //VIEW
  return (
    <div className="surveys">
      {/* {!isEmpty(selectedForm) && <SurveyFormCreator />} */}
      {!isEmpty(selectedForm) && <SurveyOverview />}
      {/* CREATE NEW SURVEYS IS HERE */}
      <div className="startview">
        <div className="title">What would you like to learn?</div>
        <div className="subtitle">
          You can learn valuable insights from your audience by asking simple
          questions about their interests, experience, or wheather or not they'd
          recommend you to a friend.
        </div>
        <div className="question-wrapper">
          <button
            className="addquestionbtn"
            onClick={() => setSelectBtnsVisible(!selectBtnsVisible)}
          >
            Add a question{" "}
            <i className={`fa fa-caret-${selectBtnsVisible ? "up" : "down"}`} />
          </button>
          {/* CREATE A NEW SURVEY BY SELECTING A SURVEY ITEM TYPE */}
          {selectBtnsVisible && (
            <SurveyItemSelect
              createNewSurveyWithType={createNewSurveyWithType}
            />
          )}
        </div>
      </div>

      {/* ALL SAVED SURVEYS IS HERE */}
      <div className="surveystable">
        <SurveySavedTable surveys={surveys} isApiWorking={isApiWorking} />
      </div>
    </div>
  );
}

export default Surveys;
