import React, { useState, useEffect } from "react";
import AdminUserBlockDeleteForm from "../users/global_users/AdminUserBlockDeleteForm";
import { blockAlbums } from "../../../utils/apis/api/auth_api_albums";

//CSS
import "./BlockAlbums.css";

const default_blocking_message =
  "Your album has been blocked due to copyright moderation. Please contact our support team for further assistance.";
const def_placeholder = "Send customised email, when blocking user content(s)";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//BlockAlbums class
function BlockAlbums(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  //
  const [blocking_message, setBlockingMessage] = useState(
    default_blocking_message
  );
  const [blocking_message_editing, setBlockingMessageEditing] = useState(false);
  //this is by default. use it all the time to tell users they have been blocked, if this turned off, users willb e blocked, but won't receive email notification about that.
  const [send_message_with_blocking, setSendMessageWithBlocking] =
    useState(true);

  //API: BLOCK USERS API CALL
  async function blockAlbumsAPICall(blockReason, otherReason) {
    setApiWorking(true);

    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      //album_ids to block
      var album_ids = props.selectedRows.join(",");
      bodyParams.push(`album_ids=${album_ids}`); //bulk album_ids to take action
      bodyParams.push(`block_reason=${blockReason}`); //this will be stored in admin_log
      bodyParams.push(`other_reason=${otherReason}`); //this will be stored in admin_log
      // #if this true, user will receive email notification on block,unblock and delete action taken by admin
      bodyParams.push(`send_email=${send_message_with_blocking}`);
      bodyParams.push(`blocking_message=${blocking_message}`); //customised email to customer when account action happens. if this empty, it will sends the default message

      const bParameters = bodyParams.join("&");
      //
      const result = await blockAlbums(bParameters);
      //✅SUCCESS
      console.log("BLOCK_ALBUMS", result);
      setApiWorking(false);
      props.refresh(); //-> refresh on successfull blocking
    } catch (error) {
      //🚫ERROR
      console.log(error);
      setApiWorking(false);
    }
  }

  //CHANGE MESSAGE TO SEND
  function changeBlockingMessage(should_change) {
    setBlockingMessageEditing(should_change);
    if (should_change) {
      setBlockingMessage("");
    } else {
      setBlockingMessage(default_blocking_message);
    }
  }

  const handleBlockAlbumsButton = (blockReason, otherReason) => {
    blockRowsAlert(blockReason, otherReason);
  };

  const blockRowsAlert = (blockReason, otherReason) => {
    const confirmation = window.confirm(
      `Are you sure you want to block these ${props.selectedRows.length} albums? Once blocked, users won't be able to buy these albums until it's unblocked.`
    );

    if (confirmation) {
      // Call your delete function here
      blockAlbumsAPICall(blockReason, otherReason);
    }
  };
  //
  //VIEW
  return (
    <div className="block_users">
      <div className="title">Block Albums({props.selectedRows.length})</div>
      <div className="selected_user_ids_to_block">
        {selectedAlbumIdsToBlock(props.selectedRows)}
      </div>
      <div className="text">
        <textarea
          value={blocking_message}
          disabled={!blocking_message_editing}
          onChange={(e) => setBlockingMessage(e.target.value)}
          placeholder={def_placeholder}
        />
      </div>
      <div className="checkbox">
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={send_message_with_blocking}
            onChange={() =>
              setSendMessageWithBlocking(!send_message_with_blocking)
            }
          />
          <span
            style={{
              textDecoration: !send_message_with_blocking
                ? "line-through"
                : "none",
            }}
          >
            Notify user via email
          </span>
        </label>

        {isApiWorking && (
          <button className="block_button" disabled={true}>
            <i className={apiFaIcon}></i>
          </button>
        )}

        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={blocking_message_editing}
            onChange={() => changeBlockingMessage(!blocking_message_editing)}
          />
          Change message
        </label>
      </div>
      {!send_message_with_blocking && (
        <div className="warning_message">
          If this turned off, users won't receive email notification, when their
          album is blocked.
        </div>
      )}
      <AdminUserBlockDeleteForm
        title="Reason for Blocking:"
        btn_title="Block"
        isApiWorking={isApiWorking}
        selectedRows={props.selectedRows}
        handleBlockUserButton={handleBlockAlbumsButton}
      />
    </div>
  );

  //selectedAlbumIdsToBlock
  function selectedAlbumIdsToBlock(data) {
    var model = [];
    data.forEach((item, index) => {
      model.push(
        <div className="user_id" key={index}>
          {item}
        </div>
      );
    });
    return model;
  }
}

export default BlockAlbums;
