import React, { useEffect, useState } from "react";
import HTMLToString from "../HTML/HTMLToString";

//Default React Class
function CreateEPK(props) {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Default | SongDrop.band";
  }, []);

  function exportHTML(html) {
    //
    props.setSelectedEmailHTML(html);
  }

  function saveHTML(html) {
    //
    let _html = {
      id: "123",
      name: "test",
      html: html,
      tags: "html, email",
    };
    console.log(_html);
    props.setSelectedEmailHTML(_html);
  }

  return (
    <div className="default">
      <HTMLToString exportHTML={exportHTML} saveHTML={saveHTML} />
    </div>
  );
}

export default CreateEPK;
