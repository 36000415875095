import React, { useEffect, useState } from "react";
import moment from "moment";
import openWikiForGenre from "../../../components/wikiLink";
import isEmpty from "../../../components/isEmpty";
import ReactLinkify from "react-linkify";
import BroadcastStarted from "./BroadcastStarted";
import TimezoneSelector from "./TimezoneSelector";
//CSS
import "./SelectedRadio.css";
//
//logo
import brandlogo from "../../../assets/img/songdrop-logo-black.png";

//SelectedRadio React Class
function SelectedRadio(props) {
  //

  //ViewDidLoad
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        // Call function to close the view
        closeSelectedRadio();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function closeSelectedRadio() {
    setTimeout(() => {
      // Get the elements to animate
      const content = document.querySelector(".radio_selected_item");
      const background = document.querySelector(".background");
      const itemContent = document.querySelector(".item_content");
      // Add classes to trigger the animations
      content.classList.add("close_bcg");
      background.classList.add("close_bcg");
      itemContent.classList.add("close_item");
      // Call the closeSelectedRadio function after the animations complete
      setTimeout(() => {
        props.closeSelectedRadio();
      }, 100);
    }, 320);
  }

  function playSelectedRadio(stream_url) {
    props.playSelectedRadio(stream_url);
  }

  function shareLiveEvent() {
    alert("Share this event:");
  }

  function didChangeAutoPlay(autoPlay) {
    props.didChangeAutoPlay(autoPlay);
  }

  //VIEW
  return (
    <div className="radio_selected_item">
      <div
        className={`background`}
        style={{ backgroundColor: props.selectedRadio.bgColor }}
      />
      <div
        className={`item_content`}
        style={{
          border: `2px solid ${props.selectedRadio.bgColor}`,
        }}
      >
        <div className="top_btns">
          <button onClick={() => closeSelectedRadio()}>
            <i className="fa fa-close" /> Close
          </button>
          <ul className="tg-list">
            <li className="tg-list-item">
              <input
                className="tgl tgl-flip"
                id="cb5"
                type="checkbox"
                value={props.autoPlay}
                onChange={(e) => didChangeAutoPlay(e.target.value)}
              />
              <label
                style={{ background: props.selectedRadio.bgColor }}
                className="tgl-btn"
                data-tg-off="⌽"
                data-tg-on="▶︎"
                for="cb5"
              ></label>
            </li>
          </ul>
          <button
            onClick={() => shareLiveEvent()}
            className="last-child-element" /* added this class */
          >
            Share <i className="fa fa-share" />
          </button>
        </div>
        <div className="card-front">
          <div className="radio_name">{props.selectedRadio.title}</div>
          <div className="radio_group">
            {getGroupName(props.selectedRadio.group)}
          </div>
          <div className="genre-container">
            {!isEmpty(props.selectedRadio.genres) &&
              props.selectedRadio.genres.split(",").map((genre, index) => {
                return (
                  <div
                    key={index}
                    title={titleWiki(genre)}
                    onClick={() => openWikiForGenre(genre)}
                    className="genre"
                    style={{ backgroundColor: props.selectedRadio.bgColor }}
                  >
                    {genre}
                  </div>
                );
              })}
          </div>
          <div className="details">
            <ReactLinkify>
              <p className="radio-desc">{props.selectedRadio.description}</p>
            </ReactLinkify>
          </div>
          <div className="radio_dates">
            <BroadcastStarted
              startTime={props.selectedRadio.start_time}
              endTime={props.selectedRadio.end_time}
            />
            <div>
              Broadcast Starts:{" "}
              {formatDateToString(props.selectedRadio.start_time)}
            </div>
            <div>
              Broadcast Ends: {formatDateToString(props.selectedRadio.end_time)}
            </div>
            <div>
              <TimezoneSelector
                start_time={props.selectedRadio.start_time}
                end_time={props.selectedRadio.end_time}
              />
            </div>
          </div>
          <button
            className="play_btn"
            onClick={() => playSelectedRadio(props.selectedRadio.url)}
          >
            {props.selectedRadio.url}
          </button>
          <div className="username">
            <div>Find us on SongDrop:</div>
            <a href="https://songdrop.uk/@/handofdoom" target="_blank">
              https://songdrop.uk/@/handofdoom
            </a>
          </div>
        </div>
        <div className="card-back">sds</div>
        <div className="disclaimer">
          <strong>Disclaimer:</strong> The content broadcasted through this app
          should not infringe any copyright laws. Users are solely responsible
          for compliance with applicable laws regarding their content.
        </div>
        <div className="powered_by">
          <div>Powered by</div>
          <a href="http://songdrop.uk" title="SongDrop website" target="_blank">
            <img className="logo" alt="" src={brandlogo} />
          </a>
        </div>
        <div className="powered_by">
          Event created: {formatDateToString(props.selectedRadio.start_time)}
        </div>
        <div className="powered_by" style={{ margin: 8 }}>
          {moment().format("yyyy")}
        </div>
        {/* <div className="long"></div> */}
      </div>
    </div>
  );

  function formatDateToString(date, formatStr = "ddd MMM DD YYYY HH:mm:ss") {
    var momObj = moment(date);
    var today = moment(); // Get the current date

    // Check if momObj represents today's date
    if (momObj.isSame(today, "day")) {
      // If momObj represents today's date, return "today" with the hours
      return "Today " + momObj.format("h:mm a");
    } else {
      // If momObj does not represent today's date, return the formatted string
      return momObj.format(formatStr);
    }
  }

  //GET: Title
  function titleWiki(genre) {
    return `go-to:wikipedia-${genre.toLowerCase()}`;
  }

  function getGroupName(group_id) {
    if (!isEmpty(props.radio_groups)) {
      let groups = props.radio_groups.filter(function (item) {
        return item.id == group_id;
      });
      if (!isEmpty(groups)) {
        return groups[0].title;
      }
    }
  }
}

export default SelectedRadio;
