import React, { useState } from "react";
import growthdata from "./growth";
import "./UserGrowthTable.css";

function UserGrowthTable() {
  //DEFAULT OPTIONS FOR TRACKING FORECAST
  const [showCurrentValue, setShowCurrentValue] = useState(true);
  const [showForecastedValue, setShowForecastedValue] = useState(true);
  const [showNotes, setShowNotes] = useState(true);
  //DETAILED OPTIONS FOR TRACKING FORECAST
  const [showCurrentArtist, setShowCurrentArtist] = useState(true);
  const [showCurrentStandard, setShowCurrentStandard] = useState(true);
  const [showForecastedStandard, setShowForecastedStandard] = useState(true);
  const [showForecastedArtist, setShowForecastedArtist] = useState(true);

  //VIEW
  return (
    <div className="usergrowth">
      <div className="toggle-buttons">
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={showCurrentValue}
            onChange={() => setShowCurrentValue(!showCurrentValue)}
          />
          <span
            style={{
              textDecoration: !showCurrentValue ? "line-through" : "none",
            }}
          >
            Current Value
          </span>
        </label>

        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={showForecastedValue}
            onChange={() => setShowForecastedValue(!showForecastedValue)}
          />
          <span
            style={{
              textDecoration: !showForecastedValue ? "line-through" : "none",
            }}
          >
            Forecasted Value
          </span>
        </label>
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={showNotes}
            onChange={() => setShowNotes(!showNotes)}
          />
          <span
            style={{
              textDecoration: !showNotes ? "line-through" : "none",
            }}
          >
            Notes
          </span>
        </label>
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={showCurrentArtist}
            onChange={() => setShowCurrentArtist(!showCurrentArtist)}
          />
          <span
            style={{
              textDecoration: !showCurrentArtist ? "line-through" : "none",
            }}
          >
            Current Artist
          </span>
        </label>
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={showCurrentStandard}
            onChange={() => setShowCurrentStandard(!showCurrentStandard)}
          />
          <span
            style={{
              textDecoration: !showCurrentStandard ? "line-through" : "none",
            }}
          >
            Current Standard
          </span>
        </label>
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={showForecastedStandard}
            onChange={() => setShowForecastedStandard(!showForecastedStandard)}
          />
          <span
            style={{
              textDecoration: !showForecastedStandard ? "line-through" : "none",
            }}
          >
            Forecasted Standard
          </span>
        </label>
        <label className="checkboxContainer">
          <input
            type="checkbox"
            checked={showForecastedArtist}
            onChange={() => setShowForecastedArtist(!showForecastedArtist)}
          />
          <span
            style={{
              textDecoration: !showForecastedArtist ? "line-through" : "none",
            }}
          >
            Forecasted Artist
          </span>
        </label>

        {/* Add similar checkboxes for other fields */}
      </div>
      <div className="last-signups-table">
        <table className="user-list-table">
          <thead>
            <tr className="user-list-header">
              <th>Date</th>
              {showCurrentValue && <th>Current Value</th>}
              {showForecastedValue && <th>Forecasted Value</th>}
              {showNotes && <th>Notes</th>}
              {/* More valuess */}
              {showCurrentArtist && <th>Current Artist</th>}
              {showCurrentStandard && <th>Current Standard</th>}
              {showForecastedArtist && <th>Forecasted Artists</th>}
              {showForecastedStandard && <th>Forecasted Standard</th>}
            </tr>
          </thead>
          <tbody>
            {growthdata.map((row, index) => (
              <tr key={index}>
                <td>{row.date}</td>
                {showCurrentValue && <td>{row.current_value}</td>}
                {showForecastedValue && <td>{row.forecasted_value}</td>}
                {showNotes && <td>{row.notes}</td>}
                {/* More valuess */}
                {showCurrentArtist && <td>{row.current_artist_value}</td>}
                {showCurrentStandard && <td>{row.current_standard_value}</td>}
                {showForecastedArtist && <td>{row.forecasted_artist_value}</td>}
                {showForecastedStandard && (
                  <td>{row.forecasted_standard_value}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserGrowthTable;
