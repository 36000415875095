const fakeUserData = [
  {
    date: "2023-01",
    current_value: "100",
    forecasted_value: "120",
    notes: "Steady growth",
    current_artist_value: "50",
    current_standard_value: "50",
    forecasted_standard_value: "60",
    forecasted_artist_value: "60",
  },
  {
    date: "2023-02",
    current_value: "120",
    forecasted_value: "140",
    notes: "Increased user engagement",
    current_artist_value: "60",
    current_standard_value: "60",
    forecasted_standard_value: "70",
    forecasted_artist_value: "70",
  },
  // ... continue data for other months
  {
    date: "2023-12",
    current_value: "300",
    forecasted_value: "320",
    notes: "Expected surge in activity",
    current_artist_value: "150",
    current_standard_value: "150",
    forecasted_standard_value: "180",
    forecasted_artist_value: "180",
  },
];

export default fakeUserData;
