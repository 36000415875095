import React, { useState, useEffect } from "react";
import isEmpty from "../../../../components/isEmpty";
import HTMLToString from "../HTML/HTMLToString";
//CSS
import "./EPKViewer.css";

//EPKViewer class
function EPKViewer(props) {
  //
  const [isHidden, setIsHidden] = useState(true);
  const [dragAndDropIsHidden, setDragAndDropIsHidden] = useState(true);

  function exportHTML(html) {
    //
    props.setSelectedEmailHTML(html);
  }

  function saveHTML(html) {
    //
    let _html = {
      id: "123",
      name: "test",
      html: html,
      tags: "html, email",
    };
    console.log(_html);
    props.setSelectedEmailHTML(_html);
  }
  //
  //ViewDidLoad
  useEffect(() => {
    //
  }, []);

  //VIEW
  return (
    <div className="epk_viewer">
      <div className="btn_wrap">
        <div className="radio">
          <input id="radio-1" name="radio" type="radio" defaultChecked />
          <button
            className="radio-label"
            onClick={() => setIsHidden(!isHidden)}
          >
            {isEmpty(props.selected_email_html)
              ? "Selected EPK "
              : `${props.selected_email_html.name}-epk`}
            <i
              className={!isHidden ? "fa fa-chevron-up" : "fa fa-chevron-down"}
            />
          </button>
        </div>
        <button
          className="radio-label"
          onClick={() => setDragAndDropIsHidden(!dragAndDropIsHidden)}
        >
          <i
            className={
              !dragAndDropIsHidden ? "fa fa-chevron-up" : "fa fa-plus-circle"
            }
          />{" "}
          New HTML Email
        </button>
      </div>

      {!isHidden && (
        <div className="">
          {isEmpty(props.selected_email_html.html) && <div>New Template</div>}
          <div className="">
            <div className="epk-border">
              <div>
                <button>Preview</button>
              </div>
              <iframe
                title="iframe"
                src={`data:text/html,${encodeURIComponent(
                  props.selected_email_html.html
                )}`}
              ></iframe>
            </div>
            <button
              className="load_epks_select_btn"
              onClick={(e) => props.load_more_epks()}
            >
              Select another
            </button>
          </div>
        </div>
      )}
      {!dragAndDropIsHidden && (
        <div>
          <HTMLToString exportHTML={exportHTML} saveHTML={saveHTML} />
        </div>
      )}
      <div className="test_prompt">
        <strong>Test Your Email:</strong> With this feature, you can send a test
        email to yourself or others using our email system. Simply enter the
        recipient's email address, compose your message, and hit send. This is a
        great way to ensure that your emails are being delivered properly and
        appearing as you intended. Give it a try and see for yourself!
      </div>
    </div>
  );
}

export default EPKViewer;
