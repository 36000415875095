import React from "react";
import "./BlogEmptyList.css";
//placeholder
import empty_placeholder from "./blog_empty.gif";
//BlogEmptyList
const BlogEmptyList = () => (
  //
  <div className="emptyList-wrap">
    <div>Ups, we could not find the related article.</div>
    <div>
      <img src={empty_placeholder} alt="empty" />
    </div>
  </div>
);
export default BlogEmptyList;
