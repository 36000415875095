import React, { useState, useEffect } from "react";
import isEmpty from "../../../../components/isEmpty";

//JS
import EPKViewer from "../EPK/EPKViewer";
//CSS
import "./TestEmailSender.css";
import { sendTestEPK } from "../../../../utils/apis/api/auth_api_presskit";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

// TestEmailSender class
function TestEmailSender(props) {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  const [is_sent_success, setSentSuccessfull] = useState(false);

  //
  const [sender_email, setSenderEmail] = useState(
    `${props.user.username || "songdrop"}-official@sdappnet.com`
  );
  const [email, setEmail] = useState(props.user.email);
  const [name, setName] = useState("");
  // const [subject, setSubject] = useState(
  //   props.user.name + " - Electronic Press Kit"
  // );
  const [subject, setSubject] = useState(
    "SONGDROP SOCIAL MUSIC PITCHDECK - SEED - UK"
  );
  const [message, setMessage] = useState("");
  //
  const [selected_email_html, setSelectedEmailHTML] = useState(null);

  //
  // ViewDidLoad
  useEffect(() => {}, []);

  //API_SEND_EMAIL
  async function sendEmail(e) {
    e.preventDefault();

    setApiWorking(true);
    if (isEmpty(email)) {
      alert("Please enter an email address");
      setApiWorking(false);
      return;
    }
    if (isEmpty(subject)) {
      alert("Please enter a subject");
      setApiWorking(false);
      return;
    }

    if (isEmpty(props.selected_email_html)) {
      alert(
        "There is no Email selected to send. Please select an Email to send"
      );
      setApiWorking(false);
      return;
    }
    // if (isEmpty(message)) {
    //   alert("Please enter a message");
    //   setApiWorking(false);
    //   return;
    // }

    setApiWorking(true);
    //API_CALL_NEW_DESIGN
    try {
      console.log(props.user);
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`email=${email}`);
      bodyParams.push(`subject=${subject || ""}`);
      bodyParams.push(`message=${message || ""}`);
      bodyParams.push(`html_email=${props.selected_email_html.html}`);
      const bParameters = bodyParams.join("~");
      //✅SUCCESS
      let result = await sendTestEPK(bParameters);
      setSentSuccessfull(true);
      console.log(result);
      setApiWorking(false);
      //🚫Error
    } catch (error) {
      setApiWorking(false);
      alert(error.message);
    }
  }

  // VIEW
  return (
    <div className="test-email">
      <div className="email">
        <div className="uploaded_file">
          <h1>
            <strong>Send a test email</strong>
          </h1>
        </div>
        <div className="message">
          <p>Add personalised message</p>
          <input
            placeholder="Sender Email"
            disabled
            value={`from: ${sender_email}`}
          />
          <input
            placeholder="Enter email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            placeholder="Enter email subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <textarea
            placeholder="Email text(Optional)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {isApiWorking && (
            <button disabled={true}>
              <i className={apiFaIcon} />
            </button>
          )}
          {!isApiWorking && !is_sent_success && (
            <button
              onClick={(e) => sendEmail(e)}
              disabled={isEmpty(props.selected_email_html)}
            >
              <i className="fa fa-paper-plane" /> Send Test Email
            </button>
          )}
          {!isApiWorking && is_sent_success && (
            <button disabled>
              <i className="fa fa-check-circle" /> EPK Sent
            </button>
          )}
          {!isApiWorking && is_sent_success && (
            <button onClick={() => setSentSuccessfull(false)}>
              <i className="fa fa-envelope" /> New Email
            </button>
          )}
          <EPKViewer
            selected_email_html={props.selected_email_html}
            load_more_epks={props.load_more_epks}
            setSelectedEmailHTML={props.setSelectedEmailHTML}
          />
        </div>
      </div>
    </div>
  );
}

export default TestEmailSender;
