import React, { useEffect, useState } from "react";
import EventFormListItem from "./EventFormListItem";

//CSS
import "./EventFormItemSelect.css";

const buttons = [
  {
    type: "gigs",
    image:
      "https://icon-library.com/images/ticket-icon-png/ticket-icon-png-2.jpg",
    title: "Gigs",
    description: "Announce your upcoming gigs",
  },
  {
    type: "broadcasts",
    image:
      "https://media.istockphoto.com/vectors/live-broadcast-icon-in-transparent-style-antenna-vector-illustration-vector-id1150435225?k=6&m=1150435225&s=170667a&w=0&h=sOOGcsQGFNbM1FbZY9vE5_0T1Md5hLY1zX9HG5Vwev0=",
    title: "Online Broadcasts",
    description: "Announce your upcoming online broadcasts",
  },

  {
    type: "survey",
    image:
      "https://static.vecteezy.com/system/resources/previews/000/344/949/original/vector-feedback-glyph-icons.jpg",
    title: "Feedback",
    description: "Ask your audience feedback on events",
  },
];

//EventFormItemSelect React Class
function EventFormItemSelect(props) {
  //ViewDidLoad
  useEffect(() => {
    //title
  }, []);

  function selectButtonType(type) {
    // props.createNewRelease(type);
  }

  //VIEW
  return (
    <div className="eventitemselect">
      {buttons.map((button, index) => (
        <EventFormListItem
          index={index}
          button={button}
          selectButtonType={selectButtonType}
        />
      ))}
    </div>
  );
}

export default EventFormItemSelect;
