import React, { useState, useEffect, useCallback } from "react";

//CSS
import "./AdminUnblockForm.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//AdminUnblockForm
function AdminUnblockForm(props) {
  //
  const [unblockReason, setBlockReason] = useState("appeal_review");
  const [otherReason, setOtherReason] = useState("");

  const handleReasonChange = (event) => {
    const selectedReason = event.target.value;
    setBlockReason(selectedReason);

    // Clear the other reason when a non-'other' option is selected
    if (selectedReason !== "other") {
      setOtherReason("");
    }
  };

  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };

  useEffect(() => {}, [handleUnblockUser]);

  const handleUnblockUser = useCallback(() => {
    props.handleUnBlockUserButton(unblockReason, otherReason);
  }, [unblockReason, otherReason, props]);

  //VIEW
  return (
    <div>
      <label htmlFor="unblockReason">{props.title}</label>
      <select
        id="unblockReason"
        value={unblockReason}
        onChange={handleReasonChange}
      >
        <option value="appeal_review">Appeal/Review</option>
        <option value="mistake_misunderstanding">
          Mistake/Misunderstanding
        </option>
        <option value="time_based">Time-Based</option>
        <option value="resolution_of_issue">Resolution of Issue</option>
        <option value="policy_change">Policy Change</option>
        <option value="user_compliance">User Compliance</option>
        <option value="other">Other</option>
      </select>

      {unblockReason === "other" && (
        <div>
          <label htmlFor="otherReason">Other Reason:</label>
          <input
            type="text"
            id="otherReason"
            value={otherReason}
            onChange={handleOtherReasonChange}
            placeholder="Specify other reason"
          />
        </div>
      )}
      {!props.isApiWorking && (
        <button
          className="green_i_button"
          onClick={() => handleUnblockUser()}
          disabled={
            props.selectedRows.length === 0 ||
            (unblockReason === "other" && otherReason.trim() === "")
          }
        >
          {props.btn_title}
        </button>
      )}
      {props.isApiWorking && (
        <button className="green_i_button" disabled={true}>
          <i className={apiFaIcon}></i>
        </button>
      )}
    </div>
  );
}

export default AdminUnblockForm;
