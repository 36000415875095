import React from "react";

//CSS
import "./AddBroadcastView.css";

// AddBroadcastView
function AddBroadcastView(props) {
  return (
    <div className="add-broadcast">
      <h1>Add My Broadcast</h1>
      <p>
        The purpose of this app is to offer independent artists a practical
        solution for live broadcasting without requiring expensive equipment. It
        allows them to use their phones to stream performances and share them as
        events on the website. This way artists can reach wider audiences and
        promote their music effectively.
      </p>
      <div className="disclaimer">
        <strong>Disclaimer:</strong> The content broadcasted through this app
        should not infringe any copyright laws. Users are solely responsible for
        compliance with applicable laws regarding their content.
      </div>
      <p>To add your broadcast on SongDrop, you will need:</p>
      <ol>
        <li>
          A SongDrop artist account. If you don't have one yet, you can register{" "}
          <a href="https://songdrop.band/register">here</a>.
        </li>
        <li>
          A running Icecast server, preferably on Digital Ocean. You can follow
          the instructions in{" "}
          <a href="https://www.digitalocean.com/community/tutorials/how-to-set-up-and-use-icecast-on-a-vps">
            this tutorial
          </a>{" "}
          on how to set up an Icecast server.
          <ul>
            <li>
              An Icecast server is a streaming media server which can take your
              live voice and broadcast it into an mp3 link.
            </li>
          </ul>
        </li>
        <li>
          Radio! iOS app which you can download for free from the App Store.
          Once you have installed the app, enter the connection details from
          your Icecast server, such as name, host, port, mount, username, and
          password to start broadcasting live on Songdrop!
        </li>
      </ol>
      <p>
        After you have set up your Icecast server, please fill out{" "}
        <a href="https://songdrop.band/broadcast">this form</a> with the details
        of your broadcast such as group, title, genres, description, start time,
        end time, and URL. This will enable you to share your live voice as an
        event on SongDrop.
      </p>
      <button onClick={() => props.closeHowToBroadcast()}>Close</button>
    </div>
  );
}

export default AddBroadcastView;
