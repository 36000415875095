import React, { useEffect, useState, useRef } from "react";
import isEmpty from "../../../components/isEmpty";
import moment from "moment";
import numberFormatter from "./numberFormatter";
import Footer from "../../../components/Footer/Footer";
import useLocalStorage from "../../../components/useLocalStorage";
import AudioPlayer from "./APlayer";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
// import { confirmAlert } from "react-confirm-alert"; // Import confirmAlert
// import "react-confirm-alert/src/react-confirm-alert.css";

//CSS
import "./Radio.css";
import AddBroadcastView from "./AddBroadcastView";
import SelectedRadio from "./SelectedRadio";
import RadioStats from "./RadioStats";
import RadioTimeline from "./RadioTimeline";
import LiveTime from "./LiveTime";
import {
  get_music_genres,
  get_artist_radios,
  get_broadcast_by_id,
} from "./radio_api";
import getMp3Info from "./get_radio_stats";

//logo
import brandlogo from "../../../assets/img/songdrop-logo-black.png";
import { func } from "prop-types";

var offlineText = "🔴SERVER/INFO NOT FOUND";
var instanceRunningText = "🟡SERVER STANDBY";
var broadcastingNowText = "✅BROADCASTING";

//fa
const faDown = "fa fa-caret-square-o-down";
const faUp = "fa fa-caret-square-o-up";
const faCheck = "fa fa-check-square";

//
//Radio React Class
function Radio(props) {
  //
  const [isApiWorking, setIsApiWorking] = useState(true);

  const [addBroadcastIsShown, setAddBroadcastToShow] = useState(false);

  //
  //PLAY_STREAM_DATA
  const playerRef = useRef(null);
  const [streamUrl, setStreamUrl] = useState(null);
  const [status_xsl_url, setStatusXslURL] = useState("status-json.xsl");
  const [volume, setVolume] = useLocalStorage("local_audio_volume", 0.1);
  //
  //ICECAST_HEADER_DATA
  const [server_status, setServerStatus] = useState(broadcastingNowText);
  //SET_LISTENERS_DATA
  const [listenersVisible, setListenersVisible] = useState(false);
  const [listeners, setListeners] = useState(1);
  const [peak_listeners, setPeakListeners] = useState(0);
  //SET_TIMELINE_DATA
  const [dayPickerVisible, setDayPickerVisible] = useState(false);
  const [radio_groups, setRadioGroups] = useState([]);
  const [selected_radio_groups, setSelectedRadioGroups] = useState([]);
  const [radio_artists, setRadioArtists] = useState([]);
  const [start_time, setStartTime] = useState(moment().add(-4, "hour"));
  const [end_time, setEndTime] = useState(moment().add(4, "hour"));
  const [visible_start_time, setVisibleStartTime] = useState(null);
  const [visible_end_time, setVisibleEndTime] = useState(null);
  const [scrollUnit, setScrollUnit] = useState("day"); //hour,day,week,month,year
  const [sidebarWidth, setSideBarWidth] = useState(150); //hide/show (0,150)

  //SELECTED_RADIO_POPUP
  const [selectedRadio, setSelectedRadio] = useState(null); //if it's null it won't show as popup
  const [_selectedRadio, _setSelectedRadio] = useState(null); //this will store in the back the latest
  //AUTO_PLAY
  const [autoPlayRadio, setAutoPlayRadio] = useLocalStorage(
    "r_auto_play",
    true
  );
  const [autoPlay, setAutoPlay] = useState(true);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Radio | SongDrop.band";

    //GET_GENRES_GROUP_LIST
    get_radio_group_list();
    //GET_TIMELINE_DATA
    get_radio_artist_list();

    //GET_SPECIFIC_BROADCAST_IF_ID_EXISTS
    if (window.location.href && window.location.href.length > 0) {
      const segments = window.location.href.split("/");
      if (segments[4]) {
        const firstSegment = segments[4].split("?");
        const broadcast_id = firstSegment[0];
        if (!isEmpty(broadcast_id)) {
          getSelectedRadioById(broadcast_id);
        }
        // do something with broadcast_id
      } else {
        console.log("Error: Invalid URL");
      }
    } else {
      console.log("Error: Window location object is undefined");
    }

    //AUTOPLAY_SONGDROP_RADIO
    // setTimeout(function () {
    //   startApp();
    // }, 1000);

    //
  }, []);

  function startApp() {
    // confirmAlert({
    //   title: "Welcome to Radio!",
    //   message:
    //     "A place for Independent Live Music Performances around the globe!!\nDo you want to play some music?",
    //   buttons: [
    //     {
    //       label: "Maybe later",
    //       onClick: () => {
    //         console.log("asdsad");
    //       },
    //     },
    //     {
    //       label: "▶ PLAY default radio",
    //       onClick: () => {
    //         setAutoPlay(true);
    //         playSelectedRadio("http://radio.songdrop.cloud:8000/radio.mp3");
    //       },
    //     },
    //   ],
    // });
  }
  //SAVE AUTOPLAY
  function _autoPlayEnabled(_autoplay) {
    setAutoPlayRadio(_autoplay);
  }

  //this will select an open a new link
  async function getSelectedRadioById(broadcast_id) {
    //
    // setIsApiWorking(true);
    try {
      //SUCCESS
      let channel = await get_broadcast_by_id(broadcast_id);
      console.log("CHANNEL_FOUND", channel);
      selectRadioChannel(channel);
      setStartTime(moment(channel.start_time).add(-0.1, "hour"));
      setEndTime(moment(channel.end_time).add(0.1, "hour"));
      //
      //ERROR
    } catch (error) {
      // setIsApiWorking(false);
      alert(error.message);
      console.log(error);
    }
  }

  //async funciton
  async function playSelectedRadio(stream_url) {
    //
    setStreamUrl(stream_url);

    //get meta
    setListeners(1000);
    setPeakListeners(1);
    console.log("PLAY_SELECTED_RADIO", stream_url);
    //
    get_radio_metadata(stream_url, status_xsl_url);
  }

  async function get_radio_metadata(radio_url, status_xsl_url) {
    try {
      //SUCCESS
      let result = await getMp3Info(radio_url, status_xsl_url);

      setListeners(result.listeners);
      setPeakListeners(result.peak_listeners);
      console.log("get_radio_metadata", result);
      // alert(
      //   `${result.server_type} list:${result.peak_listeners} peak:${result.listeners}`
      // );
      //
      console.log("result.server_type", result.server_type);

      const isServerOnline = !isEmpty(result.server_type);
      setServerStatus(
        isServerOnline ? broadcastingNowText : instanceRunningText
      );

      //ERROR
    } catch (error) {
      if (error.message === "Network Error") {
        setServerStatus(offlineText);
      }
      if (error.message === "source is undefined") {
        setServerStatus(instanceRunningText);
      }
      // setListeners(1);
      // setPeakListeners(1);
      console.log("meta_error", error.message);
    }
  }

  //API
  async function get_radio_group_list() {
    setIsApiWorking(true);

    try {
      //SUCCESS
      let result = await get_music_genres();
      setRadioGroups(result);
      //
      setTimeout(function () {
        setIsApiWorking(false);
      }, 2000);
      //ERROR
    } catch (error) {
      console.log(error);
    }
  }

  async function get_radio_artist_list() {
    setIsApiWorking(true);
    try {
      //SUCCESS
      let result = await get_artist_radios();
      setRadioArtists(result);
      setStartTime(moment().add(-0.1, "hour"));
      setEndTime(moment().add(4, "hour"));
      //
      setTimeout(function () {
        setIsApiWorking(false);
      }, 2000);
      //ERROR
    } catch (error) {
      setIsApiWorking(false);

      console.log(error);
    }
  }

  //FILTER_RADIO_GROUPS
  function filter_radio_groups(radio_groups_to_filter) {
    setSelectedRadioGroups(radio_groups_to_filter);
  }

  //SET_ARTIST_TO_VIEW
  function selected_radio_artist(artist_radio_id) {
    let artist_radio = radio_artists.filter(function (item) {
      return item.id == artist_radio_id;
    });
    if (!isEmpty(artist_radio)) {
      setSelectedRadio(artist_radio[0]);
      _setSelectedRadio(artist_radio[0]);
      if (autoPlayRadio) {
        playSelectedRadio(artist_radio[0].url);
      }
    }
  }

  function closeSelectedRadio() {
    setSelectedRadio(null);
  }

  function showListeners() {
    setListenersVisible(!listenersVisible);
  }

  function selectRadioChannel(radio_channel) {
    setSelectedRadio(radio_channel);
    _setSelectedRadio(radio_channel);
    if (autoPlayRadio) {
      playSelectedRadio(radio_channel.url);
    }
  }

  //MARK: PLAY_RANDOM_RADIO_FROM_SONGDROP
  function randomSongDropBroadcast() {
    let songdrop_radio = radio_artists.filter(function (item) {
      return item.group == 0;
    });
    if (!isEmpty(songdrop_radio)) {
      const randomSongDrop =
        songdrop_radio[Math.floor(Math.random() * songdrop_radio.length)];
      //
      selectRadioChannel(randomSongDrop);
    }
  }

  //MARK: PLAY_RANDOM_RADIO_FROM_WORLD
  function randomWorldBroadcast() {
    let world_radio = radio_artists.filter(function (item) {
      return item.group != 0;
    });
    if (!isEmpty(world_radio)) {
      const randomWorldBroadcast =
        world_radio[Math.floor(Math.random() * world_radio.length)];
      selectRadioChannel(randomWorldBroadcast);
    }
  }

  //MARK: PLAY_RANDOM_RADIO_FROM_PODCASTS
  function randomPodcast() {
    let podcast_radio = radio_artists.filter(function (item) {
      return item.group == 19;
    });
    if (!isEmpty(podcast_radio)) {
      const randomPostcast =
        podcast_radio[Math.floor(Math.random() * podcast_radio.length)];
      selectRadioChannel(randomPostcast);
    }
  }

  function playRadiosFromGroup(group_id) {
    let group_radio = radio_artists.filter(function (item) {
      return item.group == group_id;
    });
    if (!isEmpty(group_radio)) {
      const randomGroupPlay =
        group_radio[Math.floor(Math.random() * group_radio.length)];
      selectRadioChannel(randomGroupPlay);
    }
  }

  //RELOAD DATA
  function reloadTimeLineData() {
    get_radio_group_list();
  }

  function addYourBroadcast() {
    setAddBroadcastToShow(true);
  }

  function closeHowToBroadcast() {
    setAddBroadcastToShow(false);
  }

  function s_setSelectedRadio(_selectedRadio) {
    closeHowToBroadcast();
    setSelectedRadio(_selectedRadio);
  }

  function didChangeAutoPlay(auto_play) {
    setAutoPlay(auto_play);
    setAutoPlayRadio(auto_play);
  }

  //VIEW
  return (
    <div className="radio">
      {/* SELECTED_RADIO_VIEW */}
      {!isEmpty(selectedRadio) && (
        <SelectedRadio
          didChangeAutoPlay={didChangeAutoPlay}
          autoPlay={autoPlay}
          radio_groups={radio_groups}
          selectedRadio={selectedRadio}
          closeSelectedRadio={closeSelectedRadio}
          playSelectedRadio={playSelectedRadio}
        />
      )}
      {/* HOW TO BROADCAST */}
      {addBroadcastIsShown && (
        <AddBroadcastView closeHowToBroadcast={closeHowToBroadcast} />
      )}
      {/* PLAY_RADIO */}
      <div className="radio_content">
        <div className="radio_player">
          <div className="mp3player">
            <div
              className="name"
              onClick={() => s_setSelectedRadio(_selectedRadio)} //show again the last loaded radio
            >
              <div className="_radio">RADIO!</div>
              <div className="stats">
                <i className="fa fa-headphones f_headphones" />{" "}
                {numberFormatter(listeners, 0)}
              </div>
              <div className="stats">
                <i className="fa fa-signal" />{" "}
                {numberFormatter(peak_listeners, 0)}
              </div>
            </div>
            <div className="_player">
              {!isEmpty(streamUrl) && (
                <AudioPlayer
                  tintColor={(selectedRadio && selectedRadio.bgColor) || "gray"}
                  src={streamUrl}
                  autoplay={autoPlay}
                  volume={volume}
                  setVolume={setVolume}
                />
              )}
            </div>
          </div>
        </div>
        <div className="_stats">
          <button
            className="stats_btn"
            onClick={showListeners}
            title="Show/Hide SideBar"
          >
            <i className={listenersVisible ? faUp : faDown} /> {server_status}
          </button>
          {listenersVisible && (
            <RadioStats streamUrl={streamUrl} status_xsl_url={status_xsl_url} />
          )}
        </div>
        <div className="_timeline">
          <div className="_t_buttons">
            <button onClick={hideSideBar} title="Show/Hide SideBar">
              <i className="fa fa-bars" />
            </button>
            <button onClick={onPrevClick} title="Previous Day">
              <i className="fa fa-chevron-circle-left" />
              <span>{" Prev"}</span>
            </button>
            <button
              onClick={onTodayClick}
              onDoubleClick={() => setDayPickerVisible(!dayPickerVisible)}
              title="Today"
            >
              <i className="fa fa-calendar-o" />
            </button>
            <button onClick={onNextClick} title="Next Day">
              <span>{"Next "}</span>{" "}
              <i className="fa fa-chevron-circle-right" />
            </button>
            <div className="live-time">
              <LiveTime />
            </div>
          </div>
          {dayPickerVisible && (
            <div className="day_picker">
              <div>
                <DayPicker
                  numberOfMonths={1}
                  mode="single"
                  selectedDays={[start_time]}
                  onDayClick={selectedDayToPick}
                />
              </div>
              <div className="day_picker_btns">
                <button
                  onClick={() => selectedDataUnit("hour", 0)}
                  title="Hour"
                >
                  {scrollUnit === "hour" ? <i className={faCheck} /> : ""}
                  <span>{"Hour"}</span>
                </button>
                <button onClick={() => selectedDataUnit("day", 0)} title="Day">
                  {scrollUnit === "day" ? <i className={faCheck} /> : ""}
                  <span>{"Day"}</span>
                </button>
                <button
                  onClick={() => selectedDataUnit("week", 0)}
                  title="Week"
                >
                  {scrollUnit === "week" ? <i className={faCheck} /> : ""}
                  <span>{"Week"}</span>
                </button>
                <button
                  onClick={() => selectedDataUnit("month", 0)}
                  title="Month"
                >
                  {scrollUnit === "month" ? <i className={faCheck} /> : ""}
                  <span>{"Month"}</span>
                </button>
                <button
                  onClick={() => selectedDataUnit("year", 0)}
                  title="Year"
                >
                  {scrollUnit === "year" ? <i className={faCheck} /> : ""}
                  <span>{"Year"}</span>
                </button>
              </div>
            </div>
          )}
          <RadioTimeline
            selected_group_id={(_selectedRadio && _selectedRadio.group) || -999}
            isApiWorking={isApiWorking}
            sidebarWidth={sidebarWidth}
            radio_groups={radio_groups}
            selected_radio_groups={selected_radio_groups}
            radio_artists={radio_artists}
            visible_start_time={visible_start_time}
            visible_end_time={visible_end_time}
            start_time={start_time}
            end_time={end_time}
            filter_radio_groups={filter_radio_groups}
            selected_radio_artist={selected_radio_artist}
            reloadTimeLineData={reloadTimeLineData}
            randomPodcast={randomPodcast}
            randomWorldBroadcast={randomWorldBroadcast}
            randomSongDropBroadcast={randomSongDropBroadcast}
            playRadiosFromGroup={playRadiosFromGroup}
          />
          <button
            className="add_broadcast"
            onClick={addYourBroadcast}
            title="Add your broadcast"
          >
            <i className="fa fa-microphone" />
            <span>{" Add your broadcast"}</span>
          </button>
        </div>
        <div className="powered_by">
          <div>Powered by</div>
          <a href="http://songdrop.uk" title="SongDrop website" target="_blank">
            <img className="logo" alt="" src={brandlogo} />
          </a>
        </div>
        <div className="_footer">
          <Footer />
        </div>
      </div>
    </div>
  );

  function selectedDataUnit(unit, time_range) {
    setScrollUnit(unit);

    if (unit === "hour") {
      let newVisibleTimeStart = moment().add(-0.1, "hour");
      let newVisibleTimeEnd = moment().add(1, "hour");
      console.log(newVisibleTimeStart);
      //
      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }

    if (unit === "day") {
      let newVisibleTimeStart = moment()
        .add(time_range, "day")
        .startOf("day")
        .valueOf();
      let newVisibleTimeEnd = moment()
        .add(time_range, "day")
        .endOf("day")
        .valueOf();
      console.log(newVisibleTimeStart);
      //
      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }

    if (unit === "week") {
      let newVisibleTimeStart = moment()
        .add(time_range, "week")
        .startOf("week")
        .valueOf();
      let newVisibleTimeEnd = moment()
        .add(time_range, "week")
        .endOf("week")
        .valueOf();
      //
      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }
    if (unit === "month") {
      let newVisibleTimeStart = moment()
        .add(time_range, "month")
        .startOf("month")
        .valueOf();
      let newVisibleTimeEnd = moment()
        .add(time_range, "month")
        .endOf("month")
        .valueOf();
      //
      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }
    if (unit === "year") {
      let newVisibleTimeStart = moment()
        .add(time_range, "year")
        .startOf("year")
        .valueOf();
      let newVisibleTimeEnd = moment()
        .add(time_range, "year")
        .endOf("year")
        .valueOf();
      //
      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }

    //this enabled zoom/scroll
    setTimeout(function () {
      setVisibleStartTime(null);
      setVisibleEndTime(null);
    }, 1000);
  }

  //DATE_PICKER_FUNCTIONS
  function selectedDayToPick(day) {
    console.log(day);
    //
    setStartTime(moment(day).add(0, "hour"));
    setEndTime(moment(day).add(24, "hour"));
    //
    setVisibleStartTime(moment(day).add(0, "hour"));
    setVisibleEndTime(moment(day).add(24, "hour"));
    //this enabled zoom/scroll
    setTimeout(function () {
      setVisibleStartTime(null);
      setVisibleEndTime(null);
    }, 1000);
  }

  //SHOW TODAY'S BROADCASTS
  function onTodayClick() {
    //
    setStartTime(moment().add(-0.2, "hour"));
    setEndTime(moment().add(24, "hour"));
    //
    setVisibleStartTime(moment().add(-0.2, "hour"));
    setVisibleEndTime(moment().add(8, "hour"));
    //enable scrolling
    setTimeout(function () {
      setVisibleStartTime(null);
      setVisibleEndTime(null);
    }, 1000);
  }

  //BTN FUNCTIONS
  function onPrevClick() {
    const zoom = end_time - start_time;
    //
    setStartTime(start_time - zoom);
    setEndTime(end_time - zoom);

    setVisibleStartTime(start_time - zoom);
    setVisibleEndTime(end_time - zoom);

    if (scrollUnit === "week") {
      let newVisibleTimeStart = moment(start_time)
        .add(-1, "week")
        .startOf("week")
        .valueOf();
      let newVisibleTimeEnd = moment(start_time)
        .add(-1, "week")
        .endOf("week")
        .valueOf();
      //
      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }
    if (scrollUnit === "month") {
      let newVisibleTimeStart = moment(start_time)
        .add(-1, "month")
        .startOf("month")
        .valueOf();
      let newVisibleTimeEnd = moment(start_time)
        .add(-1, "month")
        .endOf("month")
        .valueOf();
      //
      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }
    if (scrollUnit === "year") {
      let newVisibleTimeStart = moment(start_time)
        .add(-1, "year")
        .startOf("year")
        .valueOf();
      let newVisibleTimeEnd = moment(start_time)
        .add(-1, "year")
        .endOf("year")
        .valueOf();
      //
      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }

    //this enabled zoom/scroll
    setTimeout(function () {
      setVisibleStartTime(null);
      setVisibleEndTime(null);
    }, 1000);
  }

  function onNextClick() {
    const zoom = end_time - start_time;

    setStartTime(moment(start_time + zoom));
    setEndTime(moment(end_time + zoom));

    setVisibleStartTime(moment(start_time + zoom));
    setVisibleEndTime(moment(end_time + zoom));

    if (scrollUnit === "week") {
      let newVisibleTimeStart = moment(start_time)
        .add(1, "week")
        .startOf("week")
        .valueOf();
      let newVisibleTimeEnd = moment(start_time)
        .add(1, "week")
        .endOf("week")
        .valueOf();
      //

      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }
    if (scrollUnit === "month") {
      let newVisibleTimeStart = moment(start_time)
        .add(1, "month")
        .startOf("month")
        .valueOf();
      let newVisibleTimeEnd = moment(start_time)
        .add(1, "month")
        .endOf("month")
        .valueOf();
      //
      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }
    if (scrollUnit === "year") {
      let newVisibleTimeStart = moment(start_time)
        .add(1, "year")
        .startOf("year")
        .valueOf();
      let newVisibleTimeEnd = moment(start_time)
        .add(1, "year")
        .endOf("year")
        .valueOf();
      //
      setStartTime(newVisibleTimeStart);
      setEndTime(newVisibleTimeEnd);
      setVisibleStartTime(newVisibleTimeStart);
      setVisibleEndTime(newVisibleTimeEnd);
    }

    //this enabled zoom/scroll
    setTimeout(function () {
      setVisibleStartTime(null);
      setVisibleEndTime(null);
    }, 1000);
  }

  //HIDE/SHOW SIDEBAR
  function hideSideBar() {
    if (sidebarWidth > 0) {
      setSideBarWidth(0);
    } else {
      setSideBarWidth(150);
    }
    setRadioGroups(radio_groups);
    setRadioArtists(radio_artists);
    setStartTime(start_time);
    setEndTime(end_time);
  }
}

export default Radio;
