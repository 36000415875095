import React, { useState, useEffect } from "react";
import CountrySelect from "../../../../components/CountrySelect";
import GenreDropDownList from "../../../../components/GenreDropDownList";
import { createEPKContact } from "../../../../utils/apis/api/auth_api_presskit";
import isEmpty from "../../../../components/isEmpty";
import emailvalidator from "email-validator";
//CSS
import "./CreateEPKContact.css";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//CreateEPKContact class
function CreateEPKContact(props) {
  //
  const [isApiWorking, setIsApiWorking] = useState(false);
  //
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [genres, setGenres] = useState([]);
  const [type, setType] = useState(0);

  //ViewDidLoad
  useEffect(() => {
    //API
    // create_bulk_epk_contact(json_blogs);
  }, []);

  async function create_bulk_epk_contact(data) {
    for (const item of data) {
      if (isEmpty(item.name)) {
        console.log("Item.name is not filled");
        return;
      }
      if (isEmpty(item.email)) {
        console.log("Item.email is not filled");
        return;
      }
      if (isEmpty(item.country)) {
        console.log("Item.country is not filled");
        return;
      }
      // if (isEmpty(item.city)) {
      //   console.log("Item.city is not filled");
      //   continue;
      // }
      try {
        var bodyParams = [];
        bodyParams.push(`name=${item.name}`);
        bodyParams.push(`email=${item.email}`);
        bodyParams.push(`country=${item.country}`);
        bodyParams.push(`city=${""}`);
        bodyParams.push(`genres=${item.genres}`);
        bodyParams.push(`type=${item.type}`);

        let bParameters = bodyParams.join("&");

        //✅SUCCESS
        let result = await createEPKContact(bParameters);
        console.log(result);
        setIsApiWorking(false);

        //🚫ERROR
      } catch (error) {
        setIsApiWorking(false);
        console.log(error);
      }
    }
  }

  async function create_epk_contact() {
    if (isEmpty(name)) {
      alert("Enter a name for submission");
      console.log("Item.name is not filled");
      return;
    }
    if (isEmpty(email) || !emailvalidator.validate(email)) {
      alert("Enter a valid email for submission");
      console.log("Item.email is not filled");
      return;
    }
    if (isEmpty(country)) {
      alert("Enter a country for submission");
      console.log("Item.country is not filled");
      return;
    }
    if (isEmpty(genres)) {
      alert("Enter genre(s) for submission");
      console.log("Item.genre is not filled");
      return;
    }
    setIsApiWorking(true);
    //
    try {
      var bodyParams = [];
      bodyParams.push(`name=${name}`);
      bodyParams.push(`email=${email}`);
      bodyParams.push(`country=${country}`);
      bodyParams.push(`city=${city}`);
      bodyParams.push(`genres=${genres.join(",")}`);
      bodyParams.push(`type=${type}`);

      let bParameters = bodyParams.join("&");

      //✅SUCCESS
      let result = await createEPKContact(bParameters);
      console.log(result);
      alert(result.message);
      setName("");
      setEmail("");
      setIsApiWorking(false);

      //🚫ERROR
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
    }
  }

  async function submitNewEPKContact(e) {
    e.preventDefault();
    create_epk_contact();
  }

  //COUNTRY
  function didSelectCountry(country) {
    console.log("country", country.name);
    setCountry(country.name);
  }

  //VIEW
  return (
    <div className="epk_contact_form">
      <input
        className="form_input"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={isApiWorking}
      />
      <input
        className="form_input"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={isApiWorking}
      />
      <CountrySelect
        didSelectCountry={didSelectCountry}
        selected_country={isEmpty(country) ? "" : country}
      />
      <input
        className="form_input"
        placeholder="City(optional)"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        disabled={isApiWorking}
      />
      <GenreDropDownList genres={genres} setGenres={setGenres} />
      <select
        onChange={(e) => setType(e.target.value)}
        value={type}
        className="form_input"
      >
        <option value={0}>Radio Station</option>
        <option value={1}>Magazine</option>
        <option value={2}>Blog</option>
      </select>
      {/* API_WORKING */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {/* SUBMIT_FORM */}
      {!isApiWorking && (
        <button onClick={(e) => submitNewEPKContact(e)}>Submit</button>
      )}
      <div className="policy">
        Please only submit publicly available email addresses for radios,
        magazines, blogs, and other media outlets that are interested in
        receiving new music submissions. Do not include any private submission
        links or personal email addresses. By submitting an email address, you
        confirm that you have the necessary permissions to share this
        information with us and that you understand that we will use this
        information solely for the purpose of sending music-related information
        via SongDrop email.
      </div>
    </div>
  );
}

export default CreateEPKContact;
