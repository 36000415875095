import React, { useState, useEffect } from "react";

//FA
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//EmailTopNavBar class
function EmailTopNavBar(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="button_wrap">
      <button
        className={props.page_title === "audience" ? "active" : ""}
        onClick={() => props.switchToView("audience")}
      >
        <i className="fa fa-users" /> Audience
      </button>
      <button
        className={props.page_title === "campaigns" ? "active" : ""}
        onClick={() => props.switchToView("campaigns")}
      >
        <i className="fa fa-line-chart" /> Campaigns
      </button>
      <button
        className={props.page_title === "automations" ? "active" : ""}
        onClick={() => props.switchToView("automations")}
      >
        <i className={"fa fa-sitemap"} /> Automations
      </button>
      <button
        className={props.page_title === "epk" ? "active" : ""}
        onClick={() => props.switchToView("epk")}
      >
        <i className="fa fa-id-badge" /> E-Press Kit
      </button>
      <button
        className={props.page_title === "create" ? "active" : ""}
        onClick={() => props.switchToView("create")}
      >
        <i className={props.isApiWorking ? apiFaIcon : "fa fa-plus-circle"} />{" "}
        Create
      </button>
      <button
        className={props.page_title === "test" ? "active" : ""}
        onClick={() => props.switchToView("test")}
      >
        <i className="fa fa-at" /> Test
      </button>
      <button
        className={props.page_title === "send" ? "active" : ""}
        onClick={() => props.switchToView("send")}
      >
        <i className="fa fa-paper-plane" /> Send
      </button>
    </div>
  );
}

export default EmailTopNavBar;
