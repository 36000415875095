import React, { useEffect, useState } from "react";
import emailvalidator from "email-validator";
import passwordValidator from "password-validator";
import isEmpty from "../../../components/isEmpty";
import { signUp } from "../../../utils/apis/unauth";
import URLParser from "../../../components/URLParser";
//JS
import Footer from "../../../components/Footer/Footer";
//css
import "./Register.css";
//icons+about
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
import about from "../../../assets/about/about.json";

//password schema
//8-16 chars,1 digit,1 special,1 lowercased,1 uppercased
var schema = new passwordValidator();

//Register class
function Register() {
  //api
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //user input data
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [regcode, setCode] = useState("");

  //legal
  const [isLegalAccepted, setLegalAccepted] = useState(false);

  const attributes = (key, value) => {
    return {
      Name: key,
      Value: value,
    };
  };

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Register - Admin | SongDrop.band";
    //url paths
    let url_paths = URLParser();
    var email = decodeURIComponent(url_paths["email"] || "");
    var admin_name = decodeURIComponent(url_paths["admin_name"] || "").replace(
      "%",
      " "
    );
    //sets the email
    setEmail(email);
    setName(admin_name);
    //
    setupPasswordSchema();
  }, []);

  //API call: Submit Login
  const handleSubmit = async (event) => {
    event.preventDefault();
    //set api to working
    setApiError("");
    setApiWorking(true);

    //check valid email address form
    if (!emailvalidator.validate(email)) {
      let error = "Email is not valid: example@songdrop.band";
      setApiError(error);
      setApiWorking(false);
      scrollToError();
      return;
    }
    //check if password valid
    if (!schema.validate(password)) {
      const error =
        "Password should have: 8-16 chars, 1 uppercase, 1 lowercase, 1 digit, 1 symbol.";
      setApiError(error);
      setApiWorking(false);
      scrollToError();
      return;
    }
    //call api
    let _email = email.toLowerCase();
    let _pass = password;
    const attributeList = [];
    attributeList.push(attributes("custom:adminname", name));
    attributeList.push(attributes("custom:regcode", regcode));
    //Register
    try {
      //✅Success: User Registeres
      await signUp(_email, _pass, attributeList);
      alert(
        `Sign Up Successed\nWe sent you an email to ${_email} with your verification code.`
      );
      //email was sent to verify user account
      window.location.href = `/validate=${_email}`;
    } catch (error) {
      //❌Error: Register error error
      alert(`Sign Up Failed\n${error.message}`);
      setApiError(`Sign Up Failed\n${error.message}`);
      setApiWorking(false);
      setTimeout(function () {
        scrollToError();
      }, 100);
    }
  };

  //INPUT FUNCTIONS
  //update email value as state
  function handleEmailChanged(email) {
    setEmail(email);
  }

  //update password value as state
  function handlePasswordChanged(password) {
    setPassword(password);
  }

  //update name value as state
  function handleNameChanged(name) {
    setName(name);
  }

  //update code value as state
  function handleCodeChanged(regcode) {
    setCode(regcode);
  }

  function didCheckLegal() {
    setLegalAccepted(!isLegalAccepted);
  }

  //VIEW
  return (
    <div className="register-container">
      <div className="body">
        <div className="top">
          <img className="logo" alt="" src={logoIcon} onClick={goToLoginPage} />
          <div className="info">
            <i
              title="What is SongDrop?"
              className="fa fa-question-circle"
              onClick={() => infoClicked()}
            />
          </div>
        </div>
        <div className="header">
          <div className="welcome">
            <p>
              Create{" "}
              <p
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  display: "inline-block",
                  padding: "0px 4px 0px 4px",
                }}
              >
                <strong>ADMIN</strong>
              </p>{" "}
              account to:
            </p>
          </div>
        </div>
        <div className="content" id="scrollTo">
          {!isEmpty(apiError) && (
            <div className="error-container">
              <p className="title">Error during sign up:</p>
              <p className="error-message">{apiError}</p>
            </div>
          )}
          <form className="form" onSubmit={handleSubmit}>
            <div className="input-field">
              <p>Email</p>
              <input
                value={email}
                placeholder="example@songdrop.uk"
                style={{ textTransform: "lowercase" }}
                onChange={(e) => handleEmailChanged(e.target.value)}
              />
            </div>
            <div className="input-field">
              <p>Password</p>
              <input
                value={password}
                placeholder="•••••••"
                type="password"
                onChange={(e) => handlePasswordChanged(e.target.value)}
              />
            </div>
            <div className="input-field">
              <p>Name</p>
              <input
                value={name}
                placeholder="Employee name"
                onChange={(e) => handleNameChanged(e.target.value)}
              />
            </div>
            <div className="input-field">
              <p>Code</p>
              <input
                value={regcode}
                placeholder="Code via email"
                onChange={(e) => handleCodeChanged(e.target.value)}
              />
            </div>

            <div className="checkbox">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={isLegalAccepted}
                  onChange={() => didCheckLegal()}
                />
              </div>
              <p>
                By clicking Register, you agree to our{" "}
                {<a onClick={goToTermsUrl}>Employment Policy</a>}, and our{" "}
                {<a onClick={goToPrivacyUrl}>Confidential Policy</a>}.
              </p>
            </div>
            <div className="button-container">
              {isApiWorking === false && (
                <button
                  type="submit"
                  className="joinButton"
                  disabled={!isSubmitEnabled()}
                >
                  Register
                </button>
              )}
              {isApiWorking === true && (
                <button
                  className="btn btn_sign_in btn-primary"
                  style={{ backgroundColor: "transparent", color: "darkgray" }}
                  disabled={true}
                >
                  <i
                    className="fa fa-refresh spin-it"
                    style={{ color: "darkGray" }}
                  />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="footer-container">
        <div className="footer-wrapper">
          <Footer />
        </div>
      </div>
    </div>
  );

  //Scroll to error
  function scrollToError() {
    if (isEmpty(apiError)) {
      return;
    }
    var element = document.getElementById("scrollTo");
    element.scrollIntoView();
  }

  //temrs url
  function goToTermsUrl() {
    window.open(about.terms_url, "_blank");
  }

  //privacy url
  function goToPrivacyUrl() {
    window.open(about.privacy_url, "_blank");
  }

  //show info(what songdrop is about)
  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/login";
  }

  //password schema setup
  function setupPasswordSchema() {
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(16) // Maximum length 16
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .has()
      .symbols() // Should has symbols
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]); // Blacklist these values
  }

  //Enable/Disable submit button
  function isSubmitEnabled() {
    if (isApiWorking === true) {
      return false;
    } else {
      return (
        !isEmpty(email) &&
        !isEmpty(password) &&
        !isEmpty(regcode) &&
        !isEmpty(name) &&
        isLegalAccepted
      );
    }
  }
}

export default Register;
