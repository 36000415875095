import React from "react";
import "./SurveyResponseTable.css"; // Import your CSS file for styling

const SurveyResponseTable = (props) => {
  return (
    <div className="survey-response-table">
      <table>
        <thead>
          <tr>
            <th>Known Contacts</th>
            <th>Unknown Contacts</th>
            <th>New Contacts</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>known_contacts_number</td>
            <td>unknown_contacts_number</td>
            <td>new_contacts_number</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SurveyResponseTable;
