import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useContainerDimensions } from "../../../../components/useContainerDimensions";

//CSS
import "./Automations.css";

require("react-big-calendar/lib/css/react-big-calendar.css");

const localizer = momentLocalizer(moment);
const fa_ticket = <i class="fa fa-ticket" aria-hidden="true"></i>;
const fa_music = <i class="fa fa-music" aria-hidden="true"></i>;

const saved_events = [
  {
    id: "1",
    type: "release",
    start_date: "2023-12-06 11:00",
    end_date: "2023-12-06 11:00",
    title: "Time Crime is OUT!!!!",
    status: "1",
  },
  {
    id: "2",
    type: "release",
    start_date: "2023-12-06 11:00",
    end_date: "2023-12-06 11:00",
    title: "Time Crime is OUT!!!!",
    status: "1",
  },
];

//Automations React Class
function Automations(props) {
  //
  const _componentRef = useRef("cal-refer");
  const { width, height } = useContainerDimensions(_componentRef);
  const [isApiWorking, setApiWorking] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [selectBtnsVisible, setSelectBtnsVisible] = useState(true);

  const localizer = momentLocalizer(moment); // or globalizeLocalizer

  const handleSelectSlot = useCallback(({ start, end }) => {
    //  props.handleSelectSlot(start, end);
  }, []);

  const handleSelectEvent = useCallback(
    (
      event //props.handleSelectEvent(event),
    ) => []
  );

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(),
    }),
    []
  );

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Automations | SongDrop.band";
    console.log("Automations");
  }, []);

  return (
    <div className="automations" ref={_componentRef}>
      <Calendar
        defaultDate={defaultDate}
        defaultView={Views.MONTH}
        events={props.events}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
        scrollToTime={scrollToTime}
        style={{
          flexGrow: 1,
          height: 500,
        }}
      />
    </div>
  );
}

export default Automations;
