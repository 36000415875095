import React, { useEffect, useState } from "react";
const items = [
  {
    title: "May 2017",
    cardTitle: "Manifestation",
    cardSubtitle:
      "Hand Of Doom's first EP - Manifestation - was first released.",
    cardDetailedText: `The three piece duo, Gus Everitt(vocal+guitar), Andreas Morelli(vocal+bass) and Kyo(drumms) recorded 4 songs on the Ep.`,
  },
  {
    title: "Gig 2022",
    cardTitle: "Manifestation",
    cardSubtitle: "GIG",
    cardDetailedText: `Fiddlers elbow`,
    media: {
      source: {
        url: "https://www.instagram.com/p/CazMQAdLZyb/?utm_source=ig_web_copy_link",
        type: "mp4",
      },
      type: "VIDEO",
      name: "MANIFESTATION",
    },
  },
  {
    title: "17.04.2022",
    cardTitle: "♫Manifestation - Hand Of Doom VIDEO CLIP PREMIER",
    cardSubtitle: "Manifestation - Hand Of Doom is released on SongDrop.",
    cardDetailedText: `Official music video clip for "Manifestation": the title track from our debut EP.  Starring our good friend Juda Gentelini (aka Ranxerox) as The Thrasher, this video tells the story of deep subconscious stress and worries manifesting as a terrifying Ghoul in a nightmare, which he is powerless to fight or escape, until he wakes.

    This video was nearly 4 years in the making, having been abandoned shortly after our EP release in 2018. It was resurrected in late 2021, and now it will bring to a close the "Manifestation" era of Hand Of Doom, ahead of our upcoming album "Stray From The Path", coming January 2023!
    
    Filmed and directed by Gabriel Majorsky, edited by Gus Kim and Gabriel Majorsky.
    
    Buy Manifestation here: https://handofdoom.bandcamp.com/releases
    Check out our social media: https://www.facebook.com/handofdoommetal
    https://www.instagram.com/handofdoomo...
    Juda's Insta: https://www.instagram.com/ranx.juda/`,
    url: "https://www.youtube.com/watch?v=JLftv9KnUgQ",
    media: {
      source: {
        url: "https://www.youtube.com/embed/JLftv9KnUgQ",
        type: "mp4",
      },
      type: "VIDEO",
      name: "MANIFESTATION",
    },
  },
  {
    title: "07.05.2022",
    cardTitle: "Stray From The Path",
    cardSubtitle: "Studio Recording Stray From the Path",
    cardDetailedText: `On Thursday, we finished recording our first full-length album "Stray From The Path" at Satellite Studios with our boy Cam Shuck! We spent 10 straight days laying down 7 tracks of pure savagery. These were simultaneously some of the hardest and most fun days of our lives, as we slaved to realise this vision. All of us played our arses off and sang our hearts out for this record, and we are beyond excited to share it with the world! More updates coming very soon...`,
    media: {
      source: {
        url: "https://scontent-man2-1.xx.fbcdn.net/v/t39.30808-6/279206279_663081461814140_5644700686213302284_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=730e14&_nc_ohc=8-isogMPjkQAX95Zk1X&_nc_ht=scontent-man2-1.xx&oh=00_AfDYIl6mhFWhW6sXR9ZVlnyGVrWfdNUJJfEeNjZwyIdiIA&oe=63B87AB4",
        type: "png",
      },
      type: "IMAGE",
      name: "IMAGE",
    },
  },
  {
    title: "10.07.2022",
    cardTitle: "Manifestation - Hand Of Doom",
    cardSubtitle: "Manifestation - Hand Of Doom is released on SongDrop.",
    cardDetailedText: `It has 4 songs.`,
    url: "https://songdrop.uk/qr/xqpNalLMoL1663951705890",
  },
  {
    title: "01.12.2022",
    cardTitle: "BARBED WIRE MEWS VIDEO C",
    cardSubtitle: "First Studio Album is officially released",
    cardDetailedText: ["paragraph1", "paragraph2"],
    timelineContent: <div>Custom content</div>,
    media: {
      name: "dunkirk beach",
      source: {
        url: "https://www.youtube.com/watch?v=timtXCQniGM",
      },
      type: "VIDEO",
    },
  },
  {
    title: "03.01.2023",
    cardTitle: "NO EVENTS TODAY",
    cardSubtitle: "Hand of Doom didn't share any events for today.",
    cardDetailedText: "",
  },

  {
    title: "Jan 2023",
    cardTitle: "STRAY FROM THE PATH ALBUM LAUNCH",
    cardSubtitle: "First Studio Album is officially released",
    cardDetailedText:
      "We will be returning to our spiritual home, The Dev, to launch our debut LP Stray From The Path on Saturday 21st January!Support comes in the form of Sewer Trench bringing their filthy, crusty death metal to the main slot and Lobotomica opening proceedings with their mind-bending tech-thrash 🤯We've got a belter of a special guest to announce down the line too, and at a cost of precisely nothing to get in you'd be mad to miss it. Event link in comments*Stray From The Path released 06/01/23*",
    timelineContent: "",
    media: {
      name: "dunkirk beach",
      source: {
        url: "https://www.youtube.com/embed/timtXCQniGM",
      },
      type: "IMAGE",
    },
  },
];

export default items;
