import React, { useEffect, useState } from "react";

//CSS
import "./SurveySavedTable.css"; // Import your CSS file for styling
//JSON
import surveyStatus from "../survey_status.json";

//FA
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//SurveySavedTable
const SurveySavedTable = (props) => {
  //
  const [selectedRows, setSelectedRows] = useState([]);

  //SELECT ROWS TO DELETE
  const handleRowSelection = (survey_id) => {
    if (selectedRows.includes(survey_id)) {
      setSelectedRows(selectedRows.filter((row) => row !== survey_id));
    } else {
      setSelectedRows([...selectedRows, survey_id]);
    }
  };

  const deleteRows = () => {
    const confirmation = window.confirm(
      `Are you sure you want to delete these ${selectedRows.length} surveys? `
    );

    if (confirmation) {
      // Call your delete function here
    }
  };

  const openSurveyToEdit = (survey_id) => {};

  //VIEW
  return (
    <div className="survey-saved-table">
      <div>
        You have <strong>{props.surveys.length} </strong> saved surveys.
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Status</th>
            <th>Responses</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          {props.surveys.map((result, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  onChange={() => handleRowSelection(result.survey_id)}
                  checked={selectedRows.includes(result.survey_id)}
                />
              </td>
              <td>
                <button
                  className="click-surveys"
                  onClick={(e) => openSurveyToEdit(result.survey_id)}
                >
                  {result.title}
                </button>
                <span>Edited: {result.updated_at}</span>
              </td>
              <td>
                <div className="status">
                  <label
                    className="highlight"
                    style={{
                      background: returnStatusColor(result.status),
                    }}
                  >
                    {returnStatusTitle(result.status)}
                  </label>
                </div>
              </td>
              <td>
                <span>
                  <strong>{result.responses}</strong>
                </span>
                <span>Responses</span>
              </td>
              <td>{result.created_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {props.isApiWorking && (
        <button className="api-emails" disabled={true}>
          <i className={apiFaIcon}></i>
        </button>
      )}
      <button
        className="delete-surveys"
        onClick={() => deleteRows()}
        disabled={selectedRows.length === 0}
      >
        Delete Selected Surveys
      </button>
    </div>
  );

  //STYLING
  //Returns the right status color
  function returnStatusColor(status) {
    var background = "lightgray"; // default value
    surveyStatus.map((item) => {
      if (status == item.status) {
        background = item.colour;
        return background;
      }
    });
    return background;
  }

  //Returns the right status title. eg. (0: DRAFT)
  function returnStatusTitle(status) {
    var _statusTitle = "Draft"; // default value
    surveyStatus.map((item) => {
      if (status == item.status) {
        _statusTitle = item.title;
      }
    });
    return _statusTitle;
  }
};

export default SurveySavedTable;
