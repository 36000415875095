import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import { getUsersList } from "../../../../utils/apis/api/auth_api_users";
import Pagination from "../../../../components/Pagination/Pagination";
import URLParser from "../../../../components/URLParser";
import useLocalStorage from "../../../../components/useLocalStorage";
//CSS
import "./GlobalUsers.css";
//JS
import NavBar from "../../../../components/Navbar/NavBar";
import BlockUsers from "./BlockUsers";
import UnblockUsers from "./UnblockUsers";
import DeleteUsers from "./DeleteUsers";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://admin.songdrop.band/users
//GlobalUsers
function GlobalUsers(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //OPTIONS
  const [blocking, setBlocking] = useState(false);
  const [unblocking, setUnblocking] = useState(false);
  const [deleting, setDeleting] = useState(false);

  //users
  const [users, setUsers] = useState([]);
  const [total_users_number, setTotalUsersNumber] = useState(0);
  const [verified_users_number, setVerifiedUsersNumber] = useState([]);
  const [blocked_users_number, setBlockedUsersNumber] = useState([]);

  //SELECTED_USER_IDS_TO_BLOCK
  const [selectedRows, setSelectedRows] = useState([]);
  const [api_search, setApiSearch] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  //API_PAGINATION
  const [filterAccounts, setFilterAccounts] = useState("");
  const [filterStart, setFilterPage] = useState(0);
  const [filterArray, setFilterArray] = useState([
    { id: 1, name: "Item " + 1 },
  ]);

  //This handle how many item returned/page
  const [selectedPagination, setSelectedPagination] = useLocalStorage(
    "pagination_limit_users",
    20
  ); // Initially selected value
  const [paginationArray, setPaginationArray] = useState([
    10, 20, 50, 100, 200, 500, 1000, 5000, 10000,
  ]);
  const handlePaginationChange = (e) => {
    setSelectedPagination(parseInt(e.target.value, 10)); // Parse the selected value to an integer
    // Perform actions based on the selected pagination value (e.g., fetching data)
  };

  //
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Users | Admin SongDrop";
    let url_paths = URLParser();
    var search = url_paths["search"] || "";
    setSearchTerm(search);
    //api
    getUsersAPI(search);
  }, [filterAccounts, selectedPagination, filterStart]);

  //API: getUsersAPI
  async function getUsersAPI(api_search) {
    setApiWorking(true);

    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`page_offset=${filterStart}`);
      bodyParams.push(`page_limit=${selectedPagination}`);
      bodyParams.push(`account_status=${filterAccounts}`);
      bodyParams.push(`search_term=${api_search || ""}`);
      const bParameters = bodyParams.join("&");
      //
      const result = await getUsersList(bParameters);
      //✅SUCCESS
      setUsers(result["users"]);
      setTotalUsersNumber(result["metrics"]["all_users_number"]);
      setBlockedUsersNumber(result["metrics"]["blocked_users_number"]);
      setVerifiedUsersNumber(result["metrics"]["verified_users_number"]);
      console.log("GET_USERS", result);

      //filterpageitems
      var filterItems = [
        ...Array(result["metrics"]["all_users_number"]).keys(),
      ].map((i) => ({
        id: i + 1,
        name: "Item " + i,
      }));
      setFilterArray(filterItems);
      //
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      console.log(error);
      setApiWorking(false);
    }
  }

  const toggleSortDirection = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedData = [...users];

  if (sortField) {
    sortedData.sort((a, b) => {
      const aValue = a[sortField]; //.toLowerCase();
      const bValue = b[sortField]; //.toLowerCase();

      if (aValue < bValue) {
        return sortDirection === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  const filteredData = sortedData.filter(
    (item) =>
      item.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const highlightText = (text, highlight) => {
    if (!highlight) return text;

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, i) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <mark key={i}>{part}</mark>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  //SELECT ROWS TO DELETE
  const handleRowSelection = (user_id) => {
    if (selectedRows.includes(user_id)) {
      setSelectedRows(selectedRows.filter((row) => row !== user_id));
    } else {
      setSelectedRows([...selectedRows, user_id]);
    }
  };

  const refreshRows = () => {
    setFilterPage(0);
    setFilterAccounts("none");
    //
  };

  //CHANGE: Pagination PageNumber
  function onChangePage(currentPage) {
    setFilterPage(currentPage);
  }

  //GET: /fglobal_userss?page={currentPaginationNumber}
  function getCurrentPage() {
    // let url_paths = URLParser();
    // var page = url_paths["page"] || 1;
    return Math.max(1, parseInt(filterStart));
  }

  function checkAndLoadUsers(type) {
    //remove selected items
    setSelectedRows([]);
    setFilterAccounts(type);
    //
  }

  function switchOptions(option_type) {
    setBlocking(false);
    setUnblocking(false);
    setDeleting(false);
    setFilterPage(0);
    switch (option_type) {
      case "blocking":
        setBlocking(true);
        break;

      case "unblocking":
        setUnblocking(true);
        break;

      case "deleting":
        setDeleting(true);
        break;

      default:
        break;
    }
  }

  function search() {
    setApiWorking(true);
    setFilterPage(0);
    window.location.href = `/users?search=${searchTerm}`;
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      search();
    }
  }

  //VIEW
  return (
    <div className="users-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="globalusers">
        <div>
          Users (<strong>{total_users_number}</strong>)
        </div>
        <div className="search-wrapper">
          <input
            type="text"
            placeholder="Search by title"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button type="button" onClick={() => search()}>
            {isApiWorking ? (
              <i className={apiFaIcon} />
            ) : (
              <i className="fa fa-search"></i>
            )}
          </button>
        </div>
        <div className="button-wrapper">
          <button className="refresh-btn" onClick={() => refreshRows()}>
            {isApiWorking ? (
              <i className={apiFaIcon} />
            ) : (
              <i className="fa fa-refresh" />
            )}
          </button>
          <label className="checkboxContainer">
            <input
              type="checkbox"
              id="Blocked"
              checked={filterAccounts === "blocked"}
              onChange={() => checkAndLoadUsers("blocked")}
            />
            Blocked({blocked_users_number})
          </label>

          <label className="checkboxContainer">
            <input
              type="checkbox"
              id="Verified"
              checked={filterAccounts === "verified"}
              onChange={() => checkAndLoadUsers("verified")}
            />
            Verified({verified_users_number})
          </label>

          <label className="checkboxContainer">
            <input
              type="checkbox"
              id="Selected"
              checked={selectedRows.length !== 0}
              onChange={() => setSelectedRows([])}
            />
            Selected({selectedRows.length})
          </label>
          <select
            className="paginationSelect"
            value={selectedPagination}
            onChange={handlePaginationChange}
          >
            {paginationArray.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            className="delete-emails"
            onClick={() => switchOptions("blocking")}
            disabled={selectedRows.length === 0}
          >
            Block
          </button>
          <button
            className="delete-emails"
            onClick={() => switchOptions("unblocking")}
            disabled={selectedRows.length === 0}
          >
            Unblock
          </button>
          <button
            className="delete-users"
            onClick={() => switchOptions("deleting")}
            disabled={selectedRows.length === 0}
          >
            Delete
          </button>
        </div>
        <div className="options">
          {blocking && (
            <BlockUsers
              selectedRows={selectedRows}
              user={props.user}
              refresh={search}
            />
          )}
          {unblocking && (
            <UnblockUsers
              selectedRows={selectedRows}
              user={props.user}
              refresh={search}
            />
          )}
          {deleting && (
            <DeleteUsers
              selectedRows={selectedRows}
              user={props.user}
              refresh={search}
            />
          )}
        </div>
        <div className="pagination-wrapper">
          <Pagination
            items={filterArray}
            pageSize={selectedPagination}
            currentPage={getCurrentPage()}
            onChangePage={(index) => onChangePage(index)}
          />
        </div>
        <div className="globalusers-table">
          <table>
            <thead>
              <tr>
                <th></th>
                {/* <th>user_id</th> */}
                <th onClick={() => toggleSortDirection("username")}>
                  username
                  {sortField === "username" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("name")}>
                  name
                  {sortField === "name" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("usertype")}>
                  usertype
                  {sortField === "usertype" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("account_status")}>
                  account_status
                  {sortField === "account_status" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("created_at")}>
                  created_at
                  {sortField === "created_at" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("genres")}>
                  genres
                  {sortField === "genres" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("online")}>
                  online
                  {sortField === "online" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((result, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() => handleRowSelection(result.user_id)}
                      checked={selectedRows.includes(result.user_id)}
                    />
                  </td>
                  {/* <td>{result.user_id}</td> */}
                  <td>
                    <a href={`/@/${result.username}`} target="_blank">
                      {highlightText(result.username, searchTerm)}
                    </a>
                  </td>
                  <td> {highlightText(result.name, searchTerm)}</td>
                  <td>{typeToString(result.usertype)}</td>
                  <td>{statusToString(result.account_status)}</td>
                  <td>{result.created_at}</td>
                  <td>{result.genres}</td>
                  <td>{onlineToString(result.is_online)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isApiWorking && (
          <button className="api-emails" disabled={true}>
            <i className={apiFaIcon}></i>
          </button>
        )}
        {!isApiWorking && isEmpty(filteredData) && (
          <div className="empty-tabledata">
            <i className="fa fa-user" aria-hidden="true"></i> No records found
          </div>
        )}
        <div className="pagination-wrapper">
          <Pagination
            items={filterArray}
            pageSize={selectedPagination}
            currentPage={getCurrentPage()}
            onChangePage={(index) => onChangePage(index)}
          />
        </div>
      </div>
    </div>
  );

  function typeToString(type) {
    switch (type) {
      case 0:
        return "standard";
      case 1:
        return "artist";
      case 2:
        return "label";
      default:
        return "unknown";
    }
  }

  function statusToString(account_status) {
    switch (account_status) {
      case "verified":
        return (
          <p style={{ color: "green" }}>
            <i className="fa fa-check-circle" /> {account_status}
          </p>
        );
      case "blocked":
        return (
          <p style={{ color: "red" }}>
            <i className="fa fa-ban" /> {account_status}
          </p>
        );
      case 2:
        return "label";
      default:
        return "unknown";
    }
  }

  function onlineToString(is_online) {
    switch (is_online) {
      case 1:
        return (
          <p style={{ color: "green" }}>
            <i className="fa fa-circle" /> Online
          </p>
        );
      case 0:
        return (
          <p style={{ color: "darkgray" }}>
            <i className="fa fa-circle" /> Offline
          </p>
        );
      default:
        return "unknown";
    }
  }
}

export default GlobalUsers;
