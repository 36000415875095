import React, { useEffect, useState, useContext } from "react";
import isEmpty from "../../../../components/isEmpty";
import Pagination from "../../../../components/Pagination/Pagination";
import URLParser from "../../../../components/URLParser";
import { getSession } from "../../../../utils/apis/Accounts";
import { getUserActivityHistory } from "../../../../utils/apis/api/auth_api_user";

//CSS
import "./LastActivity.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const apiFaEnabled = "fa fa-check-square";
const faWarning = "fa fa-exclamation-triangle";
const faDown = "fa fa-arrow-down";
const faUp = "fa fa-arrow-up";

const filterPageItem = { id: 1, name: "Item " + 1 };

//MFA
function LastActivity(props) {
  //API
  const [isApiWorking, setIsApiWorking] = useState(false);
  //
  const [tableData, setTableData] = useState([]);

  //pagingation
  const [page, setPage] = useState(0);
  const [isDateAscending, setDateAscending] = useState(true);
  const [filterStart, setFilterPage] = useState(0);
  const [filterLimit, setFilterLimit] = useState(50);
  const [filterType, setFilterType] = useState(0);
  const [filterArray, setFilterArray] = useState([filterPageItem]);

  //ViewDidLoad
  useEffect(() => {
    //GET_LAST_ACTIVITY_LIST
    getUserAHistory(1);
  }, []);

  //GET_MFA_QR
  const getUserAHistory = async (page) => {
    setIsApiWorking(false);
    try {
      //get user details
      const result = await getUserActivityHistory(page);
      //✅SUCCESS
      setIsApiWorking(false);
      console.log(result);
      //🚫ERROR
    } catch (error) {
      console.log("USER_GET_ACTIVITY_ERROR", error.message);
      setIsApiWorking(false);
    }
  };

  //VIEW
  return (
    <div className="last_activity">
      <div className="title">Last Activity</div>
      <div className="table">
        <div className="table-header">
          <div className="table-hitem">Action</div>
          <div className="table-hitem">IP Address</div>
          <div className="table-hitem">
            <button onClick={() => setDateAscending(!isDateAscending)}>
              <i className={isDateAscending ? faDown : faUp} /> Time
            </button>
          </div>
        </div>
        <div className="table-body">
          {/* API_WORKING */}
          {isApiWorking && (
            <button type="table-api">
              <i className={apiFaIcon} />
            </button>
          )}
          {/* API_DONE */}
          {!isApiWorking && buildTableData()}
          {!isApiWorking && (
            <div className="table-page">
              <Pagination
                items={filterArray}
                pageSize={filterLimit}
                currentPage={getCurrentPage()}
                onChangePage={(index) => onChangePage(index)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  //BUILD: pagedata
  function buildTableData() {
    var _tableData = [];
    if (isEmpty(tableData)) {
      return _tableData;
    }
    tableData.forEach((item, index) => {
      const tableRow = (
        <div key={index} className="linkitem">
          <div className="action">{item.action}</div>
          <div className="ip_address">{item.ip_address}</div>
          <div className="date">{item.date}</div>
        </div>
      );
      _tableData.push(tableRow);
    });
    return _tableData;
  }

  //GET: /forum/last_categories?p={currentPaginationNumber}
  function getCurrentPage() {
    let url_paths = URLParser();
    var page = url_paths["page"] || 1;
    return Math.max(1, parseInt(page));
  }

  //CHANGE: FILTER
  function changeFilterType(filterType) {
    setFilterType(filterType);
    const urlPath = `/forum/last_categories?page=${filterStart}&limit=${filterLimit}&filter=${filterType}`;
  }

  //CHANGE: Pagination PageNumber
  function onChangePage(currentPage) {
    const urlPath = `/forum/last_categories?page=${currentPage}&limit=${filterLimit}&filter=${filterType}`;
    window.location.href = urlPath;
  }
}

export default LastActivity;
