import React, { Component } from "react";
//CSS
import "./APlayer.css";

class APlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      duration: 0,
      currentTime: 0,
      volume: this.props.volume || 0.5,
      isPlaying: false,
      tintColor: this.props.tintColor,
      loading: true,
    };

    this.audio = null; // initiates the audio here

    this.handlePlayPause = this.handlePlayPause.bind(this);
    this.handleVolumeChange = this.handleVolumeChange.bind(this);
    this.handleSeekChange = this.handleSeekChange.bind(this);
    this.formatTime = this.formatTime.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
  }

  componentDidMount() {
    this.initializeAudio(this.props.src); // Initialize Audio here
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.audio.pause(); // pause current instance
      this.setState({ loading: true });
      this.initializeAudio(this.props.src); // Initialize New Audio Source
      this.handlePlay();
    }
  }

  initializeAudio(audioSrc) {
    this.audio = new Audio(audioSrc);
    this.audio.volume = this.state.volume; // sets volume to initial value
    this.audio.autoplay = this.props.autoplay;

    this.audio.addEventListener("loadedmetadata", () => {
      if (this.props.autoplay) {
        this.handlePlay();
      }

      this.setState({
        duration: Math.round(this.audio.duration),
        isPlaying: this.props.autoplay,
        loading: false,
      });
    });

    this.audio.addEventListener("timeupdate", () => {
      this.setState({ currentTime: Math.round(this.audio.currentTime) });
    });

    this.audio.addEventListener("error", () => {
      this.setState({ error: true, isPlaying: false, loading: false });
    });

    return this.audio;
  }

  componentWillUnmount() {
    this.audio.pause();
    this.audio.removeAttribute("src");
    this.audio.load();

    this.audio.removeEventListener("error", () => {});
  }

  handlePlay() {
    this.audio.play().catch((error) => console.error(error));
    this.setState({ isPlaying: true });
  }

  handlePause() {
    this.audio.pause();
    this.setState({ isPlaying: false });
  }

  handlePlayPause() {
    const { isPlaying } = this.state;

    if (isPlaying) {
      this.handlePause();
    } else {
      this.handlePlay();
    }
  }

  handleVolumeChange(e) {
    const value = parseFloat(e);

    if (!isNaN(value) && value >= 0 && value <= 1) {
      this.audio.volume = value;
      this.setState({ volume: value });
    }
    this.props.setVolume(value);
  }

  handleSeekChange(e) {
    this.audio.currentTime = e.target.value;
    this.setState({ currentTime: Math.round(this.audio.currentTime) });
  }

  handleRetry() {
    this.audio.pause();
    this.audio.currentTime = 0;
    this.audio.load();
    this.setState({ error: false, loading: true });
    this.handlePlay();
  }

  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);

    if (seconds === Infinity || isNaN(seconds)) {
      return "LIVE"; // Return LIVE if seconds is NaN or Infinity
    }

    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  render() {
    const { isPlaying, currentTime, duration, error, tintColor, loading } =
      this.state;

    return (
      <div className="audio-player">
        <div className="controls">
          <div className="playback">
            {loading ? ( // Show spinner when audio is loading
              <button style={{ backgroundColor: tintColor }}>
                <i className="fa fa-refresh fa-spin" />
              </button>
            ) : error ? ( // Error
              <div>
                <button
                  onClick={this.handleRetry}
                  style={{ backgroundColor: tintColor }}
                >
                  <i className="fa fa-exclamation-triangle" />
                </button>
              </div>
            ) : (
              //Play/Pause
              <button
                onClick={this.handlePlayPause}
                style={{ backgroundColor: tintColor }}
              >
                {isPlaying ? (
                  <i className="fa fa-pause" />
                ) : (
                  <i className="fa fa-play" />
                )}
              </button>
            )}
          </div>
          <div className="timer">
            <span className="current-time">{this.formatTime(currentTime)}</span>
            <input
              type="range"
              min="0"
              max={duration}
              step="0.01"
              value={currentTime}
              onChange={this.handleSeekChange}
            />
            <span className="current-duration">
              {this.formatTime(duration)}
            </span>
          </div>

          <div className="volume">
            <i
              onClick={() =>
                this.handleVolumeChange(this.state.volume == 0 ? 0.5 : 0.0)
              }
              className={
                this.state.volume > 0 ? `fa fa-volume-up` : `fa fa-volume-off`
              }
            />
            <input
              id="volume_range"
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={this.state.volume}
              onChange={(e) => this.handleVolumeChange(e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default APlayer;
