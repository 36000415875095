import React, { useState, useEffect } from "react";
import useLocalStorage from "../../../components/useLocalStorage";
import isEmpty from "../../../components/isEmpty";
import fs from "fs";
//JS
import NavBar from "../../../components/Navbar/NavBar";
import EmailTopNavBar from "./EmailTopNavBar";
//
import Audience from "./Audience/Audience";
import Campaigns from "./Campaigns/Campaigns";
import Automations from "./Automations/Automations";
import BulkEmailSender from "./Send/BulkEmailSender";
import TestEmailSender from "./Send/TestEmailSender";
import EPKLoader from "./EPK/EPKLoader";
import CreateEPK from "./EPK/CreateEPK";
//CSS
import "./Email.css";

//fa
const faFaq = "fa fa-question-circle";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//Email class
function Email(props) {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  const [page_title, setPageTitle] = useLocalStorage("current_page", "epk");

  const [selected_email_html, setSelectedEmailHTML] = useLocalStorage(
    "html_email_loaded",
    null
  );
  //
  //ViewDidLoad
  useEffect(() => {
    const filePath = "./investor.html";

    const fetchHtmlFile = async () => {
      try {
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch HTML file (${response.status} ${response.statusText})`
          );
        }
        const htmlContent = await response.text();
        console.log(htmlContent);
        setSelectedEmailHTML(htmlContent);
      } catch (error) {
        console.error("Error fetching or reading HTML file:", error);
      }
    };

    fetchHtmlFile();
  }, []); // Empty dependency array ensures that the effect runs only once after the initial render

  //

  //
  //VIEW
  return (
    <div className="email-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className=""></div>
      <div className="email-content">
        <EmailTopNavBar
          switchToView={switchToView}
          page_title={page_title}
          isApiWorking={isApiWorking}
        />
        {page_title === "audience" && <Audience user={props.user} />}
        {page_title === "automations" && <Automations user={props.user} />}
        {page_title === "campaigns" && <Campaigns user={props.user} />}
        {/* {page_title === "epk" && (
          <EPKLoader
            showBrowser={true}
            selected_email_html={selected_email_html}
            setSelectedEmailHTML={setSelectedEmailHTML}
          />
        )} */}
        {page_title === "create" && (
          <CreateEPK
            user={props.user}
            setApiWorking={setApiWorking}
            selected_email_html={selected_email_html}
            setSelectedEmailHTML={setSelectedEmailHTML}
          />
        )}
        {page_title === "test" && (
          <TestEmailSender
            user={props.user}
            selected_email_html={selected_email_html}
            setSelectedEmailHTML={setSelectedEmailHTML}
          />
        )}
        {page_title === "send" && (
          <BulkEmailSender
            user={props.user}
            selected_email_html={selected_email_html}
            setSelectedEmailHTML={setSelectedEmailHTML}
          />
        )}
      </div>
    </div>
  );

  function switchToView(view) {
    switch (view) {
      case "audience":
        setPageTitle("audience");
        break;
      case "campaigns":
        setPageTitle("campaigns");
        break;
      case "automations":
        setPageTitle("automations");
        break;
      case "epk":
        setPageTitle("epk");
        break;
      case "create":
        setPageTitle("create");
        break;
      case "test":
        setPageTitle("test");
        break;
      case "send":
        setPageTitle("send");
        break;
      default:
        break;
    }
  }
}

export default Email;
