import { getIDToken, getUserID } from "../auth";
import axios from "axios";


export const getSystemMessages = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_system_msgs(userId, tokens["IDToken"]);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_system_msgs = async (user_id, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/system_messages/get_msgs" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .get(URL, { headers }) 
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const createSystemMessages = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await create_system_msg(userId, tokens["IDToken"],bodyParameters);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const create_system_msg = async (user_id, token,bodyParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/system_messages/create_msg" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers }) 
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const deleteSystemMessages = async (queryParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await delete_system_msg(userId, tokens["IDToken"],queryParameters);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const delete_system_msg = async (user_id, token, queryParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/system_messages/delete_msg" + "?" + `user_id=${user_id}` + "&" + queryParameters;
    console.log(URL)
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, null, { headers }) 
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}