// 1. Importing the SDK
import AWS from "aws-sdk";

const BUCKET = process.env.REACT_APP_DO_BUCKET;
const REGION = process.env.REACT_APP_DO_REGION;
const ACCESS_KEY_ID = process.env.REACT_APP_DO_IAM;
const SECRET_ACCESS_KEY = process.env.REACT_APP_DO_IAM_SECRET;

// 2. Configuring the S3 instance for Digital Ocean Spaces
const spacesEndpoint = new AWS.Endpoint(`${REGION}.digitaloceanspaces.com`);
const S3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: ACCESS_KEY_ID,
  secretAccessKey: SECRET_ACCESS_KEY,
});

export function uploadFileToS3(file, path, fileName) {
  let notify;
  const params = {
    Bucket: BUCKET,
    Key: path + "/" + fileName,
    Body: file,
  };
  let promise = new Promise(async (resolve, reject) => {
    S3.putObject(params)
      .on("build", (request) => {
        request.httpRequest.headers.Host = `https://${BUCKET}.${REGION}.digitaloceanspaces.com`;
        request.httpRequest.headers["Content-Length"] = file.size;
        request.httpRequest.headers["Content-Type"] = file.mimetype;
        request.httpRequest.headers["x-amz-acl"] = "private";
      })
      .on("httpUploadProgress", function (_progress) {
        notify(_progress.loaded / _progress.total);
      })
      .send((err, data) => {
        if (err) {
          console.log("S3_UPLOAD_FAILED");
          return reject(err);
        }
        if (data) {
          const url = `https://${BUCKET}.${REGION}.digitaloceanspaces.com/${path}/${fileName}`;
          console.log("S3_UPLOAD_SUCCESS");
          resolve(url);
        }
      });
  });
  // here is the monkey patch
  promise.progress = (handler) => {
    notify = handler;
    return promise;
  };
  return promise;
}
