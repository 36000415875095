import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import { listAllAlbums } from "../../../utils/apis/api/auth_api_albums";
import Pagination from "../../../components/Pagination/Pagination";
import URLParser from "../../../components/URLParser";
import useLocalStorage from "../../../components/useLocalStorage";
//CSS
import "./Albums.css";
//JS
import NavBar from "../../../components/Navbar/NavBar";
import BlockAlbums from "./BlockAlbums";
import UnblockAlbums from "./UnblockAlbums";
import DeleteAlbums from "./DeleteAlbums";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://admin.songdrop.band/albums
//props
function Albums(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //OPTIONS
  const [blocking, setBlocking] = useState(false);
  const [unblocking, setUnblocking] = useState(false);
  const [deleting, setDeleting] = useState(false);

  //albums
  const [albums, setAlbums] = useState([]);
  const [total_albums_number, setTotalAlbumsNumber] = useState(0);
  const [draft_albums_number, setDraftlbumsNumber] = useState(0);
  const [onsale_albums_number, setOnSaleAlbumsNumber] = useState(0);
  const [offsale_albums_number, setOffSaleAlbumsNumber] = useState(0);
  const [blocked_albums_number, setBlockedAlbumsNumber] = useState(0);

  const [showImages, setShowImages] = useState(false);

  const [atypes, setATypes] = useState([
    "draft",
    "onsale",
    "offsale",
    "blocked",
  ]);

  //SELECTED_USER_IDS_TO_BLOCK
  const [selectedRows, setSelectedRows] = useState([]);
  const [api_search, setApiSearch] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  //API_PAGINATION
  const [filterAccounts, setFilterAccounts] = useState("");
  const [filterStart, setFilterPage] = useState(0);
  const [filterArray, setFilterArray] = useState([
    { id: 1, name: "Item " + 1 },
  ]);

  //This handle how many item returned/page
  const [selectedPagination, setSelectedPagination] = useLocalStorage(
    "pagination_limit_users",
    20
  ); // Initially selected value
  const [paginationArray, setPaginationArray] = useState([
    20, 50, 100, 200, 500, 1000, 5000, 10000,
  ]);
  const handlePaginationChange = (e) => {
    setSelectedPagination(parseInt(e.target.value, 10)); // Parse the selected value to an integer
    // Perform actions based on the selected pagination value (e.g., fetching data)
  };

  //
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Albums | Admin SongDrop";
    let url_paths = URLParser();
    var search = url_paths["search"] || "";
    setSearchTerm(search);
    //api
    getAlbumsAPI(search);
  }, [filterAccounts, selectedPagination]);

  //API: getUsersAPI
  async function getAlbumsAPI(api_search) {
    setApiWorking(true);

    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`page_offset=${filterStart}`);
      bodyParams.push(`page_limit=${selectedPagination}`);
      bodyParams.push(`album_status=${filterAccounts}`);
      bodyParams.push(`search_term=${api_search || ""}`);
      const bParameters = bodyParams.join("&");
      //
      const result = await listAllAlbums(bParameters);
      //✅SUCCESS
      setAlbums(result["albums"]);
      setTotalAlbumsNumber(result["metrics"]["all_albums_number"]);
      setDraftlbumsNumber(result["metrics"]["draft_albums_number"]);
      setOnSaleAlbumsNumber(result["metrics"]["onsale_albums_number"]);
      setOffSaleAlbumsNumber(result["metrics"]["offsale_albums_number"]);
      setBlockedAlbumsNumber(result["metrics"]["blocked_albums_number"]);

      console.log("GET_ALBUMS", result);

      //
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      console.log(error);
      setApiWorking(false);
    }
  }

  const toggleSortDirection = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedData = [...albums];

  if (sortField) {
    sortedData.sort((a, b) => {
      const aValue = a[sortField]; //.toLowerCase();
      const bValue = b[sortField]; //.toLowerCase();

      if (aValue < bValue) {
        return sortDirection === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  const filteredData = sortedData.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.artist.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const highlightText = (text, highlight) => {
    if (!highlight) return text;

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, i) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <mark key={i}>{part}</mark>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  //SELECT ROWS TO DELETE
  const handleRowSelection = (user_id) => {
    if (selectedRows.includes(user_id)) {
      setSelectedRows(selectedRows.filter((row) => row !== user_id));
    } else {
      setSelectedRows([...selectedRows, user_id]);
    }
  };

  const refreshRows = () => {
    setFilterAccounts("none");
    //
  };

  //CHANGE: Pagination PageNumber
  function onChangePage(currentPage) {}

  //GET: /fglobal_userss?page={currentPaginationNumber}
  function getCurrentPage() {
    // let url_paths = URLParser();
    // var page = url_paths["page"] || 1;
    return Math.max(1, parseInt(filterStart));
  }

  function checkAndLoadAlbums(type) {
    //remove selected items
    setSelectedRows([]);
    setFilterAccounts(type);
    //
  }

  function switchOptions(option_type) {
    setBlocking(false);
    setUnblocking(false);
    setDeleting(false);
    switch (option_type) {
      case "blocking":
        setBlocking(true);
        break;

      case "unblocking":
        setUnblocking(true);
        break;

      case "deleting":
        setDeleting(true);
        break;

      default:
        break;
    }
  }

  function search() {
    setApiWorking(true);
    window.location.href = `/albums?search=${searchTerm}`;
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      search();
    }
  }

  //VIEW
  return (
    <div className="albums-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="globalalbums">
        <div>
          Albums (
          <strong>
            {albums.length} | {total_albums_number}
          </strong>
          )
        </div>
        <div className="search-wrapper">
          <input
            type="text"
            placeholder="Search by title"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button type="button" onClick={() => search()}>
            {isApiWorking ? (
              <i className={apiFaIcon} />
            ) : (
              <i className="fa fa-search"></i>
            )}
          </button>
        </div>
        <div className="button-wrapper">
          <button className="refresh-btn" onClick={() => refreshRows()}>
            {isApiWorking ? (
              <i className={apiFaIcon} />
            ) : (
              <i className="fa fa-refresh" />
            )}
          </button>
          <span
            className="checkboxContainer"
            style={{
              textDecoration:
                filterAccounts !== "draft" ? "line-through" : "none",
            }}
          >
            <input
              type="checkbox"
              id="Draft"
              checked={filterAccounts === "draft"}
              onChange={() => checkAndLoadAlbums("draft")}
            />
            Draft({draft_albums_number})
          </span>

          <label className="checkboxContainer">
            <input
              type="checkbox"
              id="onsale"
              checked={filterAccounts === "onsale"}
              onChange={() => checkAndLoadAlbums("onsale")}
            />
            On Sale({onsale_albums_number})
          </label>

          <label className="checkboxContainer">
            <input
              type="checkbox"
              id="offsale"
              checked={filterAccounts === "offsale"}
              onChange={() => checkAndLoadAlbums("offsale")}
            />
            Off Sale({offsale_albums_number})
          </label>

          <label className="checkboxContainer">
            <input
              type="checkbox"
              id="blocked"
              checked={filterAccounts === "blocked"}
              onChange={() => checkAndLoadAlbums("blocked")}
            />
            Blocked({blocked_albums_number})
          </label>

          <label className="checkboxContainer">
            <input
              type="checkbox"
              id="Selected"
              checked={selectedRows.length !== 0}
              onChange={() => setSelectedRows([])}
            />
            Selected({selectedRows.length})
          </label>
          <select
            className="paginationSelect"
            value={selectedPagination}
            onChange={handlePaginationChange}
          >
            {paginationArray.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            className="delete-emails"
            onClick={() => switchOptions("blocking")}
            disabled={selectedRows.length === 0}
          >
            Block
          </button>
          <button
            className="delete-emails"
            onClick={() => switchOptions("unblocking")}
            disabled={selectedRows.length === 0}
          >
            Unblock
          </button>
          <button
            className="delete-users"
            onClick={() => switchOptions("deleting")}
            disabled={selectedRows.length === 0}
          >
            Delete
          </button>
        </div>
        <div className="options">
          {blocking && (
            <BlockAlbums
              selectedRows={selectedRows}
              user={props.user}
              refresh={search}
            />
          )}
          {unblocking && (
            <UnblockAlbums
              selectedRows={selectedRows}
              user={props.user}
              refresh={search}
            />
          )}
          {deleting && (
            <DeleteAlbums
              selectedRows={selectedRows}
              user={props.user}
              refresh={search}
            />
          )}
        </div>
        <div className="pagination-wrapper">
          <Pagination
            items={filterArray}
            pageSize={selectedPagination}
            currentPage={getCurrentPage()}
            onChangePage={(index) => onChangePage(index)}
          />
        </div>
        <div className="globalalbums-table">
          <table>
            <thead>
              <tr>
                <th></th>
                <th onClick={() => toggleSortDirection("tag")}>
                  New
                  {sortField === "tag" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => setShowImages(!showImages)}>
                  <i className="fa fa-picture-o" />{" "}
                  <i class="fa fa-arrows-h" aria-hidden="true"></i>
                </th>
                <th onClick={() => toggleSortDirection("title")}>
                  Title
                  {sortField === "title" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("artist")}>
                  Artist
                  {sortField === "artist" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("album_type")}>
                  Type
                  {sortField === "album_type" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("album_status")}>
                  Status
                  {sortField === "album_status" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("created_at")}>
                  Created At
                  {sortField === "created_at" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("a_genres")}>
                  Genres
                  {sortField === "a_genres" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => toggleSortDirection("track_count")}>
                  Tracks
                  {sortField === "track_count" && (
                    <span className="sort-indicator">
                      <i
                        className={`fa fa-caret-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((result, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() => handleRowSelection(result.album_id)}
                      checked={selectedRows.includes(result.album_id)}
                    />
                  </td>
                  <td>{result.tag}</td>
                  {!showImages && (
                    <td>
                      {!isEmpty(result.cover_img_url) && (
                        <i className="fa fa-picture-o" />
                      )}
                    </td>
                  )}

                  {showImages && (
                    <td>
                      <img
                        className="cimage"
                        alt=""
                        src={
                          result.cover_img_url ||
                          "https://cdn.songdrop.cloud/docs/album_placeholder.png"
                        }
                      />
                    </td>
                  )}
                  {/* <td>
                    {showImages && (
                      <img
                        className="cimage"
                        alt=""
                        src={
                          result.cover_img_url ||
                          "https://cdn.songdrop.cloud/docs/album_placeholder.png"
                        }
                      />
                    )}
                  </td> */}
                  <td>
                    <a
                      href={`https://songdrop.uk/qr/${result.album_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {highlightText(result.title, searchTerm)}
                    </a>
                  </td>
                  <td> {highlightText(result.artist, searchTerm)}</td>
                  <td>{typeToString(result.album_type)}</td>
                  <td>{statusToString(result.album_status)}</td>
                  <td>{result.created_at}</td>
                  <td>{result.a_genres}</td>
                  <td className="track_ids">{result.track_count} tracks</td>
                  <td className="track_ids">
                    <a
                      href={`/qr/${result.album_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Order ED
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isApiWorking && (
          <button className="api-emails" disabled={true}>
            <i className={apiFaIcon}></i>
          </button>
        )}
        {!isApiWorking && isEmpty(filteredData) && (
          <div className="empty-tabledata">
            <i className="fa fa-music" aria-hidden="true"></i> No records found
          </div>
        )}
        <div className="pagination-wrapper">
          <Pagination
            items={filterArray}
            pageSize={selectedPagination}
            currentPage={getCurrentPage()}
            onChangePage={(index) => onChangePage(index)}
          />
        </div>
      </div>
    </div>
  );

  function typeToString(type) {
    switch (type) {
      case 0:
        return "Album";
      case 1:
        return "EP";
      case 2:
        return "Single";
      default:
        return "unknown";
    }
  }

  function statusToString(album_status) {
    switch (album_status) {
      case 0:
        return (
          <p style={{ color: "darkgrey" }}>
            <i className="fa fa-edit" /> DRAFT
          </p>
        );
      case 1:
        return (
          <p style={{ color: "green" }}>
            <i className="fa fa-check-circle" /> ON SALE
          </p>
        );
      case 2:
        return (
          <p style={{ color: "orange" }}>
            <i className="fa fa-square-o" /> OFF SALE
          </p>
        );
      case 3:
        return (
          <p style={{ color: "red" }}>
            <i className="fa fa-ban" /> BLOCKED
          </p>
        );

      default:
        return "unknown";
    }
  }
}

export default Albums;
