import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import NavBar from "../../../components/Navbar/NavBar";

import {
  getSystemMessages,
  createSystemMessages,
  deleteSystemMessages,
} from "../../../utils/apis/api/auth_api_system";
//CSS
import "./SystemMessages.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
//JS

//LINK
//✅logged in
//https://admin.songdrop.band

//Dashboard
function SystemMessages(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  const [isFormVisible, setFormVisible] = useState(false);

  const [application, setApplication] = useState(0);
  const [username, setUsername] = useState("songdropofficial");
  const [message, setMessage] = useState("");

  const [band_messages, setBandMessages] = useState([]);
  const [ios_messages, setiOSMessages] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "System Messages | Admin SongDrop";
    //api
    getData();
  }, []);

  //API
  async function getData() {
    setApiWorking(true);
    setApiError("");

    try {
      let result = await getSystemMessages();
      let band_messages = result["band_messages"];
      let ios_messages = result["ios_messages"];
      setApiWorking(false);
      setBandMessages(band_messages);
      setiOSMessages(ios_messages);
    } catch (error) {
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setApiWorking(true);
    setApiError("");

    try {
      var bodyParams = [];
      bodyParams.push(`application=${application}`);
      bodyParams.push(`username=${username}`);
      bodyParams.push(`text=${message}`);
      const bParameters = bodyParams.join("&");
      console.log(bParameters);
      let result = await createSystemMessages(bParameters);
      console.log(result);
      setApiWorking(false);
      setMessage("");
      setFormVisible(false);
      //get data again
      getData();
    } catch (error) {
      console.log(error);
      setApiWorking(false);
      setApiError(error.message);
      alert(error.message);
    }
  };

  const handleDelete = async (message_id, type) => {
    setApiWorking(true);
    setApiError("");

    try {
      var queryParams = [];
      queryParams.push(`user_id=${props.user.user_id}`);
      queryParams.push(`msg_id=${message_id}`);
      queryParams.push(`type=${type}`);
      const qParameters = queryParams.join("&");
      let result = await deleteSystemMessages(qParameters);
      console.log(result);
      setApiWorking(false);
      //get data again
      getData();
    } catch (error) {
      setApiWorking(false);
      setApiError(error.message);
    }
  };

  //VIEW
  return (
    <div className="sys-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="sys-content">
        <div className="header">
          <p className="title-msg">Create/Delete Global System Messages.</p>
          <p>Messages for Band website will be displayed on the Dashboard.</p>
          <p>Messages for iOS will be displayed in the app.</p>
          <button onClick={() => setFormVisible(!isFormVisible)}>
            +Create New Message
          </button>
        </div>
        {isFormVisible && (
          <form className="form" onSubmit={(e) => handleSubmit(e)}>
            <p>Send message to global users</p>
            <select onSelect={(e) => setApplication(e.target.value)}>
              <option value={0}>Band website</option>
              <option value={1}>iOS</option>
            </select>
            <input
              placeholder="Username"
              value={username}
              disabled={true}
              onChange={(e) => setUsername(e.target.value)}
            />
            <textarea
              placeholder="Global message"
              onChange={(e) => setMessage(e.target.value)}
            />
            <div>
              Note: Submit only important messages, like system updates,
              official announcements etc.
            </div>
            {isApiWorking && (
              <button disabled={true}>
                <i className={apiFaIcon} />
              </button>
            )}
            {!isApiWorking && <button>Send Message</button>}
          </form>
        )}
        <br />
        {isApiWorking && (
          <div className="stat-load">
            <div className="stat-loader">
              <i className={apiFaIcon} />
            </div>
          </div>
        )}
        {(!isEmpty(band_messages) || !isEmpty(ios_messages)) && (
          <div className="messages">
            <div className="title-msg">Last System Messages:</div>
            <div className="">
              <strong>Band messages:</strong>
              {isEmpty(band_messages) && <p>No messages</p>}
              {!isEmpty(band_messages) && <div>{buildBandMsgItemRows()}</div>}
            </div>
            <div className="">
              <strong>iOS messages:</strong>
              {isEmpty(ios_messages) && <p>No messages</p>}
              {!isEmpty(ios_messages) && <div>{buildiOSMsgItemRows()}</div>}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  //BUILD: Dev message items
  function buildBandMsgItemRows() {
    var tabs = [];
    if (isEmpty(band_messages)) {
      return tabs;
    }
    band_messages.forEach((message, index) => {
      tabs.push(
        <div key={index} className="message-wrapper">
          <div className="message">
            <div className="title">{message.username}</div>
            <div className="text">{message.text}</div>
            <div className="timestamp">{convertDate(message.updated_at)}</div>
            {isApiWorking && (
              <button disabled={true}>
                <i className={apiFaIcon} />
              </button>
            )}
            {!isApiWorking && (
              <button onClick={() => deleteBandMsgWithId(message.id)}>
                Delete
              </button>
            )}
          </div>
        </div>
      );
    });
    return tabs;
  }

  //BUILD: Dev message items
  function buildiOSMsgItemRows() {
    var tabs = [];
    if (isEmpty(ios_messages)) {
      return tabs;
    }
    ios_messages.forEach((message, index) => {
      tabs.push(
        <div key={index} className="message-wrapper">
          <div className="message">
            <div className="title">{message.username}</div>
            <div className="text">{message.text}</div>
            <div className="timestamp">{convertDate(message.updated_at)}</div>
            {isApiWorking && (
              <button disabled={true}>
                <i className={apiFaIcon} />
              </button>
            )}
            {!isApiWorking && (
              <button onClick={() => deleteiOSMsgWithId(message.id)}>
                Delete
              </button>
            )}
          </div>
        </div>
      );
    });
    return tabs;
  }

  function deleteBandMsgWithId(message_id) {
    if (window.confirm("Are you sure you want to delete this message?")) {
      handleDelete(message_id, "band");
    }
  }

  function deleteiOSMsgWithId(message_id) {
    if (window.confirm("Are you sure you want to delete this message?")) {
      handleDelete(message_id, "ios");
    }
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default SystemMessages;
