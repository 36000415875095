import React, { useEffect, useState } from "react";
import ReactLinkify from "react-linkify";

//CSS
import "./DPMTables.css";
import { isElement, isEmpty } from "lodash";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//DPMTables
function DPMTables(props) {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    // Simulate an API call here and set the data
  }, []);

  const toggleSortDirection = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedData = [...props.tableData];

  if (sortField) {
    sortedData.sort((a, b) => {
      const aValue = a[sortField].toLowerCase();
      const bValue = b[sortField].toLowerCase();

      if (aValue < bValue) {
        return sortDirection === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  const filteredData = sortedData.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const highlightText = (text, highlight) => {
    if (!highlight) return text;

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, i) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <mark key={i}>{part}</mark>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <div className="dpm-tables">
      <input
        type="text"
        placeholder="Search by title"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <table>
        <thead>
          <tr>
            <th onClick={() => toggleSortDirection("title")}>
              Title
              {sortField === "title" && (
                <span className="sort-indicator">
                  <i
                    className={`fa fa-caret-${
                      sortDirection === "asc" ? "up" : "down"
                    }`}
                    aria-hidden="true"
                  />
                </span>
              )}
            </th>
            <th onClick={() => toggleSortDirection("admin")}>
              Uploader
              {sortField === "admin" && (
                <span className="sort-indicator">
                  <i
                    className={`fa fa-caret-${
                      sortDirection === "asc" ? "up" : "down"
                    }`}
                    aria-hidden="true"
                  />
                </span>
              )}
            </th>
            <th onClick={() => toggleSortDirection("version")}>
              Version
              {sortField === "version" && (
                <span className="sort-indicator">
                  <i
                    className={`fa fa-caret-${
                      sortDirection === "asc" ? "up" : "down"
                    }`}
                    aria-hidden="true"
                  />
                </span>
              )}
            </th>
            <th onClick={() => toggleSortDirection("document_url")}>
              URL
              {sortField === "document_url" && (
                <span className="sort-indicator">
                  <i
                    className={`fa fa-caret-${
                      sortDirection === "asc" ? "up" : "down"
                    }`}
                    aria-hidden="true"
                  />
                </span>
              )}
            </th>
            <th onClick={() => toggleSortDirection("created_at")}>
              Created At
              {sortField === "created_at" && (
                <span className="sort-indicator">
                  <i
                    className={`fa fa-caret-${
                      sortDirection === "asc" ? "up" : "down"
                    }`}
                    aria-hidden="true"
                  />
                </span>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>
                <strong>{highlightText(item.title, searchTerm)}</strong>
                <p>{highlightText(item.description, searchTerm)}</p>
              </td>
              <td>{item.admin}</td>
              <td>{item.version}</td>
              <td>
                <a href={`${item.document_url}`} target="_blank">
                  <i
                    className="fa fa-cloud-download"
                    style={{ fontSize: "20px" }}
                  />
                  {/* {item.document_url} */}
                </a>
              </td>
              <td>{item.created_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {props.isApiWorking && (
        <button disabled={true}>
          <i className={apiFaIcon} />
        </button>
      )}
      {!props.isApiWorking && isEmpty(filteredData) && (
        <div className="empty-tabledata">
          <i className="fa fa-table" aria-hidden="true"></i> No records
          available
        </div>
      )}
    </div>
  );
}

export default DPMTables;
