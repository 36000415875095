import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import Footer from "../../../components/Footer/Footer";
import NavBar from "../../../components/Navbar/NavBar";
import { getStatistics } from "../../../utils/apis/api/auth_api_statistics";
//CSS
import "./Statistics.css";
import StatisticSelect from "./StatisticSelect";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
//JS

//LINK
//✅logged in
//https://admin.songdrop.band

//Dashboard
function Statistics(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //DEFAULT OPTIONS FOR TRACKING FORECAST
  const [showAlbumData, setShowAlbumData] = useState(true);
  const [showTrackData, setShowTrackData] = useState(true);
  const [showDropData, setShowDropData] = useState(true);
  const [showSalesData, setShowSalesData] = useState(true);
  const [showListenersData, setShowListenersData] = useState(true);

  const [statData, setStatData] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Statistics | Admin SongDrop";
    //api
    geStatsAPI("today");
  }, [
    showAlbumData,
    showTrackData,
    showDropData,
    showSalesData,
    showListenersData,
  ]);

  //API: getUsersAPI
  async function geStatsAPI(time) {
    setApiWorking(true);

    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`time=${time}`);
      bodyParams.push(`show_album=${showAlbumData}`);
      bodyParams.push(`show_track=${showTrackData}`);
      bodyParams.push(`show_drop=${showDropData}`);
      bodyParams.push(`show_listeners=${showListenersData}`);
      const bParameters = bodyParams.join("&");
      console.log(bParameters);
      //✅SUCCESS
      const result = await getStatistics(bParameters);
      console.log(result);
      //
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      console.log(error);
      setApiWorking(false);
    }
  }

  function handleSelect(value) {
    geStatsAPI(value);
  }
  //VIEW
  return (
    <div className="statistics-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="statistics-content">
        <div className="toggle-buttons">
          <StatisticSelect handleSelect={handleSelect} />
          <label className="checkboxContainer">
            <input
              type="checkbox"
              checked={showAlbumData}
              onChange={() => setShowAlbumData(!showAlbumData)}
            />
            <span
              style={{
                textDecoration: !showAlbumData ? "line-through" : "none",
              }}
            >
              Albums
            </span>
          </label>
          <label className="checkboxContainer">
            <input
              type="checkbox"
              checked={showTrackData}
              onChange={() => setShowTrackData(!showTrackData)}
            />
            <span
              style={{
                textDecoration: !showTrackData ? "line-through" : "none",
              }}
            >
              Tracks
            </span>
          </label>
          <label className="checkboxContainer">
            <input
              type="checkbox"
              checked={showDropData}
              onChange={() => setShowDropData(!showDropData)}
            />
            <span
              style={{
                textDecoration: !showDropData ? "line-through" : "none",
              }}
            >
              Drops
            </span>
          </label>
          <label className="checkboxContainer">
            <input
              type="checkbox"
              checked={showSalesData}
              onChange={() => setShowSalesData(!showSalesData)}
            />
            <span
              style={{
                textDecoration: !showSalesData ? "line-through" : "none",
              }}
            >
              Sales
            </span>
          </label>
          <label className="checkboxContainer">
            <input
              type="checkbox"
              checked={showListenersData}
              onChange={() => setShowListenersData(!showListenersData)}
            />
            <span
              style={{
                textDecoration: !showListenersData ? "line-through" : "none",
              }}
            >
              Listeners
            </span>
          </label>
        </div>

        <div>
          {isApiWorking && (
            <button className="api-emails" disabled={true}>
              <i className={apiFaIcon}></i>
            </button>
          )}
          {!isApiWorking && isEmpty(statData) && (
            <div className="empty-tabledata">
              <i className="fa fa-bars" aria-hidden="true"></i> No records found
            </div>
          )}
          {!isApiWorking && <div className="mapdata"></div>}
        </div>
      </div>
    </div>
  );
}

export default Statistics;
