import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//GetQRCodeMFA
export const getMFAQRCode = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_mfa_qr_request(
      userId,
      tokens["IDToken"],
      tokens["AccessToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

const get_mfa_qr_request = async (userId, token, accessToken) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/admin/mfa/get_qr_code" +
      "?" +
      `accessToken=${accessToken}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyMFACode = async (userCode) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await verify_mfa_req(
      userId,
      tokens["IDToken"],
      tokens["AccessToken"],
      userCode
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

const verify_mfa_req = async (userId, token, accessToken, userCode) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/admin/mfa/validate_mfa" +
      "?" +
      `accessToken=${accessToken}` +
      "&" +
      `userCode=${userCode}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, null, { headers })
      .then((response) => {
        let result = response["data"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const disableMFACode = async (userCode) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await disable_mfa_req(
      userId,
      tokens["IDToken"],
      tokens["AccessToken"],
      userCode
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

const disable_mfa_req = async (userId, token, accessToken, userCode) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_ADMIN_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/admin/mfa/disable_mfa" +
      "?" +
      `sub=${userId}` +
      "&" +
      `accessToken=${accessToken}` +
      "&" +
      `userCode=${userCode}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, null, { headers })
      .then((response) => {
        let result = response["data"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
