import React, { useState } from "react";
//CSS
import "./DMPFormUpload.css";
//

//
//DMPFormUpload
function DMPFormUpload(props) {
  //
  const DocsUploadRef = React.useRef(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const [fileReaderWorking, setFileReaderWorking] = useState(false);

  //UPLOAD_BTN_CLICK
  function handleBtnClick(event) {
    event.preventDefault();
    DocsUploadRef.current.click();
  }

  //DRAG AND DROP TO UPLOAD
  function handleDragOver(event) {
    event.preventDefault();
    setIsDragActive(true);
  }

  function handleDragLeave(event) {
    event.preventDefault();
    setIsDragActive(false);
  }

  function handleDrop(event) {
    event.preventDefault();
    setIsDragActive(false);
    const files = event.dataTransfer.files;
    const documentFiles = Array.from(files).filter(
      (file) => file.type === "application/pdf"
    );
    if (documentFiles.length > 0) {
      const docFile = documentFiles[0];
      fileInput({ target: { files: [docFile] } });
    } else {
      alert("Please drop a PDF file.");
    }
  }

  //VALIDATE_FILEUPLOAD
  async function fileInput(event) {
    if (event.target.files.length === 0) return;
    setFileReaderWorking(true);
    //
    var reader = new FileReader();
    const selectedFile = event.target.files[0]; //

    if (selectedFile) {
      if (selectedFile.type === "application/pdf") {
        // Process the PDF file
        reader.onload = async function (fileEvent) {
          //
          try {
            setFileReaderWorking(false);
            // Get the file content
            const fileContent = fileEvent.target.result;
            const fileName = selectedFile.name;

            // -> SEND FILE CONTENT TO UPLOAD
            props.handleUpload(fileContent, fileName);
          } catch (err) {
            // ERROR -> UPLOAD FILE ERROR
            alert(err.message);
            //
            setFileReaderWorking(false);
          }
        };
        reader.readAsArrayBuffer(selectedFile);
      } else {
        alert("Please select a PDF file.");
        setFileReaderWorking(false);
        // Clear the input to prevent further processing
        event.target.value = "";
      }
    }
  }

  //VIEW
  return (
    <div
      className={`file-upload-box ${isDragActive ? "drag-active" : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <form className="upload-content">
        <div className="top-content">
          <div className="center">
            <i className="fa fa-cloud-upload"></i>
            <div>{props.title}</div>
          </div>
        </div>
        {props.isApiWorking === false && fileReaderWorking === false && (
          <div className="bottom-content">
            <button className="upload-btn" onClick={(e) => handleBtnClick(e)}>
              Upload
            </button>
            <input
              type="file"
              accept="pdf/*"
              style={{ display: "none" }}
              ref={DocsUploadRef}
              onChange={fileInput}
            />
          </div>
        )}
        {props.isApiWorking === true ||
          (fileReaderWorking === true && props.apiProgress === 0 && (
            <div className="bottom-content">
              <button className="upload-btn" disabled={true}>
                <i className="fa fa-refresh spin-it" />
              </button>
            </div>
          ))}
        {props.isApiWorking === true && props.apiProgress !== 0 && (
          <div className="bottom-content">
            <div className="progress-bar">
              <div
                className="p-item"
                style={{ width: `${props.apiProgress * 100}%` }}
              >
                <span>{parseFloat(props.apiProgress * 100).toFixed(2)}%</span>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default DMPFormUpload;
