import React, { useEffect, useRef, useState } from "react";
import { getUserMetrix } from "../../../../utils/apis/api/auth_api_metrix";
import isEmpty from "../../../../components/isEmpty";

//CSS
import "./UserMetrix.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faUCheck = "fa fa-user-circle-o";
const faUPlus = "fa fa-user-plus";
const faUMinus = "fa fa-user-times";
const faDiscord = "fa fa-discord";

//UserMetrix
function UserMetrix(props) {
  //API
  const [isApiWorking, setIsApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  //LOGGED_IN_USER_LOCALLY_STORED_DATA
  const [userMetrix, setUserMetrix] = useState(null);

  useEffect(() => {
    callUserMetrix();
  }, []);

  //API: Cognito user logged in
  async function callUserMetrix() {
    props.setApiWorking(true);
    setIsApiWorking(true);

    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`metrix_only=${props.metrix_only || "1"}`);
      const bParameters = bodyParams.join("&");
      const result = await getUserMetrix(bParameters);
      //✅SUCCESS
      console.log("USER_METRIX_SUCCESS", result);
      setUserMetrix(result["metrics"]);
      setIsApiWorking(false);
      props.setApiWorking(false);
      //user_list
      if (!isEmpty(result["user_list"])) {
        props.setUserList(result["user_list"]);
      }
      if (!isEmpty(result["users_monthly"])) {
        const sortedSalesData = result["users_monthly"];
        // const sortedSalesData = result["users_monthly"].sort(
        //   (a, b) => b.date > a.date
        // );
        props.setChartData(sortedSalesData);
      }
    } catch (error) {
      //🚫ERROR
      console.log(error);
      setIsApiWorking(false);
    }
  }

  const currentDate = () => {
    const now = new Date();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthYear = `${months[now.getMonth()]}-${now.getFullYear()}`;
    return monthYear;
  };

  // Example usage:
  console.log(currentDate()); // Output: Dec-2023

  //VIEW
  return (
    <div className="user_metrix">
      {/* API_WORKING */}
      {isApiWorking && (
        <div className="metrix_title">
          <i className={apiFaIcon} /> <strong>USER METRIX</strong>
        </div>
      )}
      {/* API_DONE */}
      {!isApiWorking && !isEmpty(userMetrix) && (
        <div className="user_metrix_dashboard">
          <div className="metrix_title">
            <strong>USER METRIX</strong>
          </div>
          <div className="metrics-container" title="Users">
            <div className="metric-card">
              <a href={props.hrefOnClick} style={{ color: "#00b100" }}>
                <i className={faUCheck} /> Users: {userMetrix["user_number"]}
              </a>
            </div>
            <div className="metric-card" title="Registered users today">
              <p
                style={{
                  color:
                    userMetrix["new_users_today"] > 0 ? "green" : "inherit",
                }}
              >
                <i className={faUPlus} /> Today:{" "}
                <strong>{userMetrix["new_users_today"]}</strong>
              </p>
            </div>
            <div className="metric-card" title="Lost users this month">
              <p
                style={{
                  color:
                    userMetrix["lost_users_number"] > 0 ? "red" : "inherit",
                }}
              >
                <i className={faUMinus} /> Lost:{" "}
                {userMetrix["lost_users_number"]}
              </p>
            </div>
            <div className="metric-card" title="Registered users this month">
              <p>
                <i className={faUPlus} /> {currentDate()}:{" "}
                {userMetrix["new_users_number"]}
              </p>
            </div>
            <a
              className="metric-card"
              href="https://discord.com/widget?id=1060578263560364102&theme=dark"
              target="_blank"
              rel="noopener noreferrer"
              title="Online Discord Users"
            >
              <iframe
                src="https://discord.com/widget?id=1060578263560364102&theme=dark"
                width="100px"
                height="100px"
                allowtransparency="true"
                frameBorder="0"
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                title="SONGDROP DISCORD SERVER"
              ></iframe>
            </a>
            {/* Add more metric cards as needed */}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserMetrix;
