import React from "react";
import Moment from "moment";
import numberFormatter from "../../../components/numberFormatter";
import isEmpty from "../../../components/isEmpty";
import isSame from "../../../components/isSame";
import ReactAudioPlayer from "react-audio-player";
// import DownloadApp from "../dashboard/DownloadApp";

//CSS
import "./ListenDrop.css";

//ListenDrop
function ListenDrop(props) {
  //SELECT: Drop
  function setSelecteddropToListen() {
    props.setSelecteddropToListen(props.drop);
  }

  //VIEW
  return (
    <div className="drop-highlightbox">
      <div className="listen-drop">
        <i className="fa fa-eye" />
        {numberFormatter(props.dropToListen.views_count, 1)} views
        <i className="fa fa-music" />
        {props.dropToListen.song.title} - {props.dropToListen.song.artist}
        <i
          onClick={() => setSelecteddropToListen(null)}
          className="close fa fa-times-circle"
        />
      </div>
      <div className="listeninapp">
        <i className="fa fa-music" /> Listen the full Song as a Drop in our app.
      </div>
      {!isEmpty(props.streamUrl) &&
        props.streamUrl === props.dropToListen.song.prev_url && (
          <div className="mp3Player">
            <p className="prev">Preview</p>
            <div className="player">
              <ReactAudioPlayer
                src={props.streamUrl}
                autoPlay
                controls
                style={{ width: "100%" }}
              />
            </div>
          </div>
        )}
      {/* <DownloadApp /> */}
    </div>
  );

  //SET: active cell
  function setCellActiveIfNeeded() {
    if (!isEmpty(props.drop) && !isEmpty(props.dropToView)) {
      return isSame(props.drop.drop_id, props.dropToView.drop_id)
        ? "active"
        : "";
    }
    return "";
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default ListenDrop;
