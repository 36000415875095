import React, { useState, useEffect } from "react";
import BlogEmptyList from "../BlogContent/BlogEmptyList";
import BlogList from "../BlogList/BlogList";
import BlogSearchBar from "../BlogSearchBar/BlogSearchBar";
import {
  getBlogArticlesAPI,
  createBlogAPI,
} from "../../../../utils/apis/api/auth_api_blog";

//CSS
import "./BlogHome.css";
import isEmpty from "../../../../components/isEmpty";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//BlogHome
const BlogHome = (props) => {
  //
  const [isApiWorking, setApiWorking] = useState(true);
  const [all_blogs, setAllBlogs] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    //
    document.title = "Blog | Admin SongDrop";
    //API -> get blogs
    getBlogs();
  }, []);

  //API
  const getBlogs = async () => {
    setApiWorking(true);
    //
    try {
      var bodyParams = [];
      bodyParams.push(`page=${0}`);
      bodyParams.push(`limit=${50}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      let result = await getBlogArticlesAPI(bParameters);
      //✅SUCCESS
      let blogs = result["blogs"];
      setBlogs(blogs);
      setAllBlogs(blogs);
      console.log("RESULT", blogs);
      //
      setApiWorking(false);
    } catch (error) {
      console.log("ERROR", error.message);
      //🚫ERROR
      setApiWorking(false);
    }
  };

  // Clear search and show all blogs
  const createNew = async () => {
    if (isApiWorking) {
      return;
    }
    setApiWorking(true);
    //
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      let result = await createBlogAPI(bParameters);
      //✅SUCCESS
      console.log("CREATE_NEW_RESULT", result);
      let id = result["id"];
      //->>>RE_SET_BLOG
      window.location.href = `/blog/${id}`;
      //
      setApiWorking(false);
      //🚫ERROR
    } catch (error) {
      alert(error.message);
      console.log("ERROR", error.message);
      setApiWorking(false);
    }
  };

  // Search submit
  const handleSearchBar = () => {
    if (isEmpty(all_blogs)) {
      return [];
    }
    const filteredBlogs = all_blogs.filter((blog) => {
      return blog.title.toLowerCase().includes(searchKey.toLowerCase().trim());
    });

    setBlogs(filteredBlogs);
  };

  // Clear search and show all blogs
  const handleClearSearch = () => {
    setBlogs(all_blogs);
    setSearchKey("");
  };

  const handleSearchKey = (value) => {
    setSearchKey(value);
    if (isEmpty(value)) {
      setBlogs(all_blogs);
    }
  };

  return (
    <div className="bloghome">
      {/* Search Bar */}
      <BlogSearchBar
        value={searchKey}
        createNew={createNew}
        clearSearch={handleClearSearch}
        submitSearch={handleSearchBar}
        handleSearchKey={(e) => handleSearchKey(e.target.value)}
      />
      {/* API_LOADING */}
      {isApiWorking === true && (
        <button className="_api_working" type="submit" disabled={true}>
          <i className={apiFaIcon}></i>
        </button>
      )}
      {/* Blog List & Empty View */}
      {!blogs.length && !isApiWorking ? (
        <BlogEmptyList />
      ) : (
        <BlogList blogs={blogs} />
      )}
    </div>
  );
};

export default BlogHome;
