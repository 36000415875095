import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import YouTube from "react-youtube";
import Linkify from "react-linkify";
import InstagramEmbed from "react-instagram-embed";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

//css
import "./Chrono.css";

//youtube options
const youtubeOpts = {
  height: "200",
  width: "100%",
  playerVars: {
    autoplay: 0,
  },
  url: "",
};

//Chrono
function Chrono(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="chrono_body">
      {!isEmpty(props.mode) && props.mode === "HORIZONTAL" && (
        <div>
          <div className="straigth-line"></div>
          <div className="top_horizontal_timeline parent">
            {build_top_horizontal_timeline()}
          </div>
          <div className="horizontal_timeline_cards">
            {build_horizontal_timeline_cards()}
          </div>
        </div>
      )}
      {!isEmpty(props.mode) && props.mode === "VERTICAL" && (
        <div>
          <div className="left_vertical_timeline"></div>
          <div className="right_vertical_timeline_cards"></div>
        </div>
      )}
    </div>
  );

  //BUILD: pagedata
  function build_top_horizontal_timeline() {
    var ui_items = [];
    if (isEmpty(props.items)) {
      return ui_items;
    }

    //IS_TODAY?
    //IS_HIGHLIGHTED

    props.items.forEach((item, index) => {
      const tableRow = (
        <div
          id={`scroll-horizontal-top-${index}`}
          key={index}
          className="time-item child"
          onClick={() => clickedAtIndex(index, item.title)}
        >
          <div
            className={
              item.title === props.selected_date
                ? "time-title-active"
                : "time-title"
            }
            style={{
              backgroundColor:
                item.title === props.selected_date ? "#006eff" : "white",
              border:
                item.title === props.selected_date
                  ? "0px solid lightgray"
                  : "0px solid black",
              color: item.title === props.selected_date ? "white" : "black",
            }}
          >
            {item.title === props.selected_date && (
              <i className="fa fa-caret-down" />
            )}{" "}
            {item.title}
          </div>
          <div
            style={{
              marginTop: item.title === props.selected_date ? "16px" : "12px",
            }}
          >
            {item.title === props.selected_date && (
              <div className="active">
                {!isEmpty(item.media) && (
                  <div>
                    {!isEmpty(item.media.source) && (
                      <div>
                        {!isEmpty(item.media.source.url) && (
                          <div>
                            {item.media.source.url.includes("youtube") && (
                              <i
                                className="fa fa-youtube-play"
                                style={{ color: "red" }}
                              />
                            )}
                            {item.media.source.url.includes("instagram") && (
                              <i
                                className="fa fa-instagram"
                                style={{ color: "black" }}
                              />
                            )}
                            {item.media.source.url.includes("no-event") && (
                              <i
                                className="fa fa-calendar"
                                style={{ color: "darkgray" }}
                              />
                            )}
                            {item.media.source.url.includes("fbcdn.net") && (
                              <i
                                className="fa fa-picture-o"
                                style={{ color: "black" }}
                              />
                            )}
                            {item.media.source.url.includes("mp4") ||
                              (item.media.source.url.includes("avi") && (
                                <i
                                  className="fa fa-play-circle"
                                  style={{ color: "black" }}
                                />
                              ))}
                          </div>
                        )}
                      </div>
                    )}{" "}
                    {isEmpty(item.media.source) && (
                      <i className="fa fa-circle" />
                    )}
                  </div>
                )}
                {isEmpty(item.media) && <i className="fa fa-sticky-note" />}
              </div>
            )}

            {item.title !== props.selected_date && (
              <div className="inactive">
                <i className="fa fa-circle" />
              </div>
            )}
          </div>

          {/* {console.log(item)} */}
        </div>
      );
      ui_items.push(tableRow);
    });
    return ui_items;
  }

  function clickedAtIndex(index, date) {
    props.goToDateIndex(index, date);
    goToViolation(`scroll-horizontal-top-${index}`);
    goToViolation(`scroll-horizontal-card-${index}`);
  }

  //LINKS
  //
  //BUILD: pagedata
  function build_horizontal_timeline_cards() {
    var ui_items = [];
    if (isEmpty(props.items)) {
      return ui_items;
    }

    //IS_TODAY?
    //IS_HIGHLIGHTED

    props.items.forEach((item, index) => {
      const _youtubeOpts = {
        height: "200",
        width: "100%",
        playerVars: {
          autoplay: item.title === props.selected_date ? 1 : 0, //
        },
        url: "",
      };
      const tableRow = (
        <div
          id={`scroll-horizontal-card-${index}`}
          key={index}
          className="card-item"
          onClick={() => clickedAtIndex(index, item.title)}
          style={{
            border:
              item.title === props.selected_date
                ? "2px solid #006eff"
                : "0px solid black",
          }}
        >
          {!isEmpty(item.media) && (
            <div>
              {/* <strong> ✅MEDIA ATTACHED</strong> */}
              {console.log(" Media Attached")}
              {!isEmpty(item.media.source) && (
                <div>
                  {/* <div>MEDIA_SOURCE</div> */}
                  {!isEmpty(item.media.source.url) && (
                    <div>
                      {/* <div>{item.media.source.url}</div> */}
                      {item.media.source.url.includes("youtube") && (
                        <YouTube
                          videoId={youtube_parser(item.media.source.url)}
                          opts={_youtubeOpts}
                        />
                      )}
                      {item.media.source.url.includes("instagram") && (
                        <div className="instagram">
                          <InstagramEmbed url={item.media.source.url} />
                        </div>
                      )}
                      {item.media.source.url.includes("jpg") ||
                        (item.media.source.url.includes("png") &&
                          item.media.source.url.includes("no-event") && (
                            <img src={item.media.source.url} width="500"></img>
                          ))}
                      {item.media.source.url.includes("jpg") ||
                        (item.media.source.url.includes("png") &&
                          !item.media.source.url.includes("no-event") && (
                            <Zoom>
                              <img
                                src={item.media.source.url}
                                width="500"
                              ></img>
                            </Zoom>
                          ))}
                      {item.media.source.url.includes("fbcdn.net") && (
                        <Zoom>
                          <img src={item.media.source.url} width="500"></img>
                        </Zoom>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="card-title">{item.cardTitle}</div>
          <div className="card-subtitle">{item.cardSubtitle}</div>

          <div className="card-detailed-text">
            <Linkify>
              <p>{`${item.cardDetailedText}`}</p>
            </Linkify>
          </div>

          {console.log(item.cardTitle)}
          {isEmpty(item.media) && (
            <div>
              <strong>🚫NO MEDIA ATTACHED</strong>
              {console.log("No Media Attached")}
            </div>
          )}
          <div className="bottom-bar">
            <button disabled>
              <i className="fa fa-chevron-down" /> More
            </button>
            <button disabled={item.cardTitle === "NO EVENTS TODAY"}>
              <i className="fa fa-share" /> Share
            </button>
            <button disabled>
              <i className="fa fa-eye" /> 12k views
            </button>
            <button>
              <i className="fa fa-heart" /> 12m
            </button>
          </div>
        </div>
      );
      ui_items.push(tableRow);
    });
    return ui_items;
  }
  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  function goToViolation(id) {
    const violation = document.getElementById(id);
    window.scrollTo({
      center: violation.offsetTop,
      behavior: "smooth",
    });
  }
}

export default Chrono;
