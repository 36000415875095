import React, { useEffect, useState, useRef } from "react";
import { useContainerDimensions } from "../../../components/useContainerDimensions";
import useLocalStorage from "../../../components/useLocalStorage";

import RadioCharts from "./RadioCharts";
import moment from "moment";
import isEmpty from "../../../components/isEmpty";
import StreamStarted from "./StreamStarted";
import downloadCSV from "./downloadCSV";
import getMp3Info from "./get_radio_stats";

//CSS
import "./RadioStats.css";

var fetchDataEverySecond = 10;

//faicons
const faDate = "fa fa-calendar";
const faListeners = "fa fa-headphones";
const faPeakListeners = "fa fa-signal";
const faGenres = "fa fa-music";
const faTitle = "fa fa-play";
const faLocation = "fa fa-globe";

//Default React Class
function RadioStats(props) {
  //Helper:siteWidth ref(resize chartview)
  const componentRef = useRef("radio-chart");
  const { width, height } = useContainerDimensions(componentRef);
  const timeoutRef = useRef(null);

  //body:chart
  let new_data = {
    date: `${moment().format("DD-MM-YYYY HH:mm:ss")}`,
    listeners: 1,
    peak_listeners: 1,
    genre: "-",
    title: "☑️ Started to listen",
    server_location: "Earth",
  };

  const [icecastData, setIcecastData] = useState({});
  const [chartData, setChartData] = useState([new_data]);
  const [peak_listeners, setPeakListeners] = useState(0);
  //
  const [showDate, setShowDate] = useLocalStorage("r_dat_ls", true);
  const [showListeners, setShowListeneres] = useLocalStorage("r_lis_ls", true);
  const [showPeak, setShowPeak] = useLocalStorage("r_pea_ls", true);
  const [showTitle, setShowTilte] = useLocalStorage("r_tit_ls", true);
  const [showGenre, setShowGenre] = useLocalStorage("r_gen_ls", true);
  const [showLocation, setShowLocation] = useLocalStorage("r_loc_ls", true);

  //ViewDidLoad
  useEffect(() => {
    //title
    radioCharts();

    //cleanup function
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  async function radioCharts() {
    getRadioCharts(props.streamUrl, props.status_xsl_url);
    timeoutRef.current = setTimeout(radioCharts, fetchDataEverySecond * 1000);
  }

  async function getRadioCharts(radio_url, status_xsl_url) {
    //
    //
    try {
      let json = await getMp3Info(radio_url, status_xsl_url);
      console.log("JSON", json);
      //✅SUCCESS
      //server header
      let server_id = json["server_id"];
      let server_host = json["host"];
      let server_location = json["server_location"];
      let server_start = json["server_start_iso8601"];
      let server_start_iso8601 = json["server_start_iso8601"];
      //
      let listenurl = json["listenurl"];
      let genre = json["genre"];
      let server_description = json["server_description"];
      let title = json["title"];
      let server_name = json["server_name"];
      let bitrate = json["bitrate"];
      let stream_start = json["stream_start"];
      let peak_listeners = json["peak_listeners"];
      let listeners = json["listeners"];
      let server_type = json["server_type"];
      let request_date = `${moment().format("HH:mm:ss")}`;

      let icecastData = {
        server_id: server_id,
        server_host: server_host,
        server_location: server_location,
        listenurl: listenurl,
        genre: genre,
        server_description: server_description,
        title: title,
        server_name: server_name,
        bitrate: bitrate,
        stream_start: stream_start,
        peak_listeners: peak_listeners,
        listeners: listeners,
        server_type: server_type,
        request_date: request_date,
      };

      setIcecastData(icecastData);
      //

      setPeakListeners(peak_listeners);
      //
      let new_data = {
        date: request_date,
        listeners: listeners,
        peak_listeners: peak_listeners,
        genre: genre || "No Genre",
        title: title || "No Title",
        server_location: server_location,
      };

      console.log(new_data);

      //
      setChartData((prevData) => {
        return [...prevData, new_data];
      });

      //🚫ERROR
    } catch (error) {
      // console.log(error.message);
    }
  }

  return (
    <div className="radio_stats" ref={componentRef}>
      <div className="ld_head">Listeners/Time</div>
      <RadioCharts
        backgroundColor={"transparent"}
        tintColor={"#2ac24b"}
        width={width + 5}
        data={chartData}
        peak_listeners={peak_listeners}
      />
      <div className="icecast-container">
        <div className="icecast-header">Icecast Server Data</div>
        {!isEmpty(icecastData) && (
          <div>
            <div className="icecast-row">
              <div className="icecast-label">Stream Started at:</div>
              <div className="icecast-value">
                {icecastData.stream_start || "No Data"}
                <StreamStarted
                  startTime={icecastData.stream_start || "No Data"}
                />
              </div>
            </div>
            <div className="icecast-row">
              <div className="icecast-label">Listeners:</div>
              <div className="icecast-value">
                {icecastData.listeners || "No Data"}
              </div>
            </div>
            <div className="icecast-row">
              <div className="icecast-label">Title:</div>
              <div className="icecast-value">
                {icecastData.title || "No Title"}
              </div>
            </div>
            <div className="icecast-row">
              <div className="icecast-label">Genre:</div>
              <div className="icecast-value">
                {icecastData.genre || "No Genre"}
              </div>
            </div>
            <div className="icecast-row">
              <div className="icecast-label">Location:</div>
              <div className="icecast-value">
                {icecastData.server_location || "Earth"}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="table-container">
        <div className="checkboxes">
          <label title="Date">
            <input
              type="checkbox"
              checked={showDate}
              onChange={() => setShowDate(!showDate)}
            />
            <i className={faDate} />
          </label>
          <label title="Listeners">
            <input
              type="checkbox"
              checked={showListeners}
              onChange={() => setShowListeneres(!showListeners)}
            />
            <i className={faListeners} />
          </label>
          <label title="Peak Listeners">
            <input
              type="checkbox"
              checked={showPeak}
              onChange={() => setShowPeak(!showPeak)}
            />
            <i className={faPeakListeners} />
          </label>
          <label title="Title">
            <input
              type="checkbox"
              checked={showTitle}
              onChange={() => setShowTilte(!showTitle)}
            />
            <i className={faTitle} />
          </label>
          <label title="Genre">
            <input
              type="checkbox"
              checked={showGenre}
              onChange={() => setShowGenre(!showGenre)}
            />
            <i className={faGenres} />
          </label>
          <label title="Location">
            <input
              type="checkbox"
              checked={showLocation}
              onChange={() => setShowLocation(!showLocation)}
            />
            <i className={faLocation} />
          </label>
        </div>
        <table className="table">
          <tr className="header-row">
            {showDate && <td className="header-cell">Date</td>}
            {showListeners && (
              <td className="header-cell">{`Listeners(${peak_listeners})`}</td>
            )}
            {showPeak && (
              <td className="header-cell">{`Peak(${peak_listeners})`}</td>
            )}
            {showTitle && <td className="header-cell">Title</td>}
            {showGenre && <td className="header-cell">Genre</td>}
            {showLocation && <td className="header-cell">Location</td>}
          </tr>
          {tableData()}
        </table>
        <div className="_export">
          <p>
            <i className="fa fa-share-square" /> Export as CSV file
          </p>
          <button onClick={() => exportTableToCSV("tabledata.csv")}>
            Download CSV
          </button>
        </div>
      </div>
    </div>
  );
  //table
  function tableData() {
    var tableData = [];
    if (isEmpty(chartData)) {
      return tableData;
    }

    chartData.forEach((data, index) => {
      const tableRow = (
        <tr className="data-row" key={index}>
          {showDate && <td className="data-cell">{data.date}</td>}
          {showListeners && <td className="data-cell">{data.listeners}</td>}
          {showPeak && <td className="data-cell">{data.peak_listeners}</td>}
          {showTitle && <td className="data-cell">{data.title}</td>}
          {showGenre && <td className="data-cell">{data.genre}</td>}
          {showLocation && (
            <td className="data-cell">{data.server_location}</td>
          )}
        </tr>
      );
      tableData.push(tableRow);
    });

    return tableData;
  }

  //EXPORT/DOWNLOAD table in CSV
  function exportTableToCSV(filename) {
    var csv = [];
    var rows = document.querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th, NumberFormat");
      for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      csv.push(row.join(";"));
    }
    // Download CSV file
    downloadCSV(csv.join("\n"), filename);
  }
}

export default RadioStats;
