import React, { useEffect, useRef, useState } from "react";
import useLocalStorage from "../../../components/useLocalStorage";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import SearchBar from "../../../components/SearchBar/SearchBar";
import FaqCategoryAsyncSelect from "./FaqCategoryAsyncSelect";
import Linkify from "react-linkify";
import Highlight from "react-highlighter";
import URLParser from "../../../components/URLParser";

import {
  getFaqDash,
  searchFaq,
  createFaqPost,
  deleteFaqPost,
  editFaqPost,
} from "../../../utils/apis/api/auth_api_faq";
//CSS
import "./Faq.css";
//JS
import NavBar from "../../../components/Navbar/NavBar";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const chevronDown = "fa fa-chevron-down";
const chevronRight = "fa fa-chevron-right";
const faExpand = "fa fa-plus-square";
const faCollapse = "fa fa-minus-square";
//LINK
//✅logged in
//https://admin.songdrop.band

const searchBarPageItems = [{ title: "faq", link: "/faq" }];

//Dashboard
function Faq(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  //pageitems
  const [faqItems, setFaqItems] = useState(searchBarPageItems);
  const [searchText, setSearchText] = useState("");

  const [openAll, setOpenAll] = useLocalStorage("faqOpenAll", false);
  const [openedIndex, setOpenedIndex] = useState({ section: -99, row: -99 });

  const [isFormVisible, setFormVisible] = useLocalStorage(
    "faqFormVisible",
    true
  );
  const [faqType, setFaqType] = useState(0);
  const [category, setCategory] = useState(null);

  const [username, setUsername] = useState("officialsongdrop");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [hashtags, setHashtags] = useState("");

  //categories
  const [faq_categories, setFaqCategories] = useState([]);

  const [topic_id_in_edit, setTopicIdToEdit] = useState(null);
  const [faq_title_in_edit, setFaqTitleToEdit] = useState("");
  const [faq_text_in_edit, setFaqTextToEdit] = useState("");
  const [faq_hashtags_in_edit, setFaqHashtagsToEdit] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "FAQ | Admin SongDrop";
    //api
    loadApiData();
  }, []);

  function loadApiData() {
    let url_paths = URLParser();
    var searchText = url_paths["search"] || "";
    if (isEmpty(searchText)) {
      getFAQData();
    } else {
      setSearchText(searchText);
      searchData(searchText);
    }
  }
  //API: GET ALL FAQ DATA
  async function getFAQData() {
    setApiWorking(true);
    setApiError("");
    try {
      //✅SUCCESS
      let result = await getFaqDash();
      let categories = result["categories"];
      setFaqCategories(categories);
      setApiWorking(false);
      //🚫ERROR
    } catch (error) {
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //API: SEARCH TOpic
  async function searchData(searchText) {
    setApiWorking(true);
    setApiError("");

    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`search=${searchText}`);
      const qParameters = queryParams.join("&");
      //✅SUCCESS
      let result = await searchFaq(qParameters);
      let faq_categories = result["categories"];
      // console.log("RESULT", faq_categories);
      //
      setFaqCategories(faq_categories);
      setApiWorking(false);
      //->expand
      expandAll();
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //API: CREATE NEW TOPIC/CATEGORY
  const createNewTopicOrCategory = async (event) => {
    event.preventDefault();
    setApiWorking(true);
    setApiError("");

    try {
      var category_id = !isEmpty(category) ? category.value : "";
      let _hashtags = hashtags
        .split(/[\s\n\r]/gim)
        .filter((v) => v.startsWith("#"))
        .join(",");
      //
      var faq_type = faqType; //0: Create Category, 1: Create Topic
      var bodyParams = [];
      bodyParams.push(`faq_type=${faq_type}`);
      bodyParams.push(`title=${title}`);
      bodyParams.push(`text=${message}`);
      bodyParams.push(`category_id=${category_id}`);
      bodyParams.push(`hashtags=${_hashtags}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await createFaqPost(bParameters);
      console.log(result);
      //
      setApiWorking(false);
      resetForm();
      //get data again
      loadApiData();
      //🚫ERROR
    } catch (error) {
      console.log(error);
      setApiWorking(false);
      setApiError(error.message);
      alert(error.message);
    }
  };

  //API: DELETE TOPIC
  const handleDeleteTopic = async (faq_id) => {
    setApiWorking(true);
    setApiError("");

    try {
      var bodyParams = [];
      bodyParams.push(`faq_id=${faq_id}`);
      bodyParams.push(`edit_token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await deleteFaqPost(bParameters);
      console.log(result);
      setApiWorking(false);
      //get data again
      loadApiData();
      //🚫ERROR
    } catch (error) {
      setApiWorking(false);
      setApiError(error.message);
    }
  };

  //API: UPDATE TOPIC
  const handleUpdateTopic = async () => {
    setApiWorking(true);
    setApiError("");

    try {
      let _hashtags = faq_hashtags_in_edit
        .split(/[\s\n\r]/gim)
        .filter((v) => v.startsWith("#"))
        .join(",");
      var bodyParams = [];
      bodyParams.push(`title=${faq_title_in_edit}`);
      bodyParams.push(`text=${faq_text_in_edit}`);
      bodyParams.push(`hashtags=${_hashtags}`);
      bodyParams.push(`topic_id=${topic_id_in_edit}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      console.log(bParameters);
      //✅SUCCESS
      let result = await editFaqPost(bParameters);
      console.log(result);
      setApiWorking(false);
      //get data again
      loadApiData();
      //🚫ERROR
    } catch (error) {
      setApiWorking(false);
      setApiError(error.message);
    }
  };

  //RESET FORM INPUT
  function resetForm() {
    setTitle("");
    setCategory(null);
    setHashtags("");
    setMessage("");
    setFormVisible(false);
  }

  //VIEW
  return (
    <div className="faq-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={searchBarPageItems}
        path="/faq"
        path_char="?"
      />
      <div className="faq-content">
        <div className="header">
          <p className="title-msg">Create/Edit FAQ topics.</p>
          <p>Create FAQ topics to display for SongDrop users.</p>
          <button onClick={() => setFormVisible(!isFormVisible)}>
            +Create New FAQ
          </button>
        </div>
        {isFormVisible && (
          <form className="form" onSubmit={(e) => createNewTopicOrCategory(e)}>
            <p>Create New FAQ</p>
            <input placeholder="username" value={username} disabled={true} />
            <select
              value={faqType}
              onChange={(e) => updateFaqType(e.target.value)}
            >
              <option value={0}>Create Topic</option>
              <option value={1}>Create Category</option>
            </select>
            {faqType === 0 && (
              <div className="category_select">
                <p>Search Category for your Topic</p>
                <FaqCategoryAsyncSelect
                  selectedValue={selectedCatValue}
                  selectedCategory={category}
                />
              </div>
            )}
            <input
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              placeholder={faqType == 0 ? "Description(250)" : "FAQ(500)"}
              onChange={(e) => setMessage(e.target.value)}
            />
            <input
              placeholder="hashtags"
              value={hashtags}
              onChange={(e) => setHashtags(e.target.value)}
            />
            <div>Note: Submit only important FAQ messages.</div>
            {isApiWorking && (
              <button disabled={true}>
                <i className={apiFaIcon} />
              </button>
            )}
            {!isApiWorking && <button>Create FAQ Post</button>}
          </form>
        )}
        <br />
        {isApiWorking && (
          <button disabled={true}>
            <i className={apiFaIcon} />
          </button>
        )}
        <div className="expand_collapse">
          <button onClick={() => collapseAll()}>Collapse</button>
          <button disabled={true}>/</button>
          <button onClick={() => expandAll()}>Expand</button>
        </div>
        <div className="categories">{faqItemCategories()}</div>
      </div>
    </div>
  );

  //BUILD: FAQ_CATEGORIES
  function faqItemCategories() {
    var tabs = [];
    if (isEmpty(faq_categories)) {
      return tabs;
    }
    // Sort faq_categories by title in alphabetical order
    var sorted_faq_categories = faq_categories.sort(function (left, right) {
      return left.title.localeCompare(right.title);
    });
    sorted_faq_categories.forEach((category, category_index) => {
      let row = (
        <div key={category_index} className="category">
          <div
            className="_title"
            onClick={() => openCloseCategory(category_index)}
          >
            <i
              className={
                isCatToggled(category_index) ? chevronDown : chevronRight
              }
              style={{ marginRight: "6px" }}
            />
            {category.title}
          </div>
          {openedIndex.section == category_index && (
            <div>
              <div className="_text">{category.description}</div>
              {/* <div className="_hashtags">{category.hashtags}</div> */}
            </div>
          )}
          {faqItemTopics(category.topics, category_index)}
        </div>
      );

      tabs.push(row);
    });
    return tabs;
  }

  //BUILD: FAQ_TOPICS
  function faqItemTopics(category_topics, category_index) {
    var tabs = [];
    if (isEmpty(category_topics)) {
      return tabs;
    }
    // Sort category_topics by title in alphabetical order
    var sorted_faq_topics = category_topics.sort(function (left, right) {
      return left.title.localeCompare(right.title);
    });
    sorted_faq_topics.forEach((topic, topic_index) => {
      let row = (
        <div key={topic_index} className="topic">
          <div
            className="_topic-title"
            onClick={() => openCloseTopic(category_index, topic_index)}
          >
            <i
              className={
                isTopicToggled(topic_index, category_index)
                  ? faCollapse
                  : faExpand
              }
              style={{ marginRight: "6px" }}
            />
            {topic.title}
          </div>
          {isTopicToggled(category_index, topic_index) && (
            <div>
              <div className="_topic-text">
                {topic_id_in_edit != topic.topic_id && (
                  <div>
                    {!isEmpty(searchText) && (
                      <Highlight search={searchText}>
                        {topic.description}
                      </Highlight>
                    )}
                    {isEmpty(searchText) &&
                      topic_id_in_edit != topic.topic_id && (
                        <Linkify> {topic.description} </Linkify>
                      )}
                  </div>
                )}
                {topic_id_in_edit === topic.topic_id && (
                  <div className="inbox-edit">
                    <input
                      value={faq_title_in_edit}
                      placeholder="Title"
                      onChange={(e) => setFaqTitleToEdit(e.target.value)}
                    />
                    <textarea
                      value={faq_text_in_edit}
                      placeholder="Description"
                      onChange={(e) => setFaqTextToEdit(e.target.value)}
                    />
                    <input
                      value={faq_hashtags_in_edit}
                      placeholder="hashtags"
                      onChange={(e) => setFaqHashtagsToEdit(e.target.value)}
                    />
                  </div>
                )}
                <div className="_topic-hashtags">{topic.hashtags}</div>
                <div className="btn-wrap">
                  {topic_id_in_edit === topic.topic_id && !isApiWorking && (
                    <button
                      disabled={isApiWorking}
                      onClick={() => handleUpdateTopic()}
                    >
                      Save
                    </button>
                  )}
                  {topic_id_in_edit === topic.topic_id && isApiWorking && (
                    <button disabled={isApiWorking}>
                      <i className={apiFaIcon} />
                    </button>
                  )}
                  <button
                    disabled={isApiWorking}
                    onClick={() => selectFaqTopicToEdit(topic)}
                  >
                    {topic_id_in_edit !== topic.topic_id ? (
                      "Edit"
                    ) : (
                      <i className="fa fa-check" />
                    )}
                  </button>
                  <button
                    disabled={isApiWorking}
                    className="red"
                    onClick={(e) => deleteFaqWithId(topic.topic_id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
      tabs.push(row);
    });
    return tabs;
  }

  //CLOSE: Categories with id
  function openCloseCategory(category_index) {
    setOpenAll(false);
    if (openedIndex.section == category_index) {
      setOpenedIndex({ section: -99, row: -99 });
    } else {
      setOpenedIndex({ section: category_index, row: -99 });
    }
  }

  //CLOSE: TOpic with id
  function openCloseTopic(category_index, topic_index) {
    setOpenAll(false);
    if (openedIndex.row === topic_index) {
      setOpenedIndex({ section: category_index, row: -99 });
    } else {
      setOpenedIndex({ section: category_index, row: topic_index });
    }
  }

  //EXPAND: All topics are expanded
  function expandAll() {
    setOpenAll(true);
  }

  //COLLAPSE: All topics are collapsed
  function collapseAll() {
    setOpenAll(false);
    setOpenedIndex({ section: -99, row: -99 });
  }

  //CHECK: IF category is toggled
  function isCatToggled(category_index) {
    if (openAll) {
      return true;
    }
    return openedIndex.section === category_index;
  }

  //CHECK: IF topic is toggled
  function isTopicToggled(category_index, topic_index) {
    if (openAll) {
      return true;
    }
    return (
      openedIndex.section === category_index && openedIndex.row === topic_index
    );
  }

  //SELECT: category for you new topic
  function selectedCatValue(category) {
    setCategory(category);
  }

  //CHANGE FAQ TOPIC CREATION TYPE: 0 CATEGORY. 1 TOPIC
  function updateFaqType(create_new_topic_type) {
    setFaqType(create_new_topic_type);
  }

  //DELETE TOPIC ALERT
  function deleteFaqWithId(faq_id) {
    if (window.confirm("Are you sure you want to delete this topic?")) {
      handleDeleteTopic(faq_id);
    }
  }

  //SELECT TOPIC TO EDIT
  function selectFaqTopicToEdit(topic) {
    if (topic_id_in_edit === topic.topic_id) {
      setTopicIdToEdit(null);
      setFaqHashtagsToEdit("");
      setFaqTextToEdit("");
      setFaqTitleToEdit("");
    } else {
      setTopicIdToEdit(topic.topic_id);
      setFaqHashtagsToEdit(topic.hashtags);
      setFaqTextToEdit(topic.description);
      setFaqTitleToEdit(topic.title);
    }
  }
}

export default Faq;
