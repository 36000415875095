import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import Moment from "moment";
import Footer from "../../../../components/Footer/Footer";
import { useContainerDimensions } from "../../../../components/useContainerDimensions";
import moment from "moment";
//CSS
import "./LastSignups.css";
//JS
import NavBar from "../../../../components/Navbar/NavBar";
import UserMetrix from "./UserMetrix";
import UserMetrixChart from "./UserMetrixChart";
//chart json default values [jan, febr...dec]
import chartJSON from "../../../../assets/json/statistics/user_metrix_chart_data.json";
import ForecastUsers from "./ForecastUsers";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://admin.songdrop.band/last_signups
//LastSignups
function LastSignups(props) {
  //Helper:siteWidth ref(resize chartview)
  const componentRef = useRef("usersdashchart");
  const { width, height } = useContainerDimensions(componentRef);
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  //body:chart
  const [chartData, setChartData] = useState(chartJSON);
  //users
  const [new_users, setNewUsers] = useState([]);
  //
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Last Signups | Admin SongDrop";
    //api
    // getData();
  }, []);

  // Counting different user types
  const userCounts = new_users.reduce((counts, item) => {
    const type = item["usertype"];
    counts[type] = (counts[type] || 0) + 1;
    return counts;
  }, {});

  //VIEW
  return (
    <div className="users-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="users-content">
        {/* USER_METRIX */}
        <UserMetrix
          metrix_only={"0"}
          user={props.user}
          hrefOnClick="/users"
          setUserList={setNewUsers}
          setChartData={setChartData}
          setApiWorking={setApiWorking}
        />
        {/* LOADING VIEW */}
        {isApiWorking && isEmpty(new_users) && (
          <div className="api_loading_empty_view">
            <i className="fa fa-table" />
            <p>Getting data...</p>
          </div>
        )}
        {/* NO USERS FOUND */}
        {!isApiWorking && isEmpty(new_users) && <div>No users found</div>}
        {/* USER METRIX CHART DATA */}
        {!isApiWorking && (
          <div>
            <div ref={componentRef}>
              <UserMetrixChart
                backgroundColor={"white"}
                tintColor={"#2ac24b"}
                width={width}
                data={chartData}
              />
            </div>
          </div>
        )}
        {/* MORE OPTIONS */}
        {!isApiWorking && !isEmpty(new_users) && (
          <ForecastUsers chartData={chartData} user={props.user} />
        )}
        {/* USER METRIX LAST 50 SIGNUPS TABLE DATA */}
        {!isApiWorking && !isEmpty(new_users) && (
          <div className="user_list">
            <div className="last">
              <strong>Last 50 sign-up</strong>
              <p style={{ fontSize: "12px" }}>
                Last registration: {new_users[0]["created_at"]}{" "}
                <strong>{moment(new_users[0]["created_at"]).fromNow()}</strong>
              </p>
              <div className="horizontal-scroll">
                <ul className="horizontal-list">
                  {Object.entries(userCounts).map(([type, count]) => (
                    <li key={type}>
                      {typeToString(parseInt(type))}: {count}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="last-signups-table">
              <table className="user-list-table">
                <thead>
                  <tr className="user-list-header">
                    <th></th>

                    <th>Type</th>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>{buildUserList()}</tbody>
              </table>
            </div>
          </div>
        )}
        <div style={{ padding: "12px" }}>
          <Footer />
        </div>
      </div>
    </div>
  );

  //BUILD: pagedata
  function buildUserList() {
    if (isEmpty(new_users)) {
      return null;
    }

    return new_users.map((item, index) => (
      <tr key={index} className="user-list-row">
        <td style={{ width: "20px" }}>
          <a href={`/users?search=${item["username"]}`} target="_blank">
            <i className="fa fa-external-link-square" />
          </a>
        </td>

        <td>{typeToString(item["usertype"])}</td>
        <td>
          <a href={`/@/${item["username"]}`}>{item["username"]}</a>
        </td>
        <td>{item["name"]}</td>
        <td>{item["created_at"]}</td>
      </tr>
    ));
  }

  function typeToString(type) {
    let label = "";
    let jsxElement = null;

    switch (type) {
      case 0:
        label = "standard";
        return <label className="standard-type">{label}</label>;
        break;
      case 1:
        label = "artist";
        return <label className="artist-type">{label}</label>;
        break;
      case 2:
        label = "label";
        return <label className="label-type">{label}</label>;
        break;
      default:
        label = "unknown";
        return <label className="unknown-type">{label}</label>;
        break;
    }
  }
}

export default LastSignups;
