import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import Footer from "../../../components/Footer/Footer";

//CSS
import "./Payments.css";
//JS
import NavBar from "../../../components/Navbar/NavBar";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://admin.songdrop.band

//Dashboard
function Payments(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Payments | Admin SongDrop";
    //api
    // getData();
  }, []);

  //VIEW
  return (
    <div className="">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div>
        <div>
          -WHEN NEXT PAYMENT WILL HAPPEN <input></input>
        </div>
        <div>-LAST PAYMENTS </div>
        <div>-ALL PAYMENTS </div>
        <div>-LINK TO XERO </div>
        <div> -LINK TO PAYPAL </div>
        <div> -CREATE PAYMENTS CSV </div>
        <div> -APPLE PAYMENTS CSV </div>
      </div>
    </div>
  );
}

export default Payments;
