import React, { useEffect, useState } from "react";
import moment from "moment";
import isEmpty from "../../../components/isEmpty";
//CSS
import "./SocialMedia.css";
import NavBar from "../../../components/Navbar/NavBar";

import PostGenerator from "./PostGenerator";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const apiFaEnabled = "fa fa-check-square";
const faWarning = "fa fa-exclamation-triangle";
//
const faHorizontal = "fa fa-arrows-h";
const faVertical = "fa fa-arrows-v";
const faDown = "fa fa-arrow-down";
const faUp = "fa fa-arrow-up";
const faLeft = "fa fa-angle-left";
const faRight = "fa fa-angle-right";
const faLast = "fa fa-angle-double-right";
const faFirst = "fa fa-angle-double-left";
const faCalendar = "fa fa-calendar";

//SocialMedia
function SocialMedia(props) {
  //API
  const [isApiWorking, setIsApiWorking] = useState(false);
  //
  const [isPostOn, setPostOn] = useState(true);

  //ViewDidLoad
  useEffect(() => {
    document.title = "Social Media | SongDrop";
  }, []);

  //VIEW
  return (
    <div className="socialmedia-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="body">
        <div>MARKETING STRATEGY FOR SONGDROP</div>
        <div className="seo">
          <div>
            <strong>
              <i className="fa fa-handshake-o" /> 1000 USERS STRATEGY FOR
              SONGDROP
            </strong>
            <div>REACH THE FIRST 1000 USERS MILESTONE </div>
            <div>CALL TO ACTION BUTTON </div>
          </div>
          <br />
        </div>
        <div className="instagram">
          <div>
            <strong>
              <i className="fa fa-instagram" /> INSTAGRAM STRATEGY FOR SONGDROP
            </strong>
          </div>
          <div>
            Storytelling week: #storytellingweek Mental-health: 16.01.
            #bluemonday -> most depressing day of the year INSTAGRAM POSTS
            2-3/week + group posts(3 images with the brand logo)
          </div>
          <div>INSTAGRAM REEL 2-3/week </div>
          <div>INSTAGRAM STORIES 2-3/week </div>
          <div>
            USE CHATAI TO GENERATE IDEAS FOR POSTS THAN SELECT THE CORRECT
            HASHTAGS FROM THE LIST
          </div>
          <br />
        </div>
        <div className="seo">
          <div>
            <strong>
              <i className="fa fa-google" /> SEO STRATEGY FOR SONGDROP
            </strong>
            <div>BLOG , SONGDROP AS SEARCH RESULTS </div>
          </div>
          <br />
        </div>
        <div className="youtube">
          <div>
            <strong>
              <i className="fa fa-youtube" /> YOUTUBE STRATEGY FOR SONGDROP
            </strong>
            <div>PUZZLE GAME REELS FOR YOUTUBE!!!! _ BEST</div>
            <div>APP TUTORIALS </div>
            <div>VIDEO CONTENT </div>
            <div>ALBUM REVIEWS </div>
            <div>ARTIST INTERVIEWS </div>
          </div>
          <br />
        </div>
        <div className="hashtag_generator">
          <button onClick={() => setPostOn(!isPostOn)}>
            <i
              className={`fa fa-${isPostOn ? "chevron-up" : "chevron-down"}`}
            />
            POSTS
          </button>
          {isPostOn && <PostGenerator />}
        </div>
        <div>Post 1</div>
        <div>Post 2</div>
      </div>
    </div>
  );
}

export default SocialMedia;
