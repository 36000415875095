import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import Footer from "../../../components/Footer/Footer";
import DMPForm from "./DMPForm";
import URLParser from "../../../components/URLParser";
import { getDMPDocsAPI } from "../../../utils/apis/api/auth_api_dmp";
//CSS
import "./DecissionProcess.css";
//JS
import NavBar from "../../../components/Navbar/NavBar";
import DPMTables from "./DPMTables";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://admin.songdrop.band/dmp

//DecissionProcess
function DecissionProcess(props) {
  const [page, setPage] = useState("tables");
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  const [tableData, setTableData] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Decission Process Documentation | Admin SongDrop";
    //api
    // getData();
    const queryParams = URLParser();
    const tab = queryParams.tab;

    // console.log(tab); // This will log "form" to the console.
    getDMPtableData();
  }, []);

  //
  function changePage(page) {
    setPage(page);
    //
    switch (page) {
      case "tables":
        getDMPtableData();
      default:
        break;
    }
  }

  //API
  const getDMPtableData = async () => {
    setApiWorking(true);
    //
    try {
      var bodyParams = [];
      bodyParams.push(`page=${0}`);
      bodyParams.push(`limit=${50}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      let result = await getDMPDocsAPI(bParameters);
      //✅SUCCESS
      let result_ = result["docs"];
      setTableData(result_);
      console.log("RESULT", result_);
      //
      setApiWorking(false);
    } catch (error) {
      console.log("ERROR", error.message);
      //🚫ERROR
      setApiWorking(false);
    }
  };

  //VIEW
  return (
    <div className="dmp-body">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="dmp-content">
        <div className="dmp-select-tab">
          <button
            className={`dmp-tab ${page === "tables" ? "active" : ""}`}
            onClick={() => changePage("tables")}
          >
            Documents
          </button>
          <button
            className={`dmp-tab ${page === "form" ? "active" : ""}`}
            onClick={() => changePage("form")}
          >
            New Form
          </button>
        </div>
        {page === "form" && <DMPForm user={props.user} />}
        {page === "tables" && (
          <div className="dmp-tables">
            <div className="dmp-message">
              <h1>Welcome to our Decision-Making Documents section. </h1>Here,
              you'll find a comprehensive collection of policies and guidelines
              that guide our platform's operations, ensure legal compliance, and
              protect user rights. Explore these documents to better understand
              how we make critical decisions and maintain a safe and responsible
              online environment for our community.
            </div>
            <DPMTables isApiWorking={isApiWorking} tableData={tableData} />
          </div>
        )}
      </div>
    </div>
  );
}

export default DecissionProcess;
