import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getFAQCategories } from "../../../utils/apis/api/auth_api_faq";

//FaqCategoryAsyncSelect class
function FaqCategoryAsyncSelect(props) {
  //height of element
  const selectHeight = 30;

  //Category
  const [selectedCategory, setSelectedCategory] = useState(
    props.selectedCategory || null
  );
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isCatLoading, setCatLoading] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //api
    // getData()
  }, []);

  async function getData() {
    setCatLoading(true);
    try {
      let result = await getFAQCategories();
      let category = result["category"];
      let selCat = {
        label: category["title"],
        value: category["category_id"],
      };
      setCategoryOptions([selCat]);
      setSelectedCategory(selCat);
      selectValue(selCat);
      setCatLoading(false);
    } catch (error) {
      setCatLoading(false);
      console.log(error.message);
    }
  }

  //OnChange
  function _onChange(object, { action }) {
    switch (action) {
      case "input-change":
        if (object) selectValue(object);
        return;
      case "menu-close":
        return;
      case "clear":
        return;
      case "select-option":
        if (object) selectValue(object);
        return;
      default:
        return;
    }
  }

  //Select value
  function selectValue(object) {
    setSelectedCategory(object);
    props.selectedValue(object);
  }

  //Api call: get categories
  async function getCategoryOptionsAsync() {
    setCatLoading(true);
    try {
      let response = await getFAQCategories();
      setCategoryOptions(
        response["categories"].map((category) => ({
          label: category.title,
          value: category.category_id,
        }))
      );
      setCatLoading(false);
    } catch (error) {
      console.error(error);
      setCatLoading(false);
    }
  }

  //VIEW
  return (
    <Select
      styles={getStyle()}
      value={selectedCategory}
      options={categoryOptions}
      onFocus={getCategoryOptionsAsync}
      onChange={_onChange}
      isLoading={isCatLoading}
      isMulti={false}
      placeholder="Select a category"
    />
  );

  //GET: style of component
  function getStyle() {
    return {
      control: (base) => ({
        ...base,
        minHeight: selectHeight,
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: 4,
      }),
      clearIndicator: (base) => ({
        ...base,
        padding: 4,
      }),
      multiValue: (base) => ({
        ...base,
        // backgroundColor: variables.colorPrimaryLighter,
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "0px 6px",
      }),
      input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
      }),
    };
  }
}

export default FaqCategoryAsyncSelect;
