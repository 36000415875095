import React, { useState } from "react";

const StatisticSelect = ({ handleSelect }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    handleSelect(value);
  };

  return (
    <select
      className="optionSelect"
      value={selectedOption}
      onChange={handleChange}
    >
      <option value="today">Today</option>
      <option value="yesterday">Yesterday</option>
      <option value="this_week">This Week</option>
      <option value="this_month">This Month</option>
      <option value="this_quarter">This Quarter</option>
      <option value="half_year">This Half a Year</option>
      <option value="this_year">This Year</option>
    </select>
  );
};

export default StatisticSelect;
