import React, { useEffect, useState } from "react";

//CSS
import "./EventFormListItem.css";

//EventFormListItem React Class
function EventFormListItem(props) {
  //ViewDidLoad
  useEffect(() => {
    //title
  }, []);

  function buttonWasClicked(type) {
    props.selectButtonType(type);
  }
  //VIEW
  return (
    <div
      className="eventlistitem"
      onClick={() => buttonWasClicked(props.button.type)}
    >
      <div className="left">
        <img src={props.button.image} alt="" />
      </div>
      <div className="right">
        <div className="title">{props.button.title}</div>
        <div className="subtitle">{props.button.description}</div>
      </div>
    </div>
  );
}

export default EventFormListItem;
