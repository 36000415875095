import React, { useEffect, useRef, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import Footer from "../../../components/Footer/Footer";
import GithubIssues from "./GithubIssues";
//CSS
import "./Copyrights.css";
//JS
import NavBar from "../../../components/Navbar/NavBar";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://admin.songdrop.band

//Dashboard
function Copyrights(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Copyrights | Admin SongDrop";
    //api
    // getData();
  }, []);

  //VIEW
  return (
    <div>
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      <div className="copyrights-body">
        <GithubIssues />
      </div>
    </div>
  );
}

export default Copyrights;
