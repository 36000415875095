import React from "react";
import QRCode from "react-qr-code";
import toHHMMSS from "../../../../components/toHHMMSS";
import isEmpty from "../../../../components/isEmpty";
//logo
import sdlogo from "../../../../assets/img/songdrop-logo-black.png";
import sdlogowhite from "../../../../assets/img/songdrop-logo-white.png";

//css
import "./EDStyleFour.css";
//json
import about from "../../../../assets/about/about.json";

////EDDesign
function EDStyleFour(props) {
  //CREATE: QR url value
  function generateQrCode() {
    if (!isEmpty(props.album.album_id)) {
      return `${about.qr_url}/${props.album.album_id}`;
    } else {
      return "";
    }
  }
  //VIEW
  return (
    <div className="sdqrmatfour">
      <div className="front-header">
        <div className="title" style={{ fontSize: `${props.fontSize}px` }}>
          {props.album.title}
        </div>
        {props.album.explicit == 1 && (
          <div className="explIcon">{<p>🅴</p>}</div>
        )}
      </div>
      <div className="albumart">
        {props.isBack === false && (
          <div>
            {props.corsEnabled === true && (
              <img alt="" src={returnImage()} crossOrigin="anonymous"></img>
            )}
            {props.corsEnabled === false ||
              (props.corsEnabled === undefined && (
                <img alt="" src={props.album.cover_img_url}></img>
              ))}
            <div className="qrwrapper">
              <div className="qrbox">
                <div className="qrcode">
                  <QRCode id="QRCode" value={generateQrCode()} size={88} />
                </div>
                <p className="scanme">SCAN ME ED</p>
              </div>
            </div>
          </div>
        )}
        {props.isBack === true && (
          <table
            className="qrcardback"
            style={{ fontSize: `${props.trackFontSize}px` }}
          >
            <thead>
              <tr>
                <th className="width80">Track list:</th>
                <th className="width20">{countAllDuration()}</th>
              </tr>
            </thead>
            <tbody>{buildTrackListTable()}</tbody>
          </table>
        )}
      </div>
      <div className="front-bottom">
        <div className="leftside">
          <img alt="" src={sdlogo} />
          <div className="year">{setYear(props.album.released_at)}</div>
        </div>
        <div className="rightside" style={{ paddingTop: props.fontSize - 4 }}>
          <div className="title" style={{ fontSize: `${props.fontSize}px` }}>
            {props.album.title}
          </div>
          <div className="artist" style={{ fontSize: `${props.fontSize}px` }}>
            {props.album.artist}
          </div>
        </div>
      </div>
    </div>
  );

  //CREATE img url path to satisfy html2canvas
  function returnImage() {
    const image = props.album.cover_img_url;
    const timestamp = new Date().getTime();
    const imageWithTimestamp = image.includes("?")
      ? `${image}&v=${timestamp}`
      : `${image}?v=${timestamp}`;
    return imageWithTimestamp;
  }

  //SUM: all track duration on the album
  function countAllDuration() {
    var albumdur = 0;
    var t_count = 0;
    props.album.tracks.map((track) => {
      let dur = parseInt(track.duration);
      t_count += 1;
      albumdur += dur;
    });
    return albumdur !== 0 ? toHHMMSS(albumdur) : `${t_count}▶︎`;
  }

  //BUILD: track list tabledata
  function buildTrackListTable() {
    var tableData = [];
    if (isEmpty(props.album.tracks)) {
      return tableData;
    }
    props.album.tracks.sort(function (left, right) {
      return left.track_number > right.track_number;
    });
    props.album.tracks.map((track, index) => {
      tableData.push(
        <tr key={index}>
          <td className="width80">{`${addZeroUnder10(track.track_number)}. ${
            track.title
          }`}</td>
          {track.duration == "0" && (
            <td className="width20">
              {" "}
              <strong>{`${isEmpty(track.url) ? "00:00" : "LIVE"}`}</strong>
            </td>
          )}
          {track.duration != "0" && (
            <td className="width20">{toHHMMSS(track.duration)}</td>
          )}
        </tr>
      );
    });
    return tableData;
  }

  //get release year
  function setYear(releaseDate) {
    if (isEmpty(releaseDate)) {
      return "";
    }
    let year = new Date().getFullYear();
    return year;
  }

  //DESIGN: 1->01, 2->02, 10->10
  function addZeroUnder10(number) {
    return number < 10 ? `0${number}` : number;
  }
}

export default EDStyleFour;
