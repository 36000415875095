import React, { useEffect, useState } from "react";
import moment from "moment";

//CSS
import "./TimeLine.css";
import data from "./Data";
import NavBar from "../../../components/Navbar/NavBar";
import Chrono from "./Chrono";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const apiFaEnabled = "fa fa-check-square";
const faWarning = "fa fa-exclamation-triangle";
//
const faHorizontal = "fa fa-arrows-h";
const faVertical = "fa fa-arrows-v";
const faDown = "fa fa-arrow-down";
const faUp = "fa fa-arrow-up";
const faLeft = "fa fa-angle-left";
const faRight = "fa fa-angle-right";
const faLast = "fa fa-angle-double-right";
const faFirst = "fa fa-angle-double-left";
const faCalendar = "fa fa-calendar";

//TimeLine
function TimeLine(props) {
  //API
  const [isApiWorking, setIsApiWorking] = useState(false);
  //
  const [tableData, setTableData] = useState([]);
  const [isHorizontal, setHorizontal] = useState(true);
  const [isAscending, setAscending] = useState(true);

  const [selected_index, setSelectedIndex] = useState(-99);
  const [selected_date, setSelectedDate] = useState("03.01.2023");
  //pagingation

  //ViewDidLoad
  useEffect(() => {
    document.title = "Timeline | SongDrop";
    //GET_LAST_ACTIVITY_LIST
    getUserTimeLine(0, 0);
  }, []);

  //GET_MFA_QR
  const getUserTimeLine = async (page, isDateAscending) => {
    setIsApiWorking(false);
  };

  const goToday = () => {
    console.log("GO_TO_TODAY");
  };

  const goFirst = () => {
    console.log("GO_TO_FIRST");
  };

  const goLast = () => {
    console.log("GO_TO_LAST");
  };

  const goNext = () => {
    console.log("GO_TO_NEXT");
  };

  const goPrevious = () => {
    console.log("GO_TO_PREVIOUS");
  };

  const goToDateIndex = (index, date) => {
    setSelectedDate(date);
    setSelectedIndex(index);
    console.log("goToDateIndex");
  };

  //VIEW
  return (
    <div className="timeline">
      <NavBar title={props.title} handleLogout={props.handleLogout} />
      {isApiWorking && (
        <button>
          <i className={apiFaIcon} />
        </button>
      )}
      {!isApiWorking && (
        <div className="timeline-body">
          <div className="horizontal_btn">
            <button
              onClick={() => setHorizontal(!isHorizontal)}
              title="Timeline Dimension"
            >
              <i className={isHorizontal ? faHorizontal : faVertical} />
              {isHorizontal ? "Horizontal" : "Vertical"}
            </button>
            <button
              onClick={() => setAscending(!isAscending)}
              title="Ascending/Descending"
            >
              <i className={isAscending ? faDown : faUp} /> Time
            </button>
            <button onClick={() => goFirst()} title="Go to First">
              <i className={faFirst} />
            </button>
            <button onClick={() => goPrevious()} title="Previous">
              <i className={faLeft} />
            </button>
            <button onClick={() => goNext} title="Next">
              <i className={faRight} />
            </button>
            <button onClick={() => goLast()} title="Go to Last">
              <i className={faLast} />
            </button>
            <button onClick={() => goToday()} title="What's on Today?">
              <i className={faCalendar} />
              {moment().format("DD-MM-YYYY")}
            </button>
          </div>
          {isHorizontal && (
            <div className="horizontal_timeline">
              <Chrono
                selected_date={selected_date}
                items={data}
                mode="HORIZONTAL"
                showAllCardsHorizontal={true}
                goToDateIndex={goToDateIndex}
              />
            </div>
          )}
          {!isHorizontal && (
            <div className="vertical_timeline">
              <Chrono
                items={data}
                mode="VERTICAL"
                showAllVerticalHorizontal={true}
                goToDateIndex={goToDateIndex}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TimeLine;
