import React, { useEffect, useState } from "react";
import SurveyListItem from "./SurveyListItem";

//CSS
import "./SurveyItemSelect.css";

const buttons = [
  {
    type: "introduction",
    image:
      "https://cdn3.iconfinder.com/data/icons/abstract-1/512/introduce_A-1024.png",
    title: "Introduction",
    description: "Select an introduction for your survey",
  },
  {
    type: "radio",
    image:
      "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fcdn.onlinewebfonts.com%2Fsvg%2Fimg_304104.png&f=1&nofb=1&ipt=4de11f99dee4444defecc2ff3a72b1d75113082ad9c37892f18a3f981d1cac79&ipo=images",
    title: "Radio Buttons",
    description: "Select a single answer from a list of options",
  },
  {
    type: "checkbox",
    image:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fopenclipart.org%2Fimage%2F2400px%2Fsvg_to_png%2F213241%2FCheck-box-icon.png&f=1&nofb=1&ipt=95554478b1f2d34895c7141cdb0051d339e36587a4dd159832a205cec100c43c&ipo=images",
    title: "Checkboxes",
    description: "Select multiple answers from a list of options",
  },
  {
    type: "range",
    image:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fstatic.thenounproject.com%2Fpng%2F153064-200.png&f=1&nofb=1&ipt=52e1287be640c9b2fdd2d12c9f83d7ce9d613555e691fdd77cbb67caaf8ee66a&ipo=images",
    title: "Range",
    description: "Select a score from a range",
  },
  {
    type: "text",
    image:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn3.iconfinder.com%2Fdata%2Ficons%2Fminicons-for-web-sites%2F24%2Fminicons-04-512.png&f=1&nofb=1&ipt=1f70f61881e48a84050c938d187237925a81c558b5ca60a772d074be246a3eae&ipo=images",
    title: "Text",
    description: "Provide an open text answer",
  },
  {
    type: "email",
    image:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flogodix.com%2Flogo%2F14646.png&f=1&nofb=1&ipt=b071c6f2c93bca49b65718a798440b6e83f0aca35737632663b9dd40a9c08f19&ipo=images",
    title: "Email",
    description: "Provide an email to be added to your audience",
  },
];

//SurveyItemSelect React Class
function SurveyItemSelect(props) {
  //ViewDidLoad
  useEffect(() => {
    //title
  }, []);

  function selectButtonType(type) {
    props.createNewSurveyWithType(type);
  }

  //VIEW
  return (
    <div className="surveyitemselect">
      {buttons.map((button, index) => (
        <SurveyListItem
          index={index}
          button={button}
          selectButtonType={selectButtonType}
        />
      ))}
    </div>
  );
}

export default SurveyItemSelect;
